import { call, put, takeLatest } from "redux-saga/effects";
import { updateSessionSuccess } from "../actions/auth";
import Api from "src/services/Api";
import { hideModal, showModal } from "../actions/modal";
import { UserGroupInvitationStatus } from "src/services/Api/enums";
import { setFullPageLoading } from "../actions/global";
import { clearSession } from "../actions";
import { MODAL_ADD_USER, MODAL_CONFIRMATION, MODAL_TOAST } from "src/components/modals/modalTypes";
import {
  ADD_CO_OWNER_ONBOARDING,
  AddCoOwnerOnboardingAction, addedCoOwnerOnboarding,
  DELETE_CO_OWNER_ONBOARDING,
  DeleteCoOwnerOnboardingAction,
  isAddingCoOwner,
  isDeletingCoOwner
} from "../actions/onboarding";
import { resetCSRFTokenSaga } from "./auth";
import { ErrorMessages, ToastType } from "src/components/shared/enums";
import {
  cancelingInvitation,
  cancelInvitationComplete,
  userAccountDeleted, userAccountDeletedCompleted,
  userAccountRemoved, userAccountRemovedCompleted
} from "../actions/coOwners";
import { captureMessage } from "@sentry/react";

export default function* onboardingSaga() {
  yield takeLatest(DELETE_CO_OWNER_ONBOARDING, deleteCoOwnerSaga);
  yield takeLatest(ADD_CO_OWNER_ONBOARDING, addCoOwnerSaga);
}

function* deleteCoOwnerSaga({ payload }: DeleteCoOwnerOnboardingAction) {
  try {
    yield put(isDeletingCoOwner(true));
    const { index, users } = payload;
    const cancelInvitation =
      users[index].status === UserGroupInvitationStatus.Invited;
    const {
      data: { success, eventId, ...session }
    } = cancelInvitation
      ? yield call(Api.deleteInvitation, users[index].id)
      : yield call(Api.deleteGroupMember, users[index].id);

    yield put(isDeletingCoOwner(false));
    yield put(updateSessionSuccess(session));
    yield put(hideModal(MODAL_CONFIRMATION));

    // TODO: Move to use hooks
    if (eventId) {
      // segmentTrack(SegmentEvents.RemoveUserRequestSubmitted);
    } else {
      // segmentTrack(
      //   cancelInvitation
      //     ? SegmentEvents.UserInvitationRemoved
      //     : payload.isSelf
      //     ? SegmentEvents.UserRemovedItsAccount
      //     : SegmentEvents.UserRemoved
      // );

      if (cancelInvitation) {
        yield put(cancelingInvitation());
      } else {
        if (payload.isSelf){
          yield put(userAccountDeleted())
        } else {
          yield(userAccountRemoved())
        }
      }

      if (payload.isSelf) {
        yield put(setFullPageLoading(true));

        // Clean session
        yield put(clearSession());

        // Reset CSRF token
        yield call(resetCSRFTokenSaga);

        // Hide global loader
        yield put(setFullPageLoading(false));
      }

      const message = cancelInvitation
        ? "The invitation has been canceled."
        : payload.isSelf
          ? `Your account has been removed.`
          : `${users[index].firstName} ${users[index].lastName} has been removed from your group.`;
      yield put(showModal(MODAL_TOAST, { message }));
      yield put(cancelInvitationComplete());
      yield put(userAccountDeletedCompleted())
      yield put(userAccountRemovedCompleted())
    }
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    if (error.status === 409)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error,
          autoDeleteTime: 10000
        })
      );
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );

    yield put(isDeletingCoOwner(false));
    yield put(hideModal(MODAL_CONFIRMATION));
  }
}

function* addCoOwnerSaga(action: AddCoOwnerOnboardingAction) {
  try {
    yield put(isAddingCoOwner(true));
    const {
      data: { success, eventId, ...session }
    } = yield call(Api.addGroupMember, action.payload.member);
    if (eventId || success) {
      yield put(addedCoOwnerOnboarding())
      yield put(updateSessionSuccess(session));
    } else {
      yield put(
        showModal(MODAL_TOAST, {
          message: `${action.payload.member.firstName} has been invited to your group`
        })
      );
      yield put(updateSessionSuccess(session));
    }
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    if (error.status === 403)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.WrongInputs,
          type: ToastType.Error
        })
      );
    else if (error.status === 409)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error
        })
      );
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
  } finally {
    yield put(hideModal(MODAL_ADD_USER));
    yield put(isAddingCoOwner(false));
  }
}
