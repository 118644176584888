import { GroupDto, Invitations, UserAccountDto } from "src/services/Api/types";
import {
  GroupInvitationStatus,
  UserGroupInvitationStatus,
  UserRelationships,
} from "src/services/Api/enums";

export const userRelationshipOptions = [
  { id: UserRelationships.Family, value: "Family" },
  { id: UserRelationships.Friend, value: "Friends" },
  { id: UserRelationships.Partner, value: "Partner" },
  { id: UserRelationships.Spouse, value: "Spouse" },
];

export type GroupStatusResponse = {
  userRelationship: undefined;
  firstName: "";
  email: "";
};

export enum WaitingStates {
  JustCreated = "JustCreated",
  WaitingForSignUp = "WaitingForSignUp",
  WaitingForVerifiedId = "WaitingForVerifiedId",
}

type TitleStates = Record<
  WaitingStates,
  {
    title: string;
    boldTitle: string;
    description: string;
  }
>;

export const titlesState: TitleStates = {
  [WaitingStates.JustCreated]: {
    title: "Welcome to your",
    boldTitle: "co-ownership group!",
    description:
      "Once all co-owners sign up, you’ll be able to verify your identity, create an agreement, and manage co-ownership.",
  },
  [WaitingStates.WaitingForSignUp]: {
    title: "Welcome to your",
    boldTitle: "co-ownership group!",
    description:
      "Once all co-owners sign up, you’ll be able to verify your identity, create an agreement, and manage co-ownership.",
  },
  [WaitingStates.WaitingForVerifiedId]: {
    title: "Your co-ownership group is",
    boldTitle: "not yet fully verified",
    description:
      "Once all co-owners have verified their ID, you'll be able to create an agreement and manage co-ownership.",
  },
};

export const getUsersWithInvitationStatus = (
  group: GroupDto,
  user: UserAccountDto
): Invitations => {
  const usersAlreadySignUp: Invitations =
    group?.users.map((x: any) => ({
      id: x.id,
      email: x.email,
      firstName: x.firstName,
      lastName: x.lastName,
      status: !group?.allUsersSignedUp
        ? UserGroupInvitationStatus.Joined
        : x.isIdentityVerified
        ? UserGroupInvitationStatus.Verified
        : UserGroupInvitationStatus.NotVerified,
    })) || [];
  const usersNoSignUp: Invitations =
    group?.invitations.map((x: any) => ({
      id: x.id,
      email: x.invitedUserEmail,
      firstName: x.invitedUserFirstName,
      lastName: "",
      status:
        x.status === GroupInvitationStatus.Completed && x.opened
          ? UserGroupInvitationStatus.CompletingProfile
          : UserGroupInvitationStatus.Invited,
    })) || [];
  const list = [...usersAlreadySignUp, ...usersNoSignUp];
  const userLoggedIn = list.filter((x) => x.email === user?.email) || [];
  const otherUsers = list.filter((x) => x.email !== user?.email) || [];
  return [...userLoggedIn, ...otherUsers];
};
