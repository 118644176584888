import { useDispatch } from "react-redux";
import { setEditingProfile } from "src/store/actions/profile";
import IconButton from "./IconButton";

function EditProfileButton() {
  const dispatch = useDispatch();
  const editProfile = () => dispatch(setEditingProfile(true));

  return (
    <IconButton
      iconClassName="icon-edit"
      className="edit-button"
      onClick={editProfile}
    />
  );
}

export default EditProfileButton;
