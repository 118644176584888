import MultiStepForm from "src/components/MultiStepForm";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import ConfirmationData from "./steps/ConfirmationData";
import CheckCoOwnedHomeAddressData from "./steps/CheckCoOwnedHomeAddressData";
import CheckCoOwnedHomePurchaseDateData from "./steps/CheckCoOwnedHomePurchaseDateData";
import CoOwnersTitleData from "./steps/CoOwnersTitleData";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Storage from "src/services/LocalStorage";
import { useDispatch } from "react-redux";
import { setFullPageLoading } from "src/store/actions/global";
import Api from "src/services/Api";
import FailedStep from "src/components/MultiStepForm/FailedStep";
import { Path } from "src/components/routing/routes";
import { SignUpFormData } from "../Leader";
import CreateAccount from "../Leader/steps/CreateAccount";
import VerifyEmail from "../Leader/steps/VerifyEmail";
import MobilePhoneNumberData from "../Leader/steps/MobilePhoneNumberData";
import VerifyPhone from "../Leader/steps/VerifyPhone";
import { captureMessage } from "@sentry/react";

function MemberSignUpForm() {
  const { invitationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [failedTitle, setFailedTitle] = useState("");
  const [failedDescription, setFailedDescription] = useState("");

  useEffect(() => {
    validateInvitationId();
  }, []);

  const validateInvitationId = async () => {
    try {
      dispatch(setFullPageLoading(true));
      const { data } = await Api.getInvitationMetadata(invitationId || "");
      Storage.setItem("groupAddressData", data.data);
      Storage.setItem("invitationId", invitationId);
    } catch (error: any) {
      captureMessage(JSON.stringify(error))
      if (error.status === 400) {
        navigate(Path.Login);
      } else if (error.status === 404) {
        setFailedTitle("This invitation is no longer available");
      } else if (error.status === 410) {
        setFailedTitle("This link expired");
        setFailedDescription(
          "For security, we've retired this link. Please try again so we can send you a new one."
        );
      } else {
        setFailedTitle("Something went wrong, please try again later.");
      }
      setFailed(true);
    } finally {
      Storage.removeItem("coOwnedHomePurchaseClosingDate");
      Storage.removeItem("coOwnedHomeAddress");
      dispatch(setFullPageLoading(false));
      setIsLoading(false);
    }
  };

  if (isLoading) return <></>;

  return failed ? (
    <FailedStep
      title={failedTitle}
      description={failedDescription}
      nextText="OK"
      onNextClick={() => navigate(Path.Home)}
    />
  ) : (
    <MultiStepForm<SignUpFormData>
      totalSteps={10}
      formName="memberPreSignUp"
      stepsNumbersForProgress={[2, 3, 4, 5]}
    >
      {({ ...props }) => {
        switch (props.formStep) {
          case 1:
            return (
              <InitialStep
                title="Let’s make sure"
                boldTitle="you’re joining the correct co-ownership group"
                description="Before creating a personal account, we need to confirm a few details."
                primaryButtonText="Get Started"
                onPrimaryButtonClick={props.nextStep}
              />
            );
          case 2:
            return <CheckCoOwnedHomeAddressData {...props} />;
          case 3:
            return <CoOwnersTitleData {...props} />;
          case 4:
            return <CheckCoOwnedHomePurchaseDateData {...props} />;
          case 5:
            return <ConfirmationData {...props} />;
          case 6:
            return (
              <InitialStep
                title="Fantastic! Now"
                boldTitle="let’s set up your account."
                description="After setting up your account, you'll join your co-ownership group on CoBuy."
                primaryButtonText="Next"
                onPrimaryButtonClick={props.nextStep}
                iconClass="icon-check-circled"
              />
            );
          case 7:
            return <CreateAccount {...props} />;
          case 8:
            return <VerifyEmail {...props} />;
          case 9:
            return <MobilePhoneNumberData {...props} />;
          case 10:
            return <VerifyPhone {...props} />;
          default:
            break;
        }
      }}
    </MultiStepForm>
  );
}

export default MemberSignUpForm;
