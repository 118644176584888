import React from "react";

type FailProps = {
  title: string;
  description?: string;
  buttonText: string;
  onButtonClick: () => void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
};

function Fail({
  title,
  description,
  buttonText,
  onButtonClick,
  children,
  footer,
}: FailProps) {
  return (
    <div className="fail-step">
      <div className="">
        <i className="icon-cross-circled" />
        <h3>{title}</h3>
        {description && <p>{description}</p>}
        {children}

        <div className="bottom-fixed">
          <div className="button-center">
            {footer}
            <button onClick={onButtonClick}>{buttonText}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fail;
