import { call, put, takeLatest } from "redux-saga/effects";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import {
  DocumentStatus,
  ErrorMessages,
  ToastType,
} from "src/components/shared/enums";
import Api from "src/services/Api";
import { DocumentCardType } from "src/services/Api/types";
import { updateSession } from "../actions/auth";
import {
  getDashboardInfoRejected,
  getDashboardInfoSuccess,
  GET_DASHBOARD_INFO,
} from "../actions/dashboard";
import { showModal } from "../actions/modal";
import { updateSessionSaga } from "./auth";
import { captureMessage } from "@sentry/react";

export default function* dashboardSaga() {
  yield takeLatest(GET_DASHBOARD_INFO, getDashboardInfoSaga);
}

// GET DASHBOARD INFO
export function* getDashboardInfoSaga() {
  try {
    const { data } = yield call(Api.getDashboard);
    yield put(getDashboardInfoSuccess(data.dashboard));
    if (
      data.dashboard.agreementStatus.find(
        (agreement: DocumentCardType) =>
          agreement.status !== DocumentStatus.Executed
      )
    )
      yield call(updateSessionSaga, updateSession(false, false, false));
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(getDashboardInfoRejected());
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
  } finally {
  }
}
