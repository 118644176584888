import Tooltip from "../Tooltip";
import parse from "html-react-parser";
import { maskPhoneNumber } from "src/utils/helpers/masks";

type PhoneInputProps = {
  id?: string;
  label?: string;
  value?: string;
  onChange?: (event: any) => void;
  name?: string;
  pattern?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  minLength?: number;
  isDisabled?: boolean;
  tooltip?: string;
};

function PhoneInput({
  id,
  label,
  name,
  value,
  onChange,
  required,
  readOnly,
  pattern,
  minLength,
  isDisabled,
  tooltip,
}: PhoneInputProps) {
  const _onChange = (ev: any) => {
    const re = /^[0-9\b]+$/;
    let val = ev.target.value;
    val = val.replace("(", "").replace(")", "").replace(/ /gm, "");
    if (val === "" || re.test(val)) {
      if (onChange) {
        onChange(val);
      }
    }
  };

  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`text-input-container ${disabledClass}`}>
      {label && id && (
        <label htmlFor={id}>
          {parse(label)}
          <Tooltip content={tooltip} />
        </label>
      )}
      <input
        id={id}
        name={name}
        type="tel"
        value={maskPhoneNumber(value)}
        onChange={_onChange}
        pattern={pattern}
        readOnly={readOnly}
        required={required}
        minLength={minLength}
        maxLength={14}
        placeholder="(123) 456 7890"
        disabled={isDisabled}
        onKeyPress={(e) => e.code === "Enter" && e.preventDefault()}
      />
    </div>
  );
}

export default PhoneInput;
