type EmptyStateProps = {
  title: string;
  description?: string;
  iconClass: string;
};

function EmptyState({ title, description, iconClass }: EmptyStateProps) {
  return (
    <div className="empty-state">
      <i className={iconClass}></i>
      <span>{title}</span>
      {description && <p>{description}</p>}
    </div>
  );
}

export default EmptyState;
