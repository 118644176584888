type PdfViewerProps = { src?: string; className?: string };

function PdfViewer({ src = "", className = "" }: PdfViewerProps) {
  return (
    <iframe title="pdf" className={`doc-viewer ${className}`} src={src}>
      <div className="no-viewer">
        <p>No online PDF viewer installed. </p>
        <p>
          Please{" "}
          <a href={src} rel="noreferrer" target={"_blank"}>
            {" "}
            click here
          </a>{" "}
          to download it
        </p>
      </div>
    </iframe>
  );
}

export default PdfViewer;
