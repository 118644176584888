import { Invitations, GroupInvitationMember } from "src/services/Api/types";

// DELETE CO-OWNER
export const DELETE_CO_OWNER_ONBOARDING = "DELETE_CO_OWNER_ONBOARDING";
export const DELETE_CO_OWNER_ONBOARDING_SUCCESS =
  "DELETE_CO_OWNER_ONBOARDING_SUCCESS";
export const DELETE_CO_OWNER_ONBOARDING_REJECTED =
  "DELETE_CO_OWNER_ONBOARDING_REJECTED";
export interface DeleteCoOwnerOnboardingAction {
  type: typeof DELETE_CO_OWNER_ONBOARDING;
  payload: {
    users: Invitations;
    index: number;
    isSelf: boolean;
  };
}
export const deleteCoOwner = (
  users: Invitations,
  index: number,
  isSelf: boolean
): DeleteCoOwnerOnboardingAction => ({
  type: DELETE_CO_OWNER_ONBOARDING,
  payload: {
    users,
    index,
    isSelf,
  },
});

// SET DELETE CO-OWNER LOADER
export const IS_DELETING_CO_OWNER = "IS_DELETING_CO_OWNER";
export interface IsDeletingCoOwnerAction {
  type: typeof IS_DELETING_CO_OWNER;
  payload: boolean;
}
export const isDeletingCoOwner = (
  loading: boolean
): IsDeletingCoOwnerAction => ({
  type: IS_DELETING_CO_OWNER,
  payload: loading,
});

// ADD CO-OWNER
export const ADD_CO_OWNER_ONBOARDING = "ADD_CO_OWNER_ONBOARDING";
export const ADD_CO_OWNER_ONBOARDING_SUCCESS =
  "ADD_CO_OWNER_ONBOARDING_SUCCESS";
export const ADD_CO_OWNER_ONBOARDING_REJECTED =
  "ADD_CO_OWNER_ONBOARDING_REJECTED";
export interface AddCoOwnerOnboardingAction {
  type: typeof ADD_CO_OWNER_ONBOARDING;
  payload: {
    member: GroupInvitationMember;
  };
}
export const addCoOwner = (
  member: GroupInvitationMember
): AddCoOwnerOnboardingAction => ({
  type: ADD_CO_OWNER_ONBOARDING,
  payload: {
    member,
  },
});

// SET ADD CO-OWNER LOADER
export const IS_ADDING_CO_OWNER = "IS_ADDING_CO_OWNER";
export interface IsAddingCoOwnerAction {
  type: typeof IS_ADDING_CO_OWNER;
  payload: boolean;
}
export const isAddingCoOwner = (loading: boolean): IsAddingCoOwnerAction => ({
  type: IS_ADDING_CO_OWNER,
  payload: loading,
});

// SET CO-OWNER ADDED
export const ADDED_CO_OWNER_ONBOARDING = "ADDED_CO_OWNER_ONBOARDING";
export interface addedCoOwnerOnboardingAction {
  type: typeof ADDED_CO_OWNER_ONBOARDING;
}
export const addedCoOwnerOnboarding = (): addedCoOwnerOnboardingAction => ({
  type: ADDED_CO_OWNER_ONBOARDING,
});

// SET CO-OWNER ADDED
export const RESET_ADD_CO_OWNER = "RESET_ADD_CO_OWNER";
export interface resetAddCoOwnerAction {
  type: typeof RESET_ADD_CO_OWNER;
}
export const resetAddCoOwner = (): resetAddCoOwnerAction => ({
  type: RESET_ADD_CO_OWNER,
});

// SET STEP LOADER
export const IS_STEP_LOADING = "IS_STEP_LOADING";
export interface IsStepLoadingAction {
  type: typeof IS_STEP_LOADING;
  payload: boolean;
}
export const isStepLoading = (loading: boolean): IsStepLoadingAction => ({
  type: IS_STEP_LOADING,
  payload: loading,
});
