import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CoOwnershipAgreementItem from "src/components/cards/AgreementStatusCard";
import FilterBar from "src/components/FilterBar";
import { StatusFilter } from "src/services/Api/enums";
import { updatePermissions } from "src/store/actions/auth";
import { setMainHeader } from "src/store/actions/global";
import {
  setAgreementStepStatus,
  setCurrentStepStatus,
} from "src/store/actions/governance";
import {
  Page,
  GovernancePermission,
  DashboardPermission,
} from "src/utils/helpers/permissions";
import { filters, filterStatus } from "src/utils/helpers/filters";
import { RootState } from "src/store/reducers";
import { getStatusIcon } from "src/utils/helpers/governance";
import {
  CoOwnershipAgreementItemStatus,
  DeviceType,
} from "src/components/shared/enums";
import EmptyState from "src/components/EmptyState";
import useInterval from "src/utils/customHooks/useInterval";
import DesktopNav from "src/components/DesktopNav";
import useDeviceType from "src/utils/customHooks/useDeviceType";

function CoOwnershipAgreementSteps() {
  const dispatch = useDispatch();

  const { page } = useSelector(({ auth }: RootState) => auth);
  const { agreementStepsStatus, currentSection } = useSelector(
    ({ governance }: RootState) => governance
  );
  const [filter, setFilter] = useState(StatusFilter.AllItems);
  const deviceType = useDeviceType();
  const permission =
    page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;
  const isMobile = deviceType === DeviceType.Mobile;

  console.log({currentSection})
  useEffect(() => {
    dispatch(
      setMainHeader({
        title: currentSection?.title ?? null,
        showMenuDrawerOnDesktop: true,
        onBackButtonClick: isMobile
          ? () =>
              dispatch(
                updatePermissions({
                  page,
                  permission: permission.SECTIONS_STATUS,
                })
              )
          : undefined,
        showNotificationsButton: true,
      })
    );
    currentSection &&
      dispatch(setAgreementStepStatus(currentSection.code, true));
  }, [isMobile]);

  useInterval(() => {
    currentSection &&
      dispatch(setAgreementStepStatus(currentSection.code, false));
  }, 30000);

  if (!agreementStepsStatus) return <></>;

  const showFilter = agreementStepsStatus.every(
    (step) =>
      step.status !== CoOwnershipAgreementItemStatus.NotStarted &&
      step.status !== CoOwnershipAgreementItemStatus.InProgress &&
      step.status !== CoOwnershipAgreementItemStatus.Done
  );

  const filteredSteps =
    agreementStepsStatus.filter((step) =>
      filterStatus[filter].includes(step.status)
    ) || [];

  return (
    <div className="agreement-status-wrapper">
      <div className="agreement-status steps">
        <DesktopNav
          title={currentSection?.title || ""}
          backButton={{
            onBackButtonClick: () =>
              dispatch(
                updatePermissions({
                  page,
                  permission: permission.SECTIONS_STATUS,
                })
              ),
          }}
        />
        {showFilter && (
          <FilterBar
            filters={filters}
            defaultFilter={StatusFilter.AllItems}
            filterItems={setFilter}
          />
        )}
        {filteredSteps.length ? (
          filteredSteps.map(
            ({
              id,
              code,
              title,
              statusText,
              hasNewComments,
              numberOfComments,
              status,
            }) => (
              <CoOwnershipAgreementItem
                key={id}
                iconClass={getStatusIcon(status)}
                title={title}
                statusText={statusText}
                onClick={() => dispatch(setCurrentStepStatus(code))}
                status={status}
                hasNewComments={hasNewComments}
                numberOfComments={numberOfComments}
              />
            )
          )
        ) : (
          <EmptyState title="No items here" iconClass="icon-check-circled" />
        )}
      </div>
    </div>
  );
}

export default CoOwnershipAgreementSteps;
