import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import useSubscription from "src/utils/customHooks/useSubscription";
import { PaymentFormData } from "..";

function SubscriptionPlan({
  nextStep,
}: MultiStepFormChildrenProps<PaymentFormData>) {
  const { subscribe } = useSubscription({
    onSuccess,
  });

  function onSuccess(step?: number) {
    if(step) nextStep(step);
    else nextStep();
  }

  return (
    <InitialStep
      boldTitle="Subscribe"
      titleAfterBold="to get started"
      iconClass="icon-payment"
      primaryButtonText="Next"
      onPrimaryButtonClick={subscribe}
    />
  );
}

export default SubscriptionPlan;
