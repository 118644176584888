import { Session } from "src/services/Api/types";

export type Permissions = Partial<Record<Page, Array<PagePermission>>>;

export type PagePermission =
  | OnboardingPermission
  | CoOwnersPermission
  | GovernancePermission
  | ProfilePermission
  | ChatPermission
  | DashboardPermission
  | DocumentVaultPermission;

// PAGES
export enum Page {
  ONBOARDING = "ONBOARDING",
  CO_OWNERS = "CO_OWNERS",
  GOVERNANCE = "GOVERNANCE",
  PROFILE = "PROFILE",
  CHAT = "CHAT",
  DASHBOARD = "DASHBOARD",
  DOCUMENT_VAULT = "DOCUMENT_VAULT",
}

// PERMISSIONS WITHIN EACH PAGE
export enum OnboardingPermission {
  INDIVIDUAL_INPUTS = "INDIVIDUAL_INPUTS",
  GROUP_CREATION = "GROUP_CREATION",
  VERIFICATION_ID = "VERIFICATION_ID",
  GROUP_STATUS = "GROUP_STATUS",
  ALL_MEMBERS_VERIFIED = "ALL_MEMBERS_VERIFIED",
}
export enum GovernancePermission {
  INITIAL_SCREEN = "INITIAL_SCREEN",
  SECTIONS_INDEX = "SECTIONS_INDEX",
  SECTION_STEPS = "SECTION_STEPS",
  SECTIONS_STATUS = "SECTIONS_STATUS",
  STEPS_STATUS = "STEPS_STATUS",
  FINALIZE_CO_OWNERSHIP_AGREEMENT = "FINALIZE_CO_OWNERSHIP_AGREEMENT",
  SIGN_CO_OWNERSHIP_AGREEMENT = "SIGN_CO_OWNERSHIP_AGREEMENT",
  CO_OWNERSHIP_AGREEMENT_STATUS = "CO_OWNERSHIP_AGREEMENT_STATUS",
  FINALIZE_MEMORANDUM_OF_AGREEMENT = "FINALIZE_MEMORANDUM_OF_AGREEMENT",
  SIGN_MEMORANDUM_OF_AGREEMENT = "SIGN_MEMORANDUM_OF_AGREEMENT",
  MEMORANDUM_OF_AGREEMENT_STATUS = "MEMORANDUM_OF_AGREEMENT_STATUS",
}
export enum CoOwnersPermission {
  CO_OWNERS = "CO_OWNERS",
}
export enum ProfilePermission {
  MY_PROFILE = "MY_PROFILE",
}
export enum ChatPermission {
  CHAT = "CHAT",
}
export enum DashboardPermission {
  DASHBOARD = "DASHBOARD",
  SECTIONS_INDEX = "SECTIONS_INDEX",
  SECTION_STEPS = "SECTION_STEPS",
  SECTIONS_STATUS = "SECTIONS_STATUS",
  STEPS_STATUS = "STEPS_STATUS",
  FINALIZE_CO_OWNERSHIP_AGREEMENT = "FINALIZE_CO_OWNERSHIP_AGREEMENT",
  SIGN_CO_OWNERSHIP_AGREEMENT = "SIGN_CO_OWNERSHIP_AGREEMENT",
  CO_OWNERSHIP_AGREEMENT_STATUS = "CO_OWNERSHIP_AGREEMENT_STATUS",
  FINALIZE_MEMORANDUM_OF_AGREEMENT = "FINALIZE_MEMORANDUM_OF_AGREEMENT",
  SIGN_MEMORANDUM_OF_AGREEMENT = "SIGN_MEMORANDUM_OF_AGREEMENT",
  MEMORANDUM_OF_AGREEMENT_STATUS = "MEMORANDUM_OF_AGREEMENT_STATUS",
}
export enum DocumentVaultPermission {
  DOCUMENT_VAULT = "DOCUMENT_VAULT",
  DOCUMENT = "DOCUMENT",
  DOCUMENT_HISTORY = "DOCUMENT_HISTORY",
}

// ALL PAGE PERMISSIONS AVAILABLE TO THE USER
export const getPermissions = ({ group, user }: Session): Permissions => {
  const atLeastTwoMembers = group && group.users.length >= 2;

  // Dashboard
  if (user?.hasAccessDashboard) {
    return {
      [Page.DASHBOARD]: getDashboardPermissions(),
      [Page.DOCUMENT_VAULT]: getDocumentVaultPermissions(),
      [Page.CHAT]: getChatPermissions(),
      [Page.CO_OWNERS]: getCoOwnersPermissions(),
      [Page.PROFILE]: getProfilePermissions(),
    };
  }
  // Governance
  if (
    (group?.allMembersVerified && group.isSubscribed) ||
    (user?.isIdentityVerified &&
      user?.hasStartedCoAgreement &&
      atLeastTwoMembers)
  ) {
    return {
      [Page.GOVERNANCE]: getGovernancePermissions(),
      [Page.CHAT]: getChatPermissions(),
      [Page.CO_OWNERS]: getCoOwnersPermissions(),
      [Page.PROFILE]: getProfilePermissions(),
    };
  }
  // Onboarding
  return {
    [Page.ONBOARDING]: getOnboardingPermissions({ user, group }),
  };
};

// DEFAULT PAGE
export const getDefaultPage = ({ group, user }: Session): Page => {
  const atLeastTwoMembers = !!group && group.users.length >= 2;

  // Dashboard
  if (user?.hasAccessDashboard) return Page.DASHBOARD;
  // Governance
  if (
    (group?.allMembersVerified && group.isSubscribed) ||
    (user?.isIdentityVerified &&
      user?.hasStartedCoAgreement &&
      atLeastTwoMembers)
  )
    return Page.GOVERNANCE;
  // Onboarding
  return Page.ONBOARDING;
};

// DEFAULT PERMISSION BY PAGE
export const getDefaultPagePermission = (
  page: Page,
  session: Session
): PagePermission =>
  ({
    [Page.ONBOARDING]: getDefaultOnboardingPermission(session),
    [Page.GOVERNANCE]: getDefaultGovernancePermission(session),
    [Page.DASHBOARD]: getDefaultDashboardPermission(),
    [Page.DOCUMENT_VAULT]: getDefaultDocumentVaultPermission(),
    [Page.CHAT]: getDefaultChatPermission(),
    [Page.CO_OWNERS]: getDefaultCoOwnersPermission(),
    [Page.PROFILE]: getDefaultProfilePermission(),
  }[page]);

// DEFAULT PERMISSION
export const getDefaultOnboardingPermission = ({
  user,
  group,
}: Session): OnboardingPermission => {
  if (group?.allMembersVerified)
    return OnboardingPermission.ALL_MEMBERS_VERIFIED;
  if (group?.allUsersSignedUp && user?.isIdentityVerified)
    return OnboardingPermission.GROUP_STATUS;
  if (group?.allUsersSignedUp && !user?.isIdentityVerified)
    return OnboardingPermission.VERIFICATION_ID;
  if (user?.isSignupCompleted && group)
    return OnboardingPermission.GROUP_STATUS;
  if (user?.isSignupCompleted) return OnboardingPermission.GROUP_CREATION;
  return OnboardingPermission.INDIVIDUAL_INPUTS;
};
export const getDefaultGovernancePermission = ({
  user,
}: Session): GovernancePermission => {
  if (user?.hasSignedMemoOfAgreement)
    return GovernancePermission.MEMORANDUM_OF_AGREEMENT_STATUS;
  if (user?.hasSignedCoAgreement)
    return GovernancePermission.CO_OWNERSHIP_AGREEMENT_STATUS;
  if (user?.hasCompletedCoAgreement)
    return GovernancePermission.SECTIONS_STATUS;
  if (user?.hasStartedCoAgreement) {
    return GovernancePermission.SECTIONS_STATUS;
  }
  return GovernancePermission.INITIAL_SCREEN;
};
export const getDefaultCoOwnersPermission = (): CoOwnersPermission => {
  return CoOwnersPermission.CO_OWNERS;
};
export const getDefaultProfilePermission = (): ProfilePermission => {
  return ProfilePermission.MY_PROFILE;
};
export const getDefaultChatPermission = (): ChatPermission => {
  return ChatPermission.CHAT;
};
export const getDefaultDashboardPermission = (): DashboardPermission => {
  return DashboardPermission.DASHBOARD;
};
export const getDefaultDocumentVaultPermission =
  (): DocumentVaultPermission => {
    return DocumentVaultPermission.DOCUMENT_VAULT;
  };

// GET PERMISSIONS
export const getOnboardingPermissions = (
  session: Session
): Array<OnboardingPermission> => {
  return [getDefaultOnboardingPermission(session)];
};
export const getGovernancePermissions = (): Array<GovernancePermission> => {
  return [
    GovernancePermission.INITIAL_SCREEN,
    GovernancePermission.SECTIONS_INDEX,
    GovernancePermission.SECTION_STEPS,
    GovernancePermission.SECTIONS_STATUS,
    GovernancePermission.STEPS_STATUS,
    GovernancePermission.FINALIZE_CO_OWNERSHIP_AGREEMENT,
    GovernancePermission.SIGN_CO_OWNERSHIP_AGREEMENT,
    GovernancePermission.CO_OWNERSHIP_AGREEMENT_STATUS,
    GovernancePermission.FINALIZE_MEMORANDUM_OF_AGREEMENT,
    GovernancePermission.SIGN_MEMORANDUM_OF_AGREEMENT,
    GovernancePermission.MEMORANDUM_OF_AGREEMENT_STATUS,
  ];
};
export const getCoOwnersPermissions = (): Array<CoOwnersPermission> => {
  return [CoOwnersPermission.CO_OWNERS];
};
export const getProfilePermissions = (): Array<ProfilePermission> => {
  return [ProfilePermission.MY_PROFILE];
};
export const getChatPermissions = (): Array<ChatPermission> => {
  return [ChatPermission.CHAT];
};
export const getDashboardPermissions = (): Array<DashboardPermission> => {
  return [
    DashboardPermission.DASHBOARD,
    DashboardPermission.SECTIONS_INDEX,
    DashboardPermission.SECTION_STEPS,
    DashboardPermission.SECTIONS_STATUS,
    DashboardPermission.STEPS_STATUS,
    DashboardPermission.FINALIZE_CO_OWNERSHIP_AGREEMENT,
    DashboardPermission.SIGN_CO_OWNERSHIP_AGREEMENT,
    DashboardPermission.CO_OWNERSHIP_AGREEMENT_STATUS,
    DashboardPermission.FINALIZE_MEMORANDUM_OF_AGREEMENT,
    DashboardPermission.SIGN_MEMORANDUM_OF_AGREEMENT,
    DashboardPermission.MEMORANDUM_OF_AGREEMENT_STATUS,
  ];
};
export const getDocumentVaultPermissions =
  (): Array<DocumentVaultPermission> => {
    return [
      DocumentVaultPermission.DOCUMENT_VAULT,
      DocumentVaultPermission.DOCUMENT_HISTORY,
      DocumentVaultPermission.DOCUMENT,
    ];
  };
