import { Option } from "src/components/shared/types";
import Tooltip from "../Tooltip";

type SimpleSwitchProps = {
  id?: string;
  name?: string;
  label?: string;
  onChange: (value: string) => void;
  selectedId?: string | null;
  options: Array<Option>;
  isDisabled?: boolean;
  tooltip?: string;
};

function SimpleSwitch({
  id,
  name,
  label,
  selectedId,
  onChange,
  options,
  isDisabled = false,
  tooltip,
}: SimpleSwitchProps) {
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`simple-switch ${disabledClass}`}>
      {label && (
        <div className="simple-switch-label">
          {label}
          <Tooltip content={tooltip} />
        </div>
      )}
      <div className="simple-switch-options">
        <input
          id={id}
          name={name}
          className="simple-switch-checkbox"
          type="checkbox"
          onChange={(e) => {
            if (!isDisabled) {
              onChange(e.target.checked ? options[0].id : options[1].id);
            }
          }}
          checked={selectedId === options[0].id}
          disabled={isDisabled}
        />
        <span className="simple-switch-span">
          <div className="left-option">{options[0].value}</div>
          <div className="right-option">{options[1].value}</div>
        </span>
      </div>
    </div>
  );
}

export default SimpleSwitch;
