import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMainHeader } from "src/store/actions/global";
import Spinner from "../Spinner";
import { Path } from "../routing/routes";
import { LocationState } from "../shared/types";

type IframeContainerProps = {
  title: string;
  path: string;
};

function IframeContainer({ title, path }: IframeContainerProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = location.state as LocationState;
  let fromPathname = locationState?.from?.pathname || "";

  useEffect(() => {
    dispatch(
      setMainHeader({
        onBackButtonClick:
          fromPathname === Path.Login ||
          fromPathname === Path.SignUp ||
          fromPathname === Path.Home ||
          fromPathname.split("/")[1] === "invite"
            ? () => navigate(fromPathname)
            : undefined,
        showLogo: true,
      })
    );
  }, []);

  return (
    <div className="terms-and-conditions">
      {loading && <Spinner />}
      <iframe
        title={title}
        src={path}
        onLoad={() => setTimeout(() => setLoading(false), 800)}
      />
    </div>
  );
}

export default IframeContainer;
