import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import { CoOwnedHomeAddressType } from "src/services/Api/types";
import { useEffect } from "react";

type SearchAddressInputProps = {
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  onChange: (address: CoOwnedHomeAddressType) => void;
  address?: string;
  isDisabled?: boolean;
};

const transformToAddressType = (details: any): CoOwnedHomeAddressType => {
  const addressSpitted = details.formatted_address.split(",");
  const [address, city] = addressSpitted;

  const state =
    details.address_components.find((field: any) =>
      field.types.includes("administrative_area_level_1")
    )?.short_name || "";
  const county =
    details.address_components.find((field: any) =>
      field.types.includes("administrative_area_level_2")
    )?.long_name || "";
  const zipCode =
    details.address_components.find((field: any) =>
      field.types.includes("postal_code")
    )?.short_name || "";

  return {
    address: address.trim(),
    vicinity: details?.vicinity ?? "",
    county,
    city: city.trim(),
    state,
    country: "USA",
    zipCode,
  };
};

function SearchAddressInput({
  id,
  label,
  name,
  placeholder,
  onChange,
  address,
  isDisabled = false,
}: SearchAddressInputProps) {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    clearCache,
    value,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  useEffect(() => {
    clearCache();
    address && setValue(address, false);
  }, []);

  const handleInput = (e: any) => {
    if (!isDisabled) {
      setValue(e.target.value);
      if (e.target.value === "" || address !== e.target.value) {
        onChange({
          address: "",
          vicinity: "",
          county: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
        });
      }
    }
  };

  const handleSelect =
    ({ place_id }: any) =>
    () => {
      getDetails({ placeId: place_id })
        .then((details: any) => {
          const address = transformToAddressType(details);
          setValue(address?.address || "", false);
          onChange(address);
          clearSuggestions();
        })
        .catch((error) => {});
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`text-input-container address-bar ${disabledClass}`}>
      {label && id && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        name={name}
        value={value}
        onChange={handleInput}
        disabled={!ready || isDisabled}
        onKeyPress={(e) => e.code === "Enter" && e.preventDefault()}
        placeholder={placeholder}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <ul className="address-options">{renderSuggestions()}</ul>
      )}
    </div>
  );
}

export default SearchAddressInput;
