//@ts-nocheck
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import {
  SegmentEvents,
  SegmentResponseStatus,
} from "src/components/shared/enums";
import Api from "src/services/Api";
import { RootState } from "src/store/reducers";
import { IndividualInputsFormData } from "..";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { captureMessage } from "@sentry/react";

function PendingInvitation({
  nextStep,
}: MultiStepFormChildrenProps<IndividualInputsFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const session = useSelector((state: RootState) => state.auth.session);
  const [isLoading, setIsLoading] = useState(false);
  const invitationData = session?.invitation;
  const invitationId = invitationData?.id;
  const invitedByUser = invitationData?.invitedByUser;

  const onEnterThisGroupClick = async () => {
    try {
      setIsLoading(true);
      invitationId && (await Api.joinGroupByInvitation(invitationId));
      segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
        step: "Pending invitation",
        data: "Enter this group",
        status: SegmentResponseStatus.Success,
      });
      nextStep();
    } catch (error) {
      captureMessage(JSON.stringify(error))
      segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
        step: "Pending invitation",
        data: "Enter this group",
        status: SegmentResponseStatus.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <InitialStep
      title="Pending "
      boldTitle="invitation"
      description={`${invitedByUser?.firstName} ${invitedByUser?.lastName} (${invitedByUser?.email}) invited you to join a co-ownership group.`}
      primaryButtonText="Enter this group"
      onPrimaryButtonClick={onEnterThisGroupClick}
      isPrimaryButtonLoading={isLoading}
      secondaryButtonText="Create a new group"
      onSecondaryButtonClick={nextStep}
      iconClass="icon-email-circled"
    />
  );
}

export default PendingInvitation;
