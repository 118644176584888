export enum UserRelationships {
  Friend = "Friend",
  Family = "Family",
  Spouse = "Spouse",
  Partner = "Partner",
}

export enum DoesLiveAtCoOwnedHome {
  Yes = "Yes",
  No = "No",
  NotYet = "NotYet",
}

export enum UserGroupInvitationStatus {
  Invited = "Invited",
  CompletingProfile = "Completing profile",
  Joined = "Joined",
  Verified = "Verified",
  NotVerified = "Not Verified",
}

export enum GroupInvitationStatus {
  Pending = "Pending",
  InProgress = "InProgress",
  Completed = "Completed",
}

export enum AgreementStatus {
  Signed = "Signed",
  PendingSignature = "Pending signature",
}

export enum StatusFilter {
  AllItems = "All items",
  NeedsAttention = "Needs attention",
  WaitingOnOther = "Waiting on other",
  Agreed = "Agreed",
}

export enum NameSuffixes {
  Jr = "Jr",
  Sr = "Sr",
  II = "II",
  III = "III",
  IV = "IV",
  V = "V",
}

export enum YesNoOptions {
  Yes = "Yes",
  No = "No",
}

export enum CoOwnersQuantity {
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
}

export enum NotificationCode {
  ModalGroupModifiedMemberDeleted = "ModalGroupModifiedMemberDeleted",
  ModalGroupModifiedMemberAdded = "ModalGroupModifiedMemberAdded",
  ChangeSuggestion = "ChangeSuggestion",
  VaultModification = "VaultModification",
  AgreementReached_CoOwnership = "AgreementReachedCoOwnership",
  AgreementReached_MOA = "AgreementReachedMOA",
  GroupModification = "GroupModification",
  StepCommentCreated = "StepCommentCreated",
  DocumentCommentCreated = "DocumentCommentCreated",
  DocumentUploadedConsensusAchieved = "DocumentUploadedConsensusAchieved",
  DocumentUpdatedConsensusAchieved = "DocumentUpdatedConsensusAchieved",
  DeleteCoOwnerConsensusAchieved = "DeleteCoOwnerConsensusAchieved",
  AddCoOwnerConsensusAchieved = "AddCoOwnerConsensusAchieved",
  CoOwnerJoinedSuccessfully = "CoOwnerJoinedSuccessfully",
  AgreementUpdateReached_CoOwnership = "AgreementUpdateReached_CoOwnership",
  AgreementUpdateReached_MOA = "AgreementUpdateReached_MOA",
}

export enum Platform {
  SMS = "SMS",
  EMAIL = "EMAIL",
}
