import { CoOwnersStep } from "src/components/shared/enums";
import { CoOwner, GroupInvitationMember } from "src/services/Api/types";

// ADD CO-OWNER
// Pending
export const ADD_CO_OWNER = "ADD_CO_OWNER";

export interface AddCoOwnerAction {
  type: typeof ADD_CO_OWNER;
  payload: GroupInvitationMember;
}

export const addCoOwner = (
  coOwner: GroupInvitationMember
): AddCoOwnerAction => ({
  type: ADD_CO_OWNER,
  payload: coOwner
});
// Success
export const ADD_CO_OWNER_SUCCESS = "ADD_CO_OWNER_SUCCESS";

export interface AddCoOwnerSuccessAction {
  type: typeof ADD_CO_OWNER_SUCCESS;
  payload: { eventId: string | null; newCoOwner: GroupInvitationMember | null };
}

export const addCoOwnerSuccess = (
  eventId: string | null,
  newCoOwner: GroupInvitationMember | null
): AddCoOwnerSuccessAction => ({
  type: ADD_CO_OWNER_SUCCESS,
  payload: { eventId, newCoOwner }
});
// Rejected
export const ADD_CO_OWNER_REJECTED = "ADD_CO_OWNER_REJECTED";

export interface AddCoOwnerRejectedAction {
  type: typeof ADD_CO_OWNER_REJECTED;
}

export const addCoOwnerRejected = (): AddCoOwnerRejectedAction => ({
  type: ADD_CO_OWNER_REJECTED
});

// DELETE CO-OWNER
// Pending
export const DELETE_CO_OWNER = "DELETE_CO_OWNER";

export interface DeleteCoOwnerAction {
  type: typeof DELETE_CO_OWNER;
  payload: {
    userId: string;
    isSignupCompleted: boolean;
    isSelf?: boolean;
    firstName?: string;
    lastName?: string;
  };
}

export const deleteCoOwner = (
  userId: string,
  isSignupCompleted: boolean,
  isSelf?: boolean,
  firstName?: string,
  lastName?: string
): DeleteCoOwnerAction => ({
  type: DELETE_CO_OWNER,
  payload: { userId, isSignupCompleted, isSelf, firstName, lastName }
});
// Success
export const DELETE_CO_OWNER_SUCCESS = "DELETE_CO_OWNER_SUCCESS";

export interface DeleteCoOwnerSuccessAction {
  type: typeof DELETE_CO_OWNER_SUCCESS;
  payload: { eventId: string | null; userIdToRemove: string | null };
}

export const deleteCoOwnerSuccess = (
  eventId: string | null,
  userIdToRemove: string | null
): DeleteCoOwnerSuccessAction => ({
  type: DELETE_CO_OWNER_SUCCESS,
  payload: { eventId, userIdToRemove }
});
// Rejected
export const DELETE_CO_OWNER_REJECTED = "DELETE_CO_OWNER_REJECTED";

export interface DeleteCoOwnerRejectedAction {
  type: typeof DELETE_CO_OWNER_REJECTED;
}

export const deleteCoOwnerRejected = (): DeleteCoOwnerRejectedAction => ({
  type: DELETE_CO_OWNER_REJECTED
});

// SET CO-OWNERS STEP
export const SET_CO_OWNERS_STEP = "SET_CO_OWNERS_STEP";

export interface SetCoOwnersStepAction {
  type: typeof SET_CO_OWNERS_STEP;
  payload: CoOwnersStep;
}

export const setCoOwnersStep = (
  value: CoOwnersStep
): SetCoOwnersStepAction => ({
  type: SET_CO_OWNERS_STEP,
  payload: value
});

// SET SELECTED CO-OWNER
export const SET_SELECTED_CO_OWNER = "SET_SELECTED_CO_OWNER";

export interface SetSelectedCoOwnerAction {
  type: typeof SET_SELECTED_CO_OWNER;
  payload: CoOwner | null;
}

export const setSelectedCoOwner = (
  coOwner: CoOwner | null
): SetSelectedCoOwnerAction => ({
  type: SET_SELECTED_CO_OWNER,
  payload: coOwner
});

// RESEND INVITATION
// Pending
export const RESEND_INVITATION = "RESEND_INVITATION";

export interface ResendInvitationAction {
  type: typeof RESEND_INVITATION;
  payload: string;
}

export const resendInvitation = (userId: string): ResendInvitationAction => ({
  type: RESEND_INVITATION,
  payload: userId
});
// Success
export const RESEND_INVITATION_SUCCESS = "RESEND_INVITATION_SUCCESS";

export interface ResendInvitationSuccessAction {
  type: typeof RESEND_INVITATION_SUCCESS;
}

export const resendInvitationSuccess = (): ResendInvitationSuccessAction => ({
  type: RESEND_INVITATION_SUCCESS
});
// Rejected
export const RESEND_INVITATION_REJECTED = "RESEND_INVITATION_REJECTED";

export interface ResendInvitationRejectedAction {
  type: typeof RESEND_INVITATION_REJECTED;
}

export const resendInvitationRejected = (): ResendInvitationRejectedAction => ({
  type: RESEND_INVITATION_REJECTED
});

export const RESET_INVITE = "RESET_INVITE";

export interface ResetInviteAction {
  type: typeof RESET_INVITE;
}

export const resetInvite = (): ResetInviteAction => ({
  type: RESET_INVITE
});

// SEND VERIFICATION ID REMINDER
// Pending
export const SEND_VERIFICATION_ID_REMINDER = "SEND_VERIFICATION_ID_REMINDER";

export interface SendVerificationIdReminderAction {
  type: typeof SEND_VERIFICATION_ID_REMINDER;
  payload: string;
}

export const sendVerificationIdReminder = (
  userId: string
): SendVerificationIdReminderAction => ({
  type: SEND_VERIFICATION_ID_REMINDER,
  payload: userId
});
// Success
export const SEND_VERIFICATION_ID_REMINDER_SUCCESS =
  "SEND_VERIFICATION_ID_REMINDER_SUCCESS";

export interface SendVerificationIdReminderSuccessAction {
  type: typeof SEND_VERIFICATION_ID_REMINDER_SUCCESS;
}

export const sendVerificationIdReminderSuccess =
  (): SendVerificationIdReminderSuccessAction => ({
    type: SEND_VERIFICATION_ID_REMINDER_SUCCESS
  });
// Rejected
export const SEND_VERIFICATION_ID_REMINDER_REJECTED =
  "SEND_VERIFICATION_ID_REMINDER_REJECTED";

export interface SendVerificationIdReminderRejectedAction {
  type: typeof SEND_VERIFICATION_ID_REMINDER_REJECTED;
}

export const sendVerificationIdReminderRejected =
  (): SendVerificationIdReminderRejectedAction => ({
    type: SEND_VERIFICATION_ID_REMINDER_REJECTED
  });

// SEND SIGNATURE REMINDER
// Pending
export const SEND_SIGNATURE_REMINDER = "SEND_SIGNATURE_REMINDER";

export interface SendSignatureReminderAction {
  type: typeof SEND_SIGNATURE_REMINDER;
  payload: string;
}

export const sendSignatureReminder = (
  userId: string
): SendSignatureReminderAction => ({
  type: SEND_SIGNATURE_REMINDER,
  payload: userId
});
// Success
export const SEND_SIGNATURE_REMINDER_SUCCESS =
  "SEND_SIGNATURE_REMINDER_SUCCESS";

export interface SendSignatureReminderSuccessAction {
  type: typeof SEND_SIGNATURE_REMINDER_SUCCESS;
}

export const sendSignatureReminderSuccess =
  (): SendSignatureReminderSuccessAction => ({
    type: SEND_SIGNATURE_REMINDER_SUCCESS
  });
// Rejected
export const SEND_SIGNATURE_REMINDER_REJECTED =
  "SEND_SIGNATURE_REMINDER_REJECTED";

export interface SendSignatureReminderRejectedAction {
  type: typeof SEND_SIGNATURE_REMINDER_REJECTED;
}

export const sendSignatureReminderRejected =
  (): SendSignatureReminderRejectedAction => ({
    type: SEND_SIGNATURE_REMINDER_REJECTED
  });

// Canceling invite
export const CANCEL_INVITE = "CANCEL_INVITE";

export interface CancelInviteAction {
  type: typeof CANCEL_INVITE;
}

export const cancelingInvitation = (): CancelInviteAction => ({
  type: CANCEL_INVITE
});

// Canceling invite complete
export const CANCEL_INVITE_COMPLETE = "CANCEL_INVITE_COMPLETE";

export interface CancelInviteCompleteAction {
  type: typeof CANCEL_INVITE_COMPLETE;
}

export const cancelInvitationComplete = (): CancelInviteCompleteAction => ({
  type: CANCEL_INVITE_COMPLETE
});

export const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT";
export interface DeleteUserAccountAction {
  type: typeof DELETE_USER_ACCOUNT;
}
export const userAccountDeleted = (): DeleteUserAccountAction => ({
  type: DELETE_USER_ACCOUNT
})

export const DELETE_USER_ACCOUNT_COMPLETED = "DELETE_USER_ACCOUNT_COMPLETED";
export interface DeleteUserAccountCompletedAction {
  type: typeof DELETE_USER_ACCOUNT_COMPLETED;
}
export const userAccountDeletedCompleted = (): DeleteUserAccountCompletedAction => ({
  type: DELETE_USER_ACCOUNT_COMPLETED
})

export const REMOVE_USER_ACCOUNT = "REMOVE_USER_ACCOUNT";
export interface RemoveUserAccountAction {
  type: typeof REMOVE_USER_ACCOUNT;
}
export const userAccountRemoved = (): RemoveUserAccountAction => ({
  type: REMOVE_USER_ACCOUNT
})

export const REMOVE_USER_ACCOUNT_COMPLETED = "REMOVE_USER_ACCOUNT_COMPLETED";
export interface RemoveUserAccountCompletedAction {
  type: typeof REMOVE_USER_ACCOUNT_COMPLETED;
}
export const userAccountRemovedCompleted = (): RemoveUserAccountCompletedAction => ({
  type: REMOVE_USER_ACCOUNT_COMPLETED
})
