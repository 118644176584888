type EditionBarProps = { text: string; show: boolean };

function EditionBar({ text, show }: EditionBarProps) {
  return (
    <div className={`edition-bar ${show ? "show" : ""}`}>
      <p>{text}</p>
    </div>
  );
}

export default EditionBar;
