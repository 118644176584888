import { call, put, select, takeLatest } from "redux-saga/effects";
import { CommentType } from "src/components/shared/types";
import Api from "src/services/Api";
import { Pagination } from "src/services/Api/types";
import {
  GetNextPageCommentsAction,
  getNextPageCommentsRejected,
  getNextPageCommentsSuccess,
  GET_NEXT_PAGE_COMMENTS,
  RefreshFirstPageCommentsAction,
  refreshFirstPageCommentsRejected,
  refreshFirstPageCommentsSuccess,
  REFRESH_FIRST_PAGE_COMMENTS,
  SendCommentAction,
  sendCommentRejected,
  sendCommentSuccess,
  SEND_COMMENT,
  setShowSwipeLoader,
} from "../actions/global";
import { RootState } from "../reducers";
import { COMMENTS_TO_TAKE } from "src/components/shared/constants";
import {
  CommentsType,
  ErrorMessages,
  // SegmentEvents,
  ToastType,
} from "src/components/shared/enums";
import { mergePagination } from "src/utils/helpers/helpers";
import { showModal } from "../actions/modal";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import { captureMessage } from "@sentry/react";

export default function* globalSaga() {
  yield takeLatest(SEND_COMMENT, sendCommentSaga);
  yield takeLatest(REFRESH_FIRST_PAGE_COMMENTS, refreshFirstPageCommentsSaga);
  yield takeLatest(GET_NEXT_PAGE_COMMENTS, getNextPageCommentsSaga);
}

// SEND COMMENT
function* sendCommentSaga({ payload }: SendCommentAction) {
  try {
    const { data } =
      payload.type === CommentsType.sectionStep
        ? // Send comment on a section step
          yield call(Api.createSectionStepComment, {
            text: payload.comment,
            sectionStepId: payload.id,
          })
        : yield call(
            // Send comment on a document
            Api.createDocumentComment,
            { text: payload.comment, documentId: payload.id }
          );
    // TODO: Move to use hooks
    // segmentTrack(SegmentEvents.UserSentComment);
    if (payload.isViewOnTop)
      yield put(refreshFirstPageCommentsSuccess(data.comments, false));

    yield put(sendCommentSuccess());
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    yield put(sendCommentRejected());
  } finally {
  }
}

// REFRESH FIRST PAGE COMMENTS
export function* refreshFirstPageCommentsSaga({
  payload,
}: RefreshFirstPageCommentsAction) {
  try {
    if (payload.isSwipeRefresh) yield put(setShowSwipeLoader(true));

    const { data } = yield call(
      payload.type === CommentsType.sectionStep
        ? // Refresh comments on section steps
          Api.getSectionStepComments
        : // Refresh comments on a document
          Api.getDocumentComments,
      payload.id,
      1,
      COMMENTS_TO_TAKE
    );

    yield put(refreshFirstPageCommentsSuccess(data.comments, false));
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(refreshFirstPageCommentsRejected());
  } finally {
    if (payload.isSwipeRefresh) yield put(setShowSwipeLoader(false));
  }
}

// GET NEXT PAGE COMMENTS
export function* getNextPageCommentsSaga({
  payload,
}: GetNextPageCommentsAction) {
  const currentCommentsPagination: Pagination<CommentType> = yield select(
    ({ global }: RootState) => global.commentsDrawer.comments.value
  );

  try {
    const {
      data: { comments: newCommentsPagination },
    } = yield call(
      payload.type === CommentsType.sectionStep
        ? // Get next page of comments on section steps
          Api.getSectionStepComments
        : // Get next page of comments on document
          Api.getDocumentComments,
      payload.id,
      currentCommentsPagination.nextPage ?? 1,
      COMMENTS_TO_TAKE
    );

    const comments = mergePagination<CommentType>(
      currentCommentsPagination,
      newCommentsPagination
    );

    yield put(getNextPageCommentsSuccess(comments));
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(getNextPageCommentsRejected());
  } finally {
  }
}
