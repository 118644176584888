import { useState, useEffect } from "react";
import { DeviceType } from "src/components/shared/enums";

function getDeviceType() {
  const { innerWidth: width } = window;
  if (width < 769) {
    return DeviceType.Mobile;
  } else return DeviceType.Desktop;
}

function useDeviceType() {
  const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType());

  useEffect(() => {
    function handleResize() {
      setDeviceType(getDeviceType());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return deviceType;
}

export default useDeviceType;
