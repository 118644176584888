import { Option } from "src/components/shared/types";
import Tooltip from "../Tooltip";

type SwitchProps = {
  id?: string;
  name?: string;
  label?: string;
  onChange: (value: string) => void;
  selectedId?: string | null;
  options: Array<Option>;
  isDisabled?: boolean;
  tooltip?: string;
};

function Switch({
  id,
  name,
  label,
  selectedId,
  onChange,
  options,
  isDisabled = false,
  tooltip,
}: SwitchProps) {
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`switch ${disabledClass}`}>
      {label && (
        <div className="switch-label">
          {label}
          <Tooltip content={tooltip} />
        </div>
      )}
      <div className="switch-options">
        <input
          id={id}
          name={name}
          className="switch-checkbox"
          type="checkbox"
          onChange={(e) =>
            onChange(e.target.checked ? options[0].id : options[1].id)
          }
          checked={selectedId === options[0].id}
          disabled={isDisabled}
        />
        <span className="switch-span">
          <div className="left-option">{options[0].value}</div>
          <div className="right-option">{options[1].value}</div>
        </span>
      </div>
    </div>
  );
}

export default Switch;
