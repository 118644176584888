import useCountDown from "src/utils/customHooks/useCountDown";
import Button from "../buttons/Button";

type CountDownProps = {
  text: string;
  countDownText: string;
  time: number;
  onButtonClick: () => Promise<void>;
  isLoading: boolean;
};

function CountDownButton({
  onButtonClick,
  isLoading,
  text,
  countDownText,
  time,
}: CountDownProps) {
  const { timeLeft, setTimeLeft, messageText } = useCountDown({
    initialTime: time,
    item: countDownText,
  });
  const onClick = async () => {
    await onButtonClick();
    setTimeLeft(time);
  };
  return (
    <div className="resend-button">
      <Button
        className={`tertiary-button ${
          !!timeLeft || isLoading ? "disabled" : ""
        }`}
        text={timeLeft === 0 ? text : messageText}
        onClick={timeLeft === 0 && !isLoading ? onClick : undefined}
        isLoading={isLoading}
        isDisabled={!!timeLeft || isLoading}
      />
    </div>
  );
}

export default CountDownButton;
