import Tooltip from "../Tooltip";
import parse from "html-react-parser";
import { TEXT_REGEX } from "../shared/constants";

type TextInputProps = {
  id?: string;
  className?: string;
  label?: string;
  value?: string;
  onChange?: (text: string) => void;
  name?: string;
  pattern?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  isDisabled?: boolean;
  tooltip?: string;
  isValid?: boolean;
  errorMessage?: string;
};

function TextInput({
  id,
  className = "",
  label,
  name,
  value = "",
  onChange,
  required,
  readOnly,
  pattern,
  placeholder,
  minLength,
  maxLength,
  isDisabled,
  tooltip,
  isValid = true,
  errorMessage,
}: TextInputProps) {
  const _onChange = (value: string) => {
    const regExp = new RegExp(TEXT_REGEX);
    if (value === "" || regExp.test(value)) onChange && onChange(value);
  };

  const disabledClass = isDisabled ? "disabled" : "";
  const errorClass = errorMessage ? "error" : "";

  return (
    <div className={`text-input-container ${disabledClass} ${errorClass}`}>
      {label && id && (
        <label htmlFor={id}>
          {parse(label)}
          <Tooltip content={tooltip} />
        </label>
      )}
      <input
        type="text"
        className={className}
        id={id}
        name={name}
        value={value}
        onChange={(e) => _onChange(e.target.value)}
        pattern={pattern}
        readOnly={readOnly}
        required={required}
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        disabled={isDisabled}
        onKeyPress={(e) => e.code === "Enter" && e.preventDefault()}
      />
      {!isValid && <div className="invalid-input">{errorMessage}</div>}
    </div>
  );
}

export default TextInput;
