import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatars from "src/components/Avatars";
import CommentsButton from "src/components/buttons/CommentsButton";
import IconButton from "src/components/buttons/IconButton";
import PopoverGuide from "src/components/popover/PopoverGuide";
import { CommentsType, PopoverGuideType } from "src/components/shared/enums";
import { setCommentsDrawer } from "src/store/actions/global";
import { setEditionMode } from "src/store/actions/governance";
import { RootState } from "src/store/reducers";

function StepStatus() {
  const dispatch = useDispatch();
  const { onEditionMode, isStepLoading, currentSectionStep } = useSelector(
    (state: RootState) => state.governance
  );
  const {
    isFullPageLoading,
    mainHeader,
    commentsDrawer: {
      comments: { hasNewComments },
    },
  } = useSelector(({ global }: RootState) => global);

  useEffect(() => {
    dispatch(
      setCommentsDrawer({
        isOpened: false,
        id: currentSectionStep?.id,
        type: CommentsType.sectionStep,
      })
    );
    return () => {
      dispatch(setCommentsDrawer({ isOpened: false, id: null, type: null }));
    };
  }, []);

  const onEditButtonClick = () => {
    if (!onEditionMode && !isStepLoading) {
      dispatch(setEditionMode(!onEditionMode));
    }
  };

  const isEditButtonDisabled =
    onEditionMode || (isStepLoading && !isFullPageLoading);

  return (
    <>
      <Avatars avatars={mainHeader.avatars} isOnGovernance={true} />
      <PopoverGuide
        type={PopoverGuideType.Comments}
        content="Tap here to discuss this item with your co-owners and view change history."
        nextPopoverType={PopoverGuideType.Avatar}
        children={<CommentsButton hasNewComments={hasNewComments} />}
      />
      <PopoverGuide
        type={PopoverGuideType.Edit}
        content="Tap here to activate edit mode and make a change. Tap again to exit edit mode."
        nextPopoverType={PopoverGuideType.Comments}
        children={
          <IconButton
            iconClassName="icon-edit"
            className="edit-button"
            isDisabled={isEditButtonDisabled}
            onClick={onEditButtonClick}
          />
        }
      />
    </>
  );
}

export default StepStatus;
