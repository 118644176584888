import { useState } from "react";
import { Option } from "src/components/shared/types";
import Tooltip from "../Tooltip";
import parse from "html-react-parser";

type SelectProps = {
  id?: string;
  label?: string;
  options: Array<Option>;
  onChange: (event: any) => void;
  selectedId?: string | null;
  placeholder?: string;
  isDisabled?: boolean;
  tooltip?: string;
  readOnly?: boolean;
};

function Select({
  id,
  label,
  options,
  onChange,
  selectedId,
  placeholder = "",
  isDisabled,
  tooltip,
  readOnly,
}: SelectProps) {
  const [openSelect, setOpenSelect] = useState(false);
  const onSelectValue = (e: any, option: Option) => {
    e.stopPropagation();
    onChange(option.id);
    setOpenSelect(false);
  };

  const disabledClass = isDisabled ? "disabled" : "";
  const openClass = openSelect ? "open" : "";

  return (
    <div className={`select-input ${openClass} ${disabledClass}`}>
      {label && (
        <label>
          {parse(label)}
          <Tooltip content={tooltip} />
        </label>
      )}
      <span
        className={selectedId ? "select-label" : "select-label placeholder"}
        onClick={() => !isDisabled && setOpenSelect(!openSelect)}
      >
        {selectedId
          ? options.filter((option) => option.id === selectedId)[0]?.value
          : placeholder}
      </span>
      <ul className="options-list">
        {options.map((option) => (
          <li key={option.value} onClick={(e) => onSelectValue(e, option)}>
            <p>{option.value}</p>
            <div className="label-radio">
              <input
                type="radio"
                checked={option.id === selectedId}
                readOnly={readOnly}
                onChange={(e) => onSelectValue(e, option)}
                disabled={isDisabled}
              />
              <span></span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Select;
