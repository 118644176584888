import { FieldsValidationType } from "src/store/actions/governance";
import {
  transformStepDataToAnswerData,
  validateField,
} from "src/utils/helpers/governance";
import {
  FormSectionStepConfirmationRequestType,
  FormSectionStepType,
} from "../shared/types";

export const fillResetOtherFields = (
  currentSectionStep: FormSectionStepType,
  stepAnswer: FormSectionStepConfirmationRequestType,
  fieldsValidation: FieldsValidationType,
  option: string | null
) => {
  let _stepAnswer = stepAnswer;
  let _fieldsValidation = fieldsValidation;

  const fieldsWithDefaultValue =
    currentSectionStep?.formSectionStepFields.filter(
      (field) => field.defaultToOn
    );

  if (fieldsWithDefaultValue?.length) {
    fieldsWithDefaultValue.forEach((fieldWithDefaultValue) => {
      const isfieldToFill = fieldWithDefaultValue.defaultToOn.id === option;

      _stepAnswer = transformStepDataToAnswerData(
        fieldWithDefaultValue.id,
        fieldWithDefaultValue.index,
        null,
        isfieldToFill
          ? fieldWithDefaultValue.defaultToOn.value.toString()
          : null,
        _stepAnswer
      );

      _fieldsValidation = {
        ..._fieldsValidation,
        [fieldWithDefaultValue.id]: validateField(
          isfieldToFill
            ? fieldWithDefaultValue.defaultToOn.value.toString()
            : null,
          null,
          fieldWithDefaultValue.formSectionStepFieldConstant,
          fieldWithDefaultValue.type
        ),
      };
    });
  }

  return { _stepAnswer, _fieldsValidation };
};
