import { NotificationType } from "src/components/shared/types";
import { NotificationCode } from "src/services/Api/enums";
import { Pagination } from "src/services/Api/types";
import {
  GetNotificationStatusAction,
  GetNotificationStatusRejectedAction,
  GetNotificationStatusSuccessAction,
  GET_NOTIFICATION_STATUS,
  GET_NOTIFICATION_STATUS_REJECTED,
  GET_NOTIFICATION_STATUS_SUCCESS,
  SetModalNotificationsAction,
  GetNotificationsAction,
  GetNotificationsRejectedAction,
  GetNotificationsSuccessAction,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_REJECTED,
  GET_NOTIFICATIONS_SUCCESS,
  SetNotificationsDrawerAction,
  SET_MODAL_NOTIFICATIONS,
  SET_NOTIFICATIONS_DRAWER,
  SET_SHOW_SWIPE_LOADER,
  SetShowSwipeLoaderAction,
  GET_NEXT_PAGE_NOTIFICATIONS_SUCCESS,
  GET_NEXT_PAGE_NOTIFICATIONS,
  GET_NEXT_PAGE_NOTIFICATIONS_REJECTED,
  GetNextPageNotificationsAction,
  GetNextPageNotificationsRejectedAction,
  GetNextPageNotificationsSuccessAction,
} from "../actions/notifications";

export const initialState: {
  notificationsDrawer: { isHidden: boolean; isOpened: boolean };
  notifications: {
    notificationsToRead: boolean;
    value: Pagination<NotificationType>;
    isGettingNotifications: boolean;
    isGettingNextPage: boolean;
    showSwipeLoader: boolean;
  };
  modalNotifications: {
    value: Array<{ code: NotificationCode }>;
    isGettingModalNotifications: boolean;
  };
} = {
  notificationsDrawer: { isHidden: true, isOpened: false },
  notifications: {
    notificationsToRead: false,
    value: {
      count: 0,
      currentPage: 1,
      data: null,
      lastPage: null,
      nextPage: null,
      prevPage: null,
    },
    isGettingNotifications: false,
    isGettingNextPage: false,
    showSwipeLoader: false,
  },
  modalNotifications: {
    value: [],
    isGettingModalNotifications: false,
  },
};

export function notificationsReducer(
  state = initialState,
  action:
    | SetNotificationsDrawerAction
    | SetShowSwipeLoaderAction
    | SetModalNotificationsAction
    | GetNotificationStatusAction
    | GetNotificationStatusSuccessAction
    | GetNotificationStatusRejectedAction
    | GetNotificationsAction
    | GetNotificationsSuccessAction
    | GetNotificationsRejectedAction
    | GetNextPageNotificationsAction
    | GetNextPageNotificationsSuccessAction
    | GetNextPageNotificationsRejectedAction
) {
  switch (action.type) {
    case SET_NOTIFICATIONS_DRAWER:
      return {
        ...state,
        notificationsDrawer: {
          ...state.notificationsDrawer,
          ...action.payload,
        },
      };
    case SET_SHOW_SWIPE_LOADER:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          showSwipeLoader: action.payload,
        },
      };
    case SET_MODAL_NOTIFICATIONS:
      return {
        ...state,
        modalNotifications: {
          ...state.modalNotifications,
          value: action.payload,
          isGettingModalNotifications: false,
        },
      };
    case GET_NOTIFICATION_STATUS:
      return {
        ...state,
        modalNotifications: {
          ...state.modalNotifications,
          isGettingModalNotifications: true,
        },
      };
    case GET_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notificationsToRead: action.payload.notificationsToRead,
        },
        modalNotifications: {
          ...state.modalNotifications,
          value:
            action.payload.modalNotifications.length !== 0
              ? action.payload.modalNotifications
              : state.modalNotifications.value,
          isGettingModalNotifications: false,
        },
      };
    case GET_NOTIFICATION_STATUS_REJECTED:
      return {
        ...state,
        modalNotifications: {
          ...state.modalNotifications,
          isGettingModalNotifications: false,
        },
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isGettingNotifications: true,
        },
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isGettingNotifications: false,
          value: action.payload,
        },
      };
    case GET_NOTIFICATIONS_REJECTED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isGettingNotifications: false,
        },
      };
    case GET_NEXT_PAGE_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isGettingNextPage: true,
        },
      };
    case GET_NEXT_PAGE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isGettingNextPage: false,
          value: action.payload,
        },
      };
    case GET_NEXT_PAGE_NOTIFICATIONS_REJECTED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isGettingNextPage: false,
        },
      };
    default:
      return state;
  }
}
