import FullPageLoading from "../loading/FullPageLoading";
import Modals from "../modals";
import { RootState } from "src/store/reducers";
import { useSelector } from "react-redux";
import CommentsDrawer from "src/components/drawers/CommentsDrawer";
import MenuDrawer from "../drawers/MenuDrawer";
import NotificationsDrawer from "../drawers/NotificationsDrawer";
import MainHeader from "../MainHeader";

type GeneralContainerProps = { children: React.ReactNode };

function GeneralContainer({ children }: GeneralContainerProps) {
  const {
    isFullPageLoading,
    isLoadingUserData,
    mainHeader,
    fullPageLoadingText,
  } = useSelector((state: RootState) => state.global);

  const contentClass = mainHeader.showMenuDrawerOnDesktop
    ? "content"
    : "content desktop-no-padding";

  return (
    <div className="general-container">
      <FullPageLoading
        loading={isFullPageLoading || isLoadingUserData}
        text={fullPageLoadingText}
      />
      {!isLoadingUserData && (
        <>
          <Modals />
          <MainHeader />
          <div className="general-container-bottom">
            <MenuDrawer />
            <div className={contentClass}>{children}</div>
            <CommentsDrawer />
            <NotificationsDrawer />
          </div>
        </>
      )}
    </div>
  );
}

export default GeneralContainer;
