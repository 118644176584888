import CommentsButton from "../buttons/CommentsButton";
import { coOwnershipStatus } from "../shared/constants";
import { CoOwnershipAgreementItemStatus } from "../shared/enums";

type AgreementStatusCardProps = {
  iconClass: string;
  title: string;
  statusText?: string;
  status?: CoOwnershipAgreementItemStatus;
  numberOfComments?: number;
  hasNewComments?: boolean;
  stepsCount?: number;
  showArrow?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
};

function AgreementStatusCard({
  iconClass,
  title,
  statusText,
  status,
  numberOfComments,
  hasNewComments,
  stepsCount,
  showArrow,
  onClick,
  isDisabled = false,
}: AgreementStatusCardProps) {
  const statusClass = status ? coOwnershipStatus[status] : "";
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`agreement-status-card ${disabledClass}`} onClick={onClick}>
      <i className={`main-icon ${iconClass}`} />
      <div className="information-container">
        <span className="title">{title}</span>
        <div className="status-container">
          {statusClass && <div className={`status-indicator ${statusClass}`} />}
          <p>{statusText}</p>
        </div>
        {!!numberOfComments && (
          <CommentsButton
            numberOfComments={numberOfComments}
            hasNewComments={hasNewComments}
            disableClick
          />
        )}
      </div>
      {stepsCount && showArrow && <i className="icon-arrow-right" />}
    </div>
  );
}

export default AgreementStatusCard;
