import useDeviceType from "src/utils/customHooks/useDeviceType";
import IconTextButton from "../buttons/IconTextButton";
import { DeviceType } from "../shared/enums";

type DesktopNavProps = {
  title: string;
  backButton?: { onBackButtonClick: () => void };
  rightButton?: {
    text: string;
    onClick: () => void;
    iconClass: string;
    isDisabled?: boolean;
  };
};

function DesktopNav({ backButton, title, rightButton }: DesktopNavProps) {
  const deviceType = useDeviceType();

  if (deviceType !== DeviceType.Desktop) return <></>;

  return (
    <div className="desktop-nav">
      <h2
        className={backButton ? "icon-back" : undefined}
        onClick={backButton ? backButton.onBackButtonClick : undefined}
      >
        {title}
      </h2>

      {rightButton && (
        <IconTextButton
          onClick={rightButton.onClick}
          text={rightButton.text}
          iconClass={rightButton.iconClass}
          isDisabled={rightButton.isDisabled}
        />
      )}
    </div>
  );
}

export default DesktopNav;
