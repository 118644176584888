import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import React, { useEffect } from "react";
import { ErrorBoundary } from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import TopLevelFail from "./components/Fail/TopLevelFail";
import { GOOGLE_LOGIN_CLIENT_ID, SENTRY_DSN } from "./constants";
import * as Sentry from "@sentry/react";
import AnalyticsProvider from "./components/Analytics";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Sentry
Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: SENTRY_DSN,
  // integrations: [new BrowserTracing(), new Replay()],
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  attachStacktrace: ["staging", "production"].includes(process.env.NODE_ENV),
  enabled: ["staging", "production"].includes(process.env.NODE_ENV),
  debug: process.env.NODE_ENV === "development"
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<TopLevelFail />}>
      <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENT_ID}>
        <AnalyticsProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </AnalyticsProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </React.StrictMode>
);