import { AvatarType } from "src/components/Avatar";
import { CommentsType, PopoverGuideType } from "src/components/shared/enums";
import { CommentType } from "src/components/shared/types";
import { Pagination } from "src/services/Api/types";

// SET FULL PAGE LOADER
export const SET_FULL_PAGE_LOADING = "SET_FULL_PAGE_LOADING";
export interface SetFullPageLoadingAction {
  type: typeof SET_FULL_PAGE_LOADING;
  payload: { isLoading: boolean; text?: string };
}
export const setFullPageLoading = (
  isLoading: boolean,
  text?: string
): SetFullPageLoadingAction => ({
  type: SET_FULL_PAGE_LOADING,
  payload: { isLoading, text },
});

// SET IS LOADING USER DATA
export const SET_IS_LOADING_USER_DATA = "SET_IS_LOADING_USER_DATA";
export interface SetIsLoadingUserDataAction {
  type: typeof SET_IS_LOADING_USER_DATA;
  payload: boolean;
}
export const setIsLoadingUserData = (
  isLoading: boolean
): SetIsLoadingUserDataAction => ({
  type: SET_IS_LOADING_USER_DATA,
  payload: isLoading,
});

// SET FORM STATE
export const SET_FORM_STATE = "SET_FORM_STATE";
export type FormState = {
  formName: string;
  formData: any;
  formStep: number;
};
export interface SetFormStateAction {
  type: typeof SET_FORM_STATE;
  payload: Partial<FormState>;
}
export const setFormState = ({
  formName,
  formData,
  formStep,
}: Partial<FormState>): SetFormStateAction => {
  return {
    type: SET_FORM_STATE,
    payload: { formName, formData, formStep },
  };
};

// SET MENU DRAWER
export const SET_MENU_DRAWER = "SET_MENU_DRAWER";
export interface SetMenuDrawerAction {
  type: typeof SET_MENU_DRAWER;
  payload: { isOpened: boolean };
}
export const setMenuDrawer = (isOpened: boolean): SetMenuDrawerAction => ({
  type: SET_MENU_DRAWER,
  payload: { isOpened },
});

// SET COMMENTS
export const SET_COMMENTS_DRAWER = "SET_COMMENTS_DRAWER";
export interface SetCommentsDrawerAction {
  type: typeof SET_COMMENTS_DRAWER;
  payload: {
    isOpened: boolean;
    id?: string | null;
    type?: CommentsType | null;
  };
}
export const setCommentsDrawer = (args: {
  isOpened: boolean;
  id?: string | null;
  type?: CommentsType | null;
}): SetCommentsDrawerAction => ({
  type: SET_COMMENTS_DRAWER,
  payload: args,
});

// SET INITIAL POPOVER
export const SET_POPOVER_GUIDE = "SET_POPOVER_GUIDE";
export interface SetPopoverGuideAction {
  type: typeof SET_POPOVER_GUIDE;
  payload: PopoverGuideType | null;
}
export const setPopoverGuide = (
  popover: PopoverGuideType | null
): SetPopoverGuideAction => ({
  type: SET_POPOVER_GUIDE,
  payload: popover,
});

// SET MAIN HEADER
export const SET_MAIN_HEADER = "SET_MAIN_HEADER";
export type MainHeaderType = {
  hide: boolean;
  hideHeaderOnMobile: boolean;
  title: string | null;
  showLogo: boolean;
  showHamburgerButton: boolean;
  showMenuDrawerOnDesktop: boolean;
  onBackButtonClick?: () => void;
  showStepStatus: boolean;
  showDocumentStatus: boolean;
  avatars: Array<AvatarType>;
  showEditProfileButton: boolean;
  showDeleteAccountButton: boolean;
  showNotificationsButton: boolean;
  showAddDocumentButton: boolean;
  progress: number | null;
  hideBottomLine: boolean;
  darkMode: boolean;
};
export interface SetMainHeaderAction {
  type: typeof SET_MAIN_HEADER;
  payload: Partial<MainHeaderType>;
}
export const setMainHeader = (
  header: Partial<MainHeaderType>
): SetMainHeaderAction => ({
  type: SET_MAIN_HEADER,
  payload: header,
});

// SET SHOW SWIPE LOADER
export const SET_SHOW_SWIPE_LOADER = "SET_SHOW_SWIPE_LOADER";
export interface SetShowSwipeLoaderAction {
  type: typeof SET_SHOW_SWIPE_LOADER;
  payload: boolean;
}
export const setShowSwipeLoader = (
  value: boolean
): SetShowSwipeLoaderAction => ({
  type: SET_SHOW_SWIPE_LOADER,
  payload: value,
});

// GET NEXT PAGE COMMENTS
// Pending
export const GET_NEXT_PAGE_COMMENTS = "GET_NEXT_PAGE_COMMENTS";
export interface GetNextPageCommentsAction {
  type: typeof GET_NEXT_PAGE_COMMENTS;
  payload: { id: string; type: CommentsType };
}
export const getNextPageComments = (
  id: string,
  type: CommentsType
): GetNextPageCommentsAction => ({
  type: GET_NEXT_PAGE_COMMENTS,
  payload: { id, type },
});
// Success
export const GET_NEXT_PAGE_COMMENTS_SUCCESS = "GET_NEXT_PAGE_COMMENTS_SUCCESS";
export interface GetNextPageCommentsSuccessAction {
  type: typeof GET_NEXT_PAGE_COMMENTS_SUCCESS;
  payload: {
    comments: Pagination<CommentType>;
  };
}
export const getNextPageCommentsSuccess = (
  comments: Pagination<CommentType>
): GetNextPageCommentsSuccessAction => ({
  type: GET_NEXT_PAGE_COMMENTS_SUCCESS,
  payload: { comments },
});
// Rejected
export const GET_NEXT_PAGE_COMMENTS_REJECTED =
  "GET_NEXT_PAGE_COMMENTS_REJECTED";
export interface GetNextPageCommentsRejectedAction {
  type: typeof GET_NEXT_PAGE_COMMENTS_REJECTED;
}
export const getNextPageCommentsRejected =
  (): GetNextPageCommentsRejectedAction => ({
    type: GET_NEXT_PAGE_COMMENTS_REJECTED,
  });

// REFRESH FIRST PAGE COMMENTS
// Pending
export const REFRESH_FIRST_PAGE_COMMENTS = "REFRESH_FIRST_PAGE_COMMENTS";
export interface RefreshFirstPageCommentsAction {
  type: typeof REFRESH_FIRST_PAGE_COMMENTS;
  payload: { id: string; type: CommentsType; isSwipeRefresh: boolean };
}
export const refreshFirstPageComments = (
  id: string,
  type: CommentsType,
  isSwipeRefresh: boolean
): RefreshFirstPageCommentsAction => ({
  type: REFRESH_FIRST_PAGE_COMMENTS,
  payload: { id, type, isSwipeRefresh },
});
// Success
export const REFRESH_FIRST_PAGE_COMMENTS_SUCCESS =
  "REFRESH_FIRST_PAGE_COMMENTS_SUCCESS";
export interface RefreshFirstPageCommentsSuccessAction {
  type: typeof REFRESH_FIRST_PAGE_COMMENTS_SUCCESS;
  payload: {
    comments: Pagination<CommentType>;
    hasNewComments: boolean;
  };
}
export const refreshFirstPageCommentsSuccess = (
  comments: Pagination<CommentType>,
  hasNewComments: boolean
): RefreshFirstPageCommentsSuccessAction => ({
  type: REFRESH_FIRST_PAGE_COMMENTS_SUCCESS,
  payload: { comments, hasNewComments },
});
// Rejected
export const REFRESH_FIRST_PAGE_COMMENTS_REJECTED =
  "REFRESH_FIRST_PAGE_COMMENTS_REJECTED";
export interface RefreshFirstPageCommentsRejectedAction {
  type: typeof REFRESH_FIRST_PAGE_COMMENTS_REJECTED;
}
export const refreshFirstPageCommentsRejected =
  (): RefreshFirstPageCommentsRejectedAction => ({
    type: REFRESH_FIRST_PAGE_COMMENTS_REJECTED,
  });

// SEND COMMENT
// Pending
export const SEND_COMMENT = "SEND_COMMENT";
export interface SendCommentAction {
  type: typeof SEND_COMMENT;
  payload: {
    id: string;
    type: CommentsType;
    comment: string;
    isViewOnTop: boolean;
  };
}
export const sendComment = (
  id: string,
  type: CommentsType,
  comment: string,
  isViewOnTop: boolean
): SendCommentAction => ({
  type: SEND_COMMENT,
  payload: { id, type, comment, isViewOnTop },
});
// Success
export const SEND_COMMENT_SUCCESS = "SEND_COMMENT_SUCCESS";
export interface SendCommentSuccessAction {
  type: typeof SEND_COMMENT_SUCCESS;
}
export const sendCommentSuccess = (): SendCommentSuccessAction => ({
  type: SEND_COMMENT_SUCCESS,
});

// Received
export const SEND_COMMENT_RECEIVED = "SEND_COMMENT_RECEIVED"
export interface SendCommentReceivedAction {
  type: typeof SEND_COMMENT_RECEIVED
}
export const sendCommentReceived = (): SendCommentReceivedAction => ({
  type: SEND_COMMENT_RECEIVED
})

// Rejected
export const SEND_COMMENT_REJECTED = "SEND_COMMENT_REJECTED";
export interface SendCommentRejectedAction {
  type: typeof SEND_COMMENT_REJECTED;
}
export const sendCommentRejected = (): SendCommentRejectedAction => ({
  type: SEND_COMMENT_REJECTED,
});
