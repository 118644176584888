type SectionProps = {
  id: string;
  title: string;
  subtitle?: string;
  isChecked: boolean;
  icon: string;
};

function Section({ id, title, subtitle, isChecked, icon }: SectionProps) {
  return (
    <div id={id} className={isChecked ? "section active" : "section"}>
      <span className="check"></span>
      <div className="sections-index-title">
        <i className={icon} />
        <div className="title">{title}</div>
      </div>
      {subtitle && isChecked && <p>{subtitle}</p>}
    </div>
  );
}

export default Section;
