import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "src/store/actions/modal";
import { RootState } from "src/store/reducers";
import parse from "html-react-parser";
import { MODAL_TOAST } from "../modalTypes";
import useInterval from "src/utils/customHooks/useInterval";
import { ToastType } from "src/components/shared/enums";

export type ToastModalProps = {
  message?: string;
  autoDeleteTime?: number;
  type?: ToastType;
};

function ToastModal() {
  const toast = useSelector((state: RootState) => state.modal.toast);
  const dispatch = useDispatch();
  const type = toast.type ?? ToastType.Success;
  useInterval(() => {
    dispatch(hideModal(MODAL_TOAST));
  }, toast.autoDeleteTime || 5000);

  return (
    <div
      className="modal-toast-overlay"
      onClick={() => dispatch(hideModal(MODAL_TOAST))}
    >
      <div className={`modal-toast ${type}`}>
        <i
          className={
            type === ToastType.Error
              ? "icon-cross-toast"
              : type === ToastType.Success
              ? "icon-ok-circle"
              : "icon-info"
          }
        />
        <p>{parse(toast.message || "")}</p>
      </div>
    </div>
  );
}

export default ToastModal;
