import Avatar from "src/components/Avatar";
import { localDateFromJSON, localTimeFromJSON } from "src/utils/helpers/dates";
import parse from "html-react-parser";
import { NotificationCode } from "src/services/Api/enums";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentSection,
  setCurrentStepStatus,
} from "src/store/actions/governance";
import { updatePermissions } from "src/store/actions/auth";
import {
  CoOwnersPermission,
  DashboardPermission,
  DocumentVaultPermission,
  getDefaultGovernancePermission,
  getDefaultPage,
  Page,
} from "src/utils/helpers/permissions";
import { RootState } from "src/store/reducers";
import { setNotificationsDrawer } from "src/store/actions/notifications";
import { setSelectedDocumentId } from "src/store/actions/vault";
import { getSectionCodeFromStepCode } from "src/utils/helpers/helpers";
import { FormSectionStepCodes } from "src/components/shared/enums";
import { SectionType } from "src/components/shared/types";

type NotificationProps = {
  code: string;
  iconClass?: string;
  text: string;
  createdAt: string;
  initials?: string | null;
  documentId?: string;
  isRead?: boolean;
  redirect?: string;
};

function Notification({
  code,
  initials,
  iconClass = "icon-cobuy",
  text,
  createdAt,
  isRead,
  documentId,
  redirect,
}: NotificationProps) {
  const dispatch = useDispatch();
  const { session }: any = useSelector(({ auth }: RootState) => auth);
  const { sections } = useSelector(({ governance }: RootState) => governance);

  const redirectToSignAgreement = () => {
    const defaultPage = getDefaultPage(session!);
    if (defaultPage === Page.GOVERNANCE) {
      dispatch(
        updatePermissions({
          page: defaultPage,
          permission: getDefaultGovernancePermission(session!),
        })
      );
    } else if (defaultPage === Page.DASHBOARD) {
      let permission = DashboardPermission.DASHBOARD;
      if (session?.group?.allMemberSignedMemoOfAgreement)
        permission = DashboardPermission.DASHBOARD;
      else if (session?.user?.hasSignedMemoOfAgreement)
        permission = DashboardPermission.MEMORANDUM_OF_AGREEMENT_STATUS;
      else if (session?.user?.hasSignedCoAgreement)
        permission = DashboardPermission.CO_OWNERSHIP_AGREEMENT_STATUS;
      else if (session?.user?.hasCompletedCoAgreement)
        permission = DashboardPermission.SECTIONS_STATUS;
      else if (session?.user?.hasStartedCoAgreement) {
        permission = DashboardPermission.SECTIONS_STATUS;
      }
      dispatch(
        updatePermissions({
          page: defaultPage,
          permission,
        })
      );
    }
  };

  const onNotificationClick = () => {
    dispatch(setNotificationsDrawer(false));
    switch (code) {
      case NotificationCode.ChangeSuggestion:
      case NotificationCode.StepCommentCreated:
        if (redirect) {
          const sectionCode = getSectionCodeFromStepCode(
            redirect as FormSectionStepCodes
          );
          const newSection = sections?.find(
            (section: SectionType) => section.code === sectionCode
          );
          newSection && dispatch(setCurrentSection(newSection));
          dispatch(setCurrentStepStatus(redirect));
        }
        break;
      case NotificationCode.VaultModification:
      case NotificationCode.DocumentCommentCreated:
      case NotificationCode.DocumentUploadedConsensusAchieved:
      case NotificationCode.DocumentUpdatedConsensusAchieved:
        if (documentId) {
          dispatch(setSelectedDocumentId(documentId));
          dispatch(
            updatePermissions({
              page: Page.DOCUMENT_VAULT,
              permission: DocumentVaultPermission.DOCUMENT,
            })
          );
        } else {
          dispatch(
            updatePermissions({
              page: Page.DOCUMENT_VAULT,
              permission: DocumentVaultPermission.DOCUMENT_VAULT,
            })
          );
        }
        break;
      case NotificationCode.AgreementReached_MOA:
      case NotificationCode.AgreementReached_CoOwnership:
      case NotificationCode.CoOwnerJoinedSuccessfully:
      case NotificationCode.AgreementUpdateReached_CoOwnership:
      case NotificationCode.AgreementUpdateReached_MOA:
        redirectToSignAgreement();
        break;
      case NotificationCode.GroupModification:
      case NotificationCode.AddCoOwnerConsensusAchieved:
      case NotificationCode.DeleteCoOwnerConsensusAchieved:
      case NotificationCode.ModalGroupModifiedMemberDeleted:
      case NotificationCode.ModalGroupModifiedMemberAdded:
        dispatch(
          updatePermissions({
            page: Page.CO_OWNERS,
            permission: CoOwnersPermission.CO_OWNERS,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={`notification ${isRead ? "" : "unread"}`}
      onClick={onNotificationClick}
    >
      {initials ? (
        <Avatar firstName={initials[0]} lastName={initials[1]} />
      ) : (
        <i className={iconClass} />
      )}
      <div className="description">{parse(text)}</div>
      <div className="time-sent">
        <p>{localTimeFromJSON(createdAt)}</p>
        <p>{localDateFromJSON(createdAt, true)}</p>
      </div>
    </div>
  );
}

export default Notification;
