import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import AddUserModal from "./AddUserModal";
import ConfirmationModal from "./ConfirmationModal";
import InformationModal from "./InformationModal";
import ToastModal from "./ToastModal";
import StepModal from "./StepModal";
import SignatureModal from "./SignatureModal";
import AddDocumentModal from "./AddDocumentModal";

const Modals = () => {
  const {
    information,
    confirmation,
    addUser,
    toast,
    step,
    signature,
    addDocument,
  } = useSelector((state: RootState) => state.modal);

  return (
    <>
      {information.isOpen && <InformationModal />}
      {confirmation.isOpen && <ConfirmationModal />}
      {addUser.isOpen && <AddUserModal />}
      {toast.isOpen && <ToastModal />}
      {step.isOpen && <StepModal />}
      {signature.isOpen && <SignatureModal />}
      {addDocument.isOpen && <AddDocumentModal />}
    </>
  );
};

export default Modals;
