import Tooltip from "../Tooltip";
import parse from "html-react-parser";

type DateInputProps = {
  id?: string;
  label?: string;
  value?: string;
  onChange?: (event: any) => void;
  name?: string;
  placeholder?: string;
  required?: boolean;
  minLength?: number;
  isDisabled?: boolean;
  tooltip?: string;
  isValid?: boolean;
  errorMessage?: string;
};

function DateInput({
  id,
  label,
  name,
  value,
  onChange,
  required,
  minLength,
  placeholder = "MM/DD/YYYY",
  isDisabled,
  tooltip,
  isValid = true,
  errorMessage,
}: DateInputProps) {
  const _onChange = (ev: any) => {
    let newValue = ev.target.value;
    const val = newValue.replaceAll("/", "");
    if (
      [...val].every((x) =>
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "/"].includes(x)
      )
    ) {
      if (onChange) {
        onChange(val);
      }
    }
  };

  const maskingDate = value
    ? `${value.substring(0, 2)}${
        value.length > 2
          ? `/${value.substring(2, 4)}${
              value.length > 4 ? `/${value.substring(4, 8)}` : ""
            }`
          : ""
      }`
    : "";

  const disabledClass = isDisabled ? "disabled" : "";
  const errorClass = errorMessage ? "error" : "";
  return (
    <div className={`date-input-container ${disabledClass} ${errorClass}`}>
      {label && id && (
        <label htmlFor={id}>
          {parse(label)}
          <Tooltip content={tooltip} />
        </label>
      )}
      <input
        id={id}
        type="tel"
        name={name}
        value={maskingDate}
        onChange={_onChange}
        required={required}
        minLength={minLength}
        maxLength={10}
        placeholder={placeholder}
        disabled={isDisabled}
        onKeyPress={(e) => e.code === "Enter" && e.preventDefault()}
      />
      {!isValid && <div className="invalid-input">{errorMessage}</div>}
    </div>
  );
}

export default DateInput;
