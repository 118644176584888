import { Link as RouterLink } from "react-router-dom";

type LinkProps = {
  children: React.ReactNode;
  to: string;
};

function Link({ children, to }: LinkProps) {
  return (
    <RouterLink className="link" to={to}>
      {children}
    </RouterLink>
  );
}

export default Link;
