import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentCard from "src/components/cards/DocumentCard";
import Spinner from "src/components/Spinner";
import Stat from "src/components/Stat";
import Table from "src/components/Table";
import { getDashboardInfo } from "src/store/actions/dashboard";
import { setMainHeader } from "src/store/actions/global";
import { RootState } from "src/store/reducers";
import useInterval from "src/utils/customHooks/useInterval";

function MainDashboard() {
  const dispatch = useDispatch();
  const {
    dashboardInfo: {
      agreementStatus,
      propertyValuation,
      accountInformation,
      mortgageInformation,
    },
    isGettingDashboardInfo,
  } = useSelector(({ dashboard }: RootState) => dashboard);

  useEffect(() => {
    dispatch(
      setMainHeader({
        showLogo: true,
        showHamburgerButton: true,
        showNotificationsButton: true,
        showMenuDrawerOnDesktop: true,
        darkMode: true,
      })
    );
    dispatch(getDashboardInfo(false));
  }, []);

  useInterval(() => dispatch(getDashboardInfo(true)), 30000);

  return (
    <div className="dashboard">
      {isGettingDashboardInfo || agreementStatus.length === 0 ? (
        <Spinner />
      ) : (
        <>
          <div className="container-information">
            <span className="address">{propertyValuation.propertyName}</span>
            <Stat
              title="Current home value"
              tooltip={propertyValuation.tooltip}
              price={propertyValuation.propertyFinancial.propertyValuation}
              percentage={propertyValuation.propertyFinancial.valueChange}
            />
            <Table
              primaryTitle="Ownership breakdown"
              secondaryTitle={propertyValuation.titleFormat}
              isDropdown={true}
              table={propertyValuation.ownershipBreakdown}
            />
          </div>
          <div className="container-cards-tables">
            <div className="document-cards-container">
              {agreementStatus.map((agreement, index) => (
                <DocumentCard key={index} document={agreement} />
              ))}
            </div>

            <div className="tables-container">
              {accountInformation && (
                <Table
                  primaryTitle="Joint financial accounts"
                  table={accountInformation}
                />
              )}
              {mortgageInformation && (
                <Table
                  primaryTitle="Mortgage info"
                  description={mortgageInformation.lender}
                  table={mortgageInformation.table}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MainDashboard;
