import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "src/components/Spinner";
import { getChatGroup, setSelectedChatGroup } from "src/store/actions/chat";
import { RootState } from "src/store/reducers";
import ChatGroup from "./ChatGroup";

function ChatDrawer() {
  const dispatch = useDispatch();
  const { isGettingChatGroups, value, selectedChatGroup } = useSelector(
    ({ chat }: RootState) => chat.chatGroups
  );

  useEffect(() => {
    dispatch(getChatGroup(!!selectedChatGroup));
  }, [selectedChatGroup]);

  return (
    <div className="chat-drawer">
      {isGettingChatGroups ? (
        <Spinner />
      ) : (
        value.map((chatGroup) => (
          <ChatGroup
            key={chatGroup.name}
            name={chatGroup.name}
            onClick={() => {
              dispatch(setSelectedChatGroup(chatGroup || null));
            }}
            newMessages={chatGroup.newMessages}
            isActive={selectedChatGroup?.name === chatGroup.name}
          />
        ))
      )}
    </div>
  );
}

export default ChatDrawer;
