import { CoOwnedHomeAddressType } from "src/services/Api/types";
import TextInput from "../TextInput";

type AddressProps = { isDisabled?: boolean; address: CoOwnedHomeAddressType };

function Address({
  isDisabled,
  address: { city, country, state, zipCode },
}: AddressProps) {
  return (
    <>
      <TextInput
        id="city"
        name="city"
        label="City"
        value={city}
        isDisabled={isDisabled}
        readOnly
      />
      <TextInput
        id="state"
        name="state"
        label="State"
        value={state}
        isDisabled={isDisabled}
        readOnly
      />
      <TextInput
        id="zipCode"
        name="zipCode"
        label="Zip Code"
        value={zipCode}
        isDisabled={isDisabled}
        readOnly
      />
      <TextInput
        id="country"
        name="country"
        label="Country"
        value={country}
        isDisabled={isDisabled}
        readOnly
      />
    </>
  );
}

export default Address;
