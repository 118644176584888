import { ChatGroup, ChatMessageType } from "src/components/shared/types";
import { Pagination } from "src/services/Api/types";
import {
  GetChatGroupAction,
  GetChatGroupRejectedAction,
  GetChatGroupSuccessAction,
  GET_CHAT_GROUP,
  GET_CHAT_GROUP_REJECTED,
  GET_CHAT_GROUP_SUCCESS,
  GetNextPageMessagesAction,
  GetNextPageMessagesRejectedAction,
  GetNextPageMessagesSuccessAction,
  RefreshFirstPageMessagesAction,
  RefreshFirstPageMessagesSuccessAction,
  RefreshFirstPageMessagesRejectedAction,
  GET_NEXT_PAGE_MESSAGES,
  GET_NEXT_PAGE_MESSAGES_REJECTED,
  GET_NEXT_PAGE_MESSAGES_SUCCESS,
  SendChatMessageAction,
  SendChatMessageRejectedAction,
  SendChatMessageSuccessAction,
  SEND_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE_REJECTED,
  SEND_CHAT_MESSAGE_SUCCESS,
  REFRESH_FIRST_PAGE_MESSAGES,
  REFRESH_FIRST_PAGE_MESSAGES_REJECTED,
  REFRESH_FIRST_PAGE_MESSAGES_SUCCESS,
  SET_SHOW_SWIPE_LOADER,
  SetShowSwipeLoaderAction,
  SetSelectedChatGroupAction,
  SET_SELECTED_CHAT_GROUP, SendChatMessageReceivedAction, SEND_CHAT_MESSAGE_RECEIVED
} from "../actions/chat";

export const initialState: {
  chatGroups: {
    value: Array<ChatGroup>;
    isGettingChatGroups: boolean;
    selectedChatGroup: ChatGroup | null;
  };
  chatMessages: {
    value: Pagination<ChatMessageType>;
    isGettingNextPage: boolean;
    isRefreshingFirstPage: boolean;
    isSendingMessages: boolean;
    sendMessageSuccess: boolean | null;
    sendMessageRejected: boolean | null;
    showSwipeLoader: boolean;
  };
} = {
  chatGroups: {
    value: [],
    selectedChatGroup: null,
    isGettingChatGroups: false,
  },
  chatMessages: {
    value: {
      count: 0,
      currentPage: 1,
      data: null,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    isGettingNextPage: false,
    isRefreshingFirstPage: false,
    isSendingMessages: false,
    sendMessageRejected: null,
    sendMessageSuccess: null,
    showSwipeLoader: false,
  },
};

export function chatReducer(
  state = initialState,
  action:
    | GetNextPageMessagesAction
    | GetNextPageMessagesSuccessAction
    | GetNextPageMessagesRejectedAction
    | RefreshFirstPageMessagesAction
    | RefreshFirstPageMessagesSuccessAction
    | RefreshFirstPageMessagesRejectedAction
    | SendChatMessageAction
    | SendChatMessageSuccessAction
    | SendChatMessageRejectedAction
    | SetShowSwipeLoaderAction
    | GetChatGroupAction
    | GetChatGroupSuccessAction
    | GetChatGroupRejectedAction
    | SetSelectedChatGroupAction
    | SendChatMessageReceivedAction
) {
  switch (action.type) {
    case GET_CHAT_GROUP:
      return {
        ...state,
        chatGroups: {
          ...state.chatGroups,
          isGettingChatGroups: !action.payload?.isRefreshing,
        },
      };
    case GET_CHAT_GROUP_SUCCESS:
      return {
        ...state,
        chatGroups: {
          ...state.chatGroups,
          value: action.payload,
          isGettingChatGroups: false,
        },
      };
    case GET_CHAT_GROUP_REJECTED:
      return {
        ...state,
        chatGroups: {
          ...state.chatGroups,
          isGettingChatGroups: false,
        },
      };
    case SET_SELECTED_CHAT_GROUP:
      return {
        ...state,
        chatGroups: {
          ...state.chatGroups,
          selectedChatGroup: action.payload,
        },
      };

    case SET_SHOW_SWIPE_LOADER:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          showSwipeLoader: action.payload,
        },
      };
    case GET_NEXT_PAGE_MESSAGES:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          isGettingNextPage: true,
        },
      };
    case GET_NEXT_PAGE_MESSAGES_SUCCESS:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          value: action.payload,
          isGettingNextPage: false,
        },
      };
    case GET_NEXT_PAGE_MESSAGES_REJECTED:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          isGettingNextPage: false,
        },
      };
    case REFRESH_FIRST_PAGE_MESSAGES:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          isRefreshingFirstPage: true,
        },
      };
    case REFRESH_FIRST_PAGE_MESSAGES_SUCCESS:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          value: action.payload,
          isRefreshingFirstPage: false,
        },
      };
    case REFRESH_FIRST_PAGE_MESSAGES_REJECTED:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          isRefreshingFirstPage: false,
        },
      };
    case SEND_CHAT_MESSAGE:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          isSendingMessages: true,
        },
      };
    case SEND_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          isSendingMessages: false,
          sendMessageSuccess: true
        },
      };
    case SEND_CHAT_MESSAGE_REJECTED:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          isSendingMessages: false,
          sendMessageRejected: true
        },
      };
    case SEND_CHAT_MESSAGE_RECEIVED:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          sendMessageSuccess: null,
          sendMessageRejected: null
        },
      };
    default:
      return state;
  }
}
