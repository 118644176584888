import { RootState } from "src/store/reducers";
import { useSelector } from "react-redux";
import Avatar from "src/components/Avatar";
import ChatInput from "src/components/inputs/ChatInput";

type CommentInputProps = { sendMessage: (message: string) => void };

function CommentInput({ sendMessage }: CommentInputProps) {
  const { session }: any = useSelector(({ auth }: RootState) => auth);

  return (
    <div className="comment-input">
      <Avatar
        firstName={session?.user?.firstName[0] || ""}
        lastName={session?.user?.lastName[0] || ""}
      />
      <div className="input-text">
        <ChatInput
          name="comment"
          placeholder="Share your thoughts"
          maxLength={500}
          onSendClick={sendMessage}
        />
      </div>
    </div>
  );
}

export default CommentInput;
