//@ts-nocheck
import { useEffect, useState } from "react";
import IconTextButton from "src/components/buttons/IconTextButton";
import Button from "src/components/buttons/Button";
import StepTitle from "src/components/MultiStepForm/Step/StepTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelStepEdition,
  confirmStep,
  navigateToSectionIndex, setAgreementStepStatus,
  setAgreementStepStatusSuccess,
  setAllStepsCompleted,
  setCurrentStepStatus,
  setCurrentStepStatusSuccess,
  setEditionMode
} from "src/store/actions/governance";
import {
  refreshFirstPageComments,
  refreshFirstPageCommentsSuccess,
  setCommentsDrawer,
  setMainHeader,
} from "src/store/actions/global";
import { getAvatars, getNextSection } from "src/utils/helpers/governance";
import { RootState } from "src/store/reducers";
import { hideModal, showModal } from "src/store/actions/modal";
import { updatePermissions } from "src/store/actions/auth";
import {
  Page,
  GovernancePermission,
  DashboardPermission,
} from "src/utils/helpers/permissions";
import {
  MODAL_CONFIRMATION,
  MODAL_STEP,
} from "src/components/modals/modalTypes";
import DynamicInput from "src/components/DynamicInput";
import { initialState } from "src/store/reducers/global";
import { CommentsType, DeviceType } from "src/components/shared/enums";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import useInterval from "../../../utils/customHooks/useInterval";

function DynamicStep() {
  const {
    onEditionMode,
    isStepLoading,
    previousSectionStep,
    currentSection,
    sections,
    currentSectionStep,
    stepValidation,
    allStepsCompleted,
  } = useSelector((state: RootState) => state.governance);
  const isCommentsDrawerOpened = useSelector(
    ({ global }: RootState) => global.commentsDrawer.isOpened
  );
  const { isFullPageLoading } = useSelector(({ global }: RootState) => global);
  const deviceType = useDeviceType();
  const { page, session } = useSelector(({ auth }: RootState) => auth);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);
  const [isSkipButtonLoading, setIsSkipButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const isMobile = deviceType === DeviceType.Mobile;
  const permission =
    page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;

  useEffect(() => {
    return () => {
      dispatch(
        setCurrentStepStatusSuccess({
          currentSectionStep: null,
          previousSectionStep: null,
          allStepsCompleted: null,
        })
      );
    };
  }, []);

  useEffect(() => {
    dispatch(
      setMainHeader({
        showMenuDrawerOnDesktop: true,
        onBackButtonClick: isMobile ? onBackButtonClick : undefined,
        showLogo: true,
        avatars: getAvatars(
          currentSectionStep?.stepConfirmedBy,
          currentSectionStep?.missingToConfirm
        ),
        showNotificationsButton: true,
        showStepStatus: true,
        showHamburgerButton: true,
        progress:
          page === Page.GOVERNANCE
            ? currentSectionStep?.progressPercentage
            : null,
      })
    );
    if ((currentSectionStep?.modal || [])?.length > 0) {
      dispatch(
        showModal(MODAL_STEP, {
          onCloseButtonClick: () => dispatch(hideModal(MODAL_STEP)),
        })
      );
    }
  }, [currentSectionStep, deviceType]);

  useEffect(() => {
    if (allStepsCompleted === false) {
      dispatch(
        showModal(MODAL_CONFIRMATION, {
          title: "Complete all steps to move forward",
          description: (
            <>
              <p>
                The next section will become available once you have completed
                all steps of the current one.
              </p>
              <p>
                Click the button below to fill out the missing information.{" "}
              </p>
            </>
          ),
          iconClass: "icon-alert",
          leftButtonText: "Take me there",
          onLeftButtonClick: () => {
            dispatch(hideModal(MODAL_CONFIRMATION));
            dispatch(setAgreementStepStatusSuccess());
            dispatch(
              updatePermissions({
                page,
                permission: permission.STEPS_STATUS,
              })
            );
          },

          onCloseButtonClick: () => {
            dispatch(setAllStepsCompleted(null));
            dispatch(hideModal(MODAL_CONFIRMATION));
          },
        })
      );
    }
  }, [allStepsCompleted]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!onEditionMode && !isCommentsDrawerOpened) {
        dispatch(
          setCurrentStepStatus(currentSectionStep?.code || "", true, true)
        );
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [currentSectionStep, onEditionMode, isCommentsDrawerOpened]);

  useEffect(() => {
    currentSection &&
    dispatch(setAgreementStepStatus(currentSection.code, false));
  }, [isMobile]);

  useInterval(() => {
    currentSection &&
    dispatch(setAgreementStepStatus(currentSection.code, false));
  }, 30000);

  const userConfirmedStep = currentSectionStep?.stepConfirmedBy.find(
    (user) => user.id === session?.user?.id
  );
  const isFirstToConfirm = currentSectionStep?.stepConfirmedBy?.length === 0;
  const isOnEditionMode = !isFirstToConfirm && onEditionMode;
  const isBackButtonDisabled =
    isOnEditionMode || (isStepLoading && !isFullPageLoading);

  if (
    isStepLoading === false &&
    (isConfirmButtonLoading || isSkipButtonLoading)
  ) {
    setIsConfirmButtonLoading(false);
    setIsSkipButtonLoading(false);
  }

  const onConfirmButtonClick = () => {
    if (!isStepLoading && !isConfirmButtonLoading) {
      setIsConfirmButtonLoading(true);
      dispatch(confirmStep());  
    }
  };

  const onBackButtonClick = () => {
    dispatch(setCommentsDrawer({ isOpened: false }));
    if (session?.group?.firstFlowPass) {
      if (previousSectionStep) {
        dispatch(setCurrentStepStatus(previousSectionStep));
      } else {
        dispatch(navigateToSectionIndex(currentSection, true));
      }
    } else {
      dispatch(setEditionMode(false));
      dispatch(
        updatePermissions({
          page: page,
          permission: permission.STEPS_STATUS,
        })
      );
    }
  };

  const onSkipButtonClick = () => {
    if (!isStepLoading && !isSkipButtonLoading) {
      setIsSkipButtonLoading(true);
      dispatch(setCommentsDrawer({ isOpened: false }));
      if (currentSectionStep?.nextStepCode) {
        dispatch(setCurrentStepStatus(currentSectionStep.nextStepCode, false));
      } else {
        console.log("Drawer", initialState.commentsDrawer.comments.value);
        dispatch(
          refreshFirstPageCommentsSuccess(
            initialState.commentsDrawer.comments.value,
            false
          )
        );
        const nextSection = getNextSection(sections!, currentSection!);
        if (nextSection) dispatch(navigateToSectionIndex(nextSection));
        else {
          dispatch(
            updatePermissions({
              page: Page.GOVERNANCE,
              permission: GovernancePermission.SECTIONS_STATUS,
            })
          );
        }
      }
    }
  };

  const onCancelButtonClick = () => {
    if (!isStepLoading && !isSkipButtonLoading) {
      setIsSkipButtonLoading(true);
      dispatch(cancelStepEdition());
    }
  };

  const openConfirmationModal = () =>
    dispatch(
      showModal(MODAL_CONFIRMATION, {
        title: "Are you sure you want to make this change?",
        description: (
          <p>
            If you make this change, all co-owners will have to re-execute your
            Co-ownership Agreement for it to take effect. Make any other
            necessary changes before re-executing (signing).
          </p>
        ),
        rightButtonText: "Cancel",
        onRightButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
        leftButtonText: "Apply change",
        onLeftButtonClick: () => {
          if (!isStepLoading && !isConfirmButtonLoading) {
            setIsConfirmButtonLoading(true);
            dispatch(confirmStep(false, true));
          }
        },
        onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
      })
    );

  const atLeastOneMemberSignedCOA = session?.group?.users.find(
    (user) => user.hasSignedCoAgreement
  );

  if (!currentSectionStep) return <></>;
  return (
    <div className="step-question-wrapper">
      <div className="step-question">
        <StepTitle
          title={currentSectionStep.title}
          tooltip={currentSectionStep.tooltip}
          description={currentSectionStep.subtitle}
          backButton={
            isMobile
              ? undefined
              : {
                  onBackButtonClick: onBackButtonClick,
                }
          }
          isDisabled={
            isSkipButtonLoading ||
            isConfirmButtonLoading ||
            isBackButtonDisabled
          }
        />
        <form onSubmit={(e) => e.preventDefault()}>
          {currentSectionStep?.formSectionStepFields.map((field, index) => (
            <DynamicInput
              key={index}
              formSectionStepField={field}
              fieldIndex={index}
              lastField={
                index === currentSectionStep?.formSectionStepFields.length - 1
              }
            />
          ))}
          {stepValidation &&
            !stepValidation.isValid &&
            stepValidation.errorMessage && (
              <div className="invalid-form">{stepValidation.errorMessage}</div>
            )}
        </form>
        <IconTextButton
          onClick={() => {
            if (!isStepLoading) {
              dispatch(
                refreshFirstPageComments(
                  currentSectionStep.id,
                  CommentsType.sectionStep,
                  false
                )
              );
              dispatch(
                setCommentsDrawer({ isOpened: true, id: currentSectionStep.id })
              );
            }
          }}
          text="Add a comment"
          iconClass="icon-plus"
          isDisabled={isSkipButtonLoading || isConfirmButtonLoading}
        />

        <Button
          text={
            isFirstToConfirm || !onEditionMode ? "Confirm" : "Save and confirm"
          }
          onClick={() =>
            atLeastOneMemberSignedCOA
              ? openConfirmationModal()
              : onConfirmButtonClick()
          }
          isDisabled={
            !stepValidation?.isValid ||
            isSkipButtonLoading ||
            (!session?.group?.firstFlowPass &&
              (isFirstToConfirm || !onEditionMode) &&
              !!userConfirmedStep)
          }
          isLoading={isConfirmButtonLoading && !atLeastOneMemberSignedCOA}
        />
        {(currentSectionStep?.isSkippableToNextStep ||
          !session?.group?.firstFlowPass ||
          (!isFirstToConfirm && onEditionMode)) && (
          <Button
            className="tertiary-button"
            text={
              session?.group?.firstFlowPass &&
              (isFirstToConfirm || !onEditionMode)
                ? "Skip to next"
                : "Cancel"
            }
            onClick={() =>
              session?.group?.firstFlowPass &&
              (isFirstToConfirm || !onEditionMode)
                ? onSkipButtonClick()
                : onCancelButtonClick()
            }
            isLoading={isSkipButtonLoading}
            isDisabled={isConfirmButtonLoading}
          />
        )}
      </div>
    </div>
  );
}

export default DynamicStep;
