type CommentActionProps = {
  text: string;
};

function CommentAction({ text }: CommentActionProps) {
  return (
    <div className="comments-alert">
      <p>{text}</p>
    </div>
  );
}

export default CommentAction;
