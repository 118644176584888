import { useDispatch, useSelector } from "react-redux";
import { CoOwnersStep } from "src/components/shared/enums";
import { CoOwner } from "src/services/Api/types";
import { setCoOwnersStep } from "src/store/actions/coOwners";
import { RootState } from "src/store/reducers";

type PendingConsensusBannerContentProps = {
  coOwners: Array<CoOwner>;
  coOwner: CoOwner;
};

function PendingConsensusBannerContent({
  coOwners,
  coOwner,
}: PendingConsensusBannerContentProps) {
  const dispatch = useDispatch();
  const { invitedUserEmail, invitedUserFirstName, userIdToRemove } =
    useSelector(
      ({ signature }: RootState) => signature.coOwners.pendingConsensusActions
    );

  const isDeleteAction = !!userIdToRemove;
  const userToDeleteIsYourself =
    coOwner.isSelf && coOwner.id === userIdToRemove;
  const userToDelete = coOwners.find((user) => user.id === userIdToRemove);

  return isDeleteAction ? (
    coOwner.isSelf ? (
      <>
        You have a pending request to remove{" "}
        {`${
          userToDeleteIsYourself
            ? "yourself."
            : `${userToDelete?.firstName} ${userToDelete?.lastName} (${userToDelete?.email}).`
        } `}
        <button
          type={"button"}
          className="inline-button"
          onClick={() => dispatch(setCoOwnersStep(CoOwnersStep.Signature))}
        >
          Click here to respond
        </button>
      </>
    ) : (
      <>
        {coOwner.firstName} {coOwner.lastName} has a pending deletion request
      </>
    )
  ) : coOwner.isSelf ? (
    <>
      You have a pending request to add {invitedUserFirstName} (
      {invitedUserEmail}).{" "}
      <button
        type={"button"}
        className="inline-button"
        onClick={() => dispatch(setCoOwnersStep(CoOwnersStep.Signature))}
      >
        Click here to respond
      </button>
    </>
  ) : (
    <>{coOwner.firstName} has a pending addition request</>
  );
}

export default PendingConsensusBannerContent;
