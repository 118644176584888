import { DocumentCardType, Pagination, VaultContent, VaultDocument } from "src/services/Api/types";
import { DocumentVaultPermission, Page, PagePermission } from "src/utils/helpers/permissions";
import {
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_DOCUMENT_COMPLETE,
  DOWNLOAD_DOCUMENT_REJECTED,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DownloadDocumentAction,
  DownloadDocumentCompleteAction,
  DownloadDocumentRejectedAction,
  DownloadDocumentSuccessAction,
  GET_NEXT_PAGE_DOCUMENT_HISTORY,
  GET_NEXT_PAGE_DOCUMENT_HISTORY_REJECTED,
  GET_NEXT_PAGE_DOCUMENT_HISTORY_SUCCESS,
  GET_VAULT,
  GET_VAULT_DOCUMENT,
  GET_VAULT_DOCUMENT_HISTORY,
  GET_VAULT_DOCUMENT_HISTORY_REJECTED,
  GET_VAULT_DOCUMENT_HISTORY_SUCCESS,
  GET_VAULT_DOCUMENT_REJECTED,
  GET_VAULT_DOCUMENT_SUCCESS,
  GET_VAULT_REJECTED,
  GET_VAULT_SUCCESS,
  GetNextPageDocumentHistoryAction,
  GetNextPageDocumentHistoryRejectedAction,
  GetNextPageDocumentHistorySuccessAction,
  GetVaultAction,
  GetVaultDocumentAction,
  GetVaultDocumentHistoryAction,
  GetVaultDocumentHistoryRejectedAction,
  GetVaultDocumentHistorySuccessAction,
  GetVaultDocumentRejectedAction,
  GetVaultDocumentSuccessAction,
  GetVaultRejectedAction,
  GetVaultSuccessAction,
  REJECT_DOCUMENT,
  REJECT_DOCUMENT_REJECTED,
  REJECT_DOCUMENT_SUCCESS,
  RejectDocumentAction,
  RejectDocumentRejectedAction,
  RejectDocumentSuccessAction,
  RejectDocumentCompleteAction,
  SET_PREVIOUS_DOCUMENT_HISTORY_PERMISSION,
  SET_PREVIOUS_VAULT_DOCUMENT_PERMISSION,
  SET_SELECTED_DOCUMENT_ID,
  SetPreviousDocumentHistoryPermissionAction,
  SetPreviousVaultDocumentPermissionAction,
  SetSelectedDocumentIdAction,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_REJECTED,
  UPLOAD_DOCUMENT_SUCCESS,
  UploadDocumentAction,
  UploadDocumentRejectedAction,
  UploadDocumentSuccessAction, REJECT_DOCUMENT_COMPLETE
} from "../actions/vault";

export const initialState: {
  vaultContent: { value: VaultContent; isLoading: boolean };
  uploadDocument: { isLoading: boolean };
  deleteDocument: {
    isLoading: boolean;
    deleteDocumentSuccess: boolean | null;
    deleteDocumentRejected: boolean | null;
  };
  downloadDocument: {
    isLoading: boolean;
    downloadSuccess: boolean | null;
    downloadRejected: boolean | null;
  }
  selectedDocumentId: string | null;
  vaultDocument: {
    value: VaultDocument;
    isLoading: boolean;
    previousPage: Page;
    previousPermission: PagePermission;
  };
  vaultDocumentHistory: {
    value: Pagination<DocumentCardType>;
    isLoading: boolean;
    previousPage: Page;
    previousPermission: PagePermission;
  };
} = {
  vaultContent: {
    value: {
      COACard: null,
      MOACard: null,
      otherDocuments: null,
    },
    isLoading: false,
  },
  uploadDocument: { isLoading: false },
  deleteDocument: {
    isLoading: false,
    deleteDocumentRejected: null,
    deleteDocumentSuccess: null
  },
  downloadDocument: {
    isLoading: false,
    downloadRejected: null,
    downloadSuccess: null
  },
  selectedDocumentId: null,
  vaultDocument: {
    value: {
      confirmations: [],
      missingToConfirm: [],
      document: null,
      hasNewComments: false,
    },
    isLoading: false,
    previousPage: Page.DOCUMENT_VAULT,
    previousPermission: DocumentVaultPermission.DOCUMENT_VAULT,
  },
  vaultDocumentHistory: {
    value: {
      count: 0,
      currentPage: 1,
      data: null,
      lastPage: null,
      nextPage: null,
      prevPage: null,
    },
    isLoading: false,
    previousPage: Page.DOCUMENT_VAULT,
    previousPermission: DocumentVaultPermission.DOCUMENT_VAULT,
  },
};

export function vaultReducer(
  state = initialState,
  action:
    | GetVaultAction
    | GetVaultSuccessAction
    | GetVaultRejectedAction
    | GetVaultDocumentAction
    | GetVaultDocumentSuccessAction
    | GetVaultDocumentRejectedAction
    | GetVaultDocumentHistoryAction
    | GetVaultDocumentHistorySuccessAction
    | GetVaultDocumentHistoryRejectedAction
    | GetNextPageDocumentHistoryAction
    | GetNextPageDocumentHistorySuccessAction
    | GetNextPageDocumentHistoryRejectedAction
    | UploadDocumentAction
    | UploadDocumentSuccessAction
    | UploadDocumentRejectedAction
    | RejectDocumentAction
    | RejectDocumentSuccessAction
    | RejectDocumentRejectedAction
    | RejectDocumentCompleteAction
    | SetSelectedDocumentIdAction
    | SetPreviousDocumentHistoryPermissionAction
    | SetPreviousVaultDocumentPermissionAction
    | DownloadDocumentAction
    | DownloadDocumentSuccessAction
    | DownloadDocumentRejectedAction
    | DownloadDocumentCompleteAction
) {
  switch (action.type) {
    case GET_VAULT:
      return {
        ...state,
        vaultContent: { ...state.vaultContent, isLoading: true },
      };
    case GET_VAULT_SUCCESS:
      return {
        ...state,
        vaultContent: { value: action.payload.vaultContent, isLoading: false },
      };
    case GET_VAULT_REJECTED:
      return {
        ...state,
        vaultContent: { ...state.vaultContent, isLoading: false },
      };
    case GET_VAULT_DOCUMENT:
      return {
        ...state,
        vaultDocument: {
          ...state.vaultDocument,
          isLoading: !action.payload.isUpdate,
        },
      };
    case GET_VAULT_DOCUMENT_SUCCESS:
      return {
        ...state,
        vaultDocument: {
          ...state.vaultDocument,
          value: {
            ...state.vaultDocument.value,
            ...action.payload.vaultDocument,
          },
          isLoading: false,
        },
      };
    case GET_VAULT_DOCUMENT_REJECTED:
      return {
        ...state,
        vaultDocument: { ...state.vaultDocument, isLoading: false },
      };
    case GET_VAULT_DOCUMENT_HISTORY:
      return {
        ...state,
        vaultDocumentHistory: {
          ...state.vaultDocumentHistory,
          isLoading: true,
        },
      };
    case GET_VAULT_DOCUMENT_HISTORY_SUCCESS:
      return {
        ...state,
        vaultDocumentHistory: {
          ...state.vaultDocumentHistory,
          value: action.payload.vaultDocumentHistory,
          isLoading: false,
        },
      };
    case GET_VAULT_DOCUMENT_HISTORY_REJECTED:
      return {
        ...state,
        vaultDocumentHistory: {
          ...state.vaultDocumentHistory,
          isLoading: false,
        },
      };
    case GET_NEXT_PAGE_DOCUMENT_HISTORY:
      return {
        ...state,
        vaultDocumentHistory: {
          ...state.vaultDocumentHistory,
          isLoading: true,
        },
      };
    case GET_NEXT_PAGE_DOCUMENT_HISTORY_SUCCESS:
      return {
        ...state,
        vaultDocumentHistory: {
          ...state.vaultDocumentHistory,
          value: action.payload.vaultDocumentHistory,
          isLoading: false,
        },
      };
    case GET_NEXT_PAGE_DOCUMENT_HISTORY_REJECTED:
      return {
        ...state,
        vaultDocumentHistory: {
          ...state.vaultDocumentHistory,
          isLoading: false,
        },
      };
    case UPLOAD_DOCUMENT:
      return {
        ...state,
        uploadDocument: { ...state.uploadDocument, isLoading: true },
      };
    case UPLOAD_DOCUMENT_SUCCESS:
    case UPLOAD_DOCUMENT_REJECTED:
      return {
        ...state,
        uploadDocument: {
          isLoading: false,
        },
      };
    case REJECT_DOCUMENT:
      return {
        ...state,
        deleteDocument: { ...state.deleteDocument, isLoading: true },
      };
    case REJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          isLoading: false,
          deleteDocumentSuccess: true
        },
      };
    case REJECT_DOCUMENT_REJECTED:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          isLoading: false,
          deleteDocumentRejected: true
        },
      };
    case REJECT_DOCUMENT_COMPLETE:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          deleteDocumentSuccess: null,
          deleteDocumentRejected: null
        },
      };
    case SET_SELECTED_DOCUMENT_ID:
      return {
        ...state,
        selectedDocumentId: action.payload.id,
      };
    case SET_PREVIOUS_DOCUMENT_HISTORY_PERMISSION:
      return {
        ...state,
        vaultDocumentHistory: {
          ...state.vaultDocumentHistory,
          previousPage: action.payload.page,
          previousPermission: action.payload.permission,
        },
      };
    case SET_PREVIOUS_VAULT_DOCUMENT_PERMISSION:
      return {
        ...state,
        vaultDocument: {
          ...state.vaultDocument,
          previousPage: action.payload.page,
          previousPermission: action.payload.permission,
        },
      };
    case DOWNLOAD_DOCUMENT:
      return {
        ...state,
        downloadDocument: { ...state.downloadDocument, isLoading: true },
      };
    case DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadDocument: {
          isLoading: false,
          downloadSuccess: true
        },
      };
    case DOWNLOAD_DOCUMENT_REJECTED:
      return {
        ...state,
        downloadDocument: {
          isLoading: false,
          downloadRejected: true
        },
      };
    case DOWNLOAD_DOCUMENT_COMPLETE:
      return {
        ...state,
        downloadDocument: {
          downloadSuccess: null,
          downloadRejected: null
        },
      };
    default:
      return state;
  }
}
