import { AddUserModalProps } from "src/components/modals/AddUserModal";
import { ConfirmationModalProps } from "src/components/modals/ConfirmationModal";
import { InformationModalProps } from "src/components/modals/InformationModal";
import { ToastModalProps } from "src/components/modals/ToastModal";
import { StepModalProps } from "src/components/modals/StepModal";
import { SignatureModalProps } from "src/components/modals/SignatureModal";
import { AddDocumentModalProps } from "src/components/modals/AddDocumentModal";
import { HideModal, HIDE_MODAL, ShowModal, SHOW_MODAL } from "../actions/modal";

export const initialState: {
  confirmation: ConfirmationModalProps & { isOpen: boolean };
  information: InformationModalProps & { isOpen: boolean };
  addUser: AddUserModalProps & { isOpen: boolean };
  toast: ToastModalProps & { isOpen: boolean };
  step: StepModalProps & { isOpen: boolean };
  signature: SignatureModalProps & { isOpen: boolean };
  addDocument: AddDocumentModalProps & { isOpen: boolean };
} = {
  confirmation: { isOpen: false },
  information: { isOpen: false },
  addUser: { isOpen: false },
  toast: { isOpen: false },
  step: { isOpen: false },
  signature: { isOpen: false },
  addDocument: { isOpen: false },
};

export function modalReducer(
  state = initialState,
  action: ShowModal | HideModal
) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        [action.payload.modalName]: { ...action.payload.props, isOpen: true },
      };
    case HIDE_MODAL:
      return {
        ...state,
        [action.payload.modalName]: { isOpen: false },
      };
    default:
      return state;
  }
}
