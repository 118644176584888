import { useSelector } from "react-redux";
import { CoOwner, UserAccountDto } from "src/services/Api/types";
import { RootState } from "src/store/reducers";
import { convertJSONDateToLongStringLocalDate } from "src/utils/helpers/dates";

type PendingConsensusBannerContentProps = {
  coOwners: Array<CoOwner>;
  user: UserAccountDto;
};

function PendingConsensusBannerContent({
  coOwners,
  user,
}: PendingConsensusBannerContentProps) {
  const {
    createdByUserId,
    eventExpirationDate,
    invitedUserEmail,
    invitedUserFirstName,
    userIdToRemove,
    userIdsThatConfirmedEvent,
  } = useSelector(
    ({ signature }: RootState) => signature.coOwners.pendingConsensusActions
  );

  const isDeleteAction = !!userIdToRemove;
  const hasUserSigned = userIdsThatConfirmedEvent.includes(user.id);
  const isRequestCreatedByOtherUser = createdByUserId !== user.id;

  const getUserCompleteName = (userId: string | null) => {
    const user = coOwners.find((user) => user.id === userId);
    if (!user) return "";
    return `${user.firstName} ${user.lastName}`;
  };

  const getUserEmail = (userId: string) => {
    const user = coOwners.find((user) => user.id === userId);
    if (!user) return "";
    return `${user.email}`;
  };
  const isYourSelf = (userId: string) => {
    const user = coOwners.find((user) => user.id === userId);
    if (!user) return false;
    return user.isSelf;
  };

  return isDeleteAction ? (
    isRequestCreatedByOtherUser ? (
      hasUserSigned ? (
        <>
          The request to remove{" "}
          <b>
            {isYourSelf(userIdToRemove)
              ? "yourself"
              : getUserCompleteName(userIdToRemove)}
          </b>{" "}
          ({getUserEmail(userIdToRemove)}) will expire on{" "}
          <em>
            <b>
              {convertJSONDateToLongStringLocalDate(
                eventExpirationDate || "",
                true
              )}
            </b>
          </em>
        </>
      ) : (
        <>
          {getUserCompleteName(createdByUserId)} submitted a request to remove{" "}
          <b>
            {isYourSelf(userIdToRemove)
              ? "yourself"
              : createdByUserId === userIdToRemove
              ? "himself"
              : getUserCompleteName(userIdToRemove)}
          </b>{" "}
          ({getUserEmail(userIdToRemove)}). Sign the request before the
          expiration date:{" "}
          <em>
            <b>
              {convertJSONDateToLongStringLocalDate(
                eventExpirationDate || "",
                true
              )}
            </b>
          </em>
        </>
      )
    ) : (
      <>
        You submitted a request to remove{" "}
        <b>
          {isYourSelf(userIdToRemove)
            ? "yourself"
            : getUserCompleteName(userIdToRemove)}
        </b>{" "}
        ({getUserEmail(userIdToRemove)}). All co-owners will have to sign the
        request before the expiration date:{" "}
        <em>
          <b>
            {convertJSONDateToLongStringLocalDate(
              eventExpirationDate || "",
              true
            )}
          </b>
        </em>
      </>
    )
  ) : isRequestCreatedByOtherUser ? (
    hasUserSigned ? (
      <>
        The request to add <b>{invitedUserFirstName}</b> ({invitedUserEmail})
        will expire on{" "}
        <em>
          <b>
            {convertJSONDateToLongStringLocalDate(
              eventExpirationDate || "",
              true
            )}
          </b>
        </em>
      </>
    ) : (
      <>
        {getUserCompleteName(createdByUserId)} submitted a request to add{" "}
        <b>{invitedUserFirstName}</b> ({invitedUserEmail}). Sign the request
        before the expiration date:{" "}
        <em>
          <b>
            {convertJSONDateToLongStringLocalDate(
              eventExpirationDate || "",
              true
            )}
          </b>
        </em>
      </>
    )
  ) : (
    <>
      You submitted a request to add <b>{invitedUserFirstName}</b> (
      {invitedUserEmail}). All co-owners will have to sign the request before
      the expiration date:{" "}
      <em>
        <b>
          {convertJSONDateToLongStringLocalDate(
            eventExpirationDate || "",
            true
          )}
        </b>
      </em>
    </>
  );
}

export default PendingConsensusBannerContent;
