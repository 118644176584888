import { useContext, useState } from "react";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import {
  dateDiffInDays,
  formatDateFromString,
  formatIsoDateFromString,
  fromStepDataToStringDate,
  validDate,
} from "src/utils/helpers/dates";
import { SignUpFormData } from "src/pages/Onboarding/signUp/Leader";
import Storage from "src/services/LocalStorage";
import DateInput from "src/components/inputs/DateInput";
import { getClosingDateStatus } from "src/components/SegmentAnalytics/utils";
import {
  SegmentEvents,
  SegmentResponseStatus,
} from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";
import * as constants from "../../../../../constants";

function CoOwnedHomePurchaseClosingDateData({
  formData,
  nextStep,
  prevStep,
  saveData,
  progress,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const [stepData, setStepData] = useState(
    fromStepDataToStringDate(formData.coOwnedHomePurchaseDate) || ""
  );
  const [failed, setFailed] = useState(false);

  const checkDate = () => {
    const today = new Date();
    const date = formatIsoDateFromString(stepData);
    const closingDate = new Date(date);

    return (
      dateDiffInDays(today, closingDate) > -constants.CLOSING_DATE_LOWER_BOUND &&
      dateDiffInDays(today, closingDate) <= constants.CLOSING_DATE_UPPER_BOUND
    );
  };

  const onNextClick = () => {
    if (!!stepData) {
      const isCandidateQualified = checkDate();
      segmentTrack(SegmentEvents.LeaderPreSignUpStepSubmitted, {
        step: "Closing date of the home purchase?",
        data: {
          closing_date: formatDateFromString(stepData)
        },
        status: isCandidateQualified
          ? SegmentResponseStatus.Success
          : SegmentResponseStatus.Error,
      });
      if (isCandidateQualified) {
        const date = formatIsoDateFromString(stepData);
        saveData({
          coOwnedHomePurchaseDate: date,
        });
        Storage.setItem("coOwnedHomePurchaseClosingDate", new Date(date));
        nextStep();
      } else {
        setFailed(true);
      }
      segmentTrack(SegmentEvents.ClosingDateSubmitted, {
        closing_date: formatDateFromString(stepData),
        status: getClosingDateStatus(stepData),
      });
    }
  };

  const isValidDate = stepData.length !== 8 || validDate(stepData);

  return (
    <Step
      title="Closing date of the home purchase?"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={stepData.length !== 8 || !validDate(stepData)}
      onBackClick={() => prevStep()}
      progress={progress}
      failed={failed}
      failedDescription="During our beta phase, we're focused on helping co-owner groups who are either under contract on a new home or have jointly purchased a home within the last ten years."
    >
      <DateInput
        id="Date"
        label="Date"
        value={stepData}
        onChange={setStepData}
        errorMessage={
          isValidDate ? undefined : "Invalid date, format must be MM/DD/YYYY"
        }
        isValid={isValidDate}
      />
    </Step>
  );
}

export default CoOwnedHomePurchaseClosingDateData;
