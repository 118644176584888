import { useState } from "react";

type TableProps = {
  table: Array<Array<string>>;
  primaryTitle: string;
  secondaryTitle?: string;
  description?: string;
  isDropdown?: boolean;
};

function Table({
  isDropdown = false,
  primaryTitle,
  secondaryTitle,
  description,
  table,
}: TableProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`information-table ${isDropdown ? "dropdown-table" : ""} ${
        isOpen ? "open" : ""
      }`}
    >
      {secondaryTitle && <span>{secondaryTitle}</span>}
      {description && <span className="description">{description}</span>}
      <p className="information-table-title">
        {primaryTitle}
        {isDropdown && (
          <i className="icon-arrow-down" onClick={() => setIsOpen(!isOpen)}></i>
        )}
      </p>
      <div className="table">
        {table.map((row, rowIndex) => (
          <div key={rowIndex} className="row">
            {row.map((column, columnIndex) => (
              <span key={columnIndex}>
                {columnIndex !== 1 ? column : <b>{column}</b>}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Table;
