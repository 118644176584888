import { useDispatch, useSelector } from "react-redux";
import {
  refreshFirstPageComments,
  setCommentsDrawer,
} from "src/store/actions/global";
import { RootState } from "src/store/reducers";
import { CommentsType } from "../shared/enums";
import IconButton from "./IconButton";

type CommentsButtonProps = {
  hasNewComments?: boolean;
  numberOfComments?: number;
  disableClick?: boolean;
};

function CommentsButton({
  hasNewComments,
  numberOfComments,
  disableClick = false,
}: CommentsButtonProps) {
  const dispatch = useDispatch();
  const {
    isFullPageLoading,
    commentsDrawer: { isOpened: isCommentsDrawerOpened },
  } = useSelector(({ global }: RootState) => global);
  const { currentSectionStep, isStepLoading } = useSelector(
    ({ governance }: RootState) => governance
  );

  const onClick = () => {
    console.log("clicked...")
    if (!disableClick && !isStepLoading) {
      if (isCommentsDrawerOpened) {
        dispatch(setCommentsDrawer({ isOpened: false }));
      } else {
        if (currentSectionStep) {
          dispatch(
            setCommentsDrawer({
              isOpened: true,
              id: currentSectionStep.id,
              type: CommentsType.sectionStep,
            })
          );
          dispatch(
            refreshFirstPageComments(
              currentSectionStep.id,
              CommentsType.sectionStep,
              false
            )
          );
        }
      }
    }
  };

  return (
    <IconButton
      iconClassName="icon-chat"
      className="comments-button"
      hasNotifications={hasNewComments}
      isDisabled={isStepLoading && !isFullPageLoading}
      numberOfNotifications={numberOfComments}
      onClick={onClick}
    />
  );
}

export default CommentsButton;
