//@ts-nocheck
import { useContext, useState } from "react";
import TextInput from "src/components/inputs/TextInput";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import { IndividualInputsFormData } from "../index";
import { validateName } from "src/utils/helpers/validations";
import Select from "src/components/inputs/Select";
import { suffixOptions } from "src/components/shared/constants";
import { SegmentEvents } from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/reducers";
import { Session } from "../../../../services/Api/types";
import { updateSessionSuccess } from "../../../../store/actions/auth";

function FullNameData({
                        formData,
                        nextStep,
                        saveData,
                        progress
                      }: MultiStepFormChildrenProps<IndividualInputsFormData>) {
  const dispatch = useDispatch()
  const session = useSelector(({ auth }: RootState) => auth.session) as Session;
  const { segmentTrack, segmentIdentifyUser } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [stepData, setStepData] = useState(formData?.fullNameData || "");

  const onNextClick = () => {
    if (isValidName()) {
      const { firstName: first_name, lastName: last_name, ...other } = stepData;
      dispatch(updateSessionSuccess({ ...session, user: { ...session?.user!, ...stepData }} ))
      segmentIdentifyUser({ ...session, user: { ...session?.user, ...stepData }});
      segmentTrack(SegmentEvents.UserDetailsStepSubmitted, {
        step: "What's your full (legal) name?",
        data: { first_name, last_name, ...other },
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });
      saveData({ fullNameData: stepData });
      nextStep();
    }
  };

  const isValidName = () =>
    validateName(stepData?.firstName) && validateName(stepData?.lastName);

  return (
    <Step
      title="What's your full (legal) name?"
      description="Required for ID verification"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={!isValidName()}
      progress={progress}
      className="bottom-not-fixed"
    >
      <TextInput
        id="firstName"
        label="First name"
        value={stepData?.firstName}
        onChange={(firstName) => setStepData({ ...stepData, firstName })}
        name="firstName"
      />
      <TextInput
        id="mi"
        label="M.I."
        value={stepData?.mi}
        onChange={(mi) => setStepData({ ...stepData, mi })}
        name="mi"
      />
      <TextInput
        id="lastName"
        label="Last Name"
        value={stepData?.lastName}
        onChange={(lastName) => setStepData({ ...stepData, lastName })}
        name="lastName"
      />
      <Select
        id="suffix"
        label="Suffix, if applicable"
        onChange={(suffix) => setStepData({ ...stepData, suffix })}
        selectedId={stepData?.suffix}
        options={suffixOptions}
      />
    </Step>
  );
}

export default FullNameData;
