import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import OneTimeCode from "src/components/OneTimeCode";
import Api from "src/services/Api";
import { setFullPageLoading, setMainHeader } from "src/store/actions/global";
import { SignUpFormData } from "..";
import { showModal } from "src/store/actions/modal";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import { updatePermissions, updateSessionSuccess } from "src/store/actions/auth";
import { getDefaultPage, getDefaultPagePermission, getPermissions } from "src/utils/helpers/permissions";
import { maskPhoneNumber } from "src/utils/helpers/masks";
import {
  ErrorMessages,
  SegmentEvents,
  SegmentResponseStatus,
  SegmentUserType,
  ToastType
} from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";
import { captureMessage } from "@sentry/react";

function VerifyPhone({
                       formData: { emailChallengeId, phoneNumber, phoneNumberChallengeId },
                       prevStep,
                       saveData
                     }: MultiStepFormChildrenProps<SignUpFormData>) {
  const dispatch = useDispatch();
  const {
    segmentTrack,
    segmentIdentify,
    segmentGroup,
    marshalSegmentGroup
  } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [oneTimeCode, setOneTimeCode] = useState("");
  const [hasErrorCode, setHasErrorCode] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    dispatch(
      setMainHeader({
        hideBottomLine: true,
        onBackButtonClick: failed ? () => setFailed(false) : prevStep
      })
    );
  }, [failed]);

  const resendCode = async () => {
    try {
      setResendCodeLoading(true);
      const {
        data: { phoneNumberChallengeId }
      } = await Api.signUpVerifyPhone({
        emailChallengeId,
        phoneNumber
      });
      saveData({
        phoneNumberChallengeId
      });
      dispatch(
        showModal(MODAL_TOAST, {
          message: "Sent! Please check your phone."
        })
      );
    } catch (error) {
      captureMessage(JSON.stringify(error))
      dispatch(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
    } finally {
      setResendCodeLoading(false);
    }
  };

  const verifyCode = async (value: string) => {
    try {
      dispatch(setFullPageLoading(true));
      setHasErrorCode(false);
      const { data } = await Api.signUpSubmitPhoneCode(phoneNumberChallengeId, {
        emailChallengeId,
        phoneNumber,
        otpCode: value
      });
      const { success, ...session } = data;
      segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
        step: "Verify phone",
        data: value,
        status: SegmentResponseStatus.Success
      });
      segmentIdentify(session.user);
      segmentTrack(SegmentEvents.UserSignedUp, {
        user_id: session.user.id,
        user_to_group_id: session?.userToGroupId,
        email: session.user.email,
        phone: session.user.phoneNumber,
        user_type: session.invitation
          ? SegmentUserType.Member
          : SegmentUserType.Leader
      }, { groupId: session?.group?.id });

      if (session.invitation) {
        const verificationStatus = session.invitation.invitedByUser.isIdentityVerified ? { id_verification_status: "Some" } : {}
        segmentGroup(session.group.id, marshalSegmentGroup(session.group, { ...verificationStatus, governance_status: " " }));
        segmentTrack(SegmentEvents.AccountAddedUser, {
          user_id: session.user.id,
          user_to_group_id: session?.userToGroupId
        }, { groupId: session?.group?.id });
      }

      const permissions = getPermissions(session);
      const page = getDefaultPage(session);
      const permission = getDefaultPagePermission(page, session);
      dispatch(updateSessionSuccess(session));
      dispatch(updatePermissions({ permissions, page, permission }));
    } catch (error: any) {
      captureMessage(JSON.stringify(error))
      segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
        step: "Verify phone",
        data: value,
        status: SegmentResponseStatus.Error
      });
      if (error.status === 403) {
        setHasErrorCode(true);
      } else if (error.status === 400) {
        setHasErrorCode(true);
      } else {
        setFailed(true);
      }
    } finally {
      dispatch(setFullPageLoading(false));
    }
  };

  const onOneTimeCodeChange = (value: string) => {
    setOneTimeCode(value);
    value.length === 6 ? verifyCode(value) : setHasErrorCode(false);
  };

  return (
    <OneTimeCode
      otcFailed={failed}
      phone={maskPhoneNumber(phoneNumber)}
      onTryAgainButtonClick={prevStep}
      oneTimeCode={oneTimeCode}
      onOneTimeCodeChange={onOneTimeCodeChange}
      hasErrorCode={hasErrorCode}
      countDownButton={{
        text: "Resend text message",
        countDownText: "text message",
        time: 30,
        onButtonClick: resendCode,
        isLoading: resendCodeLoading
      }}
    />
  );
}

export default VerifyPhone;
