import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_SIGNATURE } from "src/components/modals/modalTypes";
import { AgreementDocumentCode } from "src/components/shared/enums";
import { updatePermissions } from "src/store/actions/auth";
import { setMainHeader } from "src/store/actions/global";
import { hideModal, showModal } from "src/store/actions/modal";
import { signAgreement } from "src/store/actions/signature";
import { RootState } from "src/store/reducers";
import { getAvatars } from "src/utils/helpers/governance";
import { DashboardPermission, GovernancePermission, Page } from "src/utils/helpers/permissions";
import ReviewAndSign from "./ReviewAndSign";

type ReviewAndSignAgreementProps = { code: AgreementDocumentCode };

function ReviewAndSignAgreement({ code }: ReviewAndSignAgreementProps) {
  const { agreements } = useSelector(({ signature }: RootState) => signature);
  const { page } = useSelector(({ auth }: RootState) => auth);
  const dispatch = useDispatch();

  const agreement = agreements[code];
  const isCoOwnershipAgreement =
    code === AgreementDocumentCode.CoOwnershipAgreement;

  useEffect(() => {
    dispatch(
      setMainHeader({
        showLogo: true,
        showMenuDrawerOnDesktop: true,
        showHamburgerButton: true,
        showNotificationsButton: true
      })
    );
  }, []);


  const onPrimaryButtonClick = () =>
    dispatch(
      showModal(MODAL_SIGNATURE, {
        title: "Sign agreement",
        description:
          "Please enter your full name and today’s date exactly as shown to complete the signing process.",
        onButtonClick: () =>
          dispatch(
            signAgreement({
              code,
              hash: agreement.hash.value!,
              eventId: agreement.agreementStatus.value?.eventId!
            })
          ),
        onCloseButtonClick: () => dispatch(hideModal(MODAL_SIGNATURE))
      })
    );

  const onSecondaryButtonClick = () =>
    dispatch(
      updatePermissions({
        page,
        permission:
          page === Page.GOVERNANCE
            ? GovernancePermission.SECTIONS_STATUS
            : DashboardPermission.SECTIONS_STATUS
      })
    );

  return (
    <ReviewAndSign
      title={
        isCoOwnershipAgreement
          ? "Review and sign your Co-ownership Agreement"
          : "Review and sign your Memo. of Agreement"
      }
      avatars={getAvatars(
        agreement.agreementStatus.value?.stepConfirmedBy,
        agreement.agreementStatus.value?.missingToConfirm
      )}
      documentContent={
        agreement.agreementStatus.value?.document.fileContent ?? ""
      }
      primaryButtonText="Continue to signing"
      secondaryButtonText={
        isCoOwnershipAgreement ? "Make a change before signing" : undefined
      }
      primaryButtonClick={onPrimaryButtonClick}
      secondaryButtonClick={
        isCoOwnershipAgreement ? onSecondaryButtonClick : undefined
      }
    />
  );
}

export default ReviewAndSignAgreement;
