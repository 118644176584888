import { FieldsValidationType } from "src/store/actions/governance";
import {
  FormSectionStepConfirmationRequestType,
  FormSectionStepType,
} from "../shared/types";
import { fillResetOtherFields } from "./fillResetOtherFields";
import { showHideOtherFields } from "./showHideOtherFields";

export const radioButtonGroupOnChange = (
  currentSectionStep: FormSectionStepType,
  stepAnswer: FormSectionStepConfirmationRequestType,
  fieldsValidation: FieldsValidationType,
  option: string | null
) => {
  let result = { _stepAnswer: stepAnswer, _fieldsValidation: fieldsValidation };
  result = showHideOtherFields(
    currentSectionStep,
    result._stepAnswer,
    result._fieldsValidation,
    option
  );

  result = fillResetOtherFields(
    currentSectionStep,
    result._stepAnswer,
    result._fieldsValidation,
    option
  );

  return result;
};
