import { useSelector } from "react-redux";
import { AgreementDocumentCode } from "src/components/shared/enums";
import { RootState } from "src/store/reducers";
import { DashboardPermission } from "src/utils/helpers/permissions";
import AgreementStatus from "../Governance/agreements/AgreementStatus";
import FinalizeAgreement from "../Governance/agreements/FinalizeAgreement";
import ReviewAndSignAgreement from "../Governance/agreements/ReviewAndSignAgreement";
import CoOwnershipAgreementSections from "../Governance/coOwnershipAgreementStatus/CoOwnershipAgreementSections";
import CoOwnershipAgreementSteps from "../Governance/coOwnershipAgreementStatus/CoOwnershipAgreementSteps";
import DynamicStep from "../Governance/DynamicStep";
import SectionsIndex from "../Governance/SectionsIndex";
import MainDashboard from "./MainDashboard";

function Dashboard() {
  // @ts-ignore
  const permission = useSelector(({ auth }: RootState) => auth.permission);

  if (permission === DashboardPermission.SECTIONS_INDEX)
    return <SectionsIndex />;
  if (permission === DashboardPermission.SECTION_STEPS) return <DynamicStep />;
  if (permission === DashboardPermission.SECTIONS_STATUS)
    return <CoOwnershipAgreementSections />;
  if (permission === DashboardPermission.STEPS_STATUS)
    return <CoOwnershipAgreementSteps />;
  if (permission === DashboardPermission.FINALIZE_CO_OWNERSHIP_AGREEMENT)
    return (
      <FinalizeAgreement code={AgreementDocumentCode.CoOwnershipAgreement} />
    );
  if (permission === DashboardPermission.SIGN_CO_OWNERSHIP_AGREEMENT)
    return (
      <ReviewAndSignAgreement
        code={AgreementDocumentCode.CoOwnershipAgreement}
      />
    );
  if (permission === DashboardPermission.CO_OWNERSHIP_AGREEMENT_STATUS)
    return (
      <AgreementStatus code={AgreementDocumentCode.CoOwnershipAgreement} />
    );
  if (permission === DashboardPermission.FINALIZE_MEMORANDUM_OF_AGREEMENT)
    return (
      <FinalizeAgreement code={AgreementDocumentCode.MemorandumOfAgreement} />
    );
  if (permission === DashboardPermission.SIGN_MEMORANDUM_OF_AGREEMENT)
    return (
      <ReviewAndSignAgreement
        code={AgreementDocumentCode.MemorandumOfAgreement}
      />
    );
  if (permission === DashboardPermission.MEMORANDUM_OF_AGREEMENT_STATUS)
    return (
      <AgreementStatus code={AgreementDocumentCode.MemorandumOfAgreement} />
    );
  else return <MainDashboard />;
}

export default Dashboard;
