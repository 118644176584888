import { IDocument } from "@cyntler/react-doc-viewer";
import Spinner from "../Spinner";

type DocViewerLoaderProps = {
  document: IDocument | undefined;
  fileName: string;
};

function DocViewerLoader({ document, fileName }: DocViewerLoaderProps) {
  return <Spinner />;
}

export default DocViewerLoader;
