import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import ModalLayout from "../ModalLayout";
import parse from "html-react-parser";
import Button from "src/components/buttons/Button";

export type StepModalProps = {
  information?: React.ReactNode;
  onCloseButtonClick?: () => void;
};

function StepModal() {
  const modal = useSelector((state: RootState) => state.modal.step);
  const [dotStep, setDotStep] = useState(1);
  const currentSectionStep = useSelector(
    (state: RootState) => state.governance.currentSectionStep
  );

  const dotSteps = (currentSectionStep?.modal || []).length;

  const onNextStep = () => {
    if (dotSteps === dotStep) {
      modal.onCloseButtonClick?.();
    } else {
      setDotStep(dotStep + 1);
    }
  };

  const content = (
    <div className="step-modal">
      <div onClick={modal.onCloseButtonClick} className="close-modal">
        <i className="icon-cross" />
      </div>
      {parse(currentSectionStep?.modal[dotStep - 1] || "")}
      {dotSteps > 1 && (
        <div className="dots">
          {Array(dotSteps)
            .fill("")
            .map((x, idx) => (
              <span
                key={idx}
                className={`${idx + 1 === dotStep ? "selected" : ""}`}
              />
            ))}
        </div>
      )}
      <Button text="OK" onClick={onNextStep} />
    </div>
  );

  return <ModalLayout content={content} />;
}

export default StepModal;
