//@ts-nocheck
import MemberItem from "src/components/MemberItem";
import { showModal, hideModal } from "src/store/actions/modal";
import { useDispatch, useSelector } from "react-redux";
import { UserGroupInvitationStatus } from "src/services/Api/enums";
import { useEffect, useState } from "react";
import { setFormState, setMainHeader } from "src/store/actions/global";
import { Invitations } from "src/services/Api/types";
import { RootState } from "src/store/reducers";
import {
  titlesState,
  getUsersWithInvitationStatus,
  WaitingStates,
} from "./helper";
import {
  updateSession,
  logout,
  updatePermissions,
} from "src/store/actions/auth";
import { deleteCoOwner, addCoOwner } from "src/store/actions/onboarding";
import InitialStepHeader from "src/components/MultiStepForm/InitialStep/InitialStepHeader";
import IconTextButton from "src/components/buttons/IconTextButton";
import { OnboardingPermission } from "src/utils/helpers/permissions";
import Button from "src/components/buttons/Button";
import {
  MODAL_ADD_USER,
  MODAL_CONFIRMATION,
} from "src/components/modals/modalTypes";
import useInterval from "src/utils/customHooks/useInterval";
import {
  resendInvitation,
  sendVerificationIdReminder,
} from "src/store/actions/coOwners";

function GroupStatus() {
  const [waitingState, setWaitingState] = useState<WaitingStates>(
    WaitingStates.JustCreated
  );
  const [users, setUsers] = useState<Invitations>([]);
  const session = useSelector((state: RootState) => state.auth.session);
  const isLoggingOut = useSelector(({ auth }: RootState) => auth.isLoggingOut);
  const dispatch = useDispatch();

  const groupData = session?.group;
  const userData = session?.user;

  useEffect(() => {
    dispatch(
      setMainHeader({
        showLogo: true,
        hideHeaderOnMobile: true,
      })
    );
  }, []);

  useInterval(() => {
    dispatch(updateSession(false, false, true));
  }, 30000);

  useEffect(() => {
    updateWaitingState();
  }, [userData, groupData]);

  const updateWaitingState = () => {
    const usersWithInvitationStatus = getUsersWithInvitationStatus(
      groupData!,
      userData!
    );
    const hasAlreadyCoOwners = users.length > 0;
    const newWaitingState = groupData?.allUsersSignedUp
      ? WaitingStates.WaitingForVerifiedId
      : hasAlreadyCoOwners
      ? WaitingStates.WaitingForSignUp
      : WaitingStates.JustCreated;

    if (
      usersWithInvitationStatus.filter((x) => x.id === userData?.id)[0]
        ?.status === UserGroupInvitationStatus.NotVerified &&
      groupData?.allUsersSignedUp
    ) {
      dispatch(setFormState({ formStep: 1, formName: "verificationId" }));
      /* Fix */
      console.log("Group Status: setFullPageLoading(false) / setIsLoadingUserData(false)");
      dispatch(setFullPageLoading(false));
      dispatch(setIsLoadingUserData(false));
      /********/
      dispatch(
        updatePermissions({
          permission: OnboardingPermission.VERIFICATION_ID,
        })
      );
    }
    setWaitingState(newWaitingState);
    setUsers(usersWithInvitationStatus);
  };

  const openDeleteConfirmationModal = (
    coOwnerIndex: number,
    name: string,
    isSelf: boolean
  ) => {
    const cancelInvitation =
      users[coOwnerIndex].status === UserGroupInvitationStatus.Invited;
    const title = cancelInvitation
      ? "Are you sure you want to cancel this invitation?"
      : isSelf
      ? `Are you sure you want to delete your account?`
      : `Are you sure you want to remove ${name} from your group?`;
    dispatch(
      showModal(MODAL_CONFIRMATION, {
        title,
        leftButtonText: "Yes",
        rightButtonText: "Cancel",
        onLeftButtonClick: () =>
          dispatch(deleteCoOwner(users, coOwnerIndex, isSelf)),
        onRightButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
        onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
      })
    );
  };

  const onAddButtonClick = () =>
    dispatch(
      showModal(MODAL_ADD_USER, {
        title: "Add new co-owner",
        description: "Invite a new co-owner to join your co-ownership group.",
        buttonText: "Send invite",
        onButtonClick: (coOwner: any) =>
          dispatch(
            addCoOwner({
              firstName: coOwner.firstName,
              email: coOwner.email,
              userRelationship: coOwner.userRelationship,
            })
          ),
        onCloseButtonClick: () => dispatch(hideModal(MODAL_ADD_USER)),
      })
    );

  const showAddCoOwnerButton = users.length < 4;

  return (
    <div className="content-wrapper ownership-group">
      <InitialStepHeader
        iconClassName="icon-users-circled"
        {...titlesState[waitingState]}
      />
      <h5 className="small-title">Group members</h5>
      {users.map((user, index) => (
        <MemberItem
          key={index}
          name={`${user.firstName} ${user.lastName}`}
          email={user.email}
          self={userData?.email === user.email}
          badgeClass={
            UserGroupInvitationStatus.Joined === user.status ||
            UserGroupInvitationStatus.Verified === user.status
              ? "success"
              : ""
          }
          badgeText={user.status}
          onRightButtonClick={() =>
            openDeleteConfirmationModal(
              index,
              `${user.firstName} ${user.lastName}`,
              userData?.email === user.email
            )
          }
          rightButtonText="Delete"
          onLeftButtonClick={
            userData?.email !== user.email
              ? user.status === UserGroupInvitationStatus.Invited
                ? () => dispatch(resendInvitation(users[index].id))
                : user.status === UserGroupInvitationStatus.NotVerified
                ? () => dispatch(sendVerificationIdReminder(users[index].id))
                : undefined
              : undefined
          }
          leftButtonText={
            user.status === UserGroupInvitationStatus.Invited
              ? "Resend"
              : user.status === UserGroupInvitationStatus.NotVerified
              ? "Send reminder"
              : undefined
          }
        />
      ))}
      {showAddCoOwnerButton && (
        <IconTextButton
          onClick={onAddButtonClick}
          text="Add another co-owner"
          iconClass="icon-plus"
          isDisabled={isLoggingOut}
        />
      )}
      <Button text="Log out" onClick={() => dispatch(logout())} />
    </div>
  );
}

export default GroupStatus;
