export const API_URL: string = (process.env.REACT_APP_API_URL as string);
export const API_KEY: string = (process.env.REACT_APP_API_KEY as string);

// GOOGLE
export const PUBLIC_RECAPTCHA_SITE_KEY: string = (process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY as string);
export const GOOGLE_LOGIN_CLIENT_ID: string = (process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID as string);

// SENTRY
export const SENTRY_DSN:string =  (process.env.REACT_APP_SENTRY_DSN as string);

// PADDLE
export const PADDLE_SANDBOX:boolean = process.env.REACT_APP_PADDLE_SANDBOX === "true";
export const PADDLE_VENDOR_ID:number = (parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID as string));

// VOUCHED
// export const VOUCHED_SANDBOX:boolean = process.env.REACT_APP_VOUCHED_SANDBOX === "true";
// export const VOUCHED_APP_ID:string = (process.env.REACT_APP_VOUCHED_APP_ID as string);

// SEGMENT
export const SEGMENT_KEY:string = (process.env.REACT_APP_SEGMENT_KEY as string)

// INTERCOM
export const INTERCOM_APP_ID:string = (process.env.REACT_APP_INTERCOM_APP_ID as string)
export const INTERCOM_VERIFICATION_SECRET:string = (process.env.REACT_APP_INTERCOM_VERIFICATION_SECRET as string)

// CLOSING DATE
export const CLOSING_DATE_LOWER_BOUND:number = 3653
export const CLOSING_DATE_UPPER_BOUND:number = 60
