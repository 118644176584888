import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import StepTitle from "./StepTitle";
import { useDispatch } from "react-redux";
import { setMainHeader } from "src/store/actions/global";
import Button from "src/components/buttons/Button";
import { Path } from "src/components/routing/routes";
import Fail from "src/components/Fail";

type StepProps = {
  children: React.ReactNode;
  onNextClick: (step?: number) => void;
  nextText: string;
  isButtonDisabled: boolean;
  isButtonLoading?: boolean;
  title?: string;
  description?: string;
  onBackClick?: (step?: number) => void;
  progress?: number;
  onFailedNextClick?: () => void;
  failedNextText?: string;
  failed?: boolean;
  failedTitle?: string;
  failedDescription?: string;
  failedFooter?: React.ReactNode;
  hideLogoOnMobile?: boolean;
  hideHeaderOnMobile?: boolean;
  tooltip?: string;
  className?: string;
};

export function Step({
  children,
  title,
  description,
  onNextClick,
  nextText,
  onBackClick,
  isButtonDisabled = false,
  isButtonLoading = false,
  progress,
  failedNextText = "Ok",
  onFailedNextClick,
  failed,
  failedTitle = "Bummer",
  failedDescription,
  failedFooter = (
    <div className="fail-footer-message">
      <p>Want to be notified when this changes?</p>
      <p>
        Email us: <a href="mailto:hello@cobuy.io">hello@cobuy.io</a>
      </p>
    </div>
  ),
  hideLogoOnMobile = false,
  hideHeaderOnMobile = false,
  tooltip,
  className = "",
}: StepProps) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setMainHeader({
        onBackButtonClick: failed ? undefined : onBackClick,
        showLogo: failed ? undefined : !hideLogoOnMobile,
        hideHeaderOnMobile: failed ? undefined : hideHeaderOnMobile,
        progress: failed ? null : progress ?? null,
        hideBottomLine: failed,
      })
    );
  }, [failed]);

  if (failed)
    return (
      <Fail
        title={failedTitle}
        description={failedDescription}
        buttonText={failedNextText}
        onButtonClick={() =>
          onFailedNextClick ? onFailedNextClick() : navigate(Path.Home)
        }
        footer={failedFooter}
      />
    );

  return (
    <div className={`step-question ${className}`}>
      <StepTitle title={title} tooltip={tooltip} description={description} />

      <form onSubmit={(e) => e.preventDefault()}>{children}</form>

      <Button
        text={nextText}
        onClick={onNextClick}
        isDisabled={isButtonDisabled}
        isLoading={isButtonLoading}
      />
    </div>
  );
}

export default Step;
