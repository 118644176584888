type FilterProps = { text: string; isSelected: boolean; onClick: Function };

function Filter({ text, isSelected, onClick }: FilterProps) {
  return (
    <li className={isSelected ? "selected" : ""} onClick={() => onClick()}>
      {text}
    </li>
  );
}

export default Filter;
