import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "src/store/reducers";
import { getPathFromPage } from "src/utils/helpers/routing";

type PublicRouteProps = { children: React.ReactNode };

function PublicRoute({ children }: PublicRouteProps) {
  const { isAuth, page } = useSelector((state: RootState) => state.auth);

  if (!isAuth || !page) return <>{children}</>;
  return <Navigate to={getPathFromPage[page]} replace />;
}

export default PublicRoute;
