import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import {
  getNextPageComments,
  refreshFirstPageComments,
  sendComment,
  sendCommentReceived,
  setCommentsDrawer
} from "src/store/actions/global";
import CommentInput from "./CommentInput";
import CommentMessage from "./CommentMessage";
import CommentAction from "./CommentAction";
import EmptyState from "src/components/EmptyState";
import Spinner from "src/components/Spinner";
import RightDrawer from "../RightDrawer";
import InfiniteScroll from "react-infinite-scroll-component";
import { useContext, useEffect, useRef, useState } from "react";
import useSwipe from "src/utils/customHooks/useSwipe";
import useInterval from "src/utils/customHooks/useInterval";
import useWindowDimensions from "src/utils/customHooks/useWindowDimensions";
import { AnalyticsContext, AnalyticsContextType } from "../../Analytics";
import { SegmentEvents } from "../../shared/enums";
import { Session } from "../../../services/Api/types";

function CommentsDrawer() {
  // @ts-ignore
  const session = useSelector(({ auth }: RootState) => auth.session) as Session;
  const {
    isOpened,
    id,
    type,
    comments: {
      value: comments,
      showSwipeLoader,
      isRefreshingFirstPage,
      isGettingNextPage,
      sendCommentSuccess
    }
  } = useSelector(({ global }: RootState) => global.commentsDrawer);

  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [isViewOnTop, setIsViewOnTop] = useState(true);
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement | null>(null);

  const { onTouchEnd, onTouchMove, onTouchStart, swipeDistance } = useSwipe({
    divRef: divRef,
    onSwipeDown: () =>
      id && type && dispatch(refreshFirstPageComments(id, type, true)),
    swipeThreshold: 50
  });

  useInterval(
    () =>
      isViewOnTop &&
      isOpened &&
      id &&
      type &&
      dispatch(refreshFirstPageComments(id, type, false)),
    5000
  );

  const onScroll = () => {
    if (divRef.current) {
      const { scrollTop } = divRef.current;
      if (scrollTop > height * 1.2) setIsViewOnTop(false);
      else if (scrollTop < height) setIsViewOnTop(true);
    }
  };

  const sendMessage = (message: string) => {
    id && type && dispatch(sendComment(id, type, message, isViewOnTop));
  };

  const fetchNextPage = () => {
    comments.nextPage && id && type && dispatch(getNextPageComments(id, type));
  };

  useEffect(() => {
    if (sendCommentSuccess) {
      segmentTrack(SegmentEvents.CommentSent, {
        user_id: session?.user?.id,
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });
      dispatch(sendCommentReceived());
    }
  }, [sendCommentSuccess, session?.user?.id, session?.userToGroupId, session?.group?.id]);

  if (!id || !type) return <></>;

  return (
    <RightDrawer isOpened={isOpened}>
      <div className="fixed-content">
        <div className="header">
          <p className="title">Comments</p>
          <i
            className="icon-cross"
            onClick={() => dispatch(setCommentsDrawer({ isOpened: false }))}
          />
        </div>
        <CommentInput sendMessage={sendMessage} />
      </div>

      <div
        id="comments-scroll-content"
        className="comments-scroll-content"
        ref={divRef}
        onScroll={onScroll}
        style={{
          paddingTop: `${swipeDistance > 100 ? 100 : swipeDistance}px`,
          transition: "all 0.2s"
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {comments.data === null ? (
          isRefreshingFirstPage ? (
            <Spinner />
          ) : (
            <EmptyState
              title="No comments yet"
              description="Open comments on this page will appear here"
              iconClass="icon-chat-circled"
            />
          )
        ) : comments.data.length !== 0 ? (
          <InfiniteScroll
            className="comments-infinite-scroll"
            dataLength={comments.data.length}
            next={fetchNextPage}
            hasMore={comments.nextPage !== null}
            loader={isGettingNextPage && <Spinner />}
            scrollableTarget="comments-scroll-content"
          >
            {showSwipeLoader && <Spinner />}
            {comments.data.map(
              (
                {
                  isChangeSuggestion,
                  isConfirmation,
                  writtenByUser: { firstName, lastName } = {
                    firstName: "",
                    lastName: ""
                  },
                  text,
                  createdAt
                },
                index
              ) => {
                const commonProps = { key: index, text, createdAt };
                if (isChangeSuggestion || isConfirmation)
                  return <CommentAction {...commonProps} />;
                return (
                  <CommentMessage
                    {...commonProps}
                    name={firstName}
                    lastName={lastName}
                  />
                );
              }
            )}
          </InfiniteScroll>
        ) : (
          <EmptyState
            title="No comments yet"
            description="Open comments on this page will appear here"
            iconClass="icon-chat-circled"
          />
        )}
      </div>
    </RightDrawer>
  );
}

export default CommentsDrawer;
