//@ts-nocheck
import { useContext, useState } from "react";
import { initialAddressData } from "src/components/inputs/AddressInput";
import RadioButtonGroup from "src/components/inputs/RadioButtonGroup";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import { SegmentEvents } from "src/components/shared/enums";
import { DoesLiveAtCoOwnedHome } from "src/services/Api/enums";
import { IndividualInputsFormData } from "..";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/reducers";
import { Session } from "../../../../services/Api/types";
import { updateSessionSuccess } from "../../../../store/actions/auth";

const DoesLiveAtCoOwnedHomeOptions = [
  { id: DoesLiveAtCoOwnedHome.Yes, value: "Yes" },
  {
    id: DoesLiveAtCoOwnedHome.NotYet,
    value: "Not yet, but I will after closing"
  },
  { id: DoesLiveAtCoOwnedHome.No, value: "No" }
];

function CoOwnedHomeData({
                           formData,
                           nextStep,
                           prevStep,
                           saveData,
                           progress
                         }: MultiStepFormChildrenProps<IndividualInputsFormData>) {
  const session = useSelector(({ auth }: RootState) => auth.session) as Session;
  const { segmentTrack, segmentIdentifyUser } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [stepData, setStepData] = useState(formData.coOwnedHomeData);
  const dispatch = useDispatch()

  const onNextClick = () => {
    saveData({ coOwnedHomeData: stepData });
    const { live: occupant, address: { zipCode: zip_code, ...addressData } } = stepData
    dispatch(updateSessionSuccess({ ...session, user: { ...session?.user!, address: { ...session?.user?.address!, ...stepData } } }))
    segmentIdentifyUser({ ...session, user: { ...session?.user, address: { ...session?.user?.address, ...stepData } } } );
    segmentTrack(SegmentEvents.UserDetailsStepSubmitted, {
      step: "Do you live at the co-owned home?",
      data: { occupant, ...{ address: { zip_code, ...addressData}} },
      user_to_group_id: session?.userToGroupId
    }, { groupId: session?.group?.id });
    if (stepData?.live === "Yes") {
      saveData({
        coOwnedHomeData: {
          live: stepData.live
        }
      });
      nextStep(6);
    } else nextStep();
  };

  return (
    <Step
      title="Do you live at the co-owned home?"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={!stepData?.live}
      progress={progress}
      onBackClick={() => prevStep()}
    >
      <RadioButtonGroup
        options={DoesLiveAtCoOwnedHomeOptions}
        selectedId={stepData?.live}
        onChange={(live: DoesLiveAtCoOwnedHome) =>
          setStepData({
            address: stepData?.address || initialAddressData,
            live
          })
        }
      />
    </Step>
  );
}

export default CoOwnedHomeData;
