import Checkbox from "./Checkbox";

type CheckboxButtonProps = {
  id?: string;
  name?: string;
  label?: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  isDisabled?: boolean;
};

function CheckboxButton({
  id,
  name,
  label,
  checked,
  onChange,
  isDisabled,
}: CheckboxButtonProps) {
  const checkedClass = checked ? "checked" : "";
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`checkbox-button ${checkedClass} ${disabledClass}`}>
      <Checkbox
        id={id}
        name={name}
        label={label}
        checked={checked}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default CheckboxButton;
