import IconButton from "./IconButton";

type BackButtonProps = { onClick?: () => void; isDisabled?: boolean };

function BackButton({ onClick, isDisabled = false }: BackButtonProps) {
  return (
    <IconButton
      className="back-button"
      iconClassName="icon-back"
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}

export default BackButton;
