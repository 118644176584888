import { shuffleArray } from "./helpers";

export function dateDiffInDays(a: any, b: any) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY) + 1;
}

export const validDate = (date: string) => {
  if (date.length !== 8) {
    return false;
  }
  const formatDate = formatDateFromString(date);
  const isValid =
    /^(?:(0[1-9]|1[012])[/.](0[1-9]|[12][0-9]|3[01])[/.](19|20)[0-9]{2})$/.test(
      formatDate
    );
  return isValid;
};

export const formatDateFromString = (date: string) => {
  const day = date.substring(2, 4);
  const month = date.substring(0, 2);
  const year = date.substring(4, 8);
  return `${month}/${day}/${year}`;
};

export const formatIsoDateFromString = (date: string) => {
  const day = date.substring(2, 4);
  const month = date.substring(0, 2);
  const year = date.substring(4, 8);
  return `${year}-${month}-${day}`;
};

export const localDateAndTimeFromJSON = (jsonDate: string, short?: boolean) => {
  const date = localDateFromJSON(jsonDate, short);
  const time = localTimeFromJSON(jsonDate);

  return `${date} - ${time}`;
};

export const localDateFromJSON = (jsonDate: string, short?: boolean) => {
  const date = new Date(jsonDate);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: short ? "numeric" : "2-digit",
    day: short ? "numeric" : "2-digit",
  });
};

export const localTimeFromJSON = (jsonDate: string) => {
  const date = new Date(jsonDate);
  return date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

export const fromStepDataToStringDate = (date?: string) => {
  if (!date) {
    return date;
  }
  const split = date.split("-");
  return `${split[1]}${split[2]}${split[0]}`;
};

export const olderThan18 = (dateString: string) => {
  if (dateString.length !== 8) {
    return false;
  }
  const date = formatDateFromString(dateString);
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age >= 18;
};

export const dateInTheFuture = (dateString: string) => {
  const formattedDate = formatDateFromString(dateString);
  const now = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  ).getTime();
  const date = new Date(formattedDate).getTime();

  return now < date;
};

export const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ordinal = (number: number) => {
  number = Number(number);
  if (!number || Math.round(number) !== number) {
    return number;
  }
  const signal = number < 20 ? number : Number(("" + number).slice(-1));
  switch (signal) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const dateIsToday = (dateString: string) => {
  const formattedDate = formatDateFromString(dateString);
  const date = new Date(formattedDate).getTime();
  const now = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  ).getTime();

  return now === date;
};

export const convertPurchaseDates = (dates: string[]) => {
  return shuffleArray(
    dates.map((date: string) => {
      return {
        id: date.split("T")[0],
        value: convertJSONDateToLongStringUTCDate(date),
      };
    })
  );
};

export const convertJSONDateToLongStringUTCDate = (jsonDate: string) => {
  const d = new Date(jsonDate);
  const day = d.getUTCDate();
  const month = months[d.getUTCMonth()];
  const year = d.getUTCFullYear();
  const ord = ordinal(d.getUTCDate()).toString();
  return `${month} ${day}${ord}, ${year}`;
};

export const convertJSONDateToLongStringLocalDate = (
  jsonDate: string,
  showTime: boolean
) => {
  const d = new Date(jsonDate);
  const day = d.toLocaleString("en-US", { day: "numeric" });
  const month = d.toLocaleString("en-US", { month: "long" });
  const year = d.toLocaleString("en-US", { year: "numeric" });
  const time = d.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const ord = ordinal(d.getUTCDate()).toString();
  return `${month} ${day}${ord}, ${showTime ? time : year}`;
};
