import Button from "src/components/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setMainHeader } from "src/store/actions/global";
import { RootState } from "src/store/reducers";
import { setCurrentStepStatus } from "src/store/actions/governance";
import Section from "src/components/Section";
import { SectionType } from "src/components/shared/types";

function SectionsIndex() {
  const { isStepLoading, currentSection, sections } = useSelector(
    (state: RootState) => state.governance
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setMainHeader({
        showLogo: true,
        hideHeaderOnMobile: true,
        showMenuDrawerOnDesktop: true,
      })
    );
  }, []);

  const compare = (a: SectionType, b: SectionType) => {
    if (a.index < b.index) return -1;
    if (a.index > b.index) return 1;
    return 0;
  };

  if (!sections) return <></>;
  const orderedSections = sections ? [...sections].sort(compare) : [];
  return (
    <div className="sections-index-wrapper">
      <div className="sections-index">
        {orderedSections.map((section, index) => (
          <Section
            key={index}
            id={section.id}
            isChecked={section.code === currentSection?.code}
            title={section.title}
            subtitle={section.subtitle}
            icon={section.icon}
          />
        ))}

        <div className="bottom-fixed">
          <Button
            text="Next"
            onClick={() =>
              !isStepLoading &&
              dispatch(
                setCurrentStepStatus(
                  currentSection?.firstSectionStep || "",
                  false
                )
              )
            }
            isLoading={isStepLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default SectionsIndex;
