//@ts-nocheck
import MultiStepForm from "src/components/MultiStepForm";
import AddressData from "./steps/AddressData";
import BirthDateData from "./steps/BirthDateData";
import CoBorrowerData from "./steps/CoBorrowerData";
import CoOwnedHomeData from "./steps/CoOwnedHomeData";
import FullNameData from "./steps/FullNameData";
import { useDispatch, useSelector } from "react-redux";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import { DoesLiveAtCoOwnedHome } from "src/services/Api/enums";
import { RootState } from "src/store/reducers";
import { updatePermissions } from "src/store/actions/auth";
import { CoOwnedHomeAddressType } from "src/services/Api/types";
import {
  getDefaultPage,
  getDefaultPagePermission,
  getPermissions,
} from "src/utils/helpers/permissions";
import PendingInvitation from "./steps/PendingInvitation";
import { setFullPageLoading, setIsLoadingUserData } from "../../../store/actions/global";

export type IndividualInputsFormData = {
  fullNameData: FullNameDataType;
  birthDate: string;
  coOwnedHomeData: CoOwnedHomeDataType;
  coBorrower: "Yes" | "No";
  coOwnedHomeAddress: CoOwnedHomeAddressType;
  coOwnedHomePurchaseClosingDate: string;
};

export type FullNameDataType = {
  firstName?: string;
  mi?: string;
  lastName?: string;
  suffix?: string;
};

export type CoOwnedHomeDataType = {
  live: DoesLiveAtCoOwnedHome;
  address: CoOwnedHomeAddressType;
};

function IndividualInputsForm() {
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.auth.session);
  const initialFormStep =
    !session?.invitation || session?.invitation?.opened ? 2 : 1;

  return (
    <MultiStepForm<IndividualInputsFormData>
      totalSteps={7}
      formName="individualInputs"
      initialFormStep={initialFormStep}
      stepsNumbersForProgress={[2, 3, 4, 5, 6]}
    >
      {({ ...props }) => {
        switch (props.formStep) {
          case 1:
            return <PendingInvitation {...props} />;
          case 2:
            return <FullNameData {...props} />;
          case 3:
            return <BirthDateData {...props} />;
          case 4:
            return <CoOwnedHomeData {...props} />;
          case 5:
            return <AddressData {...props} />;
          case 6:
            return <CoBorrowerData {...props} />;
          case 7: {
            return (
              <InitialStep
                title="Congratulations! You’ve successfully"
                boldTitle="created an account."
                primaryButtonText="Next"
                onPrimaryButtonClick={() => {
                  const permissions = getPermissions(session!);
                  const page = getDefaultPage(session!);
                  const permission = getDefaultPagePermission(page, session!);
                  dispatch(
                    updatePermissions({
                      page,
                      permissions,
                      permission,
                    })
                  );
                  /* Fix */
                  console.log("setFullPageLoading(false) / setIsLoadingUserData(false)");
                  dispatch(setFullPageLoading(false));
                  dispatch(setIsLoadingUserData(false));
                  /********/
                }}
                iconClass="icon-check-circled"
              />
            );
          }
          default:
            break;
        }
      }}
    </MultiStepForm>
  );
}

export default IndividualInputsForm;
