type ModalLayoutProps = {
  content: React.ReactNode;
};

function ModalLayout({ content }: ModalLayoutProps) {
  return (
    <div className="modal-overlay">
      <div className="modal">{content}</div>
    </div>
  );
}

export default ModalLayout;
