//@ts-nocheck
import { useSelector } from "react-redux";
import DynamicStep from "src/pages/Governance/DynamicStep";
import SectionsIndex from "src/pages/Governance/SectionsIndex";
import { RootState } from "src/store/reducers";
import { GovernancePermission } from "src/utils/helpers/permissions";
import CoCreateCoOwnershipAgreement from "./coOwnershipAgreementStatus/CoCreateCoOwnershipAgreement";
import CoOwnershipAgreementSections from "./coOwnershipAgreementStatus/CoOwnershipAgreementSections";
import CoOwnershipAgreementSteps from "./coOwnershipAgreementStatus/CoOwnershipAgreementSteps";
import FinalizeAgreement from "./agreements/FinalizeAgreement";
import { AgreementDocumentCode } from "src/components/shared/enums";
import AgreementStatus from "./agreements/AgreementStatus";
import ReviewAndSignAgreement from "./agreements/ReviewAndSignAgreement";

function Governance() {
  const permission = useSelector(({ auth }: RootState) => auth.permission);

  if (permission === GovernancePermission.INITIAL_SCREEN)
    return <CoCreateCoOwnershipAgreement />;
  if (permission === GovernancePermission.SECTIONS_INDEX)
    return <SectionsIndex />;
  if (permission === GovernancePermission.SECTION_STEPS) return <DynamicStep />;
  if (permission === GovernancePermission.STEPS_STATUS)
    return <CoOwnershipAgreementSteps />;
  if (permission === GovernancePermission.FINALIZE_CO_OWNERSHIP_AGREEMENT)
    return (
      <FinalizeAgreement code={AgreementDocumentCode.CoOwnershipAgreement} />
    );
  if (permission === GovernancePermission.SIGN_CO_OWNERSHIP_AGREEMENT)
    return (
      <ReviewAndSignAgreement
        code={AgreementDocumentCode.CoOwnershipAgreement}
      />
    );
  if (permission === GovernancePermission.CO_OWNERSHIP_AGREEMENT_STATUS)
    return (
      <AgreementStatus code={AgreementDocumentCode.CoOwnershipAgreement} />
    );
  if (permission === GovernancePermission.FINALIZE_MEMORANDUM_OF_AGREEMENT)
    return (
      <FinalizeAgreement code={AgreementDocumentCode.MemorandumOfAgreement} />
    );
  if (permission === GovernancePermission.SIGN_MEMORANDUM_OF_AGREEMENT)
    return (
      <ReviewAndSignAgreement
        code={AgreementDocumentCode.MemorandumOfAgreement}
      />
    );
  if (permission === GovernancePermission.MEMORANDUM_OF_AGREEMENT_STATUS)
    return (
      <AgreementStatus code={AgreementDocumentCode.MemorandumOfAgreement} />
    );
  else return <CoOwnershipAgreementSections />;
}

export default Governance;
