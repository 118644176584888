import { useDispatch } from "react-redux";
import { hideModal, showModal } from "src/store/actions/modal";
import { MODAL_INFORMATION } from "../modals/modalTypes";

type TooltipProps = {
  content?: string;
  iconClass?: string;
};

function Tooltip({ content, iconClass = "icon-info" }: TooltipProps) {
  const dispatch = useDispatch();

  const showTooltip: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    dispatch(
      showModal(MODAL_INFORMATION, {
        information: content,
        onCloseButtonClick: () => dispatch(hideModal(MODAL_INFORMATION)),
      })
    );
  };
  return (
    <>
      {content && (
        <i className={`tooltip ${iconClass}`} onClick={showTooltip} />
      )}
    </>
  );
}

export default Tooltip;
