import MultiStepForm from "src/components/MultiStepForm";
import Login from "./steps/Login";
import VerifyEmail from "./steps/VerifyEmail";

export type LoginFormData = {
  email: string;
  emailChallengeId: string;
};

function LoginForm() {
  return (
    <MultiStepForm<LoginFormData> totalSteps={2} formName="login">
      {({ ...props }) => {
        switch (props.formStep) {
          case 1:
            return <Login {...props} />;
          case 2:
            return <VerifyEmail {...props} />;
          default:
            break;
        }
      }}
    </MultiStepForm>
  );
}

export default LoginForm;
