import { call, put, select, takeLatest } from "redux-saga/effects";
import Api from "src/services/Api";
import { MESSAGE_TO_TAKE } from "src/components/shared/constants";
import { ChatMessageType } from "src/components/shared/types";
import { RootState } from "../reducers";
import { Pagination } from "src/services/Api/types";
import {
  getChatGroupRejected,
  getChatGroupSuccess,
  GET_CHAT_GROUP,
  getNextPageMessagesRejected,
  getNextPageMessagesSuccess,
  GET_NEXT_PAGE_MESSAGES,
  refreshFirstPageMessages,
  RefreshFirstPageMessagesAction,
  refreshFirstPageMessagesRejected,
  refreshFirstPageMessagesSuccess,
  REFRESH_FIRST_PAGE_MESSAGES,
  SendChatMessageAction,
  sendChatMessageRejected,
  sendChatMessageSuccess,
  SEND_CHAT_MESSAGE,
  setShowSwipeLoader,
} from "../actions/chat";
import { mergePagination } from "src/utils/helpers/helpers";
// import { SegmentEvents } from "src/components/shared/enums";
import { captureMessage } from "@sentry/react";

export default function* chatSaga() {
  yield takeLatest(GET_CHAT_GROUP, getChatGroupSaga);
  yield takeLatest(GET_NEXT_PAGE_MESSAGES, getNextPageMessagesSaga);
  yield takeLatest(REFRESH_FIRST_PAGE_MESSAGES, refreshFirstPageMessagesSaga);
  yield takeLatest(SEND_CHAT_MESSAGE, sendChatMessageSaga);
}

// GET CHAT GROUPS
export function* getChatGroupSaga() {
  try {
    const { data } = yield call(Api.getChatGroup);
    yield put(getChatGroupSuccess([data.chats]));
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(getChatGroupRejected());
  } finally {
  }
}

// GET NEXT PAGE MESSAGES
export function* getNextPageMessagesSaga() {
  const currentChatPagination: Pagination<ChatMessageType> = yield select(
    ({ chat }: RootState) => chat.chatMessages.value
  );

  try {
    const {
      data: { chats: newChatPagination },
    } = yield call(
      Api.getChatMessages,
      currentChatPagination.nextPage ?? 1,
      MESSAGE_TO_TAKE
    );

    const chats = mergePagination<ChatMessageType>(
      currentChatPagination,
      newChatPagination
    );

    yield put(getNextPageMessagesSuccess(chats));
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(getNextPageMessagesRejected());
  } finally {
  }
}

// REFRESH FIRST PAGE MESSAGES
export function* refreshFirstPageMessagesSaga({
  payload,
}: RefreshFirstPageMessagesAction) {
  try {
    if (payload.isSwipeRefresh) yield put(setShowSwipeLoader(true));
    const { data } = yield call(Api.getChatMessages, 1, MESSAGE_TO_TAKE);
    yield put(refreshFirstPageMessagesSuccess(data.chats));
    if (payload.refreshChatGroup) yield call(getChatGroupSaga);
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(refreshFirstPageMessagesRejected());
  } finally {
    if (payload) yield put(setShowSwipeLoader(false));
  }
}

// SEND CHAT MESSAGE
export function* sendChatMessageSaga({ payload }: SendChatMessageAction) {
  try {
    yield call(Api.sendChatMessage, { text: payload.message });
    // TODO: Move to use hooks
    // segmentTrack(SegmentEvents.UserSentMessageToChat);
    yield put(sendChatMessageSuccess());
    if (payload.isOnBottom) yield put(refreshFirstPageMessages(false, false));
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(sendChatMessageRejected());
  } finally {
  }
}
