import { AgreementDocumentCode } from "src/components/shared/enums";
import {
  ConsensusEventStatusDto,
  PendingConsensusActions,
  SignAddMemberType,
  SignDeleteMemberType,
  SignUploadDocumentType,
} from "src/services/Api/types";

// GET AGREEMENT HASH
// Pending
export const GET_AGREEMENT_HASH = "GET_AGREEMENT_HASH";
export interface GetAgreementHashAction {
  type: typeof GET_AGREEMENT_HASH;
  payload: { code: AgreementDocumentCode };
}
export const getAgreementHash = (
  code: AgreementDocumentCode
): GetAgreementHashAction => ({
  type: GET_AGREEMENT_HASH,
  payload: { code },
});
// Success
export const GET_AGREEMENT_HASH_SUCCESS = "GET_AGREEMENT_HASH_SUCCESS";
export interface GetAgreementHashSuccessAction {
  type: typeof GET_AGREEMENT_HASH_SUCCESS;
  payload: { code: AgreementDocumentCode; hash: string };
}
export const getAgreementHashSuccess = (
  code: AgreementDocumentCode,
  hash: string
): GetAgreementHashSuccessAction => ({
  type: GET_AGREEMENT_HASH_SUCCESS,
  payload: { code, hash },
});
// Rejected
export const GET_AGREEMENT_HASH_REJECTED = "GET_AGREEMENT_HASH_REJECTED";
export interface GetAgreementHashRejectedAction {
  type: typeof GET_AGREEMENT_HASH_REJECTED;
  payload: { code: AgreementDocumentCode };
}
export const getAgreementHashRejected = (
  code: AgreementDocumentCode
): GetAgreementHashRejectedAction => ({
  type: GET_AGREEMENT_HASH_REJECTED,
  payload: { code },
});

// GET AGREEMENT STATUS
// Pending
export const GET_AGREEMENT_STATUS = "GET_AGREEMENT_STATUS";
export interface GetAgreementStatusAction {
  type: typeof GET_AGREEMENT_STATUS;
  payload: { code: AgreementDocumentCode; hash: string };
}
export const getAgreementStatus = (
  code: AgreementDocumentCode,
  hash: string
): GetAgreementStatusAction => ({
  type: GET_AGREEMENT_STATUS,
  payload: { code, hash },
});
// Success
export const GET_AGREEMENT_STATUS_SUCCESS = "GET_AGREEMENT_STATUS_SUCCESS";
export interface GetAgreementStatusSuccessAction {
  type: typeof GET_AGREEMENT_STATUS_SUCCESS;
  payload: {
    code: AgreementDocumentCode;
    agreementStatus: ConsensusEventStatusDto;
  };
}
export const getAgreementStatusSuccess = (
  code: AgreementDocumentCode,
  agreementStatus: ConsensusEventStatusDto
): GetAgreementStatusSuccessAction => ({
  type: GET_AGREEMENT_STATUS_SUCCESS,
  payload: { code, agreementStatus },
});
// Rejected
export const GET_AGREEMENT_STATUS_REJECTED = "GET_AGREEMENT_STATUS_REJECTED";
export interface GetAgreementStatusRejectedAction {
  type: typeof GET_AGREEMENT_STATUS_REJECTED;
  payload: { code: AgreementDocumentCode };
}
export const getAgreementStatusRejected = (
  code: AgreementDocumentCode
): GetAgreementStatusRejectedAction => ({
  type: GET_AGREEMENT_STATUS_REJECTED,
  payload: { code },
});

// SIGN AGREEMENT
// Pending
export const SIGN_AGREEMENT = "SIGN_AGREEMENT";
export interface SignAgreementAction {
  type: typeof SIGN_AGREEMENT;
  payload: { code: AgreementDocumentCode; hash: string; eventId: string };
}
export const signAgreement = (signAgreement: {
  code: AgreementDocumentCode;
  hash: string;
  eventId: string;
}): SignAgreementAction => ({
  type: SIGN_AGREEMENT,
  payload: signAgreement,
});
// Success
export const SIGN_AGREEMENT_SUCCESS = "SIGN_AGREEMENT_SUCCESS";
export interface SignAgreementSuccessAction {
  type: typeof SIGN_AGREEMENT_SUCCESS;
}
export const signAgreementSuccess = (): SignAgreementSuccessAction => ({
  type: SIGN_AGREEMENT_SUCCESS,
});
// Rejected
export const SIGN_AGREEMENT_REJECTED = "SIGN_AGREEMENT_REJECTED";
export interface SignAgreementRejectedAction {
  type: typeof SIGN_AGREEMENT_REJECTED;
}
export const signAgreementRejected = (): SignAgreementRejectedAction => ({
  type: SIGN_AGREEMENT_REJECTED,
});

// Received
export const SIGN_AGREEMENT_RECEIVED = "SIGN_AGREEMENT_RECEIVED";
export interface SignAgreementReceivedAction {
  type: typeof SIGN_AGREEMENT_RECEIVED;
}
export const signAgreementReceived = (): SignAgreementReceivedAction => ({
  type: SIGN_AGREEMENT_RECEIVED,
});

// SIGN TO ADD A CO-OWNER
//Pending
export const SIGN_ADD_CO_OWNER = "SIGN_ADD_CO_OWNER";
export interface SignAddCoOwnerAction {
  type: typeof SIGN_ADD_CO_OWNER;
  payload: SignAddMemberType;
}
export const signAddCoOwner = ({
  eventId,
  userEmailToInvite,
}: SignAddMemberType): SignAddCoOwnerAction => ({
  type: SIGN_ADD_CO_OWNER,
  payload: { eventId, userEmailToInvite },
});
// Success
export const SIGN_ADD_CO_OWNER_SUCCESS = "SIGN_ADD_CO_OWNER_SUCCESS";
export interface SignAddCoOwnerSuccessAction {
  type: typeof SIGN_ADD_CO_OWNER_SUCCESS;
}
export const signAddCoOwnerSuccess = (): SignAddCoOwnerSuccessAction => ({
  type: SIGN_ADD_CO_OWNER_SUCCESS,
});
// Rejected
export const SIGN_ADD_CO_OWNER_REJECTED = "SIGN_ADD_CO_OWNER_REJECTED";
export interface SignAddCoOwnerRejectedAction {
  type: typeof SIGN_ADD_CO_OWNER_REJECTED;
}
export const signAddCoOwnerRejected = (): SignAddCoOwnerRejectedAction => ({
  type: SIGN_ADD_CO_OWNER_REJECTED,
});

// Complete
export const SIGN_ADD_CO_OWNER_COMPLETED = "SIGN_ADD_CO_OWNER_COMPLETED";
export interface SignAddCoOwnerCompletedAction {
  type: typeof SIGN_ADD_CO_OWNER_COMPLETED;
}
export const signAddCoOwnerCompleted = (): SignAddCoOwnerCompletedAction => ({
  type: SIGN_ADD_CO_OWNER_COMPLETED,
});


// SIGN TO DELETE A CO-OWNER
//Pending
export const SIGN_DELETE_CO_OWNER = "SIGN_DELETE_CO_OWNER";
export interface SignDeleteCoOwnerAction {
  type: typeof SIGN_DELETE_CO_OWNER;
  payload: SignDeleteMemberType;
}
export const signDeleteCoOwner = ({
  eventId,
  userIdToDelete,
}: SignDeleteMemberType): SignDeleteCoOwnerAction => ({
  type: SIGN_DELETE_CO_OWNER,
  payload: { eventId, userIdToDelete },
});
// Success
export const SIGN_DELETE_CO_OWNER_SUCCESS = "SIGN_DELETE_CO_OWNER_SUCCESS";
export interface SignDeleteCoOwnerSuccessAction {
  type: typeof SIGN_DELETE_CO_OWNER_SUCCESS;
}
export const signDeleteCoOwnerSuccess = (): SignDeleteCoOwnerSuccessAction => ({
  type: SIGN_DELETE_CO_OWNER_SUCCESS,
});
// Rejected
export const SIGN_DELETE_CO_OWNER_REJECTED = "SIGN_DELETE_CO_OWNER_REJECTED";
export interface SignDeleteCoOwnerRejectedAction {
  type: typeof SIGN_DELETE_CO_OWNER_REJECTED;
}
export const signDeleteCoOwnerRejected =
  (): SignDeleteCoOwnerRejectedAction => ({
    type: SIGN_DELETE_CO_OWNER_REJECTED,
  });
// Completed

export const SIGN_DELETE_CO_OWNER_COMPLETE = "SIGN_DELETE_CO_OWNER_COMPLETE";
export interface SignDeleteCoOwnerCompleteAction {
  type: typeof SIGN_DELETE_CO_OWNER_COMPLETE;
}
export const signDeleteCoOwnerComplete = (): SignDeleteCoOwnerCompleteAction => ({
  type: SIGN_DELETE_CO_OWNER_COMPLETE,
});

// GET PENDING CONSENSUS ACTIONS
// Pending
export const GET_PENDING_CONSENSUS_ACTIONS = "GET_PENDING_CONSENSUS_ACTIONS";
export interface GetPendingConsensusActionsAction {
  type: typeof GET_PENDING_CONSENSUS_ACTIONS;
}
export const getPendingConsensusActions = () => ({
  type: GET_PENDING_CONSENSUS_ACTIONS,
});
// Success
export const GET_PENDING_CONSENSUS_ACTIONS_SUCCESS =
  "GET_PENDING_CONSENSUS_ACTIONS_SUCCESS";
export interface GetPendingConsensusActionsSuccessAction {
  type: typeof GET_PENDING_CONSENSUS_ACTIONS_SUCCESS;
  payload: { pendingConsensusActions: PendingConsensusActions };
}
export const getPendingConsensusActionsSuccess = (
  pendingConsensusActions: PendingConsensusActions
): GetPendingConsensusActionsSuccessAction => ({
  type: GET_PENDING_CONSENSUS_ACTIONS_SUCCESS,
  payload: { pendingConsensusActions },
});
// Rejected
export const GET_PENDING_CONSENSUS_ACTIONS_REJECTED =
  "GET_PENDING_CONSENSUS_ACTIONS_REJECTED";
export interface GetPendingConsensusActionsRejectedAction {
  type: typeof GET_PENDING_CONSENSUS_ACTIONS_REJECTED;
}
export const getPendingConsensusActionsRejected =
  (): GetPendingConsensusActionsRejectedAction => ({
    type: GET_PENDING_CONSENSUS_ACTIONS_REJECTED,
  });

// SIGN TO ADD A NEW DOCUMENT
// Pending
export const CONSENT_DOCUMENT = "CONSENT_DOCUMENT";
export interface ConsentDocumentAction {
  type: typeof CONSENT_DOCUMENT;
  payload: { signDocumentUpload: SignUploadDocumentType };
}
export const consentDocument = (
  signDocumentUpload: SignUploadDocumentType
): ConsentDocumentAction => ({
  type: CONSENT_DOCUMENT,
  payload: { signDocumentUpload },
});

// Success
export const CONSENT_DOCUMENT_SUCCESS = "CONSENT_DOCUMENT_SUCCESS";
export interface ConsentDocumentSuccessAction {
  type: typeof CONSENT_DOCUMENT_SUCCESS;
}
export const consentDocumentSuccess = (): ConsentDocumentSuccessAction => ({
  type: CONSENT_DOCUMENT_SUCCESS,
});

// Rejected
export const CONSENT_DOCUMENT_REJECTED = "CONSENT_DOCUMENT_REJECTED";
export interface ConsentDocumentRejectedAction {
  type: typeof CONSENT_DOCUMENT_REJECTED;
}
export const consentDocumentRejected = (): ConsentDocumentRejectedAction => ({
  type: CONSENT_DOCUMENT_REJECTED,
});

// Complete
export const CONSENT_DOCUMENT_COMPLETE = "CONSENT_DOCUMENT_COMPLETE";
export interface ConsentDocumentCompleteAction {
  type: typeof CONSENT_DOCUMENT_COMPLETE;
}
export const consentDocumentComplete = (): ConsentDocumentCompleteAction => ({
  type: CONSENT_DOCUMENT_COMPLETE,
});


