import {
  EMAIL_MAX_LENGTH,
  EMAIL_REGEX,
  MAX_DOCUMENT_FILE_SIZE,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  SHORT_TEXT_MAX_LENGTH,
} from "src/components/shared/constants";
import { dateInTheFuture, validDate, olderThan18 } from "./dates";

export const validateEmail = (email?: string) =>
  email !== undefined &&
  email.length < EMAIL_MAX_LENGTH &&
  EMAIL_REGEX.test(email);

export const validateRelationship = (relationship?: string) => !!relationship;

export const validateShortText = (text?: string) =>
  text !== undefined && text.length > 0 && text.length < SHORT_TEXT_MAX_LENGTH;

export const validateName = (text?: string) =>
  text !== undefined &&
  text.length > NAME_MIN_LENGTH &&
  text.length < NAME_MAX_LENGTH;

export const validateFile = (file?: File | null) =>
  file && file.size < MAX_DOCUMENT_FILE_SIZE;

export const validateBirth = (date?: string) =>
  date !== undefined &&
  date.length === 8 &&
  !dateInTheFuture(date) &&
  validDate(date) &&
  olderThan18(date);
