export enum Status {
  Ok = "Ok",
  Failed = "Failed",
  HardFailed = "Hard Failed",
}

export enum CoOwnerProgressStatus {
  Joined = "Joined",
  VerifiedId = "Verified ID",
  StartedCoOwnershipAgreement = "Started Co-ownership Agreement",
  CompletedCoOwnershipAgreement = "Completed Co-ownership Agreement",
  SignedCoOwnershipAgreement = "Signed Co-ownership Agreement",
  SignedMemorandumOfAgreement = "Signed Memorandum of Agreement",
}

export enum MenuOptions {
  CoOwners = "Co-owners",
  CoOwnershipAgreement = "Co-ownership agreement",
  Dashboard = "Dashboard",
  DocumentVault = "Document Vault",
  Chat = "Chat",
  MyProfile = "My profile",
  // Reset = "Reset",
  Status = "Status",
  LogOut = "Log out",
}

export enum CoOwnershipAgreementItemStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Done = "Done",
  Pending = "Pending",
  Discuss = "Discuss",
  Agreed = "Agreed",
}

export enum DocumentStatus {
  RequiresAction = "Requires Action",
  Executed = "Executed",
  Pending = "Pending",
  Agreed = "Agreed",
  Verified = "Verified",
}

export enum PopoverGuideType {
  Edit = "Edit",
  Comments = "Comments",
  Avatar = "Avatar"
}

export enum FormSectionCodes {
  Basics = "Basics",
  OwnershipStructure = "OwnershipStructure",
  JointFinancialAccounts = "JointFinancialAccounts",
  ExpensesAndPayments = "ExpensesAndPayments",
  Policies = "Policies",
  ExitStrategy = "ExitStrategy",
  AgreementManagement = "AgreementManagement",
}

export enum FormSectionStepCodes {
  Basics_Relationships = "Basics_Relationships",
  Basics_Occupancy = "Basics_Occupancy",
  Basics_FinancialContributions = "Basics_FinancialContributions",
  Basics_NonFinancialContributions = "Basics_NonFinancialContributions",
  Basics_NonFinancialContributionsDescription = "Basics_NonFinancialContributionsDescription",
  Basics_UsageRights = "Basics_UsageRights",
  Basics_Mortgage = "Basics_Mortgage",
  Basics_HomePurchase = "Basics_HomePurchase",
  Basics_UsageRightsSpace = "Basics_UsageRightsSpace",
  Basics_UsageRightsSpaceDescriptions = "Basics_UsageRightsSpaceDescriptions",
  Basics_UsageRightsTime = "Basics_UsageRightsTime",

  OwnershipStructure_Interest = "OwnershipStructure_Interest",
  OwnershipStructure_PropertyTitle = "OwnershipStructure_PropertyTitle",

  JointFinancialAccounts_JointAccounts = "JointFinancialAccounts_JointAccounts",
  JointFinancialAccounts_JointAccountsDescription = "JointFinancialAccounts_JointAccountsDescription",
  JointFinancialAccounts_OpenJointAccounts = "JointFinancialAccounts_OpenJointAccounts",
  JointFinancialAccounts_ReserveAccount = "JointFinancialAccounts_ReserveAccount",

  JointFinancialAccounts_JointNewJointFundingAccount = "JointFinancialAccounts_JointNewJointFundingAccount",
  JointFinancialAccounts_JointNewJointFundingAccountDetails = "JointFinancialAccounts_JointNewJointFundingAccountDetails",
  JointFinancialAccounts_JointNewJointAccountMinimumBalance = "JointFinancialAccounts_JointNewJointAccountMinimumBalance",
  JointFinancialAccounts_JointNewJointAccountMinimumBalanceDetails = "JointFinancialAccounts_JointNewJointAccountMinimumBalanceDetails",

  JointFinancialAccounts_JointNewReserveFundingAccount = "JointFinancialAccounts_JointNewReserveFundingAccount",
  JointFinancialAccounts_JointNewReserveFundingAccountDetails = "JointFinancialAccounts_JointNewReserveFundingAccountDetails",
  JointFinancialAccounts_JointNewReserveAccountMinimumBalance = "JointFinancialAccounts_JointNewReserveAccountMinimumBalance",
  JointFinancialAccounts_JointNewReserveAccountMinimumBalanceDetails = "JointFinancialAccounts_JointNewReserveAccountMinimumBalanceDetails",

  JointFinancialAccounts_JointCheckingFundingAccount = "JointFinancialAccounts_JointCheckingFundingAccount",
  JointFinancialAccounts_JointCheckingFundingAccountDetails = "JointFinancialAccounts_JointCheckingFundingAccountDetails",
  JointFinancialAccounts_JointCheckingAccountMinimumBalance = "JointFinancialAccounts_JointCheckingAccountMinimumBalance",
  JointFinancialAccounts_JointCheckingAccountMinimumBalanceDetails = "JointFinancialAccounts_JointCheckingAccountMinimumBalanceDetails",

  JointFinancialAccounts_JointSavingsFundingAccount = "JointFinancialAccounts_JointSavingsFundingAccount",
  JointFinancialAccounts_JointSavingsFundingAccountDetails = "JointFinancialAccounts_JointSavingsFundingAccountDetails",
  JointFinancialAccounts_JointSavingsAccountMinimumBalance = "JointFinancialAccounts_JointSavingsAccountMinimumBalance",
  JointFinancialAccounts_JointSavingsAccountMinimumBalanceDetails = "JointFinancialAccounts_JointSavingsAccountMinimumBalanceDetails",

  ExpensesAndPayments_Splitting_Mortgage = "ExpensesAndPayments_Splitting_Mortgage",
  ExpensesAndPayments_PayingMortgage = "ExpensesAndPayments_PayingMortgage",
  ExpensesAndPayments_Splitting_Utilities = "ExpensesAndPayments_Splitting_Utilities",
  ExpensesAndPayments_PayingUtilites = "ExpensesAndPayments_PayingUtilites",
  ExpensesAndPayments_Splitting_RecurringExpenses = "ExpensesAndPayments_Splitting_RecurringExpenses",
  ExpensesAndPayments_PayingRecurringExpenses = "ExpensesAndPayments_PayingRecurringExpenses",
  ExpensesAndPayments_PayingEmergencyExpenses = "ExpensesAndPayments_PayingEmergencyExpenses",
  ExpensesAndPayments_Splitting_EmergencyExpenses = "ExpensesAndPayments_Splitting_EmergencyExpenses",
  ExpensesAndPayments_EmergencyExpenseAuthorizationThreshold = "ExpensesAndPayments_EmergencyExpenseAuthorizationThreshold",
  ExpensesAndPayments_EmergencyExpenseAuthorizationThresholdSlider = "ExpensesAndPayments_EmergencyExpenseAuthorizationThresholdSlider",
  ExpensesAndPayments_AllocatingTaxDeductions = "ExpensesAndPayments_AllocatingTaxDeductions",

  Policies_Guests = "Policies_Guests",
  Policies_MissedPayments = "Policies_MissedPayments",
  Policies_PartnershipNotIntended = "Policies_PartnershipNotIntended",
  Policies_WaiveRightToPartition = "Policies_WaiveRightToPartition",
  Policies_BorrowingAgainstProperty = "Policies_BorrowingAgainstProperty",
  Policies_Disputes = "Policies_Disputes",
  Policies_LegalFees = "Policies_LegalFees",

  ExitStrategy_BlackoutPeriod = "ExitStrategy_BlackoutPeriod",
  ExitStrategy_BlackoutPeriodEvents = "ExitStrategy_BlackoutPeriodEvents",
  ExitStrategy_BeneficiaryBlackoutPeriod = "ExitStrategy_BeneficiaryBlackoutPeriod",
  ExitStrategy_DetermineSalePrice = "ExitStrategy_DetermineSalePrice",
  ExitStrategy_FirstRightOfRefusal = "ExitStrategy_FirstRightOfRefusal",
  ExitStrategy_CompetingClaims = "ExitStrategy_CompetingClaims",
  ExitStrategy_PersonalProperty = "ExitStrategy_PersonalProperty",
  ExitStrategy_AllocatingNetProceedOnSale = "ExitStrategy_AllocatingNetProceedOnSale",

  AgreementManagement_CoownershipAgreementReview = "AgreementManagement_CoownershipAgreementReview",
  AgreementManagement_CoownershipAgreementDuration = "AgreementManagement_CoownershipAgreementDuration",
}

export enum AgreementCode {
  DeleteGroupMember = "DeleteGroupMember",
  AddGroupMember = "AddGroupMember",
  SignCoOwnershipAgreement = "SignCoOwnershipAgreement",
  SignMemorandumOfAgreement = "SignMemorandumOfAgreement",
}

export enum AgreementDocumentCode {
  CoOwnershipAgreement = "CoOwnershipAgreement",
  MemorandumOfAgreement = "MemorandumOfAgreement",
}

export enum GovernanceFormSectionStepField {
  Switch = "Switch",
  Slider = "Slider",
  RadioButtonGroup = "RadioButtonGroup",
  CheckBox = "CheckBox",
  CheckBoxButton = "CheckBoxButton",
  Select = "Select",
  ButtonGroup = "ButtonGroup",
  DateInput = "DateInput",
  TextInput = "TextInput",
  NumberInput = "NumberInput",
  TextArea = "TextArea",
  SimpleSwitch = "SimpleSwitch",
}

export enum DeviceType {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

export enum CoOwnersStep {
  CoOwners = "CoOwners",
  FillNewCoOwnerData = "FillNewCoOwnerData",
  Signature = "Signature",
}

export enum DocumentType {
  CoOwnershipAgreement = "CoOwnershipAgreement",
  MemorandumOfAgreement = "MemorandumOfAgreement",
  PurchaseSaleAgreement = "PurchaseSaleAgreement",
  MortgageDocument = "MortgageDocument",
  ClosingStatements = "ClosingStatements",
  PromissoryNote = "PromissoryNote",
  InsurancePolicy = "InsurancePolicy",
  HomeWarranty = "HomeWarranty",
  SimpleWill = "SimpleWill",
  HOADocument = "HOADocument",
  PropertyTaxDocument = "PropertyTaxDocument",
  OtherTaxDocument = "OtherTaxDocument",
  PropertySystemsDocument = "PropertySystemsDocument",
  Receipt = "Receipt",
  Other = "Other",
}

export enum MimeType {
  pdf = "application/pdf",
  jpeg = "image/jpeg",
  jpg = "image/jpg",
  png = "image/png",
  webp = "image/webp",
  doc = "application/msword",
  docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  txt = "text/plain",
  odt = "application/vnd.oasis.opendocument.text",
  ods = "application/vnd.oasis.opendocument.spreadsheet",
  odp = "application/vnd.oasis.opendocument.presentation",
  xls = "application/vnd.ms-excel",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csv = "text/csv",
  ppt = "application/vnd.ms-powerpoint",
  pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
}

export enum ExtensionType {
  pdf = ".pdf",
  jpeg = ".jpeg",
  jpg = ".jpg",
  png = ".png",
  webp = ".webp",
  doc = ".doc",
  docx = ".docx",
  txt = ".txt",
  odt = ".odt",
  ods = ".ods",
  odp = ".odp",
  xls = ".xls",
  xlsx = ".xlsx",
  csv = ".csv",
  ppt = ".ppt",
  pptx = ".pptx",
}

export enum CommentsType {
  document = "document",
  sectionStep = "sectionStep",
}

// Segment
export enum SegmentEvents {
  LeaderPreSignUpStepSubmitted = "Leader Presignup Step Submitted",
  MemberPreSignUpStepSubmitted = "Member Presignup Step Submitted",
  ClosingDateSubmitted = "Closing Date Submitted",
  UserSignupStepSubmitted = "User Signup Step Submitted",
  UserSignedUp = "User Signed Up", 
  UserDetailsStepSubmitted = "User Details Step Submitted", 
  UserVerifiedID = "User Verified ID",
  UserStartedGovernance = "User Started Governance",
  CommentSent = "Comment Sent",
  ChatSent = "Chat Sent",
  COASigned = "COA Signed",
  MOASigned = "MOA Signed",
  RemoveUserProposed = "Remove User Proposed",
  RemoveUserSigned = "Remove User Signed",
  AccountRemovedUser = "Account Removed User",
  InviteProposed = "Invite Proposed",
  InviteSigned = "Invite Signed",
  DocumentProposed = "Document Proposed",
  DocumentRejected = "Document Rejected",
  DocumentSigned = "Document Signed",
  DocumentAdded = "Document Added",
  DocumentDownloaded = "Document Downloaded",
  InviteDeleted = "Invite Deleted",
  UserSignedIn = "User Signed In",
  UserSignedOut = "User Signed Out",
  UserDeleted = "User Deleted",
  AccountCreated = "Account Created",
  AccountAddedUser = "Account Added User",
  AccountVerifiedIDs = "Account Verified IDs",
  AccountStartedGovernance = "Account Started Governance",
  UserCompletedGovernance = "User Completed Governance",
  AccountCompletedGovernance = "Account Completed Governance",
  AgreementExecuted = "Agreement Executed",
  SubscriptionCreated = "Subscription Created",
  InviteSent = "Invite Sent",
  UserSignInFailed = "User Sign In Failed",
  UserUpdatedProfile = "User Updated Profile"
}

export enum SegmentSignUpMethod {
  Email = "Email",
  Google = "Google",
}

export enum SegmentUserType {
  Member = "Member",
  Leader = "Leader",
}

export enum SegmentClosingDateStatus {
  ActingDelayed = "Acting delayed",
  ActingAfterTheFact = "Acting after the fact",
  ActingEarly = "Acting early",
  ActingTooEarly = "Acting too early",
}

export enum SegmentResponseStatus {
  Success = "Success",
  Error = "Error",
}

export enum SegmentInvitationError {
  InvitationNoLongerAvailable = "Invitation no longer available",
  InvitationExpired = "Invitation expired",
}

export enum SegmentInvitationResponse {
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export enum ErrorMessages {
  PendingActions = "Your group still has pending actions (signatures, invitations, user verifications).",
  WrongInputs = "Please double check your inputs",
  GenericError = "Something went wrong, please try again later.",
  NoLongerAvailable = "Document no longer available",
  PaymentError = "This group doesn't have any active subscription. <b>Please, go to Co-owners menu for payment</b>",
}

export enum ToastType {
  Success = "success",
  Info = "info",
  Error = "error",
}

export enum Payment {
  Done = "Done",
  Processing = "Processing",
  NotStarted = "NotStarted",
}
