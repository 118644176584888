import { Path } from "src/components/routing/routes";
import { MenuOptions } from "src/components/shared/enums";
import { Page } from "./permissions";

export const getPathFromPage: Record<Page, string> = {
  [Page.ONBOARDING]: Path.Onboarding,
  [Page.PROFILE]: Path.Profile,
  [Page.CO_OWNERS]: Path.CoOwners,
  [Page.GOVERNANCE]: Path.Governance,
  [Page.CHAT]: Path.Chat,
  [Page.DASHBOARD]: Path.Dashboard,
  [Page.DOCUMENT_VAULT]: Path.DocumentVault,
};

export const getMenuFromPage: Record<string, string> = {
  [Page.GOVERNANCE]: MenuOptions.CoOwnershipAgreement,
  [Page.DASHBOARD]: MenuOptions.Dashboard,
  [Page.DOCUMENT_VAULT]: MenuOptions.DocumentVault,
  [Page.CHAT]: MenuOptions.Chat,
  [Page.CO_OWNERS]: MenuOptions.CoOwners,
  [Page.PROFILE]: MenuOptions.MyProfile,
};

export const getPageFromPath = (path: string) =>
  path.replace("/", "").replace("-", "_").toUpperCase();

export const getTitleFromPath = (path: string) => {
  switch (path) {
    case Path.Chat:
      return "Chat";
    case Path.CoOwners:
      return "Co-Owners";
    case Path.Dashboard:
      return "Dashboard";
    case Path.DocumentVault:
      return "Document Vault";
    case Path.Governance:
      return "Governance";
    case Path.Home:
      return "Home";
    case Path.Invitation:
      return "Invitation";
    case Path.Login:
      return "Login";
    case Path.Onboarding:
      return "Onboarding";
    case Path.PrivacyPolicy:
      return "Privacy Policy";
    case Path.Profile:
      return "Profile";
    case Path.SignUp:
      return "Sign Up";
    case Path.Terms:
      return "Terms and Conditions";
    default:
      return "Page not found";
  }
};
