type RightDrawerProps = { isOpened: boolean; children: React.ReactNode };

function RightDrawer({ isOpened, children }: RightDrawerProps) {
  return (
    <div className={isOpened ? "right-drawer open" : "right-drawer"}>
      <div className="right-drawer-wrapper">{children}</div>
    </div>
  );
}

export default RightDrawer;
