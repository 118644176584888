import { AvatarType } from "src/components/Avatar";
import {
  FormSectionStepConfirmationRequestType,
  FormSectionStepFieldConstant,
  FormSectionStepFieldInputType,
  FormSectionStepFieldType,
  FormSectionStepType,
  SectionType
} from "src/components/shared/types";
import { CoOwnershipAgreementItemStatus, GovernanceFormSectionStepField } from "src/components/shared/enums";
import { UserAccountDto } from "src/services/Api/types";
import { FieldsValidationType } from "src/store/actions/governance";
import { addThousandsSeparator } from "./helpers";

export const getAvatars = (
  stepConfirmedBy?: Array<UserAccountDto>,
  missingToConfirm?: Array<UserAccountDto>
): Array<AvatarType> => {
  const confirmedUsers =
    stepConfirmedBy?.map(({ firstName, lastName }) => {
      return { firstName, lastName, isChecked: true };
    }) || [];

  const missingUsers =
    missingToConfirm?.map(({ firstName, lastName }) => {
      return { firstName, lastName, isChecked: false };
    }) || [];

  return [...confirmedUsers, ...missingUsers];
};

export const getNextSection = (
  sections: Array<SectionType>,
  section: SectionType
) => {
  const index = section.index - 1;
  let nextSection = null;
  if (index >= 0 && index < sections.length) nextSection = sections[index + 1];
  return nextSection;
};

export const getInitialFieldsOptions = (
  fields?: Array<FormSectionStepFieldType>
): Array<FormSectionStepFieldInputType> => {
  if (!fields) return [];
  return fields.map((field) => {
    const fieldHasDefaultValue = field?.defaultToOn;
    fields.find((_field) => {
      return (
        _field.formSectionStepFieldAnswer &&
        _field.formSectionStepFieldAnswer.formSectionStepFieldOption?.id ===
          field.defaultToOn?.id
      );
    });
    return {
      sectionStepFieldId: field.id,
      sectionStepFieldOptionId: field.formSectionStepFieldAnswer
        ? field.formSectionStepFieldAnswer.formSectionStepFieldOption
          ? field.formSectionStepFieldAnswer.formSectionStepFieldOption.id
          : null
        : null,
      sectionStepFieldInput: field.formSectionStepFieldAnswer
        ? field.formSectionStepFieldAnswer.formSectionStepFieldOption
          ? null
          : field.type === GovernanceFormSectionStepField.DateInput
          ? field.formSectionStepFieldAnswer.inputAnswerValue.split("T")[0]
          : field.formSectionStepFieldAnswer.inputAnswerValue
          ? field.formSectionStepFieldAnswer.inputAnswerValue
          : fieldHasDefaultValue
          ? field.defaultToOn.value.toString()
          : null
        : field.type === GovernanceFormSectionStepField.CheckBoxButton
        ? "false"
        : fieldHasDefaultValue
        ? field.defaultToOn.value.toString()
        : null,
    };
  });
};

export const transformStepDataToAnswerData = (
  fieldId: string,
  index: number,
  option: string | null,
  input: string | null,
  stepAnswer?: FormSectionStepConfirmationRequestType
) => {
  const fields = stepAnswer ? [...stepAnswer.fields] : [];
  fields[index] = {
    sectionStepFieldId: fieldId,
    sectionStepFieldOptionId: option,
    sectionStepFieldInput: input,
  };
  return { ...stepAnswer, fields };
};

export const validateField = (
  input: string | null,
  option: string | null,
  formSectionStepFieldConstant: FormSectionStepFieldConstant | null,
  type: GovernanceFormSectionStepField
) => {
  // Option case
  if (
    [
      GovernanceFormSectionStepField.ButtonGroup,
      GovernanceFormSectionStepField.RadioButtonGroup,
      GovernanceFormSectionStepField.Select,
      GovernanceFormSectionStepField.Switch,
    ].includes(type)
  ) {
    if (option !== null) {
      return { isValid: true, errorMessage: "" };
    } else return { isValid: false, errorMessage: "" };
  }
  //Input case
  else {
    if (formSectionStepFieldConstant !== null) {
      const {
        dateRangeLowerBound,
        dateRangeUpperBound,
        dateNotInBoundErrorMessage,
        maxTextInput,
        maxTextInputErrorMessage,
        minTextInput,
        minTextInputErrorMessage,
        nullableField,
        nullableFieldErrorMessage,
        numberMax,
        numberMaxErrorMessage,
        numberMin,
        numberMinErrorMessage,
      } = formSectionStepFieldConstant;

      // Null input
      if (
        nullableField &&
        (input === "" || input === null || input === undefined)
      )
        return { isValid: true, errorMessage: "" };
      if (!nullableField && input === "")
        return { isValid: false, errorMessage: nullableFieldErrorMessage };
      if (
        !nullableField &&
        (input === null || input === undefined || input === "false")
      )
        return { isValid: false, errorMessage: "" };

      // Number input
      if (
        type === GovernanceFormSectionStepField.NumberInput ||
        type === GovernanceFormSectionStepField.Slider
      ) {
        if (numberMin !== undefined && +input! < numberMin)
          return { isValid: false, errorMessage: numberMinErrorMessage };
        if (numberMax !== undefined && +input! > numberMax)
          return { isValid: false, errorMessage: numberMaxErrorMessage };
        if (
          (+input!).toString() === "NaN" ||
          input === "-0" ||
          input![input!.length - 1] === "."
        )
          return {
            isValid: false,
            errorMessage: "Please double check your input.",
          };
      }

      // Text input
      if (
        type === GovernanceFormSectionStepField.TextArea ||
        type === GovernanceFormSectionStepField.TextInput
      ) {
        if (minTextInput !== undefined && input!.trim().length < minTextInput)
          return { isValid: false, errorMessage: minTextInputErrorMessage };
        if (maxTextInput !== undefined && input!.trim().length > maxTextInput)
          return { isValid: false, errorMessage: maxTextInputErrorMessage };
      }

      // Date input
      if (type === GovernanceFormSectionStepField.DateInput) {
        const date = new Date(input || "");

        if ((input !== null && input.length < 10) || isNaN(date.getTime())) {
          return {
            isValid: false,
            errorMessage: "Invalid date, format must be MM/DD/YYYY",
          };
        }
        if (
          dateRangeLowerBound !== undefined &&
          date.getTime() < Date.now() + dateRangeLowerBound * 1000
        ) {
          return { isValid: false, errorMessage: dateNotInBoundErrorMessage };
        }
        if (
          dateRangeUpperBound !== undefined &&
          date.getTime() > Date.now() + dateRangeUpperBound * 1000
        ) {
          return { isValid: false, errorMessage: dateNotInBoundErrorMessage };
        }
      }
      return { isValid: true, errorMessage: "" };
    } else {
      if (input !== "" || input !== null || input !== undefined)
        return { isValid: true, errorMessage: "" };
      else return { isValid: false, errorMessage: "" };
    }
  }
};

export const getFieldsValidation = (
  fields: Array<FormSectionStepFieldType>,
  stepAnswer: FormSectionStepConfirmationRequestType
) => {
  let fieldsValidation = {};

  fields.forEach((field) => {
    // If field is hidden set as validated
    const isHiddenField =
      !!field.showOn &&
      !stepAnswer?.fields.find(
        (answer) =>
          answer.sectionStepFieldId === field.showOn ||
          answer.sectionStepFieldOptionId === field.showOn
      );
    const fieldHasDefaultValue =
      field?.defaultToOn &&
      fields.find(
        (_field) =>
          _field.formSectionStepFieldAnswer &&
          _field.formSectionStepFieldAnswer.formSectionStepFieldOption?.id ===
            field.defaultToOn?.id
      );
    const answer = field.formSectionStepFieldAnswer;
    const validation = isHiddenField
      ? { isValid: true, errorMessage: "" }
      : validateField(
          answer
            ? answer.inputAnswerValue
            : fieldHasDefaultValue
            ? field.defaultToOn.value.toString()
            : null,
          answer
            ? answer.formSectionStepFieldOption
              ? answer.formSectionStepFieldOption.id
              : null
            : null,
          field.formSectionStepFieldConstant,
          field.type
        );
    fieldsValidation = { ...fieldsValidation, [field.id]: { ...validation } };
  });
  return fieldsValidation;
};

export const getStepValidation = (
  fieldsValidation: FieldsValidationType,
  step: FormSectionStepType,
  stepAnswer: FormSectionStepConfirmationRequestType
) => {
  let isValid = true;
  let errorMessage = "";
  // Check individual fields
  if (fieldsValidation)
    Object.entries(fieldsValidation).forEach(([key, val]) => {
      isValid = isValid && val.isValid;
    });

  // Check mustSelectAtLeastOneOption
  if (step.mustSelectAtLeastOneOption) {
    const hasAtLeastOneOptionSelected = stepAnswer?.fields.find(
      (field) => field.sectionStepFieldInput === "true"
    );
    isValid = isValid && !!hasAtLeastOneOptionSelected;
    errorMessage = hasAtLeastOneOptionSelected
      ? errorMessage
      : step.noOptionSelectedErrorMessage || "";
  }

  // Check fieldsMustBeGreaterThan
  if (
    step.fieldsMustBeGreaterThan !== null &&
    stepAnswer?.fields
      .map((x) => Number(x.sectionStepFieldInput))
      .reduce((a, b) => a + b, 0) <= step.fieldsMustBeGreaterThan &&
    isValid
  ) {
    isValid = false;
    errorMessage =
      "Please enter monthly contributions or return to the previous screen.";
  }

  // Check maxFieldSum
  const fieldsWithMaxFieldSum = step?.formSectionStepFields.filter(
    (field) =>
      (field.type === GovernanceFormSectionStepField.ButtonGroup ||
        field.type === GovernanceFormSectionStepField.RadioButtonGroup ||
        field.type === GovernanceFormSectionStepField.SimpleSwitch) &&
      field.formSectionStepFieldOptions.find((option) => option.maxFieldSum)
  );
  if (fieldsWithMaxFieldSum?.length) {
    const selectedOptionWithMaxFieldSum = fieldsWithMaxFieldSum
      .map((fieldWithMaxFieldSum) =>
        fieldWithMaxFieldSum.formSectionStepFieldOptions.find((option) =>
          stepAnswer.fields.find(
            (answerField) => answerField.sectionStepFieldOptionId === option.id
          )
        )
      )
      .find((option) => option?.maxFieldSum);
    const maxFieldSum = selectedOptionWithMaxFieldSum?.maxFieldSum;

    if (maxFieldSum) {
      const numberInputIds = step.formSectionStepFields
        .filter(
          (field) => field.type === GovernanceFormSectionStepField.NumberInput
        )
        .map((numberInput) => numberInput.id);

      let sum = 0;
      let allFieldsFilled = true;
      stepAnswer.fields.forEach((field, index) => {
        if (numberInputIds.includes(field.sectionStepFieldId)) {
          field.sectionStepFieldInput
            ? (sum = sum + parseFloat(field.sectionStepFieldInput))
            : (allFieldsFilled = false);
        }
      });

      const isSumValid = (selectedOptionWithMaxFieldSum?.value === "Dollars")
        ? sum === maxFieldSum
        : (sum >= maxFieldSum - 0.001 && sum <= maxFieldSum + 0.001);

      isValid = selectedOptionWithMaxFieldSum?.value === "Dollars" ? isSumValid : isValid && isSumValid;
      errorMessage = isSumValid
        ? errorMessage
        : allFieldsFilled
        ? `${selectedOptionWithMaxFieldSum?.value === "Dollars" ? "Must" :  "Percentages must"} sum to ${
            selectedOptionWithMaxFieldSum?.value === "Dollars"
              ? `$${addThousandsSeparator(maxFieldSum)}.`
              : `${maxFieldSum}%.`
          }`
        : "";
    }
  }

  return { isValid, errorMessage };
};

export const allSectionsAgreed = (sections: Array<SectionType>) =>
  sections.every(
    (section) => section.status === CoOwnershipAgreementItemStatus.Agreed
  );

export const getStatusIcon = (status: CoOwnershipAgreementItemStatus) => {
  switch (status) {
    case CoOwnershipAgreementItemStatus.NotStarted:
      return "icon-empty-icon";
    case CoOwnershipAgreementItemStatus.InProgress:
      return "icon-empty-icon";
    case CoOwnershipAgreementItemStatus.Done:
      return "icon-check-circled-bold";
    case CoOwnershipAgreementItemStatus.Pending:
      return "icon-coowners";
    case CoOwnershipAgreementItemStatus.Discuss:
      return "icon-alert";
    case CoOwnershipAgreementItemStatus.Agreed:
      return "icon-check-circled-bold";
    default:
      return "";
  }
};
