type ButtonProps = {
  className?: string;
  text: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: "button" | "submit";
};

function Button({
  className = "",
  type = "button",
  text,
  onClick,
  isDisabled = false,
  isLoading = false,
}: ButtonProps) {
  const onButtonClick = () => !isDisabled && !isLoading && onClick?.();

  const loadingClass = isLoading ? "loading-button" : "";
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className="button-center">
      <button
        type={type}
        className={`${className} ${loadingClass} ${disabledClass}`}
        onClick={onButtonClick}
        disabled={isDisabled}
      >
        {isLoading ? "" : text}
      </button>
    </div>
  );
}

export default Button;
