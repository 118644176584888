import parse from "html-react-parser";
import { useEffect, useRef } from "react";
import { addThousandsSeparator } from "src/utils/helpers/helpers";
import { MAX_NUMBER_LENGTH, numberRegExp } from "../shared/constants";
import Tooltip from "../Tooltip";

type NumberInputProps = {
  id?: string;
  label?: string;
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  name?: string;
  pattern?: string;
  placeholder?: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  decimalNumbers?: number;
  mask?: string;
  tooltip?: string;
  isValid?: boolean;
  errorMessage?: string;
};

function NumberInput({
  id,
  className = "",
  label,
  name,
  value,
  onChange,
  readOnly,
  pattern,
  placeholder,
  isDisabled,
  decimalNumbers,
  mask,
  tooltip,
  isValid = true,
  errorMessage,
}: NumberInputProps) {
  const inputRef: any = useRef();

  useEffect(() => {
    moveCursorBeforeMaskSymbol();
  });

  const moveCursorBeforeMaskSymbol = () => {
    if (mask === "*%" && document.activeElement === inputRef.current) {
      inputRef.current.selectionStart =
        inputRef.current.value.length > 0
          ? inputRef.current.value.length - 1
          : inputRef.current.value.length;
      inputRef.current.selectionEnd =
        inputRef.current.value.length > 0
          ? inputRef.current.value.length - 1
          : inputRef.current.value.length;
    }
  };

  const _onChange = (maskedValue: string) => {
    let value = maskedValue;

    // Remove symbol
    if (mask) {
      const maskSymbol = mask.replace("*", "");
      value = value.replace(maskSymbol, "");
    }
    // Change , to .
    if (value.charAt(value.length - 1) === ",")
      value = value.slice(0, -1) + ".";

    // Remove "," from the thousands separator
    value = value.replaceAll(",", "");

    // Check correct value before change
    const regExp = new RegExp(numberRegExp(decimalNumbers));
    if (
      value === "" ||
      (regExp.test(value) && value.length < MAX_NUMBER_LENGTH)
    ) {
      const valueNumber = (+value).toString();
      if (
        valueNumber !== "NaN" &&
        value !== "" &&
        value[value.length - 1] !== "." &&
        value !== "-0" &&
        valueNumber !== "0" &&
        value[value.length - 1] !== "0"
      )
        value = valueNumber;
      onChange && onChange(value);
    }
  };

  const maskedValue = value
    ? mask
      ? mask.replace("*", `${addThousandsSeparator(value)}`)
      : `${addThousandsSeparator(value)}`
    : "";
  const errorClass = errorMessage ? "error" : "";
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`number-input-container ${disabledClass} ${errorClass}`}>
      {label && id && (
        <label htmlFor={id}>
          {parse(label)}
          <Tooltip content={tooltip} />
        </label>
      )}
      <input
        ref={inputRef}
        inputMode="decimal"
        type="text"
        className={className}
        id={id}
        name={name}
        value={maskedValue}
        onChange={(e) => _onChange(e.target.value)}
        placeholder={placeholder}
        readOnly={readOnly}
        step={decimalNumbers ? 1 / 10 ** decimalNumbers : 1}
        pattern={pattern}
        disabled={isDisabled}
        onKeyPress={(e) => e.code === "Enter" && e.preventDefault()}
      />
      {!isValid && <div className="invalid-input">{errorMessage}</div>}
    </div>
  );
}

export default NumberInput;
