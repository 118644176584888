import { call, put, select, takeLatest } from "redux-saga/effects";
import { MODAL_CONFIRMATION, MODAL_TOAST } from "src/components/modals/modalTypes";
import Api from "src/services/Api";
import { clearSession } from "../actions";
import { updatePermissions, updateSessionSuccess } from "../actions/auth";
import {
  ADD_CO_OWNER,
  AddCoOwnerAction,
  addCoOwnerRejected,
  addCoOwnerSuccess,
  cancelingInvitation,
  cancelInvitationComplete,
  DELETE_CO_OWNER,
  DeleteCoOwnerAction,
  deleteCoOwnerRejected,
  deleteCoOwnerSuccess,
  RESEND_INVITATION,
  ResendInvitationAction,
  resendInvitationRejected,
  resendInvitationSuccess,
  SEND_SIGNATURE_REMINDER,
  SEND_VERIFICATION_ID_REMINDER,
  SendSignatureReminderAction,
  sendSignatureReminderRejected,
  sendSignatureReminderSuccess,
  SendVerificationIdReminderAction,
  sendVerificationIdReminderRejected,
  sendVerificationIdReminderSuccess,
  setCoOwnersStep,
  setSelectedCoOwner
} from "../actions/coOwners";
import { setFullPageLoading } from "../actions/global";
import { hideModal, showModal } from "../actions/modal";
import { CoOwnersStep, ErrorMessages, SegmentEvents, ToastType } from "src/components/shared/enums";
import { getPendingConsensusActionsSaga } from "./signature";
import {
  CoOwnersPermission,
  getDefaultPage,
  getDefaultPagePermission,
  OnboardingPermission,
  Page
} from "src/utils/helpers/permissions";
import { resetCSRFTokenSaga } from "./auth";
import { analyticsGroup, analyticsTrackEvent, marshalAnalyticsGroup } from "../../components/SegmentAnalytics/utils";
import { RootState } from "../reducers";
import { Session } from "../../services/Api/types";
import { captureMessage } from "@sentry/react";

export default function* coOwnerSaga() {
  yield takeLatest(ADD_CO_OWNER, addCoOwnerSaga);
  yield takeLatest(DELETE_CO_OWNER, deleteCoOwnerSaga);
  yield takeLatest(RESEND_INVITATION, resendInvitationSaga);
  yield takeLatest(
    SEND_VERIFICATION_ID_REMINDER,
    sendVerificationIdReminderSaga
  );
  yield takeLatest(SEND_SIGNATURE_REMINDER, sendSignatureReminderSaga);
}

function* addCoOwnerSaga({ payload }: AddCoOwnerAction) {
  try {
    const {
      data: { success, eventId, ...session }
    } = yield call(Api.addGroupMember, payload);

    if (eventId) {
      yield call(getPendingConsensusActionsSaga);
      const updatedSession: Session = yield select(({ auth }: RootState) => (auth as any).session);
      yield call(analyticsTrackEvent, updatedSession, SegmentEvents.InviteProposed);
      yield put(setCoOwnersStep(CoOwnersStep.Signature));
    } else {
      const successSession: Session = yield select(({ auth }: RootState) => (auth as any).session);
      yield put(updateSessionSuccess(session));
      yield put(setCoOwnersStep(CoOwnersStep.CoOwners));
      yield put(
        showModal(MODAL_TOAST, {
          message: `${payload.firstName} has been invited to your group`
        })
      );
      yield put(addCoOwnerSuccess(null, payload));
      yield call(analyticsTrackEvent, successSession, SegmentEvents.AccountAddedUser);
    }
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    if (error.status === 403)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.WrongInputs,
          type: ToastType.Error
        })
      );
    else if (error.status === 409)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error
        })
      );
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );

    yield put(addCoOwnerRejected());
  }
}

function* deleteCoOwnerSaga({ payload }: DeleteCoOwnerAction) {
  try {
    const {
      data: { success, eventId, ...session }
    } = payload.isSignupCompleted
      ? yield call(Api.deleteGroupMember, payload.userId)
      : yield call(Api.deleteInvitation, payload.userId);
    updateSessionSuccess(session);

    if (eventId) {
      const updatedSession: Session = yield select(({ auth }: RootState) => (auth as any).session);
      yield call(analyticsTrackEvent, updatedSession, SegmentEvents.RemoveUserProposed);
      yield call(getPendingConsensusActionsSaga);
      yield put(deleteCoOwnerSuccess(eventId, payload.userId));
      yield put(
        showModal(MODAL_TOAST, {
          message: ` Your request to remove ${
            payload.isSelf
              ? "your account"
              : `${payload.firstName} ${payload.lastName}`
          } was successful, please sign and wait for your co-owners to approve your request.`
        })
      );
      yield put(
        updatePermissions({
          page: Page.CO_OWNERS,
          permission: CoOwnersPermission.CO_OWNERS
        })
      );
      yield put(setCoOwnersStep(CoOwnersStep.Signature));
      yield put(hideModal(MODAL_CONFIRMATION));
    } else {
      // TODO: Move to use hooks
      // segmentTrack(payload.isSignupCompleted ? (payload.isSelf ? SegmentEvents.UserRemovedItsAccount : SegmentEvents.UserRemoved) : SegmentEvents.UserInvitationRemoved);
      if (payload.isSignupCompleted) {
        // yield put(userAccountDeleted());
        // yield put(userAccountRemoved());
        // if (payload.isSelf) {
        //   yield put(userAccountDeleted());
        // } else {
        //   yield put(userAccountRemoved());
        // }
        // segmentTrack(payload.isSignupCompleted ? (payload.isSelf ? SegmentEvents.UserRemovedItsAccount : SegmentEvents.UserRemoved) : SegmentEvents.UserInvitationRemoved);
      } else {
        yield put(cancelingInvitation());
      }
      yield put(updateSessionSuccess(session));
      yield put(hideModal(MODAL_CONFIRMATION));

      if (payload.isSelf) {
        yield put(setFullPageLoading(true));

        // Clean session
        yield put(clearSession());

        // Reset CSRF token
        yield call(resetCSRFTokenSaga);
        yield put(setFullPageLoading(false));
      } else {
        // Get permissions
        const page = getDefaultPage(session);
        const permission = getDefaultPagePermission(page, session);
        if (
          page === Page.ONBOARDING &&
          permission === OnboardingPermission.GROUP_STATUS
        )
          yield put(
            updatePermissions({
              page,
              permission
            })
          );
        yield put(setSelectedCoOwner(null));
      }

      const message = payload.isSignupCompleted
        ? payload.isSelf
          ? `Your account has been removed.`
          : `${payload.firstName} ${payload.lastName} has been removed from your group.`
        : "The invitation has been canceled.";
      yield put(showModal(MODAL_TOAST, { message }));

      if (payload.isSignupCompleted) {
        yield call(analyticsTrackEvent, session, SegmentEvents.UserDeleted);
        yield call(analyticsGroup, session?.group?.id!, marshalAnalyticsGroup(session?.group, {
          group_size: session?.group?.users.length - 1,
          governance_status: "Started"
        }));
        yield call(analyticsTrackEvent, session, SegmentEvents.AccountRemovedUser);
        yield call(analyticsTrackEvent, session, SegmentEvents.AccountStartedGovernance);
        yield put(deleteCoOwnerSuccess(null, null));
      } else {
        yield put(cancelInvitationComplete());
      }
    }
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(deleteCoOwnerRejected());
    if (error.status === 409)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error,
          autoDeleteTime: 10000
        })
      );
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );

    yield put(hideModal(MODAL_CONFIRMATION));
  }
}

function* resendInvitationSaga({ payload }: ResendInvitationAction) {
  try {
    yield put(setFullPageLoading(true, "Resending..."));
    yield call(Api.resendInvitation, payload);
    yield put(resendInvitationSuccess());
    yield put(setFullPageLoading(false));
    yield put(
      showModal(MODAL_TOAST, { message: "This invitation has been resent." })
    );
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(resendInvitationRejected());
    yield put(setFullPageLoading(false));
    yield put(setSelectedCoOwner(null));
    if (error.status === 403)
      showModal(MODAL_TOAST, {
        message:
          "You have reached the resending limit, the invitation has been invalidated.",
        type: ToastType.Error
      });
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
  }
}

function* sendVerificationIdReminderSaga({
                                           payload
                                         }: SendVerificationIdReminderAction) {
  try {
    yield put(setFullPageLoading(true, "Sending..."));
    yield call(Api.sendVerificationReminder, payload);
    yield put(sendVerificationIdReminderSuccess());
    yield put(setFullPageLoading(false));
    yield put(showModal(MODAL_TOAST, { message: "A reminder has been sent." }));
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(sendVerificationIdReminderRejected());
    yield put(setFullPageLoading(false));
    yield put(
      showModal(MODAL_TOAST, {
        message: ErrorMessages.GenericError,
        type: ToastType.Error
      })
    );
  }
}

function* sendSignatureReminderSaga({ payload }: SendSignatureReminderAction) {
  try {
    yield put(setFullPageLoading(true, "Sending..."));
    yield call(Api.sendSignatureReminder, payload);
    yield put(sendSignatureReminderSuccess());
    yield put(setFullPageLoading(false));
    yield put(showModal(MODAL_TOAST, { message: "A reminder has been sent." }));
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(sendSignatureReminderRejected());
    yield put(setFullPageLoading(false));
    yield put(
      showModal(MODAL_TOAST, {
        message: ErrorMessages.GenericError,
        type: ToastType.Error
      })
    );
  }
}
