//@ts-nocheck
import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  MODAL_ADD_DOCUMENT,
  MODAL_CONFIRMATION,
  MODAL_TOAST,
} from "src/components/modals/modalTypes";
// import { segmentTrack } from "src/components/SegmentAnalytics/analytics";
import {
  acceptedFileTypes,
  DOCUMENTS_TO_TAKE,
} from "src/components/shared/constants";
import {
  ErrorMessages,
  MimeType,
 // SegmentEvents,
  ToastType,
} from "src/components/shared/enums";
import Api from "src/services/Api";
import {
  DocumentCardType,
  Pagination,
  UserAccountDto,
} from "src/services/Api/types";
import { mergePagination } from "src/utils/helpers/helpers";
import {
  DocumentVaultPermission,
  PagePermission,
} from "src/utils/helpers/permissions";
import {
  refreshFirstPageCommentsSuccess,
  setFullPageLoading,
} from "../actions/global";
import { hideModal, showModal } from "../actions/modal";
import {
  DownloadDocumentAction,
  downloadDocumentRejected,
  downloadDocumentSuccess,
  DOWNLOAD_DOCUMENT,
  GetNextPageDocumentHistoryAction,
  getNextPageDocumentHistoryRejected,
  getNextPageDocumentHistorySuccess,
  GetVaultDocumentAction,
  getVaultDocumentHistory,
  GetVaultDocumentHistoryAction,
  getVaultDocumentHistoryRejected,
  getVaultDocumentHistorySuccess,
  getVaultDocumentRejected,
  getVaultDocumentSuccess,
  getVaultRejected,
  getVaultSuccess,
  GET_NEXT_PAGE_DOCUMENT_HISTORY,
  GET_VAULT,
  GET_VAULT_DOCUMENT,
  GET_VAULT_DOCUMENT_HISTORY,
  RejectDocumentAction,
  rejectDocumentRejected,
  rejectDocumentSuccess,
  REJECT_DOCUMENT,
  setSelectedDocumentId,
  UploadDocumentAction,
  uploadDocumentRejected,
  uploadDocumentSuccess,
  UPLOAD_DOCUMENT,
} from "../actions/vault";
import { RootState } from "../reducers";
import { initialState } from "../reducers/global";
import { captureMessage } from "@sentry/react";

export default function* vaultSaga() {
  yield takeLatest(GET_VAULT, getVaultSaga);
  yield takeLatest(UPLOAD_DOCUMENT, uploadDocumentSaga);
  yield takeLatest(GET_VAULT_DOCUMENT, getVaultDocumentSaga);
  yield takeLatest(REJECT_DOCUMENT, rejectDocumentSaga);
  yield takeLatest(GET_VAULT_DOCUMENT_HISTORY, getVaultDocumentHistorySaga);
  yield takeLatest(DOWNLOAD_DOCUMENT, downloadDocumentSaga);
  yield takeLatest(
    GET_NEXT_PAGE_DOCUMENT_HISTORY,
    getNextPageDocumentHistorySaga
  );
}

// GET VAULT
function* getVaultSaga() {
  try {
    const { data } = yield call(Api.getVault);
    yield put(getVaultSuccess(data.contents));
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    yield put(getVaultRejected());
  }
}

// REJECT DOCUMENT
function* rejectDocumentSaga({ payload }: RejectDocumentAction) {
  try {
    yield call(Api.deleteDocument, payload.id);
    // TODO: Transfer to use hooks
    // segmentTrack(SegmentEvents.UserRejectedToAddDocument);
    yield put(hideModal(MODAL_CONFIRMATION));
    yield put(setSelectedDocumentId(null));
    yield put(
      showModal(MODAL_TOAST, {
        message: `Your rejection was successfully submitted`,
      })
    );
    yield put(rejectDocumentSuccess());
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(hideModal(MODAL_CONFIRMATION));
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
    yield put(rejectDocumentRejected());
  }
}

// UPLOAD DOCUMENT
function* uploadDocumentSaga({ payload }: UploadDocumentAction) {
  try {
    const formData = new FormData();
    Object.entries(payload.document).forEach(
      ([key, val]) => val && formData.append(key, val)
    );
    yield call(Api.uploadDocument, formData);
    // TODO: Transfer to use hooks
    // segmentTrack(SegmentEvents.AddDocumentRequestSubmitted);

    const permission: PagePermission = yield select(
      ({ auth }: RootState) => auth.permission
    );
    const selectedDocumentId: string = yield select(
      ({ vault }: RootState) => (vault as any).selectedDocumentId
    );
    // Refresh document history
    if (
      permission === DocumentVaultPermission.DOCUMENT_HISTORY &&
      selectedDocumentId
    )
      yield call(
        getVaultDocumentHistorySaga,
        getVaultDocumentHistory(selectedDocumentId)
      );
    // Refresh document vault
    if (permission === DocumentVaultPermission.DOCUMENT_VAULT)
      yield call(getVaultSaga);

    yield put(uploadDocumentSuccess());
    yield put(hideModal(MODAL_ADD_DOCUMENT));
    yield put(
      showModal(MODAL_TOAST, {
        message: "You successfully added a new document",
      })
    );
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(uploadDocumentRejected());
    yield put(hideModal(MODAL_ADD_DOCUMENT));
    if (error.status === 409) {
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error,
        })
      );
    } else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    else {
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
    }
  }
}

// GET VAULT DOCUMENT
export function* getVaultDocumentSaga({ payload }: GetVaultDocumentAction) {
  try {
    const {
      data: { success, ...data },
    } = yield call(Api.getVaultDocument, payload.id);
    const currentMissingToConfirm: Array<UserAccountDto> = yield select(
      ({ vault }: RootState) => (vault as any).vaultDocument.value.missingToConfirm
    );
    if (
      (payload.isUpdate &&
        data.missingToConfirm.length !== currentMissingToConfirm.length) ||
      !payload.isUpdate
    )
      yield put(getVaultDocumentSuccess(data));

    // Save comments
    yield put(
      refreshFirstPageCommentsSuccess(
        initialState.commentsDrawer.comments.value,
        data.hasNewComments
      )
    );
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    else if (error.status === 404)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.NoLongerAvailable,
          autoDeleteTime: 3000,
          type: ToastType.Info,
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          autoDeleteTime: 3000,
          type: ToastType.Error,
        })
      );
    yield put(setSelectedDocumentId(null));
    yield put(getVaultDocumentRejected());
  }
}

// GET VAULT DOCUMENT HISTORY
function* getVaultDocumentHistorySaga({
  payload,
}: GetVaultDocumentHistoryAction) {
  try {
    const { data } = yield call(
      Api.getVaultDocumentHistory,
      payload.id,
      1,
      DOCUMENTS_TO_TAKE
    );
    const permission: PagePermission = yield select(
      ({ auth }: RootState) => auth.permission
    );
    if (permission === DocumentVaultPermission.DOCUMENT_HISTORY)
      yield put(getVaultDocumentHistorySuccess(data.versions));
  } catch (error) {
    captureMessage(JSON.stringify(error))
    yield put(getVaultDocumentHistoryRejected());
  }
}

// GET NEXT PAGE DOCUMENT HISTORY
function* getNextPageDocumentHistorySaga({
  payload,
}: GetNextPageDocumentHistoryAction) {
  const currentDocumentHistoryPagination: Pagination<DocumentCardType> =
    yield select(
      ({ vault }: RootState) => (vault as any).vaultDocumentHistory.value.data
    );

  try {
    const {
      data: { versions: newDocumentHistoryPagination },
    } = yield call(
      Api.getVaultDocumentHistory,
      payload.id,
      currentDocumentHistoryPagination.nextPage ?? 1,
      DOCUMENTS_TO_TAKE
    );
    const documentHistoryPagination = mergePagination<DocumentCardType>(
      currentDocumentHistoryPagination,
      newDocumentHistoryPagination
    );

    const permission: PagePermission = yield select(
      ({ auth }: RootState) => auth.permission
    );
    if (permission === DocumentVaultPermission.DOCUMENT_HISTORY)
      yield put(getNextPageDocumentHistorySuccess(documentHistoryPagination));
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(getNextPageDocumentHistoryRejected());
  }
}

// DOWNLOAD DOCUMENT
export function* downloadDocumentSaga({ payload }: DownloadDocumentAction) {
  try {
    yield put(setFullPageLoading(true, "Downloading..."));
    const response: AxiosResponse<any, any> = yield call(
      Api.downloadDocument,
      payload.id
    );
    // TODO: Transfer to use hooks
    // segmentTrack(SegmentEvents.UserDownloadedDocument);
    const mimeType = response.data.type as MimeType;
    const extension = acceptedFileTypes.find(
      (fileType) => fileType.mimeType === mimeType
    )?.extension;
    yield put(downloadDocumentSuccess());
    yield put(setFullPageLoading(false));
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${payload.fileName}${extension ? extension : ""}`
    );
    document.body.appendChild(link);
    link.click();
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(downloadDocumentRejected());
    yield put(setFullPageLoading(false));
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
  }
}
