import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import Api from "src/services/Api";
import { navigateToSectionIndex } from "src/store/actions/governance";
import { RootState } from "src/store/reducers";
import useInterval from "src/utils/customHooks/useInterval";
import { updateSessionSuccess } from "../../../store/actions/auth";
import {
  analyticsGroup,
  analyticsIdentifyUser,
  analyticsTrackEvent,
  marshalAnalyticsGroup
} from "../../../components/SegmentAnalytics/utils";
import { SegmentEvents } from "../../../components/shared/enums";
import { UserAccountDto } from "../../../services/Api/types";
import { captureMessage } from "@sentry/react";

function WaitingNetwork() {
  const dispatch = useDispatch();
  const { isStepLoading } = useSelector((state: RootState) => state.governance);
  const [startNetworkTxnsLoading, setStartNetworkTxnsLoading] = useState(false);
  const [getTxnsProgressFailed, setGetTxnsProgressFailed] = useState(false);
  const [progress, setProgress] = useState(0);

  useInterval(() => {
    !getTxnsProgressFailed && progress !== 100 && getNetworkProgress();
  }, 5000);

  const startNetworkTxn = async () => {
    try {
      setStartNetworkTxnsLoading(true);
      const { data } = await Api.startTxns();
      if (data.completed) {
        const { data: { success, ...session } } = await Api.getUserData();
        await analyticsIdentifyUser({ ...session, user: {...session?.user, hasStartedCoAgreement: true} })
        await analyticsTrackEvent(session, SegmentEvents.UserStartedGovernance)
        console.log('==> Triggers from waiting network')

        const usersStartedGovernance = session?.group?.users?.filter((u: UserAccountDto) =>
          u.hasStartedCoAgreement && u.id !== session?.user?.id
        )

        if (usersStartedGovernance.length === 0) {
          await analyticsGroup(session?.group?.id!, marshalAnalyticsGroup(session?.group, {governance_status: "Started"}))
          await analyticsTrackEvent(session, SegmentEvents.AccountStartedGovernance)
          console.log('==> Account Started Gov Triggers from waiting network ')
        }

        dispatch(updateSessionSuccess(session))
        dispatch(navigateToSectionIndex());
      }
    } finally {
      setStartNetworkTxnsLoading(false);
    }
  };

  const getNetworkProgress = async () => {
    try {
      const { data } = await Api.getTxnsProgress();
      setProgress(Math.round(data.progress));
    } catch (error: any) {
      captureMessage(JSON.stringify(error))
      setGetTxnsProgressFailed(true);
    }
  };

  const onPrimaryButtonClick = async () => {
    if (getTxnsProgressFailed) {
      await startNetworkTxn();
      setGetTxnsProgressFailed(false);
    } else if (progress === 100) {
      const { data: { success, ...session } } = await Api.getUserData();
      await analyticsIdentifyUser({ ...session, user: {...session?.user, hasStartedCoAgreement: true} })
      await analyticsTrackEvent(session, SegmentEvents.UserStartedGovernance)
      console.log("===> Triggers from governance waiting for network")

      const usersStartedGovernance = session?.group?.users?.filter((u: UserAccountDto) =>
        u.hasStartedCoAgreement && u.id !== session?.user?.id
      )

      if (usersStartedGovernance.length === 0) {
        await analyticsGroup(session?.group?.id!, marshalAnalyticsGroup(session?.group, {governance_status: "Started"}))
        await analyticsTrackEvent(session, SegmentEvents.AccountStartedGovernance)
        console.log('==> Account Started Gov Triggers from waiting network on governance')
      }

      dispatch(updateSessionSuccess(session))
      dispatch(navigateToSectionIndex());
    }
  };

  const primaryButtonText = getTxnsProgressFailed ? "Try again" : "Continue";

  return (
    <InitialStep
      iconClass={
        getTxnsProgressFailed
          ? "icon-cross-circled"
          : progress !== 100
          ? undefined
          : "icon-check-circled"
      }
      title={
        getTxnsProgressFailed
          ? "Something went wrong"
          : progress !== 100
          ? "Setting"
          : "Ready to go!"
      }
      boldTitle={
        getTxnsProgressFailed
          ? undefined
          : progress !== 100
          ? "things up"
          : undefined
      }
      description={
        getTxnsProgressFailed
          ? "We are working to solve this issue, please try again."
          : progress !== 100
          ? "We use blockchain to improve your experience. Please bear with us for 1-2 minutes while we talk to the blockchain."
          : undefined
      }
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={onPrimaryButtonClick}
      isPrimaryButtonLoading={startNetworkTxnsLoading || isStepLoading}
      isPrimaryButtonDisabled={!getTxnsProgressFailed && progress !== 100}
      progress={
        getTxnsProgressFailed
          ? undefined
          : progress !== 100
          ? progress
          : undefined
      }
      progressIcon="icon-blockchain"
    />
  );
}

export default WaitingNetwork;
