import { FieldsValidationType } from "src/store/actions/governance";
import {
  transformStepDataToAnswerData,
  validateField,
} from "src/utils/helpers/governance";
import {
  FormSectionStepConfirmationRequestType,
  FormSectionStepType,
} from "../shared/types";

export const showHideOtherFields = (
  currentSectionStep: FormSectionStepType,
  stepAnswer: FormSectionStepConfirmationRequestType,
  fieldsValidation: FieldsValidationType,
  option: string | null
) => {
  let _stepAnswer = stepAnswer;
  let _fieldsValidation = fieldsValidation;

  const fieldsToShowHide = currentSectionStep?.formSectionStepFields.filter(
    (field) => field.showOn
  );

  if (fieldsToShowHide?.length) {
    fieldsToShowHide.forEach((fieldToShowHide) => {
      const isfieldToShow = fieldToShowHide.showOn === option;

      _stepAnswer = transformStepDataToAnswerData(
        fieldToShowHide.id,
        fieldToShowHide.index,
        null,
        null,
        _stepAnswer
      );

      _fieldsValidation = {
        ..._fieldsValidation,
        [fieldToShowHide.id]: isfieldToShow
          ? validateField(
              null,
              null,
              fieldToShowHide.formSectionStepFieldConstant,
              fieldToShowHide.type
            )
          : { isValid: true, errorMessage: "" },
      };
    });
  }

  return { _stepAnswer, _fieldsValidation };
};
