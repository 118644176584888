import {
  FormSectionStepConfirmationRequestType,
  FormSectionStepFieldType,
} from "../shared/types";
import Checkbox from "src/components/inputs/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/store/reducers";
import {
  conditionalSwitch,
  FieldsValidationType,
  setFieldsValidation,
  setStepAnswer,
  setStepValidation,
} from "src/store/actions/governance";
import ButtonGroup from "src/components/inputs/ButtonGroup";
import CheckboxButton from "src/components/inputs/CheckboxButton";
import DateInput from "src/components/inputs/DateInput";
import NumberInput from "src/components/inputs/NumberInput";
import RadioButtonGroup from "src/components/inputs/RadioButtonGroup";
import Select from "src/components/inputs/Select";
import Slider from "src/components/inputs/Slider";
import TextArea from "src/components/inputs/TextArea";
import TextInput from "src/components/inputs/TextInput";
import {
  getStepValidation,
  transformStepDataToAnswerData,
  validateField,
} from "src/utils/helpers/governance";
import Switch from "src/components/inputs/Switch";
import {
  fromStepDataToStringDate,
  formatIsoDateFromString,
} from "src/utils/helpers/dates";
import SimpleSwitch from "src/components/inputs/SimpleSwitch";
import { buttonGroupOnChange } from "./buttonGroupOnChange";
import { simpleSwitchOnChange } from "./simpleSwitchOnChange";
import { radioButtonGroupOnChange } from "./radioButtonGroupOnChange";
import { GovernanceFormSectionStepField } from "../shared/enums";

type DynamicInputProps = {
  formSectionStepField: FormSectionStepFieldType;
  fieldIndex: number;
  lastField: boolean;
};

function DynamicInput({
  formSectionStepField,
  fieldIndex,
  lastField,
}: DynamicInputProps) {
  const {
    stepAnswer,
    onEditionMode,
    fieldsValidation,
    currentSectionStep,
    isStepLoading,
  } = useSelector(({ governance }: RootState) => governance);
  const dispatch = useDispatch();
  const {
    id,
    title,
    type,
    tooltip,
    showOn,
    maskChangeOn,
    defaultToOn,
    formSectionStepFieldConstant,
    formSectionStepFieldOptions,
  } = formSectionStepField;

  const fieldAnswerInput =
    stepAnswer?.fields[fieldIndex]?.sectionStepFieldInput;
  const fieldAnswerOptionId =
    stepAnswer?.fields[fieldIndex]?.sectionStepFieldOptionId;

  const validateAndSaveAnswer = (
    input: string | null,
    option: string | null,
    _stepAnswer: FormSectionStepConfirmationRequestType | undefined,
    _fieldsValidation: FieldsValidationType | undefined
  ) => {
    // Set answer
    const answer = transformStepDataToAnswerData(
      id,
      fieldIndex,
      option,
      input,
      _stepAnswer
    );
    dispatch(setStepAnswer(answer));

    // Validate field
    const validationData = {
      ..._fieldsValidation,
      [id]: validateField(input, option, formSectionStepFieldConstant, type),
    };
    dispatch(setFieldsValidation(validationData));

    // Validate step
    const stepValidation = getStepValidation(
      validationData,
      currentSectionStep!,
      answer!
    );
    dispatch(setStepValidation(stepValidation));
  };

  const hideField =
    !!showOn &&
    !stepAnswer?.fields.find(
      (field) => field.sectionStepFieldOptionId === showOn
    );

  const readOnly =
    !!defaultToOn &&
    !!stepAnswer?.fields.find(
      (field) => field.sectionStepFieldOptionId === defaultToOn.id
    );

  const mask =
    maskChangeOn &&
    stepAnswer?.fields.find(
      (field) => field.sectionStepFieldOptionId === maskChangeOn?.id
    )
      ? maskChangeOn.mask
      : formSectionStepFieldConstant?.mask;

  const defaultValue =
    defaultToOn &&
    stepAnswer?.fields.find(
      (field) => field.sectionStepFieldOptionId === defaultToOn?.id
    )
      ? defaultToOn.value.toString()
      : undefined;

  const disabledField = !onEditionMode || isStepLoading;

  if (hideField) return <></>;

  if (type === GovernanceFormSectionStepField.CheckBox)
    return (
      <Checkbox
        key={fieldIndex}
        id={id}
        name={id}
        label={title}
        checked={fieldAnswerInput === "true"}
        isDisabled={disabledField}
        onChange={(input: boolean) =>
          validateAndSaveAnswer(
            input.toString(),
            null,
            stepAnswer,
            fieldsValidation
          )
        }
        defaultCheckboxOptions={
          formSectionStepField.defaultCheckboxOptions || []
        }
        fieldIndex={fieldIndex}
      />
    );

  if (type === GovernanceFormSectionStepField.CheckBoxButton)
    return (
      <div
        className={
          lastField
            ? "checkbox-button-group last-field"
            : "checkbox-button-group"
        }
      >
        <CheckboxButton
          key={id}
          id={id}
          name={id}
          label={title}
          checked={fieldAnswerInput === "true"}
          isDisabled={disabledField}
          onChange={(input: boolean) =>
            validateAndSaveAnswer(
              input.toString(),
              null,
              stepAnswer,
              fieldsValidation
            )
          }
        />
      </div>
    );

  if (type === GovernanceFormSectionStepField.DateInput) {
    return (
      <DateInput
        key={fieldIndex}
        id={id}
        name={id}
        label={title}
        value={fromStepDataToStringDate(fieldAnswerInput || undefined)}
        isDisabled={disabledField}
        tooltip={tooltip}
        onChange={(input: string) =>
          validateAndSaveAnswer(
            formatIsoDateFromString(input),
            null,
            stepAnswer,
            fieldsValidation
          )
        }
        isValid={fieldsValidation?.[id]?.isValid}
        placeholder={formSectionStepFieldConstant?.placeholder}
        errorMessage={fieldsValidation?.[id]?.errorMessage}
      />
    );
  }
  if (type === GovernanceFormSectionStepField.Select)
    return (
      <Select
        key={fieldIndex}
        id={id}
        label={title}
        tooltip={tooltip}
        onChange={(option: string) =>
          validateAndSaveAnswer(null, option, stepAnswer, fieldsValidation)
        }
        selectedId={fieldAnswerOptionId}
        options={formSectionStepFieldOptions}
        isDisabled={disabledField}
        placeholder={formSectionStepFieldConstant?.placeholder}
      />
    );

  if (type === GovernanceFormSectionStepField.NumberInput)
    return (
      <NumberInput
        key={fieldIndex}
        id={id}
        name={id}
        label={title}
        placeholder={formSectionStepFieldConstant?.placeholder}
        pattern={formSectionStepFieldConstant?.regex}
        tooltip={tooltip}
        decimalNumbers={formSectionStepFieldConstant?.numberDecimals}
        value={fieldAnswerInput ? fieldAnswerInput : defaultValue}
        mask={mask}
        isDisabled={disabledField}
        onChange={(input: string) =>
          validateAndSaveAnswer(input, null, stepAnswer, fieldsValidation)
        }
        isValid={fieldsValidation?.[id]?.isValid}
        errorMessage={fieldsValidation?.[id]?.errorMessage}
        readOnly={readOnly}
      />
    );

  if (type === GovernanceFormSectionStepField.RadioButtonGroup)
    return (
      <RadioButtonGroup
        key={fieldIndex}
        options={formSectionStepFieldOptions}
        selectedId={fieldAnswerOptionId}
        isDisabled={disabledField}
        onChange={(option: string) => {
          const { _stepAnswer, _fieldsValidation } = radioButtonGroupOnChange(
            currentSectionStep!,
            stepAnswer!,
            fieldsValidation!,
            option
          );
          validateAndSaveAnswer(null, option, _stepAnswer, _fieldsValidation);
        }}
      />
    );

  if (type === GovernanceFormSectionStepField.Switch)
    return (
      <Switch
        key={fieldIndex}
        id={id}
        name={id}
        label={title}
        tooltip={tooltip}
        options={formSectionStepFieldOptions}
        selectedId={fieldAnswerOptionId}
        isDisabled={disabledField}
        onChange={(option: string) => {
          if (
            (
              currentSectionStep?.formSectionStepFields[0]
                .defaultCheckboxOptions || []
            ).length > 0
          ) {
            dispatch(conditionalSwitch(fieldIndex, option));
          } else {
            validateAndSaveAnswer(null, option, stepAnswer, fieldsValidation);
          }
        }}
      />
    );

  if (type === GovernanceFormSectionStepField.SimpleSwitch)
    return (
      <SimpleSwitch
        key={fieldIndex}
        id={id}
        name={id}
        label={title}
        tooltip={tooltip}
        options={formSectionStepFieldOptions}
        selectedId={fieldAnswerOptionId}
        isDisabled={disabledField}
        onChange={(option: string) => {
          const { _stepAnswer, _fieldsValidation } = simpleSwitchOnChange(
            currentSectionStep!,
            stepAnswer!,
            fieldsValidation!
          );
          validateAndSaveAnswer(null, option, _stepAnswer, _fieldsValidation);
        }}
      />
    );

  if (type === GovernanceFormSectionStepField.TextInput)
    return (
      <TextInput
        key={fieldIndex}
        id={id}
        name={id}
        label={title}
        tooltip={tooltip}
        value={fieldAnswerInput || undefined}
        isDisabled={disabledField}
        onChange={(input: string) =>
          validateAndSaveAnswer(input, null, stepAnswer, fieldsValidation)
        }
        isValid={fieldsValidation?.[id]?.isValid}
        placeholder={formSectionStepFieldConstant?.placeholder}
        errorMessage={fieldsValidation?.[id]?.errorMessage}
      />
    );

  if (type === GovernanceFormSectionStepField.TextArea)
    return (
      <TextArea
        key={fieldIndex}
        id={id}
        name={id}
        tooltip={tooltip}
        label={title}
        value={fieldAnswerInput || undefined}
        isDisabled={disabledField}
        onChange={(input: string) =>
          validateAndSaveAnswer(input, null, stepAnswer, fieldsValidation)
        }
        isValid={fieldsValidation?.[id]?.isValid}
        placeholder={formSectionStepFieldConstant?.placeholder}
        errorMessage={fieldsValidation?.[id]?.errorMessage}
      />
    );

  if (type === GovernanceFormSectionStepField.Slider)
    return (
      <Slider
        key={fieldIndex}
        id={id}
        name={id}
        value={fieldAnswerInput || ""}
        isDisabled={disabledField}
        step={10}
        min={formSectionStepFieldConstant?.numberMin}
        max={formSectionStepFieldConstant?.numberMax}
        onChange={(input: string) =>
          validateAndSaveAnswer(input, null, stepAnswer, fieldsValidation)
        }
      />
    );

  if (type === GovernanceFormSectionStepField.ButtonGroup)
    return (
      <ButtonGroup
        key={fieldIndex}
        options={formSectionStepFieldOptions}
        selectedId={fieldAnswerOptionId}
        isDisabled={disabledField}
        onChange={(option: string) => {
          const { _stepAnswer, _fieldsValidation } = buttonGroupOnChange(
            currentSectionStep!,
            formSectionStepFieldOptions,
            stepAnswer!,
            fieldsValidation!,
            option
          );
          validateAndSaveAnswer(null, option, _stepAnswer, _fieldsValidation);
        }}
      />
    );
  return <></>;
}

export default DynamicInput;
