import { useDispatch, useSelector } from "react-redux";
import Avatar from "src/components/Avatar";
import Banner from "src/components/Banner";
import IconTextButton from "src/components/buttons/IconTextButton";
import DesktopNav from "src/components/DesktopNav";
import { MODAL_CONFIRMATION } from "src/components/modals/modalTypes";
import ProgressItem from "src/components/ProgressItem.tsx";
import { coOwnerProgressStatus } from "src/components/shared/constants";
import { CoOwnerProgressStatus } from "src/components/shared/enums";
import { CoOwner } from "src/services/Api/types";
import {
  deleteCoOwner,
  resendInvitation,
  sendSignatureReminder,
  sendVerificationIdReminder,
  setSelectedCoOwner,
} from "src/store/actions/coOwners";
import { hideModal, showModal } from "src/store/actions/modal";
import { RootState } from "src/store/reducers";
import PendingConsensusBannerContent from "./PendingConsensusBannerContent";

type CoOwnerStatusProps = {
  coOwner: CoOwner;
  coOwners: Array<CoOwner>;
};

function CoOwnerStatus({ coOwner, coOwners }: CoOwnerStatusProps) {
  const dispatch = useDispatch();
  const pendingConsensusActions = useSelector(
    ({ signature }: RootState) => signature.coOwners.pendingConsensusActions
  );

  const { eventId, userIdsMissingToConfirmEvent } = pendingConsensusActions;

  const allCoOwnersCompletedCoAgreement = coOwners.every((coOwner) => {
    return coOwner.hasCompletedCoAgreement;
  });

  const allCoOwnersSignedCoAgreement = coOwners.every(
    (coOwner) => coOwner.hasSignedCoAgreement
  );

  const className = [
    coOwner.isSignupCompleted ? "success" : "disabled",
    coOwner.isIdentityVerified ? "success" : "disabled",
    coOwner.hasStartedCoAgreement ? "success" : "disabled",
    coOwner.hasCompletedCoAgreement
      ? coOwner.needToReviewCoAgreement
        ? "warning"
        : "success"
      : "disabled",
    coOwner.hasSignedCoAgreement ? "success" : "disabled",
    coOwner.hasSignedMemoOfAgreement ? "success" : "disabled",
  ];

  const getActionText = (action: CoOwnerProgressStatus) => {
    if (coOwner.isSelf) return undefined;
    switch (action) {
      case CoOwnerProgressStatus.Joined:
        if (!coOwner.isInvitationCompleted) return "resend invite";
        break;
      case CoOwnerProgressStatus.VerifiedId:
        if (coOwner.isSignupCompleted && !coOwner.isIdentityVerified)
          return "send reminder";
        break;
      case CoOwnerProgressStatus.SignedCoOwnershipAgreement:
        if (
          allCoOwnersCompletedCoAgreement &&
          !coOwner.needToReviewCoAgreement &&
          !coOwner.hasSignedCoAgreement
        )
          return "send reminder";
        break;
      case CoOwnerProgressStatus.SignedMemorandumOfAgreement:
        if (allCoOwnersSignedCoAgreement && !coOwner.hasSignedMemoOfAgreement)
          return "send reminder";
        break;
      default:
        return undefined;
    }
  };

  const onActionClick = (action: CoOwnerProgressStatus) => {
    switch (action) {
      case CoOwnerProgressStatus.Joined:
        if (!coOwner.isInvitationCompleted)
          dispatch(resendInvitation(coOwner.id));
        break;
      case CoOwnerProgressStatus.VerifiedId:
        if (coOwner.isSignupCompleted && !coOwner.isIdentityVerified)
          dispatch(sendVerificationIdReminder(coOwner.id));
        break;
      case CoOwnerProgressStatus.SignedCoOwnershipAgreement:
        if (
          allCoOwnersCompletedCoAgreement &&
          !coOwner.needToReviewCoAgreement &&
          !coOwner.hasSignedCoAgreement
        )
          dispatch(sendSignatureReminder(coOwner.id));
        break;
      case CoOwnerProgressStatus.SignedMemorandumOfAgreement:
        if (allCoOwnersSignedCoAgreement && !coOwner.hasSignedMemoOfAgreement)
          dispatch(sendSignatureReminder(coOwner.id));
        break;

      default:
        return undefined;
    }
  };

  const openConfirmationModal = () => {
    if (!eventId) {
      const title = coOwner.isSignupCompleted
        ? coOwner.isSelf
          ? `Are you sure you want to delete your account?`
          : `Are you sure you want to remove ${coOwner.firstName} ${coOwner.lastName} from your group ?`
        : "Are you sure you want to cancel this invitation?";
      const description = coOwner.isSignupCompleted ? (
        coOwner.isSelf ? (
          ""
        ) : (
          <p>
            Removing a co-owner means you'll need to re-create your Co-ownership
            agreement.
          </p>
        )
      ) : (
        ""
      );
      const onLeftButtonClick = () =>
        dispatch(
          deleteCoOwner(
            coOwner.id,
            coOwner.isSignupCompleted,
            coOwner.isSelf,
            coOwner.firstName,
            coOwner.lastName
          )
        );
      dispatch(
        showModal(MODAL_CONFIRMATION, {
          title,
          description,
          leftButtonText: "Yes",
          rightButtonText: "Cancel",
          onLeftButtonClick,
          onRightButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
          onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
        })
      );
    }
  };

  const userHasNotSigned = userIdsMissingToConfirmEvent.includes(coOwner.id);

  return (
    <div className="co-owners-wrapper">
      <div className="co-owners-status">
        <DesktopNav
          title="Co-owner status"
          backButton={{
            onBackButtonClick: () => dispatch(setSelectedCoOwner(null)),
          }}
        />
        <div className="user">
          <Avatar firstName={coOwner.firstName} lastName={coOwner.lastName} />
          <p className="name">{`${coOwner.firstName} ${coOwner.lastName}`}</p>
          <p className="email">{coOwner.email}</p>
        </div>
        {userHasNotSigned && (
          <Banner iconClass="icon-alert" className="warning">
            <PendingConsensusBannerContent
              coOwner={coOwner}
              coOwners={coOwners}
            />
          </Banner>
        )}
        <div className="progress-item-list">
          {coOwnerProgressStatus.map((item, index) => (
            <ProgressItem
              key={index}
              text={item}
              className={className[index]}
              actionText={getActionText(item)}
              onActionClick={() => onActionClick(item)}
            />
          ))}
        </div>
        {eventId && <p>You can only add or delete one co-owner at a time.</p>}
        <IconTextButton
          onClick={openConfirmationModal}
          text="Delete"
          iconClass="icon-trash"
          isDisabled={!!eventId}
        />
      </div>
    </div>
  );
}

export default CoOwnerStatus;
