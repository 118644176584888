import { useDispatch, useSelector } from "react-redux";
import AddUser from "src/components/AddUser";
import StepTitle from "src/components/MultiStepForm/Step/StepTitle";
import { addCoOwner } from "src/store/actions/coOwners";
import { RootState } from "src/store/reducers";

type AddNewCoOwnerProps = { backButton?: { onBackButtonClick: () => void } };
function AddNewCoOwner({ backButton }: AddNewCoOwnerProps) {
  const dispatch = useDispatch();
  const { isAddingCoOwner } = useSelector(
    ({ coOwners }: RootState) => coOwners.addCoOwner
  );

  return (
    <div className="step-question-wrapper invite-co-owner-wrapper">
      <div className="step-question">
        <StepTitle
          title="Invite co-owner"
          description="Invite a new co-owner to join your co-ownership group."
          backButton={backButton}
          isDisabled={isAddingCoOwner}
        />
        <AddUser
          isAddingUser={isAddingCoOwner}
          onButtonClick={(coOwner) => dispatch(addCoOwner(coOwner))}
        />
      </div>
    </div>
  );
}

export default AddNewCoOwner;
