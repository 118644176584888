type InitialStepHeaderProps = {
  className?: string;
  iconClassName?: string;
  title?: string;
  boldTitle?: string;
  titleAfterBold?: string;
  description?: string;
};

function InitialStepHeader({
  iconClassName,
  title,
  boldTitle,
  titleAfterBold,
  description,
  className,
}: InitialStepHeaderProps) {
  return (
    <div className={className ? className : "centered-box success-step"}>
      {iconClassName && <i className={iconClassName} />}
      {(title || boldTitle) && (
        <h3>
          {title} <strong>{boldTitle}</strong> {titleAfterBold}
        </h3>
      )}
      {description && <p>{description}</p>}
    </div>
  );
}

export default InitialStepHeader;
