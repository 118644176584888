//@ts-nocheck
import { useContext, useState } from "react";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import { IndividualInputsFormData } from "..";
import AddressInput, { initialAddressData } from "src/components/inputs/AddressInput";
import { SegmentEvents } from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/reducers";
import { Session } from "../../../../services/Api/types";
import { updateSessionSuccess } from "../../../../store/actions/auth";

function AddressData({
                       formData,
                       nextStep,
                       prevStep,
                       saveData,
                       progress
                     }: MultiStepFormChildrenProps<IndividualInputsFormData>) {
  const dispatch = useDispatch()
  const session = useSelector(({ auth }: RootState) => auth.session) as Session;
  const { segmentTrack, segmentIdentifyUser } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [stepData, setStepData] = useState(
    formData?.coOwnedHomeData || initialAddressData
  );
  const { address, city, state, zipCode, country } = stepData.address;

  const onNextClick = () => {
    if (isDataCompleted) {
      const { live: occupant, address: { zipCode: zip_code, ...addressData } } = stepData;
      dispatch(updateSessionSuccess({ ...session, user: { ...session?.user!, address: { ...session?.user?.address!, ...stepData.address} } }))
      segmentIdentifyUser({ ...session, user: { ...session?.user!, address: { ...session?.user?.address!, ...stepData.address} } } );
      segmentTrack(SegmentEvents.UserDetailsStepSubmitted, {
        step: "Where do you live?",
        data: { occupant, ...{ address: { zip_code, ...addressData } } },
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });
      saveData({ coOwnedHomeData: stepData });
      nextStep();
    }
  };
  const isDataCompleted = address && city && state && zipCode && country;

  return (
    <Step
      title="Where do you live?"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={!isDataCompleted}
      progress={progress}
      onBackClick={() => prevStep()}
      className="bottom-not-fixed"
    >
      <AddressInput
        address={stepData.address}
        onChange={(address) => setStepData({ ...stepData, address })}
      />
    </Step>
  );
}

export default AddressData;
