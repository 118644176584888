import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatars from "src/components/Avatars";
import VaultDocumentCommentsButton from "src/components/buttons/VaultDocumentCommentsButton";
import { CommentsType } from "src/components/shared/enums";
import {
  refreshFirstPageCommentsSuccess,
  setCommentsDrawer,
} from "src/store/actions/global";
import { RootState } from "src/store/reducers";
import { initialState } from "src/store/reducers/global";
import { VaultDocument } from "../../../services/Api/types";

function DocumentStatus() {
  const dispatch = useDispatch();
  const {
    vaultDocument: { value: vaultDocument },
  } = useSelector(({ vault }: RootState) => vault);
  const {
    mainHeader: { avatars },
    commentsDrawer: {
      comments: { hasNewComments },
    },
  } = useSelector(({ global }: RootState) => global);

  useEffect(() => {
    dispatch(
      setCommentsDrawer({
        isOpened: false,
        id: (vaultDocument as VaultDocument)?.document?.id,
        type: CommentsType.document,
      })
    );
    // Clear comments
    dispatch(
      refreshFirstPageCommentsSuccess(
        initialState.commentsDrawer.comments.value,
        false
      )
    );
    return () => {
      dispatch(setCommentsDrawer({ isOpened: false, id: null, type: null }));
    };
  }, []);

  return (
    <>
      <Avatars avatars={avatars} />
      <VaultDocumentCommentsButton hasNewComments={hasNewComments} />
    </>
  );
}

export default DocumentStatus;
