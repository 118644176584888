import { useContext, useState } from "react";
import Checkbox from "src/components/inputs/Checkbox";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import {
  SegmentEvents,
  SegmentResponseStatus,
} from "src/components/shared/enums";
import { SignUpFormData } from "src/pages/Onboarding/signUp/Leader";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";

function ConfirmationData({
  formData,
  prevStep,
  saveData,
  progress,
  nextStep,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const [stepData, setStepData] = useState(formData.confirmation || false);

  const onNextClick = () => {
    if (!!stepData) {
      segmentTrack(SegmentEvents.LeaderPreSignUpStepSubmitted, {
        step: "One last thing",
        data: stepData,
        status: SegmentResponseStatus.Success,
      });
      saveData({ confirmation: stepData });
      nextStep();
    }
  };

  return (
    <Step
      title="One last thing"
      onNextClick={onNextClick}
      onBackClick={() => prevStep()}
      nextText="Confirm"
      isButtonDisabled={!stepData}
      progress={progress}
      tooltip={`<p>
            <strong>Unauthorized borrowing</strong> includes using real property
            as collateral for a loan without the express written consent of all
            co-owners.
          </p>
          <p>
            <strong>Pending litigation</strong> refers to any unresolved legal
            proceeding.
          </p>
          <p>
            <strong>Recent credit events</strong> include any unresolved credit
            circumstances or activity that may negatively impact your ability to
            borrow.
          </p>
          <p>
            <strong>Ongoing disputes</strong> include unresolved disagreements
            between co-owners, creditors, or anyone with a claim to the
            property.
          </p>`}
    >
      <p>
        Please confirm to the best of your knowledge that no co-owners in your
        group are involved in:
      </p>
      <ul className="bullet-list">
        <li>Unauthorized borrowing</li>
        <li>Pending litigation</li>
        <li>Recent credit events</li>
        <li>Ongoing disputes</li>
      </ul>
      <Checkbox
        id="confirmation"
        name="confirmation"
        className="confirmation-check"
        onChange={(value) => setStepData(value)}
        checked={stepData}
        label="I don’t have any of these issues"
      />
    </Step>
  );
}

export default ConfirmationData;
