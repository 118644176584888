import { DashboardInfo } from "src/services/Api/types";

// GET DASHBOARD INFO
// Pending
export const GET_DASHBOARD_INFO = "GET_DASHBOARD_INFO";
export interface GetDashboardInfoAction {
  type: typeof GET_DASHBOARD_INFO;
  payload: { isUpdate: boolean };
}
export const getDashboardInfo = (
  isUpdate: boolean
): GetDashboardInfoAction => ({
  type: GET_DASHBOARD_INFO,
  payload: { isUpdate },
});
// Success
export const GET_DASHBOARD_INFO_SUCCESS = "GET_DASHBOARD_INFO_SUCCESS";
export interface GetDashboardInfoSuccessAction {
  type: typeof GET_DASHBOARD_INFO_SUCCESS;
  payload: DashboardInfo;
}
export const getDashboardInfoSuccess = (
  dashboardInfo: DashboardInfo
): GetDashboardInfoSuccessAction => ({
  type: GET_DASHBOARD_INFO_SUCCESS,
  payload: dashboardInfo,
});
// Rejected
export const GET_DASHBOARD_INFO_REJECTED = "GET_DASHBOARD_INFO_REJECTED";
export interface GetDashboardInfoRejectedAction {
  type: typeof GET_DASHBOARD_INFO_REJECTED;
}
export const getDashboardInfoRejected = (): GetDashboardInfoRejectedAction => ({
  type: GET_DASHBOARD_INFO_REJECTED,
});
