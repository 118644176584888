import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/buttons/Button";
import {
  MODAL_CONFIRMATION,
  MODAL_SIGNATURE,
} from "src/components/modals/modalTypes";
import {
  CommentsType,
  DeviceType,
  MimeType,
} from "src/components/shared/enums";
import Spinner from "src/components/Spinner";
import { updatePermissions } from "src/store/actions/auth";
import {
  refreshFirstPageComments,
  setCommentsDrawer,
  setMainHeader,
} from "src/store/actions/global";
import { hideModal, showModal } from "src/store/actions/modal";
import { consentDocument } from "src/store/actions/signature";
import {
  getVaultDocument,
  getVaultDocumentSuccess,
  rejectDocument,
  setSelectedDocumentId,
} from "src/store/actions/vault";
import { RootState } from "src/store/reducers";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import { getAvatars } from "src/utils/helpers/governance";
import { initialState } from "src/store/reducers/vault";
import DesktopNav from "src/components/DesktopNav";
import PdfViewer from "src/components/PdfViewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  docViewerMimeType,
  imagesMimeType,
} from "src/components/shared/constants";
import DocViewerLoader from "src/components/DocViewerLoader";
import { UserAccountDto, VaultDocument as VaultDocumentType } from "../../../services/Api/types";

type VaultDocumentProps = { id: string };

function VaultDocument({ id }: VaultDocumentProps) {
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const isDesktop = deviceType === DeviceType.Desktop;
  const {
    vaultDocument: {
      isLoading,
      value: vaultDocument,
      previousPage,
      previousPermission,
    },
    vaultDocumentHistory: {
      value: { data: vaultDocumentHistoryData },
    },
  } = useSelector(({ vault }: RootState) => vault);
  // @ts-ignore
  const userId = useSelector(({ auth }: RootState) => auth.session?.user?.id);

  useEffect(() => {
    dispatch(getVaultDocument(id));
  }, [id]);

  useEffect(() => {
    dispatch(
      setMainHeader({
        showMenuDrawerOnDesktop: true,
        title: "Document Vault",
        onBackButtonClick: isDesktop ? undefined : onBackButtonClick,
        showNotificationsButton: true,
        showDocumentStatus: true,
        avatars: getAvatars(
          (vaultDocument as VaultDocumentType).confirmations,
          (vaultDocument as VaultDocumentType).missingToConfirm
        ),
      })
    );
  }, [isDesktop, vaultDocument]);

  const onBackButtonClick = () => {
    dispatch(
      updatePermissions({
        page: previousPage,
        permission: previousPermission,
      })
    );
    !(vaultDocumentHistoryData as [])?.length && dispatch(setSelectedDocumentId(null));
    dispatch(getVaultDocumentSuccess(initialState.vaultDocument.value));
  };

  const userHasNotSigned = (vaultDocument as VaultDocumentType).missingToConfirm?.find(
    (user: UserAccountDto) => user.id === userId
  );

  const openSignatureModal = () =>
    dispatch(
      showModal(MODAL_SIGNATURE, {
        title: "Sign request",
        description:
          "Please enter your full name and today's date exactly as shown to complete the signing process.",
        buttonText: "Sign request",
        onButtonClick: () =>
          (vaultDocument as VaultDocumentType).document &&
          dispatch(
            consentDocument({
              documentId: (vaultDocument as VaultDocumentType)?.document?.id as string,
              eventId: (vaultDocument as VaultDocumentType)?.document?.eventId as string,
            })
          ),

        onCloseButtonClick: () => dispatch(hideModal(MODAL_SIGNATURE)),
      })
    );

  const openRejectionModal = () =>
    dispatch(
      showModal(MODAL_CONFIRMATION, {
        title: "Reject new document",
        description: (
          <p>
            Once you reject the document, it will be deleted from your group.
            Are you sure you want to reject this document?
          </p>
        ),
        leftButtonText: "Reject",
        onLeftButtonClick: () =>
          (vaultDocument as VaultDocumentType).document &&
          dispatch(rejectDocument((vaultDocument as VaultDocumentType)?.document?.id as string)),
        rightButtonText: "Cancel",
        onRightButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
        onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
      })
    );

  const documentSrc = (vaultDocument as VaultDocumentType).document?.s3FileUrl;

  return (
    <div className={"vault-document"}>
      <DesktopNav
        title="Document Vault"
        backButton={{ onBackButtonClick }}
        rightButton={{
          text: "Add a comment",
          iconClass: "icon-plus",
          onClick: () => {
            if (!isLoading && (vaultDocument as VaultDocumentType).document) {
              dispatch(
                setCommentsDrawer({
                  isOpened: true,
                  id: (vaultDocument as VaultDocumentType)?.document?.id,
                  type: CommentsType.document,
                })
              );
              dispatch(
                refreshFirstPageComments(
                  (vaultDocument as VaultDocumentType)?.document?.id as string,
                  CommentsType.document,
                  false
                )
              );
            }
          },
          isDisabled: isLoading,
        }}
      />
      {isLoading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <>
          {(vaultDocument as VaultDocumentType).document &&
            (imagesMimeType.includes(
              (vaultDocument as VaultDocumentType)?.document?.mimeType as MimeType
            ) ? (
              <div
                className={`img-container ${
                  userHasNotSigned ? "extra-padding" : ""
                }`}
              >
                <img src={documentSrc} alt={(vaultDocument as VaultDocumentType)?.document?.name} />
              </div>
            ) : (vaultDocument as VaultDocumentType)?.document?.mimeType === MimeType.pdf ? (
              <PdfViewer
                src={documentSrc}
                className={userHasNotSigned ? "small-height" : ""}
              />
            ) : docViewerMimeType.includes(
              (vaultDocument as VaultDocumentType)?.document?.mimeType as MimeType
              ) ? (
              <DocViewer
                className={`doc-viewer ${
                  userHasNotSigned ? "small-height" : ""
                }`}
                pluginRenderers={DocViewerRenderers}
                prefetchMethod="GET"
                config={{
                  header: {
                    disableHeader: true,
                  },
                  loadingRenderer: { overrideComponent: DocViewerLoader },
                }}
                documents={[{ uri: (vaultDocument as VaultDocumentType)?.document?.s3FileUrl as string }]}
              />
            ) : (
              <div className="no-viewer">
                <p>
                  Not supported format.{" "}
                  <a href={documentSrc} rel="noreferrer" target={"_blank"}>
                    Click here
                  </a>{" "}
                  to download the file.
                </p>
              </div>
            ))}

          {userHasNotSigned && (
            <div className="bottom-fixed">
              <Button
                className="secondary-button"
                text="Reject"
                onClick={openRejectionModal}
              />
              <Button text="Confirm" onClick={openSignatureModal} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default VaultDocument;
