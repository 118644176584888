import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/buttons/Button";
import { AgreementDocumentCode } from "src/components/shared/enums";
import { accessDashboard } from "src/store/actions/auth";
import { getAgreementHash } from "src/store/actions/signature";
import { RootState } from "src/store/reducers";
import { useEffect } from "react";
import { setFullPageLoading } from "../../../store/actions/global";

type NiceWorkProps = {
  code: AgreementDocumentCode;
};

function NiceWork({ code }: NiceWorkProps) {
  const { isGettingHash } = useSelector(
    ({ signature }: RootState) =>
      signature.agreements.MemorandumOfAgreement.hash
  );
  const dispatch = useDispatch();

  const isCoOwnershipAgreement =
    code === AgreementDocumentCode.CoOwnershipAgreement;

  const onButtonClick = () => {
    if (isCoOwnershipAgreement)
      !isGettingHash &&
        dispatch(getAgreementHash(AgreementDocumentCode.MemorandumOfAgreement));
    else {
      dispatch(accessDashboard());
    }
  };

  const agreementText = isCoOwnershipAgreement
    ? "Co-ownership Agreement"
    : "Memorandum of Agreement";

  useEffect(() => {
    dispatch(setFullPageLoading(false))
  }, [])

  return (
    <div className="nice-work-wrapper">
      <div className="content-wrapper">
        <div className="vertical-centered">
          <div className="centered-box success-step">
            <i className="icon-nice-work"></i>
            <h1>Nice work</h1>
            <p className="text-align-center">
              All co-owners have signed and your{" "}
              <strong>{agreementText} is executed</strong>
            </p>
            <p className="text-align-left">
              You'll be able to view, download, and share your {agreementText}{" "}
              from the Document Vault.
            </p>
          </div>
          <div className="bottom-fixed have-comment text-align-left">
            <Button
              text={isCoOwnershipAgreement ? "Next" : "Go to dashboard"}
              onClick={onButtonClick}
              isLoading={isGettingHash}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NiceWork;
