import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import ChatDrawer from "src/components/drawers/ChatDrawer";
import EmptyState from "src/components/EmptyState";
import ChatInput from "src/components/inputs/ChatInput";
import ChatMessage from "src/components/Message";
import { DeviceType, SegmentEvents } from "src/components/shared/enums";
import { ChatMessageType } from "src/components/shared/types";
import Spinner from "src/components/Spinner";
import {
  getNextPageMessages,
  refreshFirstPageMessages,
  sendChatMessage,
  sendChatMessageReceived,
  setSelectedChatGroup
} from "src/store/actions/chat";
import { setMainHeader } from "src/store/actions/global";
import { RootState } from "src/store/reducers";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import useInterval from "src/utils/customHooks/useInterval";
import useWindowDimensions from "src/utils/customHooks/useWindowDimensions";
import { convertJSONDateToLongStringLocalDate, localDateFromJSON } from "src/utils/helpers/dates";
import useSwipe from "src/utils/customHooks/useSwipe";
import { AnalyticsContext, AnalyticsContextType } from "../../components/Analytics";
import { UserAccountDto } from "../../services/Api/types";

function Chat() {
  const dispatch = useDispatch();
  const { chatGroups, chatMessages } = useSelector(
    ({ chat }: RootState) => chat
  );
  // @ts-ignore
  const users = useSelector(({ auth }: RootState) => auth.session?.group?.users);
  // @ts-ignore
  const user = useSelector(({ auth }: RootState) => auth.session?.user);
  // @ts-ignore
  const session = useSelector(({ auth }: RootState) => auth.session);
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType;

  const [isViewOnBottom, setIsViewOnBottom] = useState(true);
  const divRef = useRef<HTMLDivElement | null>(null);
  const { height } = useWindowDimensions();
  const { onTouchEnd, onTouchMove, onTouchStart, swipeDistance } = useSwipe({
    divRef: divRef,
    onSwipeUp: () => dispatch(refreshFirstPageMessages(true, true)),
    swipeThreshold: 50
  });
  const deviceType = useDeviceType();

  useInterval(
    () =>
      isViewOnBottom &&
      chatGroups.selectedChatGroup &&
      dispatch(refreshFirstPageMessages(false, false)),
    5000
  );

  useEffect(() => {
    return () => {
      dispatch(setSelectedChatGroup(null));
    };
  }, []);

  useEffect(() => {
    dispatch(
      setMainHeader({
        showNotificationsButton: true,
        showMenuDrawerOnDesktop: true,
        title: !chatGroups.selectedChatGroup
          ? "Chat"
          : chatGroups.selectedChatGroup?.name,
        showHamburgerButton: !chatGroups.selectedChatGroup,
        onBackButtonClick:
          deviceType === DeviceType.Mobile && chatGroups.selectedChatGroup
            ? () => {
              dispatch(setSelectedChatGroup(null));
            }
            : undefined
      })
    );
    chatGroups.selectedChatGroup &&
    dispatch(refreshFirstPageMessages(false, true));
  }, [chatGroups.selectedChatGroup?.name, deviceType]);

  useEffect(() => {
    if (chatMessages?.sendMessageSuccess || chatMessages?.sendMessageRejected) {
      segmentTrack(SegmentEvents.ChatSent, {
        user_id: user?.id,
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });
      dispatch(sendChatMessageReceived());
    }
  }, [user?.id, chatMessages?.sendMessageSuccess, chatMessages?.sendMessageRejected, session?.userToGroupId, session?.group?.id]);

  useEffect(() => {
    window.Intercom && window.Intercom('update', { hide_default_launcher: true })
    window.Intercom && window.Intercom('hide')

    return () => {
      window.Intercom && window.Intercom('update', { hide_default_launcher: false })
    }
  }, [])

  const sendMessage = (message: string) => {
    dispatch(sendChatMessage(message, isViewOnBottom));
  };

  const fetchNextPage = () =>
    chatMessages.value.nextPage && dispatch(getNextPageMessages());

  const onScroll = () => {
    if (divRef.current) {
      const { scrollTop } = divRef.current;
      if (scrollTop > -(height * 1.2)) setIsViewOnBottom(true);
      else if (scrollTop < -(height * 1)) setIsViewOnBottom(false);
    }
  };

  const messages = chatMessages.value.data;

  return (
    <>
      <ChatDrawer />
      <div className={`chat ${!chatGroups.selectedChatGroup ? "" : "open"}`}>
        {!chatGroups.selectedChatGroup && deviceType === DeviceType.Desktop ? (
          <EmptyState
            title="No chat group selected"
            iconClass="icon-chat-circled"
          />
        ) : (
          <>
            {messages === null ? (
              chatMessages.isRefreshingFirstPage ? (
                <Spinner />
              ) : (
                <EmptyState
                  title="No messages yet"
                  iconClass="icon-chat-circled"
                />
              )
            ) : messages.length ? (
              <div
                onScroll={onScroll}
                ref={divRef}
                id="scrollableDiv"
                className="chat-messages"
                style={{
                  paddingBottom: `${
                    swipeDistance > 100 ? 180 : swipeDistance + 80
                  }px`,
                  transition: "all 0.2s"
                }}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <InfiniteScroll
                  className="chat-infinite-scroll"
                  dataLength={messages.length}
                  next={fetchNextPage}
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    height: "100%",
                    overflow: "hidden"
                  }}
                  inverse={true}
                  hasMore={chatMessages.value.nextPage !== null}
                  loader={chatMessages.isGettingNextPage && <Spinner />}
                  scrollableTarget="scrollableDiv"
                >
                  {chatMessages.showSwipeLoader && <Spinner />}
                  {messages.map((message: ChatMessageType, index: number) => {
                    return (
                      <div key={index}>
                        {(index === messages.length - 1 ||
                          localDateFromJSON(messages[index + 1].createdAt) !==
                          localDateFromJSON(message.createdAt)) && (
                          <span className="date">
                            {convertJSONDateToLongStringLocalDate(
                              message.createdAt,
                              false
                            )}
                          </span>
                        )}
                        <ChatMessage
                          className={`${
                            message.writtenByUser !==
                            messages[index + 1]?.writtenByUser
                              ? "first"
                              : ""
                          } ${
                            user?.id === message.writtenByUser
                              ? "own-message"
                              : ""
                          } ${
                            index === messages.length - 1 ? "first-of-all" : ""
                          }`}
                          showAvatar={
                            user?.id !== message.writtenByUser &&
                            message.writtenByUser !==
                            messages[index + 1]?.writtenByUser
                          }
                          writtenByUser={
                            users?.find(
                              (user: UserAccountDto) => user.id === message.writtenByUser
                            ) || user!
                          }
                          text={message.text}
                          createdAt={message.createdAt}
                          isReadByAllUsers={
                            user?.id !== message.writtenByUser
                              ? undefined
                              : users &&
                              message.readByUsers.length === users.length
                          }
                        />
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
            ) : (
              <EmptyState
                title="No messages yet"
                iconClass="icon-chat-circled"
              />
            )}
            <div className="input-text">
              <ChatInput
                name="message"
                placeholder="Share your thoughts"
                maxLength={500}
                onSendClick={sendMessage}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Chat;
