import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import ModalLayout from "../ModalLayout";
import Button from "src/components/buttons/Button";
import TextInput from "src/components/inputs/TextInput";
import {
  dateIsToday,
  localDateFromJSON,
  validDate,
} from "src/utils/helpers/dates";
import { useState } from "react";
import DateInput from "src/components/inputs/DateInput";
import Checkbox from "src/components/inputs/Checkbox";

export type UserSignatureType = {
  fullName: string;
  date: string;
  confirmation: boolean;
};

export type SignatureModalProps = {
  title?: string;
  description?: string;
  buttonText?: string;
  onButtonClick?: ({ fullName, date, confirmation }: UserSignatureType) => void;
  onCloseButtonClick?: () => void;
};

const signatureInitialState = {
  fullName: "",
  date: "",
  confirmation: false,
};

function SignatureModal() {
  const [{ fullName, date, confirmation }, setSignature] =
    useState<UserSignatureType>(signatureInitialState);

  const modal = useSelector((state: RootState) => state.modal.signature);
  const isSigning = useSelector(
    ({ signature }: RootState) => signature.isSigning
  );

  // @ts-ignore
  const { firstName, lastName } = useSelector(({ auth }: RootState) => auth.session!.user!);

  const todayPlaceholder = localDateFromJSON(new Date().toJSON());
  const fullNamePlaceholder = `${firstName} ${lastName}`;

  /* Fix ********/
  // const isValidForm =
  //   fullName.includes(firstName) &&
  //   fullName.includes(lastName) &&
  //   confirmation &&
  //   dateIsToday(date) &&
  //   validDate(date);
  //console.log(fullNamePlaceholder.toLowerCase().toString() + " == " + fullName.toLowerCase().toString());
  const isValidForm =
    fullNamePlaceholder.toLowerCase().toString() === fullName.toLowerCase().toString() &&
    confirmation &&
    dateIsToday(date) &&
    validDate(date);  
  /******************/
  const isValidDate =
    date.length !== 8 || (dateIsToday(date) && validDate(date));
  const isToday = date.length === 8 && dateIsToday(date);
  //const isValidName = !!fullName.trim() && fullName.includes(firstName) && fullName.includes(lastName)
  const isValidName = !!fullName.trim() && fullNamePlaceholder.toLowerCase().toString() === fullName.toLowerCase().toString();
  console.log({fullName})

  const onButtonClick = () => {
    if (isValidForm && !isSigning) {
      modal.onButtonClick &&
        modal.onButtonClick({ fullName, date, confirmation });
    }
  };

  const content = (
    <div className="agreement-modal">
      <div
        onClick={() =>
          !isSigning && modal.onCloseButtonClick && modal.onCloseButtonClick()
        }
        className={`close-modal ${isSigning ? "disabled" : ""}`}
      >
        <i className="icon-cross" />
      </div>

      <h4>{modal.title}</h4>
      <p>{modal.description}</p>

      <form onSubmit={(e) => e.preventDefault()}>
        <TextInput
          id="fullName"
          name="fullName"
          label="Full name"
          value={fullName}
          onChange={(fullName) =>
            setSignature({ fullName, date, confirmation })
          }
          isDisabled={isSigning}
          placeholder={fullNamePlaceholder}
          errorMessage={fullName.length === 0 ? undefined : isValidName ? undefined : "Name doesn’t match"}
          isValid={isValidName}
        />
        <DateInput
          id="date"
          name="date"
          label="Today's date"
          value={date}
          onChange={(date) => setSignature({ fullName, date, confirmation })}
          placeholder={todayPlaceholder}
          isDisabled={isSigning}
          errorMessage={
            isValidDate
              ? undefined
              : !isToday
              ? "Invalid date"
              : "Invalid date, format must be MM/DD/YYYY"
          }
          isValid={isValidDate}
        />
        <div className="agreement-checkbox-box">
          <Checkbox
            id="confirmation"
            name="confirmation"
            onChange={(confirmation) =>
              setSignature({ fullName, date, confirmation })
            }
            isDisabled={isSigning}
            checked={confirmation}
            label="I agree to use digital records and signatures."
          />
        </div>
      </form>
      {isSigning && (
        <p className="loading-text">
          The signature transaction may take up to a minute.
        </p>
      )}
      <Button
        text={modal.buttonText ?? "Sign agreement"}
        onClick={() => onButtonClick()}
        isLoading={isSigning}
        isDisabled={!isValidForm}
      />
    </div>
  );

  return <ModalLayout content={content} />;
}

export default SignatureModal;
