import ProgressBar from "../ProgressBar";
import EditionBar from "../EditionBar";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import ExitButton from "../buttons/ExitButton";
import Logo from "../Logo";
import StepStatus from "./StepStatus";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import HamburgerButton from "../buttons/HamburgerButton";
import NotificationsButton from "../buttons/NotificationsButton";
import { DeviceType } from "../shared/enums";
import BackButton from "../buttons/BackButton";
import EditProfileButton from "../buttons/EditProfileButton";
import DeleteAccountButton from "../buttons/DeleteAccountButton";
import AddDocumentButton from "../buttons/AddDocumentButton";
import DocumentStatus from "./DocumentStatus";
import {
  DashboardPermission,
  GovernancePermission,
  Page,
} from "src/utils/helpers/permissions";

function MainHeader() {
  const deviceType = useDeviceType();
  const { onEditionMode, currentSectionStep, isStepLoading } = useSelector(
    ({ governance }: RootState) => governance
  );
  const { page, permission } = useSelector(({ auth }: RootState) => auth);
  const {
    isFullPageLoading,
    mainHeader: {
      hide,
      hideHeaderOnMobile,
      title,
      showLogo,
      showHamburgerButton,
      onBackButtonClick,
      showStepStatus,
      showDocumentStatus,
      showEditProfileButton,
      showDeleteAccountButton,
      showNotificationsButton,
      showAddDocumentButton,
      progress,
      hideBottomLine,
      darkMode,
    },
  } = useSelector(({ global }: RootState) => global);

  const isMobile = deviceType === DeviceType.Mobile;
  const isDesktop = deviceType === DeviceType.Desktop;

  const isFirstToConfirm = currentSectionStep?.stepConfirmedBy?.length === 0;
  const isOnEditionMode =
    !isFirstToConfirm &&
    onEditionMode &&
    (page === Page.GOVERNANCE || page === Page.DASHBOARD) &&
    (permission === DashboardPermission.SECTION_STEPS ||
      permission === GovernancePermission.SECTION_STEPS);
  const isBackButtonDisabled =
    isOnEditionMode || (isStepLoading && !isFullPageLoading);

  const bottomLineClass =
    isMobile && (hideBottomLine || isOnEditionMode || progress !== null)
      ? "hide-line"
      : "";
  const darkModeClass = isMobile && darkMode ? "dark" : "";
  const showStatus = showStepStatus || showDocumentStatus;

  if (hide) return <></>;

  if (hideHeaderOnMobile && isMobile) return <></>;

  return (
    <div className={`main-header ${bottomLineClass} ${darkModeClass}`}>
      {isMobile && showStatus && (
        <div className="header-top">
          <div className="header-top-left">
            {showHamburgerButton && <HamburgerButton />}
            {showDocumentStatus && onBackButtonClick && (
              <BackButton
                onClick={onBackButtonClick}
                isDisabled={isBackButtonDisabled}
              />
            )}
          </div>
          <div className="header-top-center">
            {showLogo && <Logo icon={true} />}
            {title && <h1 className="title">{title}</h1>}
          </div>
          <div className="header-top-right">
            {showEditProfileButton && <EditProfileButton />}
            {showDeleteAccountButton && <DeleteAccountButton />}
            {showAddDocumentButton && <AddDocumentButton />}
            {showNotificationsButton && <NotificationsButton />}
          </div>
        </div>
      )}

      <div className="header-bottom">
        <div className="header-bottom-left">
          {isOnEditionMode && <ExitButton />}
          {!isOnEditionMode && onBackButtonClick && !showDocumentStatus && (
            <BackButton
              onClick={onBackButtonClick}
              isDisabled={isBackButtonDisabled}
            />
          )}
          {isMobile && !showStatus && showHamburgerButton && (
            <HamburgerButton />
          )}
        </div>
        {!(isMobile && showStatus) && (
          <div className="header-bottom-center">
            {!isDesktop && title && <h1 className="title">{title}</h1>}
            {(isDesktop || (showLogo && !showStatus)) && <Logo icon={true} />}
          </div>
        )}
        <div className="header-bottom-right">
          {showStepStatus && <StepStatus />}
          {showDocumentStatus && <DocumentStatus />}
          {isMobile && !showStatus && showEditProfileButton && (
            <EditProfileButton />
          )}
          {isMobile && !showStatus && showDeleteAccountButton && (
            <DeleteAccountButton />
          )}
          {isMobile && !showStatus && showAddDocumentButton && (
            <AddDocumentButton />
          )}
          {showNotificationsButton &&
            (isDesktop || (isMobile && !showStatus)) && <NotificationsButton />}
        </div>
      </div>
      <div className="header-information">
        <ProgressBar progress={progress} show={!isOnEditionMode} />
        <EditionBar text="You are in edit mode" show={isOnEditionMode} />
      </div>
    </div>
  );
}
export default MainHeader;
