import React, { useState } from "react";

type useSwipeProps = {
  divRef: React.MutableRefObject<HTMLDivElement | null>;
  swipeThreshold?: number;
  onSwipeDown?: () => void;
  onSwipeUp?: () => void;
};

function useSwipe({
  divRef,
  onSwipeDown,
  onSwipeUp,
  swipeThreshold,
}: useSwipeProps) {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipeDistance, setSwipeDistance] = useState(0);

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientY);
    if (divRef.current) {
      const { scrollTop } = divRef.current;

      if (scrollTop === 0) {
        setSwipeDistance((touchStart || 0) - (touchEnd || 0));
      }
    }
  };
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    if (swipeThreshold && onSwipeDown && -swipeDistance > swipeThreshold) {
      onSwipeDown();
    } else if (swipeThreshold && onSwipeUp && swipeDistance > swipeThreshold) {
      onSwipeUp();
    }
    setSwipeDistance(0);
  };
  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    swipeDistance,
  };
}

export default useSwipe;
