import { AddUserModalProps } from "src/components/modals/AddUserModal";
import { ConfirmationModalProps } from "src/components/modals/ConfirmationModal";
import { InformationModalProps } from "src/components/modals/InformationModal";
import { SignatureModalProps } from "src/components/modals/SignatureModal";
import { ToastModalProps } from "src/components/modals/ToastModal";
import { AddDocumentModalProps } from "src/components/modals/AddDocumentModal";

export type ModalProps =
  | ConfirmationModalProps
  | AddUserModalProps
  | ToastModalProps
  | InformationModalProps
  | SignatureModalProps
  | AddDocumentModalProps;

// SHOW MODAL
export const SHOW_MODAL = "SHOW_MODAL";
export interface ShowModal {
  type: typeof SHOW_MODAL;
  payload: { modalName: string; props: ModalProps };
}
export const showModal = (modalName: string, props: ModalProps) => ({
  type: SHOW_MODAL,
  payload: { modalName, props },
});

// HIDE MODAL
export const HIDE_MODAL = "HIDE_MODAL";
export interface HideModal {
  type: typeof HIDE_MODAL;
  payload: { modalName: string; props: ModalProps };
}
export const hideModal = (modalName: string) => ({
  type: HIDE_MODAL,
  payload: { modalName },
});
