import { useState } from "react";
import Button from "../buttons/Button";
import Select from "../inputs/Select";
import TextInput from "../inputs/TextInput";
import { userRelationshipOptions } from "src/pages/Onboarding/GroupStatus/helper";
import { GroupInvitationMember } from "src/services/Api/types";
import {
  validateEmail,
  validateName,
  validateRelationship,
} from "src/utils/helpers/validations";

type AddUserProps = {
  isAddingUser: boolean;
  onButtonClick: (user: GroupInvitationMember) => void;
};

const userInitialState: GroupInvitationMember = {
  firstName: "",
  email: "",
  userRelationship: "",
};

function AddUser({ isAddingUser, onButtonClick }: AddUserProps) {
  const [user, setUser] = useState(userInitialState);

  const isFormValid =
    validateName(user.firstName) &&
    validateEmail(user.email) &&
    validateRelationship(user.userRelationship);

  const addUser = () => {
    if (!isAddingUser && isFormValid) {
      onButtonClick(user);
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="invite-co-owners">
        <TextInput
          id={"firstName"}
          label={"Co-owner's first name"}
          name={"firstName"}
          value={user.firstName}
          isDisabled={isAddingUser}
          onChange={(firstName) => {
            setUser({ ...user, firstName });
          }}
        />
        <TextInput
          id={"email"}
          label={"Co-owner's email address"}
          name={"email"}
          value={user.email}
          isDisabled={isAddingUser}
          onChange={(email) => {
            setUser({ ...user, email });
          }}
        />
        <Select
          id="userRelationship"
          label="Your relationship"
          onChange={(value) => {
            setUser({ ...user, userRelationship: value });
          }}
          isDisabled={isAddingUser}
          selectedId={user.userRelationship}
          options={userRelationshipOptions}
        />
      </div>

      <Button
        isDisabled={!isFormValid}
        isLoading={isAddingUser}
        onClick={addUser}
        text="Send invite"
      />
    </form>
  );
}

export default AddUser;
