import { useDispatch, useSelector } from "react-redux";
import { cancelStepEdition, confirmStep } from "src/store/actions/governance";
import { hideModal, showModal } from "src/store/actions/modal";
import { RootState } from "src/store/reducers";
import { MODAL_CONFIRMATION } from "../modals/modalTypes";
import { UserAccountDto } from "../../services/Api/types";

function ExitButton() {
  const dispatch = useDispatch();
  const { stepValidation, isStepLoading } = useSelector(
    ({ governance }: RootState) => governance
  );
  const { session } = useSelector(({ auth }: RootState) => auth);

  const disabledClass = isStepLoading ? "disabled" : "";

  const onClick = () => {
    if (!isStepLoading) {
      if (stepValidation?.isValid) {
        showConfirmationModal();
      } else dispatch(cancelStepEdition());
    }
  };

  const showConfirmationModal = () =>
    dispatch(
      showModal(MODAL_CONFIRMATION, {
        title: "Do you want to save and confirm the changes before leaving?",
        leftButtonText: "Yes",
        onLeftButtonClick: saveAndConfirmChanges,
        rightButtonText: "Don't save",
        onRightButtonClick: () => {
          dispatch(cancelStepEdition());
        },
        onCloseButtonClick: () => {
          dispatch(hideModal(MODAL_CONFIRMATION));
        },
      })
    );

  const saveAndConfirmChanges = () => {
    // @ts-ignore
    const atLeastOneMemberSignedCOA = session?.group?.users.find(
      (user: UserAccountDto) => user.hasSignedCoAgreement
    );
    if (atLeastOneMemberSignedCOA) {
      dispatch(hideModal(MODAL_CONFIRMATION));
      showReConfirmationModal();
    } else dispatch(confirmStep(true));
  };

  const showReConfirmationModal = () =>
    dispatch(
      showModal(MODAL_CONFIRMATION, {
        title: "Are you sure you want to make this change?",
        description: (
          <p>
            If you make this change, all co-owners will have to re-execute your
            Co-ownership Agreement for it to take effect. Make any other
            necessary changes before re-executing (signing).
          </p>
        ),
        leftButtonText: "Apply change",
        onLeftButtonClick: () => {
          dispatch(confirmStep(true, true));
        },
        rightButtonText: "Cancel",
        onRightButtonClick: () => {
          dispatch(cancelStepEdition());
        },
        onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
      })
    );

  return (
    <button
      className={`exit-button ${disabledClass}`}
      onClick={onClick}
      type="button"
    >
      Exit
    </button>
  );
}

export default ExitButton;
