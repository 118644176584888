import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import { OnboardingPermission } from "src/utils/helpers/permissions";
import GroupCreationForm from "./GroupCreation";
import GroupStatus from "./GroupStatus";
import IndividualInputsForm from "./IndividualInputs";
import VerificationIdForm from "./VerificationId";
import PaymentForm from "./Payment";

function Onboarding() {
  const { permission } = useSelector((state: RootState) => state.auth);
  if (permission === OnboardingPermission.ALL_MEMBERS_VERIFIED)
    return <PaymentForm />;
  if (permission === OnboardingPermission.GROUP_STATUS) return <GroupStatus />;
  if (permission === OnboardingPermission.VERIFICATION_ID)
    return <VerificationIdForm />;
  if (permission === OnboardingPermission.GROUP_CREATION)
    return <GroupCreationForm />;
  return <IndividualInputsForm />;
}

export default Onboarding;
