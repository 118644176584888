import coBuyLogo from "src/assets/img/coBuyLogo.svg";

type LogoProps = { className?: string; icon?: boolean };

function Logo({ className = "", icon }: LogoProps) {
  return (
    <figure className={`logo ${className}`}>
      {icon ? (
        <i className="icon-cobuy" />
      ) : (
        <img src={coBuyLogo} alt="CoBuy Logo" />
      )}
    </figure>
  );
}

export default Logo;
