import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "src/store/reducers";
import { Page } from "src/utils/helpers/permissions";
import { getPathFromPage } from "src/utils/helpers/routing";
import { useEffect } from "react";
import { hideModal, showModal } from "../../store/actions/modal";
import { MODAL_CONFIRMATION } from "../modals/modalTypes";
import { NotificationCode } from "../../services/Api/enums";
import { setModalNotifications } from "../../store/actions/notifications";
import { Path } from "./routes";

type PrivateRouteProps = {
  children: React.ReactNode;
  permission: Page;
};

function PrivateRoute({ children, permission }: PrivateRouteProps) {
  let location = useLocation();
  const dispatch = useDispatch();
  const { isAuth, page } = useSelector((state: RootState) => state.auth);
  const modalNotifications = useSelector(
    ({ notifications }: RootState) => notifications.modalNotifications.value
  );

  useEffect(() => {
    if (modalNotifications.length !== 0) {
      showNotificationModals();
    }
  }, [modalNotifications]);


  const showNotificationModals = () => {
    let i = 0;

    const modalNotificationsWithoutDuplicates = modalNotifications.filter(
      (value, index, self) =>
        index === self.findIndex((v) => v.code === value.code)
    );

    const runShowModal = () =>
      dispatch(
        showModal(MODAL_CONFIRMATION, {
          title: "Group membership updated",
          description:
            modalNotificationsWithoutDuplicates[i].code ===
            NotificationCode.ModalGroupModifiedMemberDeleted ? (
              <p>
                A co-owner was deleted from your group. Please co-create your
                new Co-ownership Agreement.
              </p>
            ) : modalNotificationsWithoutDuplicates[i].code ===
            NotificationCode.ModalGroupModifiedMemberAdded ? (
              <p>
                A co-owner was added to your group. Please co-create your new
                Co-ownership Agreement.
              </p>
            ) : undefined,
          iconClass: "icon-alert",
          leftButtonText: "Ok",
          onLeftButtonClick: () => {
            if (modalNotificationsWithoutDuplicates.length === i + 1) {
              dispatch(setModalNotifications([]));
              dispatch(hideModal(MODAL_CONFIRMATION));
              const hostname = `${window.location.protocol}//${window.location.hostname}`;
              window.location.replace(`${hostname}${Path.Dashboard}`);
            } else {
              i = i + 1;
              runShowModal();
            }
          }
        })
      );
    runShowModal();
  };

  if (!isAuth || !page) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (permission === page) {
    return <>{children}</>;
  }

  return <Navigate to={getPathFromPage[page]} replace />;
}

export default PrivateRoute;
