import { useState } from "react";
import Badge from "../Badge";
import PopoverMenu from "../popover/PopoverMenu";
import {
  documentStatusBadgeClass,
  DocumentTypeName,
} from "../shared/constants";
import { DocumentStatus } from "../shared/enums";
import { DocumentType } from "src/components/shared/enums";
import { useDispatch, useSelector } from "react-redux";
import { updatePermissions } from "src/store/actions/auth";
import { DocumentVaultPermission, Page } from "src/utils/helpers/permissions";
import {
  setPreviousVaultDocumentPermission,
  setSelectedDocumentId,
} from "src/store/actions/vault";
import { DocumentCardType } from "src/services/Api/types";
import { getCOAStatePermission } from "src/utils/helpers/getCOAStatePermission";
import { RootState } from "src/store/reducers";
import usePopoverMenuOptions from "src/utils/customHooks/usePopoverMenuOptions";

type DocumentCardProps = {
  document: DocumentCardType;
};

function DocumentCard({ document }: DocumentCardProps) {
  const dispatch = useDispatch();
  // @ts-ignore
  const user = useSelector(({ auth }: RootState) => auth.session?.user);
  const { page, permission } = useSelector(({ auth }: RootState) => auth);
  const [isOpen, setIsOpen] = useState(false);
  const { id, name, description, type, status } = document;
  const { getPopoverMenuOptions } = usePopoverMenuOptions({ document });

  const onCardClick = () => {
    if (
      (type === DocumentType.CoOwnershipAgreement ||
        type === DocumentType.MemorandumOfAgreement) &&
      status !== DocumentStatus.Executed &&
      status !== undefined
    ) {
      user &&
        dispatch(
          updatePermissions({
            page: Page.DASHBOARD,
            permission: getCOAStatePermission(user, Page.DASHBOARD),
          })
        );
    } else {
      dispatch(setSelectedDocumentId(id));
      dispatch(
        updatePermissions({
          page: Page.DOCUMENT_VAULT,
          permission: DocumentVaultPermission.DOCUMENT,
        })
      );
      page && dispatch(setPreviousVaultDocumentPermission(page, permission!));
    }
  };

  const showType =
    type &&
    type !== DocumentType.CoOwnershipAgreement &&
    type !== DocumentType.MemorandumOfAgreement;

  return (
    <div className="document-card">
      <div className="header-container" onClick={onCardClick}>
        <div className="title-badge-container">
          <div className="title">
            {showType && (
              <p className="document-type">{DocumentTypeName[type]}</p>
            )}
            <span className="document-title">{name}</span>
          </div>
          {status && (
            <Badge
              text={status as DocumentStatus}
              className={documentStatusBadgeClass[status as DocumentStatus]}
            />
          )}
        </div>
        {description && <p className="description">{description}</p>}
      </div>
      <i className="icon-dots" onClick={() => setIsOpen(!isOpen)}>
        {isOpen && <PopoverMenu menuOptions={getPopoverMenuOptions()} />}
      </i>
    </div>
  );
}

export default DocumentCard;
