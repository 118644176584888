import { useState, useMemo, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import FailedStep from "src/components/MultiStepForm/FailedStep";
import Step from "src/components/MultiStepForm/Step";
import { Path } from "src/components/routing/routes";
import { decodeBase64 } from "src/utils/helpers/helpers";
import Api from "src/services/Api";
import Storage from "src/services/LocalStorage";
import {
  convertPurchaseDates,
  formatDateFromString,
  fromStepDataToStringDate,
} from "src/utils/helpers/dates";
import { SignUpFormData } from "src/pages/Onboarding/signUp/Leader";
import RadioButtonGroup from "src/components/inputs/RadioButtonGroup";
import {
  SegmentEvents,
  SegmentResponseStatus,
  Status,
} from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";
import { captureMessage } from "@sentry/react";

function CheckCoOwnedHomePurchaseDateData({
  formData,
  nextStep,
  prevStep,
  saveData,
  progress,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const { invitationId } = useParams();
  const [stepData, setStepData] = useState(
    formData.coOwnedHomePurchaseDate || ""
  );
  const [status, setStatus] = useState<Status>(Status.Ok);
  const [timesTried, setTimesTried] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const onNextClick = async () => {
    if (!!stepData) {
      const isCorrectDate = await isDateCorrect();
      segmentTrack(SegmentEvents.MemberPreSignUpStepSubmitted, {
        step: "Closing date of the home purchase?",
        data: formatDateFromString(fromStepDataToStringDate(stepData) || ""),
        status: isCorrectDate
          ? SegmentResponseStatus.Success
          : SegmentResponseStatus.Error,
      });

      if (isCorrectDate) {
        saveData({ coOwnedHomePurchaseDate: stepData });
        nextStep();
      } else {
        if (timesTried < 1) {
          setTimesTried(timesTried + 1);
          setStatus(Status.Failed);
        } else {
          setStatus(Status.HardFailed);
        }
      }
    }
  };

  const isDateCorrect = async () => {
    try {
      setIsLoading(true);
      await Api.validateClosingPurchaseDate(invitationId || "", stepData);
      return true;
    } catch (error) {
      captureMessage(JSON.stringify(error))
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const purchaseDates = useMemo(
    () =>
      convertPurchaseDates(
        decodeBase64(Storage.getItem("groupAddressData") || "")
          .randomPurchaseDates
      ),
    []
  );

  const renderSwitch = () => {
    switch (status) {
      case Status.Ok:
        return (
          <Step
            title="Closing date of the home purchase?"
            onNextClick={onNextClick}
            nextText="Next"
            isButtonDisabled={!stepData}
            isButtonLoading={isLoading}
            onBackClick={() => prevStep()}
            progress={progress}
          >
            <RadioButtonGroup
              options={purchaseDates}
              selectedId={stepData}
              onChange={(value: string) => {
                setStepData(value);
              }}
              isDisabled={isLoading}
            />
          </Step>
        );
      case Status.Failed:
        return (
          <FailedStep
            title="Bummer"
            description="The closing date you selected does not match the date we have for this co-ownership group. 
            Please try selecting the date again."
            nextText="Try again"
            onNextClick={() => setStatus(Status.Ok)}
          />
        );
      case Status.HardFailed:
        return (
          <FailedStep
            title="Bummer"
            description="The closing date you selected does not match the date we have for this co-ownership group. 

            You've reached the maximum number of attempts. Please request a new invite from your co-owner or contact us."
            nextText="Ok"
            onNextClick={() => navigate(Path.Home)}
            footer={
              <p className="email-us-message">
                Email us: <a href="mailto:hello@cobuy.io">hello@cobuy.io</a>
              </p>
            }
          />
        );
      default:
        return;
    }
  };
  return renderSwitch() || <></>;
}

export default CheckCoOwnedHomePurchaseDateData;
