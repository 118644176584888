import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import ModalLayout from "../ModalLayout";
import parse from "html-react-parser";

export type InformationModalProps = {
  information?: string;
  onCloseButtonClick?: () => void;
};

function InformationModal() {
  const modal = useSelector((state: RootState) => state.modal.information);
  const content = (
    <div className="information-modal">
      <div onClick={modal.onCloseButtonClick} className="close-modal">
        <i className="icon-cross" />
      </div>
      {parse(modal.information || "")}
    </div>
  );

  return <ModalLayout content={content} />;
}

export default InformationModal;
