import {
  AgreementStatus,
  UserGroupInvitationStatus,
} from "src/services/Api/enums";
import { CoOwner, PendingConsensusActions } from "src/services/Api/types";
import Badge from "../Badge";

type UsersListProps = {
  coOwners: Array<CoOwner>;
  pendingConsensusActions: PendingConsensusActions;
  onClick: Function;
};

function UsersList({
  coOwners,
  onClick,
  pendingConsensusActions,
}: UsersListProps) {
  const getBadgeText = (user: CoOwner) => {
    const hasPendingConsensusAction = !!pendingConsensusActions.eventId;
    const hasUserSigned =
      pendingConsensusActions.userIdsThatConfirmedEvent.includes(user.id);
    return user.isSignupCompleted
      ? user.isIdentityVerified
        ? hasPendingConsensusAction
          ? hasUserSigned
            ? AgreementStatus.Signed
            : AgreementStatus.PendingSignature
          : ""
        : UserGroupInvitationStatus.NotVerified
      : user.isInvitationCompleted
      ? UserGroupInvitationStatus.CompletingProfile
      : UserGroupInvitationStatus.Invited;
  };

  const getBadgeClass = (user: CoOwner) => {
    const hasPendingConsensusAction = !!pendingConsensusActions.eventId;
    const hasUserSigned =
      pendingConsensusActions.userIdsThatConfirmedEvent.includes(user.id);
    return user.isSignupCompleted
      ? user.isIdentityVerified
        ? hasPendingConsensusAction
          ? hasUserSigned
            ? "success"
            : "warning"
          : "success"
        : "warning"
      : "warning";
  };

  return (
    <ul>
      {coOwners.map((coOwner, index) => (
        <li key={index} onClick={() => onClick(coOwner)}>
          <p>
            {`${coOwner.firstName} ${coOwner.lastName}`}{" "}
            {coOwner.isSelf ? <span>(You)</span> : ""}
          </p>
          {getBadgeText(coOwner) && (
            <Badge
              text={getBadgeText(coOwner)}
              className={getBadgeClass(coOwner)}
            />
          )}
          <i className="icon-arrow-right" />
        </li>
      ))}
    </ul>
  );
}

export default UsersList;
