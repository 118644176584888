//@ts-nocheck

import { createContext, ReactNode } from "react";
import { GroupDto, UserAccountDto } from "../services/Api/types";
import { groupVerificationStatus } from "../utils/helpers/helpers";
import { SegmentUserType } from "./shared/enums";
import { analytics } from "./SegmentAnalytics/utils";

type AnalyticsProviderProps = {
  children: ReactNode;
}

export type AnalyticsContextType = {
  segmentIdentifyUser: (user: any) => void;
  segmentIdentify: (user: Pick<UserAccountDto, "id" | "email" | "phoneNumber">) => void;
  segmentReset: () => void;
  segmentPage: (title: string, path: string) => void;
  segmentTrack: (event: string, properties?: Object, context?: Object) => void;
  segmentGroup: (groupId: string, properties: Object) => void;
  marshalSegmentGroup: (group: GroupDto, overrides?: any) => Object;
}

const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const segmentIdentifyUser = (session: any) => {
    return analytics.identify(session?.user?.id, {
      user_id: session?.user?.id,
      suffix: session?.user?.suffix,
      first_name: session?.user?.firstName,
      middle_initial: session?.user?.middleInitial,
      last_name: session?.user?.lastName,
      birthday: session?.user?.dateOfBirth,
      email: session?.user?.email,
      phone: session?.user?.phoneNumber,
      address: {
        street_address: session?.user?.address?.street_address,
        vicinity: session?.user?.address?.vicinity,
        county: session?.user?.address?.county,
        city: session?.user?.address?.locality,
        state: session?.user?.address?.region,
        postal_code: session?.user?.address?.postal_code,
        country: session?.user?.address?.country
      },
      full_name: `${session?.user?.firstName} ${session?.user?.lastName}`,
      formatted_address: `${session?.user?.address?.street_address}, ${session?.user?.address?.locality}, ${session?.user?.address?.region} ${session?.user?.address?.postal_code}`,
      current_group_id: session?.group?.id,
      created_at: session.user.createdAt ? new Date(session?.user?.createdAt).toISOString(): new Date(),
      group_id: [session?.group?.id],
      user_to_group_id: session?.userToGroupId,
      id_verified: session?.user?.isIdentityVerified,
      user_type: session?.invitation ? SegmentUserType.Member : SegmentUserType.Leader,
      governance_status: session?.user?.hasSignedMemoOfAgreement && session?.user?.hasSignedCoAgreement
        ? "Steady-state"
        : session?.user?.hasSignedMemoOfAgreement
          ? "MOA Signed"
          : session?.user?.hasSignedCoAgreement
            ? "COA Signed"
            : session?.user?.needToReviewCoAgreement
              ? "Needs to review"
              : session?.user?.hasCompletedCoAgreement
                ? "Completed"
                : session?.user?.hasStartedCoAgreement
                  ? "Started"
                  : " "
    });
  };

  const segmentIdentify = (
    user: Pick<UserAccountDto, "id" | "email" | "phoneNumber">
  ) =>
    analytics.identify(user.id, {
      email: user.email,
      phone: user.phoneNumber
    });

  const segmentReset = () => analytics.reset();
  const segmentPage = (title: string, path: string) => analytics.page({ title, path });
  const segmentGroup = (groupId: string, properties: Object) => analytics.group(groupId, properties);
  const segmentTrack = (event: string, properties?: Object, context?: Object) => {
    return analytics.track(event, properties, { context });
  };

  const marshalSegmentGroup = (group: GroupDto, overrides?: any) => {
    const started = group?.users?.filter(u => u.hasStartedCoAgreement).length >= 1
    const completed = group?.users?.filter(u => u.hasStartedCoAgreement).length === group.users.length
    const COASigned = group?.users?.filter(u => u.hasSignedCoAgreement).length >= 1
    const COAExecuted = group?.allMemberSignedCoAgreement
    const MOASigned = group?.users?.filter(u => u.hasSignedMemoOfAgreement).length >= 1
    const MOAExecuted = group?.allMemberSignedMemoOfAgreement

    return {
      group_id: group.id,
      created_at: group.createdAt,
      address: {
        street_address: group.propertyAddress.street_address,
        city: group.propertyAddress.locality,
        state: group.propertyAddress.region,
        county: group.propertyAddress.county,
        postal_code: group.propertyAddress.postal_code
      },
      group_size: group.invitations.length + group.users.length,
      closing_date: group.closingPurchaseDate,
      group_status: "Active",
      id_verification_status: overrides?.id_verification_status ? overrides?.id_verification_status : groupVerificationStatus(group.users),
      subscription_status: group.isSubscribed ? "Subscribed" : "Inactive",
      governance_status: overrides?.governance_status ? overrides?.governance_status : (MOAExecuted
        ? 'MOA Executed'
        : MOASigned
          ? 'MOA Signed'
          : COAExecuted
            ? 'COA Executed'
            : COASigned
              ? 'COA Signed'
              : completed
                ? 'Completed'
                : started
                  ? 'Started'
                  : ' '),
      relationships: group.relations?.join(", ")

    };
  };

  return (
    <AnalyticsContext.Provider
      value={{
        segmentIdentifyUser,
        segmentIdentify,
        segmentReset,
        segmentPage,
        segmentTrack,
        segmentGroup,
        marshalSegmentGroup
      }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsContext };
export default AnalyticsProvider;
