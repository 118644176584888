import { NotificationType } from "src/components/shared/types";
import {
  ModalNotifications,
  NotificationStatus,
  Pagination,
} from "src/services/Api/types";

// SET NOTIFICATIONS DRAWER
export const SET_NOTIFICATIONS_DRAWER = "SET_NOTIFICATIONS_DRAWER";
export interface SetNotificationsDrawerAction {
  type: typeof SET_NOTIFICATIONS_DRAWER;
  payload: { isOpened: boolean; isHidden?: boolean };
}
export const setNotificationsDrawer = (
  isOpened: boolean,
  isHidden?: boolean
): SetNotificationsDrawerAction => ({
  type: SET_NOTIFICATIONS_DRAWER,
  payload: isHidden === undefined ? { isOpened } : { isOpened, isHidden },
});

// GET NOTIFICATIONS STATUS
// Pending
export const GET_NOTIFICATION_STATUS = "GET_NOTIFICATION_STATUS";
export interface GetNotificationStatusAction {
  type: typeof GET_NOTIFICATION_STATUS;
}
export const getNotificationStatus = (): GetNotificationStatusAction => ({
  type: GET_NOTIFICATION_STATUS,
});
// Success
export const GET_NOTIFICATION_STATUS_SUCCESS =
  "GET_NOTIFICATION_STATUS_SUCCESS";
export interface GetNotificationStatusSuccessAction {
  type: typeof GET_NOTIFICATION_STATUS_SUCCESS;
  payload: NotificationStatus;
}
export const getNotificationStatusSuccess = (
  notificationStatus: NotificationStatus
): GetNotificationStatusSuccessAction => ({
  type: GET_NOTIFICATION_STATUS_SUCCESS,
  payload: notificationStatus,
});
// Rejected
export const GET_NOTIFICATION_STATUS_REJECTED =
  "GET_NOTIFICATION_STATUS_REJECTED";
export interface GetNotificationStatusRejectedAction {
  type: typeof GET_NOTIFICATION_STATUS_REJECTED;
}
export const getNotificationStatusRejected =
  (): GetNotificationStatusRejectedAction => ({
    type: GET_NOTIFICATION_STATUS_REJECTED,
  });

// SET MODAL NOTIFICATIONS
export const SET_MODAL_NOTIFICATIONS = "SET_MODAL_NOTIFICATIONS";
export interface SetModalNotificationsAction {
  type: typeof SET_MODAL_NOTIFICATIONS;
  payload: ModalNotifications;
}
export const setModalNotifications = (
  modalNotifications: ModalNotifications
): SetModalNotificationsAction => ({
  type: SET_MODAL_NOTIFICATIONS,
  payload: modalNotifications,
});

// GET NOTIFICATIONS
// Pending
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export interface GetNotificationsAction {
  type: typeof GET_NOTIFICATIONS;
  payload?: boolean;
}
export const getNotifications = (
  isSwipeRefresh?: boolean
): GetNotificationsAction => ({
  type: GET_NOTIFICATIONS,
  payload: isSwipeRefresh,
});
// Success
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export interface GetNotificationsSuccessAction {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  payload: Pagination<NotificationType>;
}
export const getNotificationsSuccess = (
  notifications: Pagination<NotificationType>
): GetNotificationsSuccessAction => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});
// Rejected
export const GET_NOTIFICATIONS_REJECTED = "GET_NOTIFICATIONS_REJECTED";
export interface GetNotificationsRejectedAction {
  type: typeof GET_NOTIFICATIONS_REJECTED;
}
export const getNotificationsRejected = (): GetNotificationsRejectedAction => ({
  type: GET_NOTIFICATIONS_REJECTED,
});

// GET NEXT PAGE NOTIFICATIONS
// Pending
export const GET_NEXT_PAGE_NOTIFICATIONS = "GET_NEXT_PAGE_NOTIFICATIONS";
export interface GetNextPageNotificationsAction {
  type: typeof GET_NEXT_PAGE_NOTIFICATIONS;
}
export const getNextPageNotifications = (): GetNextPageNotificationsAction => ({
  type: GET_NEXT_PAGE_NOTIFICATIONS,
});
// Success
export const GET_NEXT_PAGE_NOTIFICATIONS_SUCCESS =
  "GET_NEXT_PAGE_NOTIFICATIONS_SUCCESS";
export interface GetNextPageNotificationsSuccessAction {
  type: typeof GET_NEXT_PAGE_NOTIFICATIONS_SUCCESS;
  payload: Pagination<NotificationType>;
}
export const getNextPageNotificationsSuccess = (
  chats: Pagination<NotificationType>
): GetNextPageNotificationsSuccessAction => ({
  type: GET_NEXT_PAGE_NOTIFICATIONS_SUCCESS,
  payload: chats,
});
// Rejected
export const GET_NEXT_PAGE_NOTIFICATIONS_REJECTED =
  "GET_NEXT_PAGE_NOTIFICATIONS_REJECTED";
export interface GetNextPageNotificationsRejectedAction {
  type: typeof GET_NEXT_PAGE_NOTIFICATIONS_REJECTED;
}
export const getNextPageNotificationsRejected =
  (): GetNextPageNotificationsRejectedAction => ({
    type: GET_NEXT_PAGE_NOTIFICATIONS_REJECTED,
  });

// SET SHOW SWIPE LOADER
export const SET_SHOW_SWIPE_LOADER = "SET_SHOW_SWIPE_LOADER";
export interface SetShowSwipeLoaderAction {
  type: typeof SET_SHOW_SWIPE_LOADER;
  payload: boolean;
}
export const setShowSwipeLoader = (
  value: boolean
): SetShowSwipeLoaderAction => ({
  type: SET_SHOW_SWIPE_LOADER,
  payload: value,
});
