import MultiStepForm from "src/components/MultiStepForm";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import AccessGovernance from "./steps/AccessGovernance";
import SubscriptionPlan from "./steps/SubscriptionPlan";

export type PaymentFormData = undefined;

function PaymentForm() {
  return (
    <MultiStepForm<PaymentFormData> totalSteps={3} formName="payment">
      {({ ...props }) => {
        switch (props.formStep) {
          case 1:
            return (
              <InitialStep
                title="Fantastic! All co-owners have"
                boldTitle="passed ID verification."
                primaryButtonText="Next"
                onPrimaryButtonClick={props.nextStep}
                iconClass="icon-check-circled"
              />
            );
          case 2:
            return <SubscriptionPlan {...props} />;
          case 3:
            return <AccessGovernance />;
          default:
            break;
        }
      }}
    </MultiStepForm>
  );
}

export default PaymentForm;
