import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import FailedStep from "src/components/MultiStepForm/FailedStep";
import Step from "src/components/MultiStepForm/Step";
import {
  decodeBase64,
  encodeHash,
  getFormatAddress,
} from "src/utils/helpers/helpers";
import { SignUpFormData } from "src/pages/Onboarding/signUp/Leader";
import Storage from "src/services/LocalStorage";
import { CoOwnedHomeAddressType } from "src/services/Api/types";
import {
  SegmentEvents,
  SegmentResponseStatus,
  Status,
} from "src/components/shared/enums";
import AddressInput, {
  initialAddressData,
} from "src/components/inputs/AddressInput";
import { Path } from "src/components/routing/routes";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";
import { captureMessage } from "@sentry/react";

function CheckCoOwnedHomeAddressData({
  formData,
  nextStep,
  prevStep,
  saveData,
  progress,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const [stepData, setStepData] = useState(formData.coOwnedHomeAddress);
  const [status, setStatus] = useState<Status>(Status.Ok);
  const [timesTried, setTimesTried] = useState(0);
  let navigate = useNavigate();

  const onNextClick = () => {
    if (!!stepData) {
      const isCorrectAddress = validateCorrectAddress(stepData);
      const { zipCode: zip_code, ...segmentData } = stepData

      segmentTrack(SegmentEvents.MemberPreSignUpStepSubmitted, {
        step: "Address of the co-owned home?",
        data: { zip_code, ...segmentData },
        status: isCorrectAddress
          ? SegmentResponseStatus.Success
          : SegmentResponseStatus.Error,
      });

      if (isCorrectAddress) {
        saveData({ coOwnedHomeAddress: stepData });
        nextStep();
      } else {
        if (timesTried < 2) {
          setTimesTried(timesTried + 1);
          setStatus(Status.Failed);
        } else {
          setStatus(Status.HardFailed);
        }
      }
    }
  };

  const validateCorrectAddress = (memberAddress: CoOwnedHomeAddressType) => {
    try {
      const address = getFormatAddress(memberAddress);
      const getAddressHash = encodeHash(address);
      const getUrlAddressHash = decodeBase64(
        Storage.getItem("groupAddressData") || ""
      );
      return getAddressHash === getUrlAddressHash.addressHash;
    } catch (error) {
      captureMessage(JSON.stringify(error))
      return false;
    }
  };

  const renderSwitch = () => {
    switch (status) {
      case Status.Ok:
        return (
          <Step
            title="Address of the co-owned home?"
            onNextClick={onNextClick}
            nextText="Next"
            isButtonDisabled={!stepData?.address}
            onBackClick={() => prevStep()}
            progress={progress}
            className="bottom-not-fixed"
          >
            <AddressInput
              address={stepData}
              onChange={(address) => setStepData(address)}
            />
          </Step>
        );
      case Status.Failed:
        return (
          <FailedStep
            title="Bummer"
            description="The address you entered does not match the address we have for this co-ownership group. Please try entering the address again."
            nextText="Try again"
            onNextClick={() => {
              setStepData(initialAddressData);
              setStatus(Status.Ok);
            }}
          />
        );
      case Status.HardFailed:
        return (
          <FailedStep
            title="Bummer"
            description="The address you entered does not match the address we have for this co-ownership group. You've reached the maximum number of attempts. Please request a new invite from your co-owner or contact us."
            nextText="Ok"
            onNextClick={() => navigate(Path.Home)}
            footer={
              <p className="email-us-message">
                Email us: <a href="mailto:hello@cobuy.io">hello@cobuy.io</a>
              </p>
            }
          />
        );
      default:
        return;
    }
  };
  return renderSwitch() || <></>;
}

export default CheckCoOwnedHomeAddressData;
