import MultiStepForm from "src/components/MultiStepForm";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import { GroupInvitationMember } from "src/services/Api/types";
import InviteCoOwners from "./steps/InviteCoOwners";

export type GroupCreationFormData = {
  coOwners: Array<GroupInvitationMember>;
};

function GroupCreationForm() {
  return (
    <MultiStepForm<GroupCreationFormData>
      totalSteps={2}
      formName="groupCreation"
    >
      {({ ...props }) => {
        switch (props.formStep) {
          case 1:
            return (
              <InitialStep
                title="Congratulations! You’ve successfully "
                boldTitle="created an account."
                description="It's time to invite your co-owner(s) and create a co-ownership group on CoBuy."
                primaryButtonText="Start group"
                onPrimaryButtonClick={props.nextStep}
                iconClass="icon-check-circled"
              />
            );
          case 2:
            return <InviteCoOwners {...props} />;
          default:
            break;
        }
      }}
    </MultiStepForm>
  );
}

export default GroupCreationForm;
