import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Platform } from "src/services/Api/enums";
import { updateSession } from "src/store/actions/auth";
import { getPendingConsensusActions } from "src/store/actions/signature";
import MyProfile from "./MyProfile";
import SubmitCode from "./SubmitCode";

function Profile() {
  const dispatch = useDispatch();
  const [platform, setPlatform] = useState<Platform | null>(null);

  useEffect(() => {
    dispatch(updateSession(false, true, true));
    dispatch(getPendingConsensusActions());
  }, []);

  if (platform)
    return (
      <SubmitCode
        onBackButtonClick={() => setPlatform(null)}
        platform={platform}
      />
    );
  return (
    <MyProfile onEnterCodeButtonClick={(platform) => setPlatform(platform)} />
  );
}

export default Profile;
