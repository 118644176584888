import { useDispatch, useSelector } from "react-redux";
import { setMenuDrawer } from "src/store/actions/global";
import { RootState } from "src/store/reducers";
import { Page } from "src/utils/helpers/permissions";
import IconButton from "./IconButton";

function HamburgerButton() {
  const dispatch = useDispatch();
  const isDrawerOpened = useSelector(
    ({ global }: RootState) => global.menuDrawer.isOpened
  );
  // @ts-ignore
  const page = useSelector(({ auth }: RootState) => auth.page);
  const { value: chatGroups } = useSelector(
    ({ chat }: RootState) => chat.chatGroups
  );
  const openCloseMenuDrawer = () => dispatch(setMenuDrawer(!isDrawerOpened));

  const onClick = () => {
    openCloseMenuDrawer();
  };

  return (
    <IconButton
      iconClassName="icon-menu"
      className="hamburger-button"
      isDisabled={false}
      onClick={onClick}
      hasNotifications={
        page !== Page.CHAT &&
        !!chatGroups.find((chatGroup) => chatGroup.newMessages > 0)
      }
    />
  );
}

export default HamburgerButton;
