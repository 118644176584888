import { GroupInvitationStatus } from "src/services/Api/enums";
import {
  CoOwner,
  GroupDto,
  GroupInvitationDto,
  UserAccountDto,
} from "src/services/Api/types";

export function getCoOwnersAndInvitedUsers(
  group: GroupDto,
  user: UserAccountDto
) {
  const usersAlreadySignUp =
    group?.users.map(
      (x: UserAccountDto): CoOwner => ({
        id: x.id,
        email: x.email,
        firstName: x.firstName,
        lastName: x.lastName,
        isSelf: x.id === user.id,
        isInvitationCompleted: true,
        isSignupCompleted: x.isSignupCompleted,
        isIdentityVerified: x.isIdentityVerified,
        hasStartedCoAgreement: !!x.hasStartedCoAgreement,
        needToReviewCoAgreement: !!x.needToReviewCoAgreement,
        hasCompletedCoAgreement: !!x.hasCompletedCoAgreement,
        hasSignedCoAgreement: !!x.hasSignedCoAgreement,
        hasSignedMemoOfAgreement: !!x.hasSignedMemoOfAgreement,
      })
    ) || [];
  const usersNoSignUp =
    group?.invitations.map(
      (x: GroupInvitationDto): CoOwner => ({
        id: x.id,
        email: x.invitedUserEmail,
        firstName: x.invitedUserFirstName,
        lastName: "",
        isSelf: x.id === user.id,
        isInvitationCompleted:
          x.status === GroupInvitationStatus.Completed && x.opened,
        isSignupCompleted: false,
        isIdentityVerified: false,
        hasStartedCoAgreement: false,
        hasCompletedCoAgreement: false,
        needToReviewCoAgreement: false,
        hasSignedCoAgreement: false,
        hasSignedMemoOfAgreement: false,
      })
    ) || [];
  const list = [...usersAlreadySignUp, ...usersNoSignUp];
  const userLoggedIn = list.filter((x) => x.email === user.email) || [];
  const otherUsers = list.filter((x) => x.email !== user.email) || [];
  return [...userLoggedIn, ...otherUsers];
}
