import { CoOwnershipAgreementItemStatus } from "src/components/shared/enums";
import { StatusFilter } from "src/services/Api/enums";

export const filters = [
  StatusFilter.AllItems,
  StatusFilter.NeedsAttention,
  StatusFilter.WaitingOnOther,
  StatusFilter.Agreed,
];

export const filterStatus = {
  [StatusFilter.AllItems]: [
    CoOwnershipAgreementItemStatus.Agreed,
    CoOwnershipAgreementItemStatus.Discuss,
    CoOwnershipAgreementItemStatus.Pending,
    CoOwnershipAgreementItemStatus.Done,
    CoOwnershipAgreementItemStatus.InProgress,
    CoOwnershipAgreementItemStatus.NotStarted,
  ],
  [StatusFilter.Agreed]: [CoOwnershipAgreementItemStatus.Agreed],
  [StatusFilter.NeedsAttention]: [CoOwnershipAgreementItemStatus.Discuss],
  [StatusFilter.WaitingOnOther]: [CoOwnershipAgreementItemStatus.Pending],
};
