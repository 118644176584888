import Badge from "../Badge";

type MemberItemProps = {
  name: string;
  self?: boolean;
  email: string;
  badgeText?: string;
  badgeClass?: string;
  onLeftButtonClick?: () => void;
  leftButtonText?: string;
  onRightButtonClick?: () => void;
  rightButtonText?: string;
};

function MemberItem({
  name,
  self = false,
  email,
  badgeText,
  badgeClass,
  onLeftButtonClick,
  leftButtonText,
  onRightButtonClick,
  rightButtonText,
}: MemberItemProps) {
  return (
    <div className="member-item">
      <div className="header-container">
        <p className="name">
          {name} {self && <span> (you)</span>}
        </p>
        {badgeText && <Badge className={badgeClass} text={badgeText} />}
      </div>
      <p className="email">{email}</p>
      {(leftButtonText || rightButtonText) && (
        <div className="actions">
          <button className="simple-button" onClick={onLeftButtonClick}>
            {leftButtonText}
          </button>
          <button className="simple-button" onClick={onRightButtonClick}>
            {rightButtonText}
          </button>
        </div>
      )}
    </div>
  );
}

export default MemberItem;
