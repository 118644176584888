import { Session } from "src/services/Api/types";
import {
  Page,
  Permissions,
  PagePermission,
} from "src/utils/helpers/permissions";

// RESET CSRF TOKEN
// Pending
export const RESET_CSRF_TOKEN = "RESET_CSRF_TOKEN";
export interface ResetCSRFTokenAction {
  type: typeof RESET_CSRF_TOKEN;
}
export const resetCSRFToken = (): ResetCSRFTokenAction => ({
  type: RESET_CSRF_TOKEN,
});
// Success
export const RESET_CSRF_TOKEN_SUCCESS = "RESET_CSRF_TOKEN_SUCCESS";
export interface ResetCSRFTokenSuccessAction {
  type: typeof RESET_CSRF_TOKEN_SUCCESS;
}
export const resetCSRFTokenSuccess = (): ResetCSRFTokenSuccessAction => ({
  type: RESET_CSRF_TOKEN_SUCCESS,
});
// Rejected
export const RESET_CSRF_TOKEN_REJECTED = "RESET_CSRF_TOKEN_REJECTED";
export interface ResetCSRFTokenRejectedAction {
  type: typeof RESET_CSRF_TOKEN_REJECTED;
}
export const resetCSRFTokenRejected = (): ResetCSRFTokenRejectedAction => ({
  type: RESET_CSRF_TOKEN_REJECTED,
});

// UPDATE SESSION
// Pending
export const UPDATE_SESSION = "UPDATE_SESSION";
export interface UpdateSessionAction {
  type: typeof UPDATE_SESSION;
  payload: {
    isLoadingUserData: boolean;
    showFullPageLoader: boolean;
    updatePermissions: boolean;
  };
}
export const updateSession = (
  isLoadingUserData: boolean,
  showFullPageLoader: boolean,
  updatePermissions: boolean
): UpdateSessionAction => ({
  type: UPDATE_SESSION,
  payload: { isLoadingUserData, showFullPageLoader, updatePermissions },
});
// Success
export const UPDATE_SESSION_SUCCESS = "UPDATE_SESSION_SUCCESS";
export interface UpdateSessionSuccessAction {
  type: typeof UPDATE_SESSION_SUCCESS;
  payload: Session;
}
export const updateSessionSuccess = (
  payload: Session
): UpdateSessionSuccessAction => ({
  type: UPDATE_SESSION_SUCCESS,
  payload,
});
// Rejected
export const UPDATE_SESSION_REJECTED = "UPDATE_SESSION_REJECTED";
export interface UpdateSessionRejectedAction {
  type: typeof UPDATE_SESSION_REJECTED;
}
export const updateSessionRejected = (): UpdateSessionRejectedAction => ({
  type: UPDATE_SESSION_REJECTED,
});

// LOGOUT
// Pending
export const LOGOUT = "LOGOUT";
export interface LogoutAction {
  type: typeof LOGOUT;
}
export const logout = (): LogoutAction => ({
  type: LOGOUT,
});
// Success
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}
export const logoutSuccess = (): LogoutSuccessAction => ({
  type: LOGOUT_SUCCESS,
});
// Rejected
export const LOGOUT_REJECTED = "LOGOUT_REJECTED";
export interface LogoutRejectedAction {
  type: typeof LOGOUT_REJECTED;
}
export const logoutRejected = (): LogoutRejectedAction => ({
  type: LOGOUT_REJECTED,
});

// AUTHENTICATION ERROR
export const AUTH_ERROR = "AUTH_ERROR";
export interface AuthErrorAction {
  type: typeof AUTH_ERROR;
}
export const authError = (): AuthErrorAction => ({
  type: AUTH_ERROR,
});

// HANDLE ERRORS
export const HANDLE_ERRORS = "HANDLE_ERRORS";
export interface HandleErrorsAction {
  type: typeof HANDLE_ERRORS;
  payload?: number;
}
export const handleErrors = (httpErrorNumber?: number): HandleErrorsAction => ({
  type: HANDLE_ERRORS,
  payload: httpErrorNumber,
});

// UPDATE PERMISSIONS
type UpdatePermissionsType = {
  permission?: PagePermission;
  page?: Page;
  permissions?: Permissions;
};
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";
export interface UpdatePermissionsAction {
  type: typeof UPDATE_PERMISSIONS;
  payload: UpdatePermissionsType;
}
export const updatePermissions = (
  payload: UpdatePermissionsType
): UpdatePermissionsAction => ({
  type: UPDATE_PERMISSIONS,
  payload,
});

// ACCESS DASHBOARD
// Pending
export const ACCESS_DASHBOARD = "ACCESS_DASHBOARD";
export interface AccessDashboardAction {
  type: typeof ACCESS_DASHBOARD;
}
export const accessDashboard = (): AccessDashboardAction => ({
  type: ACCESS_DASHBOARD,
});
// Success
export const ACCESS_DASHBOARD_SUCCESS = "ACCESS_DASHBOARD_SUCCESS";
export interface AccessDashboardSuccessAction {
  type: typeof ACCESS_DASHBOARD_SUCCESS;
}
export const accessDashboardSuccess = (): AccessDashboardSuccessAction => ({
  type: ACCESS_DASHBOARD_SUCCESS,
});
// Rejected
export const ACCESS_DASHBOARD_REJECTED = "ACCESS_DASHBOARD_REJECTED";
export interface AccessDashboardRejectedAction {
  type: typeof ACCESS_DASHBOARD_REJECTED;
}
export const accessDashboardRejected = (): AccessDashboardRejectedAction => ({
  type: ACCESS_DASHBOARD_REJECTED,
});

// ACCESS GOVERNANCE
// Pending
export const ACCESS_GOVERNANCE = "ACCESS_GOVERNANCE";
export interface AccessGovernanceAction {
  type: typeof ACCESS_GOVERNANCE;
}
export const accessGovernance = (): AccessGovernanceAction => ({
  type: ACCESS_GOVERNANCE,
});
// Success
export const ACCESS_GOVERNANCE_SUCCESS = "ACCESS_GOVERNANCE_SUCCESS";
export interface AccessGovernanceSuccessAction {
  type: typeof ACCESS_GOVERNANCE_SUCCESS;
}
export const accessGovernanceSuccess = (): AccessGovernanceSuccessAction => ({
  type: ACCESS_GOVERNANCE_SUCCESS,
});
// Rejected
export const ACCESS_GOVERNANCE_REJECTED = "ACCESS_GOVERNANCE_REJECTED";
export interface AccessGovernanceRejectedAction {
  type: typeof ACCESS_GOVERNANCE_REJECTED;
}
export const accessGovernanceRejected = (): AccessGovernanceRejectedAction => ({
  type: ACCESS_GOVERNANCE_REJECTED,
});
