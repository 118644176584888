import { NameSuffixes } from "src/services/Api/enums";
import {
  AgreementDocumentCode,
  CoOwnerProgressStatus,
  CoOwnershipAgreementItemStatus,
  DocumentStatus,
  DocumentType,
  ExtensionType,
  MenuOptions,
  MimeType,
} from "./enums";
import { AgreementStatusTitleStates } from "./types";

export const EMAIL_REGEX =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TEXT_REGEX = "^\\S(.*)?$";
export const INTEGER_REGEX = `^\\d*$`;
export const numberRegExp = (decimalNumbers?: number) =>
  decimalNumbers ? `^-?\\d*(\\.\\d{0,${decimalNumbers}})?$` : `^-?\\d*$`;

export const MAX_NUMBER_LENGTH = 13;
export const MESSAGE_TO_TAKE = 20;
export const NOTIFICATIONS_TO_TAKE = 20;
export const COMMENTS_TO_TAKE = 20;
export const DOCUMENTS_TO_TAKE = 10;
export const MAX_DOCUMENT_FILE_SIZE = 8000000;
export const SHORT_TEXT_MAX_LENGTH = 64;
export const NAME_MIN_LENGTH = 1;
export const NAME_MAX_LENGTH = 64;
export const EMAIL_MAX_LENGTH = 128;

export const coOwnerProgressStatus = [
  CoOwnerProgressStatus.Joined,
  CoOwnerProgressStatus.VerifiedId,
  CoOwnerProgressStatus.StartedCoOwnershipAgreement,
  CoOwnerProgressStatus.CompletedCoOwnershipAgreement,
  CoOwnerProgressStatus.SignedCoOwnershipAgreement,
  CoOwnerProgressStatus.SignedMemorandumOfAgreement,
];

export const agreementStatusTitles: AgreementStatusTitleStates = {
  [AgreementDocumentCode.CoOwnershipAgreement]: {
    title: "Co-ownership Agreement",
    boldTitle: "status",
    description:
      "We’re waiting for all co-owners to sign the Co-ownership Agreement. ",
  },
  [AgreementDocumentCode.MemorandumOfAgreement]: {
    title: "Memo. of Agreement",
    boldTitle: "status",
    description:
      "We’re waiting for all co-owners to sign the Memorandum of Agreement.",
  },
};

export const coOwnershipStatus = {
  [CoOwnershipAgreementItemStatus.Agreed]: "success",
  [CoOwnershipAgreementItemStatus.Done]: "success",
  [CoOwnershipAgreementItemStatus.Discuss]: "warning",
  [CoOwnershipAgreementItemStatus.InProgress]: "in-progress",
  [CoOwnershipAgreementItemStatus.NotStarted]: "disabled",
  [CoOwnershipAgreementItemStatus.Pending]: "disabled",
};

export const documentStatusBadgeClass = {
  [DocumentStatus.Agreed]: "success",
  [DocumentStatus.Verified]: "success",
  [DocumentStatus.RequiresAction]: "warning",
  [DocumentStatus.Executed]: "success",
  [DocumentStatus.Pending]: "warning",
};

export const menuOptionIcons = {
  [MenuOptions.CoOwnershipAgreement]: "icon-agreement",
  [MenuOptions.Chat]: "icon-chat",
  [MenuOptions.CoOwners]: "icon-users",
  [MenuOptions.Dashboard]: "icon-grid",
  [MenuOptions.DocumentVault]: "icon-document-bold",
  [MenuOptions.LogOut]: "icon-log-out",
  [MenuOptions.MyProfile]: "icon-user",
  [MenuOptions.Status]: "icon-signal",
};

export const suffixOptions = [
  {
    id: NameSuffixes.Jr,
    value: "Jr",
  },
  {
    id: NameSuffixes.Sr,
    value: "Sr",
  },
  {
    id: NameSuffixes.II,
    value: "II",
  },
  {
    id: NameSuffixes.III,
    value: "III",
  },
  {
    id: NameSuffixes.IV,
    value: "IV",
  },
  {
    id: NameSuffixes.V,
    value: "V",
  },
];

export const DocumentTypeName = {
  [DocumentType.CoOwnershipAgreement]: "Co-ownership agreement",
  [DocumentType.MemorandumOfAgreement]: "Memorandum of agreement",
  [DocumentType.PurchaseSaleAgreement]: "Purchase & Sale agreement",
  [DocumentType.MortgageDocument]: "Mortgage document",
  [DocumentType.ClosingStatements]: "Closing settlement statements",
  [DocumentType.PromissoryNote]: "Promissory note",
  [DocumentType.InsurancePolicy]: "Insurance policy",
  [DocumentType.HomeWarranty]: "Home warranty",
  [DocumentType.SimpleWill]: "Simple will",
  [DocumentType.HOADocument]: "HOA/condo document",
  [DocumentType.PropertyTaxDocument]: "Property tax document",
  [DocumentType.OtherTaxDocument]: "Other tax document",
  [DocumentType.PropertySystemsDocument]: "Property systems document",
  [DocumentType.Receipt]: "Receipt",
  [DocumentType.Other]: "Other",
};

export const documentTypeOptions = [
  {
    id: DocumentType.PurchaseSaleAgreement,
    value: DocumentTypeName[DocumentType.PurchaseSaleAgreement],
  },
  {
    id: DocumentType.MortgageDocument,
    value: DocumentTypeName[DocumentType.MortgageDocument],
  },
  {
    id: DocumentType.ClosingStatements,
    value: DocumentTypeName[DocumentType.ClosingStatements],
  },
  {
    id: DocumentType.PromissoryNote,
    value: DocumentTypeName[DocumentType.PromissoryNote],
  },
  {
    id: DocumentType.InsurancePolicy,
    value: DocumentTypeName[DocumentType.InsurancePolicy],
  },
  {
    id: DocumentType.HomeWarranty,
    value: DocumentTypeName[DocumentType.HomeWarranty],
  },
  {
    id: DocumentType.SimpleWill,
    value: DocumentTypeName[DocumentType.SimpleWill],
  },
  {
    id: DocumentType.HOADocument,
    value: DocumentTypeName[DocumentType.HOADocument],
  },
  {
    id: DocumentType.PropertyTaxDocument,
    value: DocumentTypeName[DocumentType.PropertyTaxDocument],
  },
  {
    id: DocumentType.OtherTaxDocument,
    value: DocumentTypeName[DocumentType.OtherTaxDocument],
  },
  {
    id: DocumentType.PropertySystemsDocument,
    value: DocumentTypeName[DocumentType.PropertySystemsDocument],
  },
  { id: DocumentType.Receipt, value: DocumentTypeName[DocumentType.Receipt] },
  { id: DocumentType.Other, value: DocumentTypeName[DocumentType.Other] },
];

export const acceptedFileTypes: Array<{ mimeType: string; extension: string }> =
  [
    { mimeType: MimeType.pdf, extension: ExtensionType.pdf },
    { mimeType: MimeType.jpg, extension: ExtensionType.jpg },
    { mimeType: MimeType.jpeg, extension: ExtensionType.jpeg },
    { mimeType: MimeType.png, extension: ExtensionType.png },
    { mimeType: MimeType.webp, extension: ExtensionType.webp },
    { mimeType: MimeType.doc, extension: ExtensionType.doc },
    { mimeType: MimeType.docx, extension: ExtensionType.docx },
    { mimeType: MimeType.txt, extension: ExtensionType.txt },
    { mimeType: MimeType.odt, extension: ExtensionType.odt },
    { mimeType: MimeType.ods, extension: ExtensionType.ods },
    { mimeType: MimeType.odp, extension: ExtensionType.odp },
    { mimeType: MimeType.xls, extension: ExtensionType.xls },
    { mimeType: MimeType.xlsx, extension: ExtensionType.xlsx },
    { mimeType: MimeType.csv, extension: ExtensionType.csv },
    { mimeType: MimeType.ppt, extension: ExtensionType.ppt },
    { mimeType: MimeType.pptx, extension: ExtensionType.pptx },
  ];

export const imagesMimeType = [
  MimeType.jpeg,
  MimeType.jpg,
  MimeType.png,
  MimeType.webp,
];

export const docViewerMimeType = [
  MimeType.doc,
  MimeType.docx,
  MimeType.ppt,
  MimeType.pptx,
  MimeType.txt,
  MimeType.xls,
  MimeType.xlsx,
];
