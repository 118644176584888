import { useDispatch, useSelector } from "react-redux";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import { AgreementDocumentCode } from "src/components/shared/enums";
import { getAgreementStatus } from "src/store/actions/signature";
import { RootState } from "src/store/reducers";

type FinalizeAgreementProps = { code: AgreementDocumentCode };

function FinalizeAgreement({ code }: FinalizeAgreementProps) {
  const dispatch = useDispatch();
  const { agreements } = useSelector(({ signature }: RootState) => signature);

  const agreement = agreements[code];
  const isCoOwnershipAgreement =
    code === AgreementDocumentCode.CoOwnershipAgreement;

  const onFinalizeButtonClick = () => {
    !agreement.agreementStatus.isGettingAgreementStatus &&
      dispatch(getAgreementStatus(code, agreement.hash.value!));
  };

  return (
    <div className="finalize-agreement-wrapper">
      <InitialStep
        iconClass="icon-document"
        title={isCoOwnershipAgreement ? "Finalize" : "Sign"}
        boldTitle={
          isCoOwnershipAgreement
            ? "Co-ownership Agreement"
            : "Memorandum of Agreement"
        }
        description={
          isCoOwnershipAgreement
            ? "You've reached consensus! it's time to generate, review, and sign your Co-ownership Agreement."
            : "A Memorandum of Agreement is a document that can be filed with your local government recording office to reference your Co-ownership Agreement on the public register. It's a way to make the existence of your agreement official without sharing all the details publicly."
        }
        text="By clicking below, I confirm I have read and agree to the Terms of use. I also acknowledge that the generated agreement may be updated, as needed, by the co-owners. A copy of a Memorandum of Agreement needs to be filed with the County where the property is in order to provide constructive notice of any agreements made between or amongst the parties."
        primaryButtonText="Next"
        onPrimaryButtonClick={() => onFinalizeButtonClick()}
        isPrimaryButtonLoading={
          agreement.agreementStatus.isGettingAgreementStatus
        }
        mainHeaderProps={{
          showLogo: true,
          showMenuDrawerOnDesktop: true,
          showHamburgerButton: true,
          showNotificationsButton: true,
        }}
      />
    </div>
  );
}

export default FinalizeAgreement;
