import { useDispatch, useSelector } from "react-redux";
import { setPopoverGuide } from "src/store/actions/global";
import { RootState } from "src/store/reducers";
import Button from "../buttons/Button";
import { PopoverGuideType } from "../shared/enums";

type PopoverGuideProps = {
  type: PopoverGuideType;
  content: string;
  nextPopoverType: PopoverGuideType | null;
  children: React.ReactNode;
};

function PopoverGuide({
  content,
  children,
  type,
  nextPopoverType,
}: PopoverGuideProps) {
  const activeType = useSelector(
    ({ global }: RootState) => global.activePopoverGuide
  );
  const alreadySawGuide = useSelector(
    ({ governance }: RootState) => governance.alreadySawGuide
  );
  const dispatch = useDispatch();
  const popoverClass =
    activeType === type && !alreadySawGuide
      ? "popover-guide open"
      : "popover-guide";

  return (
    <div className={popoverClass}>
      {children}
      <div className="popover-guide-background" />
      <div className="popover-guide-content">
        <p>{content}</p>
        <Button
          text="Got it"
          onClick={() => dispatch(setPopoverGuide(nextPopoverType))}
        />
      </div>
    </div>
  );
}

export default PopoverGuide;
