import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMainHeader } from "src/store/actions/global";
import Fail from "../Fail";

type FailedStepProps = {
  title: string;
  description?: string;
  nextText: string;
  onNextClick: (step?: number) => void;
  onBackClick?: (step?: number) => void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
};

function FailedStep({
  title,
  description,
  nextText,
  onNextClick,
  onBackClick,
  children,
  footer,
}: FailedStepProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setMainHeader({
        onBackButtonClick: onBackClick,
        hideBottomLine: true,
      })
    );
  }, []);

  return (
    <Fail
      title={title}
      description={description}
      buttonText={nextText}
      onButtonClick={onNextClick}
      children={children}
      footer={footer}
    />
  );
}

export default FailedStep;
