import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import OneTimeCode from "src/components/OneTimeCode";
import Api from "src/services/Api";
import { setFullPageLoading, setMainHeader } from "src/store/actions/global";
import { getFormatAddress } from "src/utils/helpers/helpers";
import { SignUpFormData } from "..";
import Storage from "src/services/LocalStorage";
import { showModal } from "src/store/actions/modal";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import {
  ErrorMessages,
  SegmentEvents,
  SegmentResponseStatus,
  ToastType,
} from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";
import { captureMessage } from "@sentry/react";

function VerifyEmail({
  formData: {
    email,
    emailChallengeId,
    coOwnedHomeAddress,
    coOwnedHomePurchaseDate,
  },
  nextStep,
  prevStep,
  saveData,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const dispatch = useDispatch();
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [oneTimeCode, setOneTimeCode] = useState("");
  const [hasErrorCode, setHasErrorCode] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    dispatch(
      setMainHeader({
        hideBottomLine: true,
        onBackButtonClick: failed ? () => setFailed(false) : prevStep,
      })
    );
  }, [failed]);

  const resendCode = async () => {
    try {
      setResendCodeLoading(true);
      const invitationId = Storage.getItem("invitationId");
      const {
        data: { emailChallengeId },
      } = await Api.signUpVerifyEmail(
        invitationId
          ? { email, invitationId }
          : {
              email,
              closingPurchaseDate: coOwnedHomePurchaseDate,
              address: getFormatAddress(coOwnedHomeAddress),
            }
      );
      saveData({ emailChallengeId });
      dispatch(
        showModal(MODAL_TOAST, {
          message: "Sent! Please check your inbox.",
        })
      );
    } catch (error) {
      captureMessage(JSON.stringify(error))
      dispatch(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
    } finally {
      setResendCodeLoading(false);
    }
  };

  const verifyCode = async (value: string) => {
    try {
      dispatch(setFullPageLoading(true));
      setHasErrorCode(false);
      await Api.signUpSubmitEmailCode(emailChallengeId, {
        emailChallengeOtp: value,
      });
      segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
        step: "Verify email",
        data: value,
        status: SegmentResponseStatus.Success,
      });
      nextStep();
    } catch (error: any) {
      captureMessage(JSON.stringify(error))
      segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
        step: "Verify email",
        data: value,
        status: SegmentResponseStatus.Error,
      });
      if (error.status === 403) {
        setHasErrorCode(true);
      } else if (error.status === 400) {
        setHasErrorCode(true);
      } else {
        setFailed(true);
      }
    } finally {
      dispatch(setFullPageLoading(false));
    }
  };

  const onOneTimeCodeChange = (value: string) => {
    setOneTimeCode(value);
    value.length === 6 ? verifyCode(value) : setHasErrorCode(false);
  };

  return (
    <OneTimeCode
      otcFailed={failed}
      email={email}
      onTryAgainButtonClick={prevStep}
      oneTimeCode={oneTimeCode}
      onOneTimeCodeChange={onOneTimeCodeChange}
      hasErrorCode={hasErrorCode}
      countDownButton={{
        text: "Resend email",
        countDownText: "email",
        time: 30,
        onButtonClick: resendCode,
        isLoading: resendCodeLoading,
      }}
    />
  );
}

export default VerifyEmail;
