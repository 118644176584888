import { UserAccountDto } from "src/services/Api/types";
import { DashboardPermission, GovernancePermission, Page } from "./permissions";

export const getCOAStatePermission = (
  user: UserAccountDto,
  page: Page
): GovernancePermission | DashboardPermission => {
  const permission =
    page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;

  if (user?.hasSignedMemoOfAgreement)
    return permission.MEMORANDUM_OF_AGREEMENT_STATUS;
  if (user?.hasSignedCoAgreement)
    return permission.CO_OWNERSHIP_AGREEMENT_STATUS;
  if (user?.hasCompletedCoAgreement) return permission.SECTIONS_STATUS;
  if (user?.hasStartedCoAgreement) {
    return permission.SECTIONS_STATUS;
  }
  if (page === Page.GOVERNANCE) return GovernancePermission.INITIAL_SCREEN;
  else return DashboardPermission.DASHBOARD;
};
