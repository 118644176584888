import parse from "html-react-parser";

type RadioProps = {
  id?: string;
  name?: string;
  label?: string;
  value?: string;
  checked?: boolean;
  onChange?: (value: string) => void;
  isReadOnly?: boolean;
  isDisabled?: boolean;
};

function Radio({
  id,
  name,
  label,
  value,
  checked,
  onChange,
  isDisabled,
  isReadOnly,
}: RadioProps) {
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`label-radio ${disabledClass}`}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => onChange && onChange(e.target.value)}
        readOnly={isReadOnly}
        disabled={isDisabled}
      />
      <span></span>
      {label && id && <label htmlFor={id}>{parse(label)}</label>}
    </div>
  );
}

export default Radio;
