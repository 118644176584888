import { CoOwnedHomeAddressType } from "src/services/Api/types";
import SearchAddressInput from "../SearchAddressInput";
import Address from "./Address";

type AddressInputType = {
  isDisabled?: boolean;
  address: CoOwnedHomeAddressType;
  onChange: (address: CoOwnedHomeAddressType) => void;
};

export const initialAddressData = {
  address: "",
  vicinity: "",
  county: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
};

function AddressInput({
  isDisabled = false,
  address = initialAddressData,
  onChange,
}: AddressInputType) {
  return (
    <>
      <SearchAddressInput
        id="address"
        name="address"
        label="Address"
        onChange={onChange}
        address={address.address}
        isDisabled={isDisabled}
      />
      <p className="address-warning">Below fields will be autopopulated</p>
      <Address isDisabled={isDisabled} address={address} />
    </>
  );
}

export default AddressInput;
