type ProgressItemProps = {
  text: string;
  className: string;
  actionText?: string;
  onActionClick?: () => void;
};

function ProgressItem({
  text,
  className,
  actionText,
  onActionClick,
}: ProgressItemProps) {
  return (
    <div className={`progress-item ${className}`}>
      <i className="icon-check" />
      <p>
        {text} {actionText && "("}
        {actionText && (
          <button
            type={"button"}
            className="inline-button"
            onClick={onActionClick}
          >
            {actionText}
          </button>
        )}
        {actionText && ")"}
      </p>
    </div>
  );
}

export default ProgressItem;
