import { useDispatch, useSelector } from "react-redux";
import { DocumentCardType, UploadDocumentType } from "src/services/Api/types";
import { DocumentStatus, DocumentType } from "src/components/shared/enums";
import { RootState } from "src/store/reducers";
import { updatePermissions } from "src/store/actions/auth";
import {
  DashboardPermission,
  DocumentVaultPermission,
  Page,
} from "../helpers/permissions";
import {
  downloadDocument,
  setPreviousDocumentHistoryPermission,
  setSelectedDocumentId,
  uploadDocument,
} from "src/store/actions/vault";
import {
  MODAL_ADD_DOCUMENT,
  MODAL_CONFIRMATION,
  MODAL_TOAST,
} from "src/components/modals/modalTypes";
import { hideModal, showModal } from "src/store/actions/modal";
import { maskLongName } from "../helpers/masks";

type usePopoverMenuOptionsProps = {
  document: DocumentCardType;
};

function usePopoverMenuOptions({ document }: usePopoverMenuOptionsProps) {
  const dispatch = useDispatch();
  const { page, permission } = useSelector(({ auth }: RootState) => auth);
  const { id, name, type, status, s3FileUrl } = document;

  const getPopoverMenuOptions = () => {
    const popoverMenuOptions = [];
    if (
      type !== DocumentType.CoOwnershipAgreement &&
      type !== DocumentType.MemorandumOfAgreement &&
      (status === DocumentStatus.Agreed || status === DocumentStatus.Verified)
    )
      popoverMenuOptions.push({
        icon: "icon-upload-version",
        text: "Update new version",
        onClick: () => showAddDocumentModal("edit"),
      });
    if (
      type === DocumentType.CoOwnershipAgreement &&
      (status === DocumentStatus.Executed || status === undefined)
    ) {
      popoverMenuOptions.push({
        icon: "icon-edit",
        text: "Update info",
        onClick: () => {
          dispatch(
            updatePermissions({
              page: Page.DASHBOARD,
              permission: DashboardPermission.SECTIONS_STATUS,
            })
          );
        },
      });
    }
    popoverMenuOptions.push({
      icon: "icon-history",
      text: "View history",
      onClick: () => {
        dispatch(setSelectedDocumentId(id));
        dispatch(
          updatePermissions({
            page: Page.DOCUMENT_VAULT,
            permission: DocumentVaultPermission.DOCUMENT_HISTORY,
          })
        );
        dispatch(setPreviousDocumentHistoryPermission(page!, permission!));
      },
    });
    if (s3FileUrl) {
      popoverMenuOptions.push({
        icon: "icon-download",
        text: "Download",
        onClick: () => dispatch(downloadDocument(id, name)),
      });
      popoverMenuOptions.push({
        icon: "icon-share",
        text: "Share",
        onClick: () => showCopyLinkModal(document),
      });
    }
    return popoverMenuOptions;
  };

  const showAddDocumentModal = (action?: string) => {
    dispatch(
      showModal(MODAL_ADD_DOCUMENT, {
        onButtonClick: (newDocument: UploadDocumentType) =>
          dispatch(uploadDocument(newDocument)),
        onCloseButtonClick: () => dispatch(hideModal(MODAL_ADD_DOCUMENT)),
        document,
        action
      })
    );
  };

  const showCopyLinkModal = (document: DocumentCardType) => {
    dispatch(
      showModal(MODAL_CONFIRMATION, {
        title: `Share "${maskLongName(document.name)}"?`,
        description: (
          <p>
            Copy a link to share externally. Heads up: anyone with the link will
            have access to this file!
          </p>
        ),
        rightButtonText: "Cancel",
        leftButtonText: "Copy link",
        onRightButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
        onLeftButtonClick: () => {
          navigator.clipboard.writeText(s3FileUrl);
          dispatch(
            showModal(MODAL_TOAST, {
              message: "Copied to clipboard!",
              autoDeleteTime: 2000,
            })
          );
          dispatch(hideModal(MODAL_CONFIRMATION));
        },
        onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
      })
    );
  };

  return { getPopoverMenuOptions };
}

export default usePopoverMenuOptions;
