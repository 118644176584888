import { Option } from "src/components/shared/types";

type ButtonGroupProps = {
  options: Array<Option>;
  selectedId?: string | null;
  onChange?: (option: string, index?: number) => void;
  isDisabled?: boolean;
};

function ButtonGroup({
  options,
  selectedId,
  onChange,
  isDisabled,
}: ButtonGroupProps) {
  const onButtonClick = (id: string) => {
    !isDisabled && onChange && onChange(id);
  };
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`button-group-container ${disabledClass}`}>
      {options.map(({ id, value }) => (
        <button
          id={id}
          key={id}
          type="button"
          className={id === selectedId ? undefined : "empty-button-group"}
          onClick={() => onButtonClick(id)}
          disabled={isDisabled}
        >
          {value}
        </button>
      ))}
    </div>
  );
}

export default ButtonGroup;
