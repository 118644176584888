type MenuButtonProps = {
  id?: string;
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
  isActive?: boolean;
  iconClass: string;
  hasNotifications?: boolean;
};

function MenuButton({
  id,
  text,
  onClick,
  isDisabled,
  isActive,
  iconClass,
  hasNotifications,
}: MenuButtonProps) {
  const isActiveClass = isActive ? "active" : "";

  return (
    <li className={isActiveClass}>
      <button
        id={id}
        type="button"
        className={`menu-button ${isActiveClass}`}
        onClick={() => !isDisabled && onClick()}
        disabled={isDisabled}
      >
        <i className={iconClass}>
          {hasNotifications && (
            <div
              className={`notification-badge ${
                iconClass === "icon-bell" ? "small-icon" : ""
              }`}
            ></div>
          )}
        </i>
        <span>{text}</span>
      </button>
    </li>
  );
}

export default MenuButton;
