import ReactCodeInput from "react-code-input";
import CountDownButton from "../CountDownButton";
import Fail from "../Fail";
import { Path } from "src/components/routing/routes";
import Link from "../Link";

type OneTimeCodeProps = {
  otcFailed: boolean;
  onTryAgainButtonClick: () => void;
  phone?: string;
  email?: string;
  oneTimeCode: string;
  onOneTimeCodeChange: (value: string) => void;
  hasErrorCode: boolean;
  countDownButton: {
    text: string;
    countDownText: string;
    time: number;
    onButtonClick: () => Promise<void>;
    isLoading: boolean;
  };
};

function OneTimeCode({
  otcFailed,
  onTryAgainButtonClick,
  email,
  phone,
  oneTimeCode,
  onOneTimeCodeChange,
  hasErrorCode,
  countDownButton,
}: OneTimeCodeProps) {
  return otcFailed ? (
    <Fail
      title="This code expired"
      description="For security, we've retired this code. Please try again so we can send
  you a new one."
      buttonText="Try again"
      onButtonClick={() => onTryAgainButtonClick()}
    />
  ) : (
    <div className="content-wrapper check-email">
      <div className="vertical-centered">
        <i
          className={`${email ? "icon-email-circled" : "icon-phone-circled"}`}
        />
        <div className="centered-box">
          <h3>
            Check your <strong>{`${email ? "email" : "phone"}`}</strong>
          </h3>

          {email && (
            <p>
              We sent a code to <strong>{email}</strong>. <br></br>Didn't receive it? Check your spam, tap below to resend, or {" "}
              <b><Link
              to={Path.SignUp}
            >
              Sign up
            </Link></b>
            </p>
          )}
          {phone && (
            <p>
              We sent a code to <strong>{phone}</strong>. If you haven't
              received a text message, please click "resend text message" to
              request a new one.
            </p>
          )}
        </div>
        <ReactCodeInput
          type="tel"
          fields={6}
          name="code"
          inputMode="numeric"
          value={oneTimeCode}
          onChange={onOneTimeCodeChange}
          isValid={!hasErrorCode}
          inputStyleInvalid={{
            border: "1px solid #D84242",
            height: 48,
            borderRadius: 4,
            marginRight: 12,
            color: "#333333",
            fontWeight: 500,
            fontSize: 20,
            textAlign: "center",
          }}
          inputStyle={{
            height: 48,
            borderRadius: 4,
            marginRight: 12,
            color: "#333333",
            fontWeight: 500,
            fontSize: 20,
            textAlign: "center",
          }}
        />
        <p className={`incorrect-code ${hasErrorCode ? "show" : ""}`}>
          Incorrect code, please try again
        </p>
        <CountDownButton
          text={countDownButton.text}
          countDownText={countDownButton.countDownText}
          time={countDownButton.time}
          onButtonClick={countDownButton.onButtonClick}
          isLoading={countDownButton.isLoading}
        />
      </div>
    </div>
  );
}

export default OneTimeCode;
