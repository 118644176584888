class Storage {
  setItem(key: string, value: any) {
    if (!value) return null;
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
    return this;
  }

  getItem(key: string) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

// eslint-disable-next-line
export default new Storage();
