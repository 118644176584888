import { DashboardInfo } from "src/services/Api/types";
import {
  GetDashboardInfoAction,
  GetDashboardInfoRejectedAction,
  GetDashboardInfoSuccessAction,
  GET_DASHBOARD_INFO,
  GET_DASHBOARD_INFO_REJECTED,
  GET_DASHBOARD_INFO_SUCCESS,
} from "../actions/dashboard";

export const initialState: {
  dashboardInfo: DashboardInfo;
  isGettingDashboardInfo: boolean;
} = {
  dashboardInfo: {
    propertyValuation: {
      propertyName: "",
      propertyFinancial: {
        propertyValuation: 0,
        valueChange: 0,
      },
      titleFormat: "",
      ownershipBreakdown: [],
      tooltip: "",
    },
    agreementStatus: [],
  },
  isGettingDashboardInfo: false,
};

export function dashboardReducer(
  state = initialState,
  action:
    | GetDashboardInfoAction
    | GetDashboardInfoSuccessAction
    | GetDashboardInfoRejectedAction
) {
  switch (action.type) {
    case GET_DASHBOARD_INFO:
      return {
        ...state,
        isGettingDashboardInfo: !action.payload.isUpdate,
      };
    case GET_DASHBOARD_INFO_SUCCESS:
      return {
        ...state,
        dashboardInfo: action.payload,
        isGettingDashboardInfo: false,
      };
    case GET_DASHBOARD_INFO_REJECTED:
      return {
        ...state,
        isGettingDashboardInfo: false,
      };
    default:
      return state;
  }
}
