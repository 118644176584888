import { useDispatch, useSelector } from "react-redux";
import {
  refreshFirstPageComments,
  setCommentsDrawer,
} from "src/store/actions/global";
import { RootState } from "src/store/reducers";
import { CommentsType } from "../shared/enums";
import IconButton from "./IconButton";
import { VaultDocument } from "../../services/Api/types";

type VaultDocumentCommentsButtonProps = {
  hasNewComments?: boolean;
};

function VaultDocumentCommentsButton({
  hasNewComments,
}: VaultDocumentCommentsButtonProps) {
  const dispatch = useDispatch();
  const isCommentsDrawerOpened = useSelector(
    ({ global }: RootState) => global.commentsDrawer.isOpened
  );
  const {
    vaultDocument: { value: vaultDocument, isLoading: isVaultDocumentLoading },
  } = useSelector(({ vault }: RootState) => vault);

  const onClick = () => {
    if (!isVaultDocumentLoading) {
      if (isCommentsDrawerOpened) {
        dispatch(setCommentsDrawer({ isOpened: false }));
      } else {
        if ((vaultDocument as VaultDocument)?.document) {
          dispatch(
            setCommentsDrawer({
              isOpened: true,
              id: (vaultDocument as VaultDocument)?.document?.id,
              type: CommentsType.document,
            })
          );
          dispatch(
            refreshFirstPageComments(
              (vaultDocument as VaultDocument)?.document?.id as string,
              CommentsType.document,
              false
            )
          );
        }
      }
    }
  };

  return (
    <IconButton
      iconClassName="icon-chat"
      className="comments-button"
      hasNotifications={hasNewComments}
      isDisabled={isVaultDocumentLoading}
      onClick={onClick}
    />
  );
}

export default VaultDocumentCommentsButton;
