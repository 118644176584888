//@ts-nocheck
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import { ErrorMessages, Payment, SegmentEvents, ToastType } from "src/components/shared/enums";
import Api from "src/services/Api";
import { updateSession } from "src/store/actions/auth";
import { setFullPageLoading } from "src/store/actions/global";
import { showModal } from "src/store/actions/modal";
import { RootState } from "src/store/reducers";
import { initiatePaddle } from "src/utils/helpers/paddle";
import useInterval from "./useInterval";
import { AnalyticsContext, AnalyticsContextType } from "../../components/Analytics";
import { captureMessage } from "@sentry/react";

type useSubscriptionProps = {
  onSuccess: (step?: number) => void;
  onClose?: () => void;
  couponCode?: string; // for coupon code 
};

function useSubscription({ onSuccess, onClose, couponCode }: useSubscriptionProps) {
  const dispatch = useDispatch();
  const { segmentTrack, segmentGroup, marshalSegmentGroup } = useContext(AnalyticsContext) as AnalyticsContextType;
  const { session } = useSelector(({ auth }: RootState) => auth);
  const isSubscribed = useSelector(
    ({ auth }: RootState) => auth.session?.group?.isSubscribed
  );

  const [timeoutId, setTimeoutId] = useState<any>();
  const [paymentState, setPaymentState] = useState<Payment>(Payment.NotStarted);
  let reloadTimeoutId: NodeJS.Timeout;
  let subscription_timeout: NodeJS.Timeout;

  useEffect(() => {
    return () => {
      clearTimeout(reloadTimeoutId);
      dispatch(setFullPageLoading(false));
    };
  }, []);

  useInterval(() => {
    if (paymentState === Payment.Processing) {
      if (isSubscribed) {
        dispatch(setFullPageLoading(false));
        setPaymentState(Payment.Done);
        dispatch(
          showModal(MODAL_TOAST, {
            message: "Congratulations! Your subscription payment was successful"
          })
        );
        clearTimeout(subscription_timeout);
        onSuccess(3);
      } else dispatch(updateSession(false, false, false));
    }
  }, 2000);

  const unlockSubscription = async () => {
    try {
      await Api.unlockSubscriptionLink();
    } catch (error) {
      captureMessage(JSON.stringify(error))
    }
  };
/* USED FOR PADDLE
  const closeCallback = async () => {
    clearTimeout(subscription_timeout);
    await unlockSubscription();
    onClose?.();
  };
*/
/* USED FOR PADDLE
  const successCallback = async (data: any) => {
    dispatch(setFullPageLoading(true, "Verifying payment"));
    try {
      setPaymentState(Payment.Processing);
      reloadTimeoutId = setTimeout(() => window.location.reload(), 120000);
      await Api.finishSubscription(data.checkout.id);
      const { data: subscriptionData } = await Api.getGroupSubscriptionPlan(session?.userToGroupId!);
      dispatch(
        showModal(MODAL_TOAST, {
          message: "Congratulations! Your subscription payment was successful"
        })
      );
      segmentGroup(session?.group?.id!, marshalSegmentGroup({ ...session?.group!, isSubscribed: true }));
      segmentTrack(SegmentEvents.SubscriptionCreated, {
        user_id: session?.user?.id,
        user_to_group_id: session?.userToGroupId,
        quantity: data?.product?.quantity,
        next_bill_date: new Date(new Date(data?.checkout?.created_at).setFullYear(new Date(data?.checkout?.created_at).getFullYear() + 1)).toUTCString(),
        unit_price: data?.checkout?.prices?.customer?.unit,
        event_time: data?.checkout?.created_at,
        subscription_id: `${subscriptionData?.subscriptionPlan?.userSubscriptionId}`,
        subscription_plan_id: subscriptionData?.subscriptionPlan?.id
      }, { groupId: session?.group?.id });

      dispatch(setFullPageLoading(false));
      clearTimeout(subscription_timeout);
      onSuccess(3);
    } catch (error) {
      captureMessage(JSON.stringify(error))
      dispatch(setFullPageLoading(false));
      clearTimeout(subscription_timeout);
      dispatch(
        showModal(MODAL_TOAST, {
          message:
            "There was an error during your payment, please contact support.",
          type: ToastType.Error
        })
      );
    }
  };
*/

  const subscribe = async () => {
    try {
      dispatch(setFullPageLoading(true));
      //initiatePaddle();
      const { data } = await Api.getSubscriptionLink(couponCode);
      console.log("URL", data);
      // After 10 min, reload page because the link is automatically unlocked
      subscription_timeout = setTimeout(() => {
        Api.unlockSubscriptionLink();
        window.location.reload();
      }, 300000);
      setTimeoutId(subscription_timeout);
      dispatch(setFullPageLoading(false));
      /* PADDLE
      window.Paddle.Checkout.open({
        override: data.link,
        successCallback,
        closeCallback
      });
      */
      window.location.href = data.link;

      clearTimeout(subscription_timeout);
    } catch (error: any) {
      console.log(error);
      captureMessage(JSON.stringify(error))
      clearTimeout(subscription_timeout);
      dispatch(setFullPageLoading(false));
      if (error.status === 409) {
        dispatch(
          showModal(MODAL_TOAST, {
            message:
              "Another group member has started the payment process. Please, try again later.",
            type: ToastType.Info
          })
        );
      } else if (error.status === 400) {
        dispatch(
          showModal(MODAL_TOAST, {
            message: "Group has already paid the subscription"
          })
        );
        dispatch(updateSession(false, false, true));
        onSuccess(3);
      } else if (error.status === 403) {
        dispatch(
          showModal(MODAL_TOAST, {
            message:
              "The payment is being processed, please try again later or contact support.",
            type: ToastType.Error
          })
        );
        dispatch(updateSession(false, true, true));
      } else {
        dispatch(
          showModal(MODAL_TOAST, {
            message: ErrorMessages.GenericError,
            type: ToastType.Error
          })
        );
      }
    }
  };

  return { subscribe };
}

export default useSubscription;