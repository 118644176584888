export const maskPhoneNumber = (stringNumber: string | undefined) =>
  stringNumber
    ? `(${stringNumber.substring(0, 3)}${
        stringNumber.length > 3
          ? `)${
              stringNumber.length > 3
                ? ` ${stringNumber.substring(3, 6)}${
                    stringNumber.length > 6
                      ? ` ${stringNumber.substring(6, stringNumber.length)}`
                      : ""
                  }`
                : ""
            }`
          : ""
      }`
    : stringNumber;

export const maskLongName = (longName: string) => {
  if (longName.length > 24) return longName.slice(0, 24) + "...";

  return longName;
};
