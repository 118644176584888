import { KeyboardEventHandler, useEffect, useRef, useState } from "react";

type ChatInputProps = {
  className?: string;
  name?: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  onSendClick: (message: string) => void;
  isDisabled?: boolean;
};

function ChatInput({
  className = "",
  name,
  placeholder,
  minLength,
  maxLength,
  onSendClick,
  isDisabled,
}: ChatInputProps) {
  const [message, setMessage] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "50px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [message]);

  const onClick = () => {
    if (message.trim() !== "") {
      onSendClick(message);
    }
    setMessage("");
  };

  const onKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.code === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onClick();
    }
  };

  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className={`chat-input-container ${disabledClass}`}>
      <textarea
        ref={textareaRef}
        className={className}
        name={name}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        disabled={isDisabled}
        onKeyPress={onKeyPress}
      />
      <i
        className={`icon-send ${message.trim() ? "" : "disabled"}`}
        onClick={onClick}
      />
    </div>
  );
}

export default ChatInput;
