//@ts-nocheck
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import Api from "src/services/Api";
import { navigateToSectionIndex } from "src/store/actions/governance";
import { RootState } from "src/store/reducers";
import WaitingNetwork from "../WaitingNetwork";
import { UserAccountDto } from "../../../services/Api/types";
import { updateSessionSuccess } from "../../../store/actions/auth";
import {
  analyticsGroup,
  analyticsIdentifyUser,
  analyticsTrackEvent,
  marshalAnalyticsGroup
} from "../../../components/SegmentAnalytics/utils";
import { SegmentEvents } from "../../../components/shared/enums";

function CoCreateCoOwnershipAgreement() {
  const dispatch = useDispatch();
  const { isStepLoading } = useSelector((state: RootState) => state.governance);
  const [startNetworkTxnsLoading, setStartNetworkTxnsLoading] = useState(false);
  const [started, setStarted] = useState(false);

  const startNetworkTxn = async () => {
    try {
      setStartNetworkTxnsLoading(true);
      const { data } = await Api.startTxns();
      if (data.completed) {
        const { data: { success, ...session } } = await Api.getUserData();
        await analyticsIdentifyUser({ ...session, user: {...session?.user, hasStartedCoAgreement: true} })
        await analyticsTrackEvent(session, SegmentEvents.UserStartedGovernance)
        console.log("===> Triggers from Create co-ownership agreement")

        const usersStartedGovernance = session?.group?.users?.filter((u: UserAccountDto) =>
          u.hasStartedCoAgreement && u.id !== session?.user?.id
        )

        if (usersStartedGovernance.length === 0) {
          await analyticsGroup(session?.group?.id!, marshalAnalyticsGroup(session?.group, {governance_status: "Started"}))
          await analyticsTrackEvent(session, SegmentEvents.AccountStartedGovernance)
          console.log('==> Account Started Gov Triggers from waiting network co-wonership agreement')
        }

        dispatch(updateSessionSuccess(session))
        dispatch(navigateToSectionIndex());
      } else setStarted(true);
    } finally {
      setStartNetworkTxnsLoading(false);
    }
  };

  return started ? (
    <WaitingNetwork />
  ) : (
    <InitialStep
      title="Co-create a"
      boldTitle="co-ownership agreement."
      description="Now that all co-owners have passed ID verification, it’s time to get on the same page about the terms of co-ownership and co-create your agreement."
      primaryButtonText="Start"
      onPrimaryButtonClick={startNetworkTxn}
      isPrimaryButtonLoading={startNetworkTxnsLoading || isStepLoading}
      iconClass="icon-users-circled"
    />
  );
}

export default CoCreateCoOwnershipAgreement;
