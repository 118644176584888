//@ts-nocheck
import { call, put, select, takeLatest } from "redux-saga/effects";
import { MODAL_SIGNATURE, MODAL_TOAST } from "src/components/modals/modalTypes";
import Api from "src/services/Api";
import { UserAccountDto } from "src/services/Api/types";
import { DashboardPermission, GovernancePermission, Page } from "src/utils/helpers/permissions";
import { updatePermissions, updateSession } from "../actions/auth";
import { setCoOwnersStep, setSelectedCoOwner } from "../actions/coOwners";
import { hideModal, showModal } from "../actions/modal";
import { getVaultDocument } from "../actions/vault";
import { RootState } from "../reducers";
import { getCOAStatePermission } from "src/utils/helpers/getCOAStatePermission";
import { getVaultDocumentSaga } from "./vault";
import {
  AgreementDocumentCode,
  CoOwnersStep,
  ErrorMessages,
  SegmentEvents,
  ToastType
} from "src/components/shared/enums";
import {
  CONSENT_DOCUMENT,
  ConsentDocumentAction,
  consentDocumentRejected,
  consentDocumentSuccess,
  GET_AGREEMENT_HASH,
  GET_AGREEMENT_STATUS,
  GET_PENDING_CONSENSUS_ACTIONS,
  GetAgreementHashAction,
  getAgreementHashRejected,
  getAgreementHashSuccess,
  GetAgreementStatusAction,
  getAgreementStatusRejected,
  getAgreementStatusSuccess,
  getPendingConsensusActionsRejected,
  getPendingConsensusActionsSuccess,
  SIGN_ADD_CO_OWNER,
  SIGN_AGREEMENT,
  SIGN_DELETE_CO_OWNER,
  SignAddCoOwnerAction,
  signAddCoOwnerRejected,
  signAddCoOwnerSuccess,
  SignAgreementAction,
  signAgreementRejected,
  signAgreementSuccess,
  SignDeleteCoOwnerAction,
  signDeleteCoOwnerSuccess
} from "../actions/signature";
import { resetCSRFTokenSaga, updateSessionSaga } from "./auth";
import { setFullPageLoading } from "../actions/global";
import { clearSession } from "../actions";
// import { segmentTrack } from "src/components/SegmentAnalytics/analytics";
import {
  analyticsGroup,
  analyticsIdentifyUser,
  analyticsTrackEvent,
  marshalAnalyticsGroup
} from "../../components/SegmentAnalytics/utils";
import { captureMessage } from "@sentry/react";

export default function* signatureSaga() {
  yield takeLatest(GET_AGREEMENT_HASH, getAgreementHashSaga);
  yield takeLatest(GET_AGREEMENT_STATUS, getAgreementStatusSaga);
  yield takeLatest(SIGN_AGREEMENT, signAgreementSaga);
  yield takeLatest(SIGN_ADD_CO_OWNER, signAddCoOwnerSaga);
  yield takeLatest(SIGN_DELETE_CO_OWNER, signDeleteCoOwnerSaga);
  yield takeLatest(CONSENT_DOCUMENT, consentDocumentSaga);
  yield takeLatest(
    GET_PENDING_CONSENSUS_ACTIONS,
    getPendingConsensusActionsSaga
  );
}

// GET AGREEMENT HASH
export function* getAgreementHashSaga({
                                        payload: { code }
                                      }: GetAgreementHashAction) {
  try {
    const {
      data: { agreement: hash }
    } = yield call(Api.reviewAndSignAgreement, code);

    // Get current page
    const page: Page = yield select(({ auth }: RootState) => auth.page);
    const permission =
      page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;

    yield put(
      updatePermissions({
        page,
        permission:
          code === AgreementDocumentCode.CoOwnershipAgreement
            ? permission.FINALIZE_CO_OWNERSHIP_AGREEMENT
            : permission.FINALIZE_MEMORANDUM_OF_AGREEMENT
      })
    );
    yield put(getAgreementHashSuccess(code, hash));
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    if (error.status === 426) {
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    } else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
    yield put(getAgreementHashRejected(code));
  }
}

// GET AGREEMENT
function* getAgreementStatusSaga({
                                   payload: { code, hash }
                                 }: GetAgreementStatusAction) {
  try {
    const { data } = yield call(Api.finalizeAgreement, code, hash);

    // Get current page
    const page: Page = yield select(({ auth }: RootState) => auth.page);
    const permission =
      page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;

    yield put(
      updatePermissions({
        page,
        permission:
          code === AgreementDocumentCode.CoOwnershipAgreement
            ? permission.SIGN_CO_OWNERSHIP_AGREEMENT
            : code === AgreementDocumentCode.MemorandumOfAgreement
              ? permission.SIGN_MEMORANDUM_OF_AGREEMENT
              : permission.SECTIONS_STATUS
      })
    );
    yield put(getAgreementStatusSuccess(code, data.agreement));
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
    yield put(getAgreementStatusRejected(code));
  }
}

// SIGN AGREEMENT
function* signAgreementSaga({
                              payload: { code, eventId, hash }
                            }: SignAgreementAction) {
  try {
    yield call(Api.signAgreement, {
      eventId,
      fileHash: hash
    });

    yield call(updateSessionSaga, updateSession(false, false, false));
    const user: UserAccountDto = yield select(({ auth }: RootState) => auth.session?.user);
    const session: UserAccountDto = yield select(({ auth }: RootState) => auth.session);

    const page: Page = yield select(({ auth }: RootState) => auth.page);
    const permission = getCOAStatePermission(user, page);

    yield call(analyticsIdentifyUser, session, { governance_status: `${code} Signed` });
    yield call(analyticsTrackEvent, session, code === AgreementDocumentCode.CoOwnershipAgreement ? SegmentEvents.COASigned : SegmentEvents.MOASigned);

    if ((code === AgreementDocumentCode.CoOwnershipAgreement && session.group.allMemberSignedCoAgreement) || (code === AgreementDocumentCode.MemorandumOfAgreement && session.group.allMemberSignedMemoOfAgreement)) {
      yield call(analyticsGroup, session.group.id!, marshalAnalyticsGroup(session.group!));
      yield call(analyticsTrackEvent, session, SegmentEvents.AgreementExecuted, { agreement_type: code });
    }

    yield put(updatePermissions({ page, permission }));
    yield put(signAgreementSuccess());
    yield put(hideModal(MODAL_SIGNATURE));
    yield put(
      showModal(MODAL_TOAST, {
        message: `You successfully signed the ${
          code === AgreementDocumentCode.CoOwnershipAgreement
            ? "Co-ownership Agreement"
            : "Memorandum of Agreement"
        }`
      })
    );
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(hideModal(MODAL_SIGNATURE));
    yield put(signAgreementRejected());
    if (error.status === 409)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error
        })
      );
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else {
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
    }
  }
}

// SIGN TO ADD A CO-OWNER
function* signAddCoOwnerSaga({ payload }: SignAddCoOwnerAction) {
  try {
    const {
      invitedUserFirstName,
      invitedUserEmail
    } = yield select(({ signature }: RootState) => signature.coOwners.pendingConsensusActions);
    const { data } = yield call(Api.signAddMember, payload);

    yield call(updateSessionSaga, updateSession(false, false, false));
    yield call(getPendingConsensusActionsSaga);
    yield put(setSelectedCoOwner(null));
    yield put(setCoOwnersStep(CoOwnersStep.CoOwners));
    yield put(hideModal(MODAL_SIGNATURE));
    yield put(signAddCoOwnerSuccess());

    const { newCoOwner } = yield select(({ coOwners }: RootState) => coOwners.addCoOwner);
    const session = yield select(({ auth }: RootState) => auth.session);

    const message = newCoOwner
      ? `Your request to add ${newCoOwner?.firstName} was successful`
      : `Your signature to add ${invitedUserFirstName} was successfully submitted`;

    console.log("==> Triggered from signAddCoOwnerSaga ", JSON.stringify(session));
    yield call(analyticsTrackEvent, session, SegmentEvents.InviteSigned);

    if (data.allMembersSigned) {
      yield call(analyticsTrackEvent, session, SegmentEvents.AccountAddedUser);
      yield call(analyticsTrackEvent, session, SegmentEvents.InviteSent, {
        invitee_first_name: newCoOwner?.firstName ? newCoOwner?.firstName : invitedUserFirstName,
        invitee_email: newCoOwner?.email ? newCoOwner?.email : invitedUserEmail
      });
    }

    yield put(showModal(MODAL_TOAST, { message }));
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(hideModal(MODAL_SIGNATURE));
    yield put(signAddCoOwnerRejected());
    if (error.status === 409)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error
        })
      );
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
  }
}

// SIGN TO DELETE A CO-OWNER
function* signDeleteCoOwnerSaga({ payload }: SignDeleteCoOwnerAction) {
  try {
    const { data } = yield call(Api.signDeleteMember, payload);
    yield put(updateSession(true, false, false));

    const user: UserAccountDto = yield select(({ auth }: RootState) => auth.session?.user);
    const session = yield select(({ auth }: RootState) => auth.session);


    yield call(analyticsTrackEvent, session, SegmentEvents.RemoveUserSigned);

    if (data.allMembersSigned) {
      yield call(analyticsTrackEvent,session, SegmentEvents.UserDeleted);
      yield call(analyticsGroup, session.group.id, marshalAnalyticsGroup(session.group, {
        group_size: session?.group?.users.length - 1,
        governance_status: "Started"
      }));
      yield call(analyticsTrackEvent, session, SegmentEvents.AccountRemovedUser);
      yield call(analyticsTrackEvent, session, SegmentEvents.AccountStartedGovernance);
    }

    if (data.allMembersSigned && payload.userIdToDelete === user.id) {
      yield put(signDeleteCoOwnerSuccess());
      yield put(
        showModal(MODAL_TOAST, {
          message: `Your account was successfully removed`
        })
      );
      yield put(setFullPageLoading(true));

      // Clean session
      yield put(clearSession());

      // Reset CSRF token
      yield call(resetCSRFTokenSaga);

      yield put(setFullPageLoading(false));
    } else {
      yield call(getPendingConsensusActionsSaga);
      yield call(updateSessionSaga, updateSession(false, false, false));
      yield put(setCoOwnersStep(CoOwnersStep.CoOwners));
      yield put(hideModal(MODAL_SIGNATURE));
      yield put(signDeleteCoOwnerSuccess());

      const users: Array<UserAccountDto> = yield select(
        ({ auth }: RootState) => auth.session?.group?.users
      );
      const userToDelete = users.find(
        (user) => user.id === payload.userIdToDelete
      );
      yield put(
        showModal(MODAL_TOAST, {
          message: `Your signature to remove ${userToDelete?.firstName} ${userToDelete?.lastName} was successfully submitted`
        })
      );
    }
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(hideModal(MODAL_SIGNATURE));
    yield put(signAddCoOwnerRejected());
    if (error.status === 409)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error
        })
      );
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
  }
}

// GET PENDING CONSENSUS ACTIONS
export function* getPendingConsensusActionsSaga() {
  try {
    const { data } = yield call(Api.getPendingConsensusActions);
    const coOwners = data?.coOwners ?? {
      eventId: null,
      userIdToRemove: null,
      createdByUserId: null,
      eventExpirationDate: null,
      invitedUserEmail: null,
      invitedUserFirstName: null,
      userIdsMissingToConfirmEvent: [],
      userIdsThatConfirmedEvent: []
    };
    yield put(getPendingConsensusActionsSuccess(coOwners));
  } catch (error) {
    captureMessage(JSON.stringify(error))
    yield put(getPendingConsensusActionsRejected());
  } finally {
  }
}

// SIGN TO ADD NEW DOCUMENT
function* consentDocumentSaga({ payload }: ConsentDocumentAction) {
  try {
    yield call(Api.consentToUpload, payload.signDocumentUpload);
    yield call(getVaultDocumentSaga, getVaultDocument(payload.signDocumentUpload.documentId));
    yield put(updateSession(true, false, true));
    const vaultDocument = yield select(({ vault }: RootState) => vault.vaultDocument.value);
    const session = yield select(({ auth }: RootState) => auth.session);

    yield call(analyticsTrackEvent, session, SegmentEvents.DocumentSigned);
    if (vaultDocument.missingToConfirm.length === 0) {
      yield call(analyticsTrackEvent, session, SegmentEvents.DocumentAdded, { document_type: vaultDocument.document.code });
    }
    yield put(hideModal(MODAL_SIGNATURE));
    yield put(consentDocumentSuccess());
    yield put(
      showModal(MODAL_TOAST, {
        message: `Your signature to add a new document was successfully submitted`
      })
    );
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(hideModal(MODAL_SIGNATURE));
    yield put(consentDocumentRejected());
    if (error.status === 409)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PendingActions,
          type: ToastType.Error
        })
      );
    else if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error
        })
      );
  }
}
