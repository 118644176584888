import { PopoverMenuOptions } from "../shared/types";

type PopoverMenuProps = {
  menuOptions: PopoverMenuOptions;
};

function PopoverMenu({ menuOptions }: PopoverMenuProps) {
  return (
    <div className="popover-menu">
      <div className="overlay"></div>
      {menuOptions.map((menuOption, index) => (
        <i
          key={index}
          tabIndex={0}
          className={menuOption.icon}
          onClick={menuOption.onClick}
        >
          {menuOption.text}
        </i>
      ))}
    </div>
  );
}

export default PopoverMenu;
