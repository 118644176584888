import Tooltip from "src/components/Tooltip";
import parse from "html-react-parser";

type StepTitleProps = {
  title?: string;
  tooltip?: string;
  description?: string;
  backButton?: { onBackButtonClick: () => void };
  isDisabled?: boolean;
};

function StepTitle({
  title,
  tooltip,
  description,
  backButton,
  isDisabled,
}: StepTitleProps) {
  return (
    <>
      {title && (
        <h4
          className={
            backButton ? `${isDisabled ? "disabled" : ""} icon-back` : undefined
          }
          onClick={
            backButton && !isDisabled ? backButton.onBackButtonClick : undefined
          }
        >
          {parse(title)}
          {!description && <Tooltip content={tooltip} />}
        </h4>
      )}
      {description && (
        <p>
          {parse(description)}
          <Tooltip content={tooltip} />
        </p>
      )}
    </>
  );
}

export default StepTitle;
