import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import OneTimeCode from "src/components/OneTimeCode";
import Api from "src/services/Api";
import { setFullPageLoading, setMainHeader } from "src/store/actions/global";
import { showModal } from "src/store/actions/modal";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import { LoginFormData } from "..";
import {
  updatePermissions,
  updateSessionSuccess,
} from "src/store/actions/auth";
import {
  getDefaultPage,
  getDefaultPagePermission,
  getPermissions,
  Page,
} from "src/utils/helpers/permissions";
import {
  ErrorMessages,
  SegmentEvents,
  SegmentSignUpMethod,
  ToastType,
} from "src/components/shared/enums";
import { getPageFromPath } from "src/utils/helpers/routing";
import { useLocation } from "react-router-dom";
import { LocationState } from "src/components/shared/types";
import { captureMessage } from "@sentry/react";
import { analyticsIdentifyUser, analyticsTrackEvent } from "../../../../components/SegmentAnalytics/utils";
import { RootState } from "../../../../store/reducers";
import { Session } from "../../../../services/Api/types";

function VerifyEmail({
  formData: { email, emailChallengeId },
  prevStep,
  saveData,
}: MultiStepFormChildrenProps<LoginFormData>) {
  // const { segmentIdentifyUser, segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const dispatch = useDispatch();
  // @ts-ignore
  const currentSession = useSelector(({ auth }: RootState) => auth.session) as Session;
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [oneTimeCode, setOneTimeCode] = useState("");
  const [hasErrorCode, setHasErrorCode] = useState(false);
  const [failed, setFailed] = useState(false);
  let location = useLocation();
  const locationState = location.state as LocationState;
  let fromPathname = locationState?.from?.pathname || "";

  useEffect(() => {
    dispatch(
      setMainHeader({
        hideBottomLine: true,
        onBackButtonClick: failed ? () => setFailed(false) : prevStep,
      })
    );
  }, [failed]);

  const resendCode = async () => {
    try {
      setResendCodeLoading(true);
      const {
        data: { emailChallengeId },
      } = await Api.loginVerifyEmail({ email });
      saveData({ emailChallengeId });
      dispatch(
        showModal(MODAL_TOAST, {
          message: "Sent! Please check your inbox.",
        })
      );
    } catch (error) {
      captureMessage(JSON.stringify(error))
      dispatch(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
    } finally {
      setResendCodeLoading(false);
    }
  };

  const verifyCode = async (value: string) => {
    try {
      dispatch(setFullPageLoading(true));
      setHasErrorCode(false);
      const { data } = await Api.loginSubmitEmailCode(emailChallengeId, {
        emailChallengeOtp: value,
      });
      const { success, ...session } = data;
      const permissions = getPermissions(session);
      const pageFromPath = getPageFromPath(fromPathname);
      const page = permissions[pageFromPath as Page]
        ? (pageFromPath as Page)
        : getDefaultPage(session);
      const permission = getDefaultPagePermission(page, session);
      dispatch(updateSessionSuccess(session));
      dispatch(updatePermissions({ permissions, page, permission }));

      const updatedSession = {...currentSession, ...session};
      await analyticsIdentifyUser(updatedSession);
      await analyticsTrackEvent(updatedSession, SegmentEvents.UserSignedIn, {
        method: SegmentSignUpMethod.Email,
        user_id: session.user.id,
        user_to_group_id: session?.userToGroupId,
      });
    } catch (error: any) {
      await analyticsTrackEvent(currentSession, SegmentEvents.UserSignInFailed)
      captureMessage(JSON.stringify(error))
      if (error.status === 403) {
        setHasErrorCode(true);
      } else if (error.status === 400) {
        setHasErrorCode(true);
      } else {
        setFailed(true);
      }
    } finally {
      dispatch(setFullPageLoading(false));
    }
  };

  const onOneTimeCodeChange = (value: string) => {
    setOneTimeCode(value);
    value.length === 6 ? verifyCode(value) : setHasErrorCode(false);
  };

  return (
    <OneTimeCode
      otcFailed={failed}
      email={email}
      onTryAgainButtonClick={prevStep}
      oneTimeCode={oneTimeCode}
      onOneTimeCodeChange={onOneTimeCodeChange}
      hasErrorCode={hasErrorCode}
      countDownButton={{
        text: "Resend email",
        countDownText: "email",
        time: 30,
        onButtonClick: resendCode,
        isLoading: resendCodeLoading,
      }}
    />
  );
}

export default VerifyEmail;
