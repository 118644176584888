import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getTitleFromPath } from "src/utils/helpers/routing";
import { AnalyticsContext, AnalyticsContextType } from "../Analytics";

function SegmentAnalytics() {
  const location = useLocation();
  const { segmentPage } = useContext(AnalyticsContext) as AnalyticsContextType

  useEffect(() => {
    segmentPage(getTitleFromPath(location.pathname), location.pathname);
  }, [location]);

  return <></>;
}

export default SegmentAnalytics;
