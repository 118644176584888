import { useContext, useState } from "react";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import Api from "src/services/Api";
import { useNavigate, useParams } from "react-router-dom";
import { MemberSignUpFormDataValidation } from "src/services/Api/types";
import { getFormatAddress } from "src/utils/helpers/helpers";
import Checkbox from "src/components/inputs/Checkbox";
import FailedStep from "src/components/MultiStepForm/FailedStep";
import { Path } from "src/components/routing/routes";
import { SignUpFormData } from "src/pages/Onboarding/signUp/Leader";
import {
  SegmentEvents,
  SegmentResponseStatus,
} from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";
import { captureMessage } from "@sentry/react";

function ConfirmationData({
  formData,
  prevStep,
  saveData,
  progress,
  nextStep,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const { invitationId } = useParams();
  const navigate = useNavigate();
  const [stepData, setStepData] = useState(formData.confirmation || false);
  const [isLoading, setIsLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [failedTitle, setFailedTitle] = useState("");

  const onNextClick = async () => {
    if (!!stepData) {
      if (formData && invitationId) {
        segmentTrack(SegmentEvents.MemberPreSignUpStepSubmitted, {
          step: "One last thing",
          data: stepData,
          status: SegmentResponseStatus.Success,
        });

        try {
          setIsLoading(true);
          const confirmData: MemberSignUpFormDataValidation = {
            address: getFormatAddress(formData.coOwnedHomeAddress),
            closingPurchaseDate: formData.coOwnedHomePurchaseDate,
            invitationId,
          };
          await Api.validateJoinGroupMember(confirmData);
          saveData({ confirmation: stepData });
          setIsLoading(false);
          nextStep();
        } catch (error: any) {
          setIsLoading(false);
          captureMessage(JSON.stringify(error))
          if (error.status === 403) {
            setFailedTitle("This invitation is no longer available.");
          } else {
            setFailedTitle("Something went wrong, please try again later.");
          }
          setFailed(true);
        }
      }
    }
  };

  return failed ? (
    <FailedStep
      title={failedTitle}
      nextText="OK"
      onNextClick={() => navigate(Path.Home)}
    />
  ) : (
    <Step
      title="One last thing"
      onNextClick={onNextClick}
      onBackClick={() => prevStep()}
      nextText="Confirm"
      isButtonDisabled={!stepData}
      isButtonLoading={isLoading}
      progress={progress}
      tooltip={`<p>
            <strong>Unauthorized borrowing</strong> includes using real property
            as collateral for a loan without the express written consent of all
            co-owners.
          </p>
          <p>
            <strong>Pending litigation</strong> refers to any unresolved legal
            proceeding.
          </p>
          <p>
            <strong>Recent credit events</strong> include any unresolved credit
            circumstances or activity that may negatively impact your ability to
            borrow.
          </p>
          <p>
            <strong>Ongoing disputes</strong> include unresolved disagreements
            between co-owners, creditors, or anyone with a claim to the
            property.
          </p>`}
    >
      <p>
        Please confirm to the best of your knowledge that no co-owners in your
        group are involved in:
      </p>
      <ul className="bullet-list">
        <li>Unauthorized borrowing</li>
        <li>Pending litigation</li>
        <li>Recent credit events</li>
        <li>Ongoing disputes</li>
      </ul>
      <Checkbox
        id="confirmation"
        name="confirmation"
        className="confirmation-check"
        onChange={(value) => setStepData(value)}
        checked={stepData}
        isDisabled={isLoading}
        label="I don’t have any of these issues"
      />
    </Step>
  );
}

export default ConfirmationData;
