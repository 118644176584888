import { useDispatch } from "react-redux";
import { conditionalCheckBox } from "src/store/actions/governance";
import { DefaultCheckboxOptionType } from "../shared/types";
import Tooltip from "../Tooltip";
import parse from "html-react-parser";

type CheckboxType = {
  id?: string;
  className?: string;
  name?: string;
  onChange?: (value: boolean) => void;
  checked?: boolean;
  label?: string;
  isDisabled?: boolean;
  tooltip?: string;
  fieldIndex?: number;
  defaultCheckboxOptions?: DefaultCheckboxOptionType[];
};

function Checkbox({
  id,
  className = "",
  name,
  onChange,
  checked,
  label,
  isDisabled,
  tooltip,
  defaultCheckboxOptions = [],
  fieldIndex,
}: CheckboxType) {
  const disabledClass = isDisabled ? "disabled" : "";
  const dispatch = useDispatch();

  const onChangeCheckBox = (e: any) => {
    if (defaultCheckboxOptions.length > 0) {
      dispatch(conditionalCheckBox(fieldIndex!));
    } else {
      onChange?.(e.target.checked);
    }
  };

  return (
    <div className={`label-check ${className} ${disabledClass}`}>
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={onChangeCheckBox}
        checked={checked}
        disabled={isDisabled}
      />
      <span></span>
      {id && label && (
        <label htmlFor={id}>
          {parse(label)}
          <Tooltip content={tooltip} />
        </label>
      )}
    </div>
  );
}

export default Checkbox;
