import { useDispatch, useSelector } from "react-redux";
import { deleteCoOwner } from "src/store/actions/coOwners";
import { hideModal, showModal } from "src/store/actions/modal";
import { RootState } from "src/store/reducers";
import { MODAL_CONFIRMATION, MODAL_TOAST } from "../modals/modalTypes";
import { ToastType } from "../shared/enums";
import IconButton from "./IconButton";

function DeleteAccountButton() {
  console.log("We are showing the delete button")
  const dispatch = useDispatch();
  // @ts-ignore
  const user = useSelector(({ auth }: RootState) => auth.session?.user);
  const { pendingConsensusActions } = useSelector(
    ({ signature }: RootState) => signature.coOwners
  );
  const showDeleteAccountModal = () =>
    pendingConsensusActions.eventId
      ? dispatch(
          showModal(MODAL_TOAST, {
            message:
              "You can only add or delete one co-owner at a time. <b>Check the current modification on the Co-owners menu.</b>",
            type: ToastType.Info,
            autoDeleteTime: 15000,
          })
        )
      : dispatch(
          showModal(MODAL_CONFIRMATION, {
            title: `Are you sure you want to delete your account?`,
            description: "",
            leftButtonText: "Yes",
            rightButtonText: "Cancel",

            onLeftButtonClick: deleteAccount,
            onRightButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
            onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
          })
        );

  const deleteAccount = () => {
    if (user)
      dispatch(
        deleteCoOwner(
          user.id,
          user.isSignupCompleted,
          true,
          user.firstName,
          user.lastName
        )
      );
  };

  return (
    <IconButton
      iconClassName="icon-trash"
      className="delete-button"
      onClick={showDeleteAccountModal}
    />
  );
}

export default DeleteAccountButton;
