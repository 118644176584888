import parse from "html-react-parser";
import Tooltip from "../Tooltip";
import { TEXT_REGEX } from "../shared/constants";

type TextAreaProps = {
  id?: string;
  label?: string;
  className?: string;
  name?: string;
  rows?: number;
  cols?: number;
  value?: string;
  placeholder?: string;
  maxLength?: number;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  tooltip?: string;
  isValid?: boolean;
  errorMessage?: string;
};

function TextArea({
  id,
  label,
  className = "",
  name,
  rows = 4,
  cols,
  value = "",
  isDisabled,
  placeholder,
  maxLength,
  onChange,
  tooltip,
  isValid = true,
  errorMessage,
}: TextAreaProps) {
  const _onChange = (value: string) => {
    const regExp = new RegExp(TEXT_REGEX);
    if (value === "" || regExp.test(value)) onChange && onChange(value);
  };

  const disabledClass = isDisabled ? "disabled" : "";
  const errorClass = errorMessage ? "error" : "";

  return (
    <div className={`text-area-container ${disabledClass} ${errorClass}`}>
      {label && id && (
        <label htmlFor={id}>
          {parse(label)}
          <Tooltip content={tooltip} />
        </label>
      )}
      <textarea
        id={id}
        className={className}
        name={name}
        rows={rows}
        cols={cols}
        disabled={isDisabled}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(e) => _onChange(e.target.value)}
        value={value}
        onKeyPress={(e) => e.code === "Enter" && e.preventDefault()}
      />
      {!isValid && <div className="invalid-input">{errorMessage}</div>}
    </div>
  );
}

export default TextArea;
