import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { Path } from "src/components/routing/routes";
import Api from "src/services/Api";
import { validateEmail } from "src/utils/helpers/validations";
import Logo from "src/components/Logo";
import { useDispatch } from "react-redux";
import Button from "src/components/buttons/Button";
import { EMAIL_REGEX } from "src/components/shared/constants";
import { setFormState, setFullPageLoading, setMainHeader } from "src/store/actions/global";
import { useContext, useEffect, useState } from "react";
import { LoginFormData } from "..";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Fail from "src/components/Fail";
import { LocationState } from "src/components/shared/types";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { decodeJWT } from "../../../../utils/helpers/helpers";
import { SegmentEvents, SegmentSignUpMethod } from "../../../../components/shared/enums";
import { updatePermissions, updateSessionSuccess } from "../../../../store/actions/auth";
import { getPageFromPath } from "../../../../utils/helpers/routing";
import { getDefaultPage, getDefaultPagePermission, getPermissions, Page } from "../../../../utils/helpers/permissions";
import { captureMessage } from "@sentry/react";
// import { captureMessage } from '@sentry/browser';


function Login({
                 nextStep,
                 saveData
               }: MultiStepFormChildrenProps<LoginFormData>) {
  const { segmentIdentifyUser, segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType;
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    watch
  } = useForm({ defaultValues: { email: "" } });
  const dispatch = useDispatch();
  const [failed, setFailed] = useState(false);
  let location = useLocation();
  const locationState = location.state as LocationState;
  let fromPathname = locationState?.from?.pathname || "";

  useEffect(() => {
    dispatch(
      setMainHeader({
        hide: !failed,
        onBackButtonClick: failed ? () => setFailed(false) : undefined,
        hideBottomLine: failed
      })
    );
  }, [failed]);

  const onGoogleSignInSuccess = async (credentialResponse: CredentialResponse) => {
    dispatch(setFullPageLoading(true));

    const { credential: socialToken } = credentialResponse;
    const { email, sub: googleId } = decodeJWT(socialToken!);

    try {
      const { data } = await Api.loginVerifyGoogle({
        email,
        socialToken: socialToken!,
        googleId
      });
      const { success, ...session } = data;
      segmentIdentifyUser(session);
      segmentTrack(SegmentEvents.UserSignedIn, {
        method: SegmentSignUpMethod.Google,
        user_id: session.user.id,
        user_to_group_id: session?.userToGroupId,
      }, { groupId: session?.group?.id });
      dispatch(updateSessionSuccess(session));
      const permissions = getPermissions(session);
      const pageFromPath = getPageFromPath(fromPathname);
      const page = permissions[pageFromPath as Page]
        ? (pageFromPath as Page)
        : getDefaultPage(session);
      const permission = getDefaultPagePermission(page, session);
      dispatch(updatePermissions({ permissions, page, permission }));
    } catch (error) {
      segmentTrack(SegmentEvents.UserSignInFailed)
      captureMessage(JSON.stringify(error))
    } finally {
      dispatch(setFullPageLoading(false));
    }
  };

  const onSubmit = async ({ email }: any) => {
    console.log("Login onSubmit");
    // throw new Error("Sentry Test Error");
    
    captureMessage("Login onSubmit")
    if (validateEmail(email)) {
      try {
        const {
          data: { emailChallengeId }
        } = await Api.loginVerifyEmail({ email });
        saveData({ email, emailChallengeId });
        nextStep();
      } catch (error) {
        captureMessage(JSON.stringify(error))
        setFailed(true);
      }
    }
  };

  return failed ? (
    <Fail
      title="Something went wrong, please try again later."
      buttonText="Ok"
      onButtonClick={() => {
        dispatch(setFormState({ formStep: 1, formData: {} }));
        setFailed(false);
      }}
    />
  ) : (
    <>
      <div className="login-background"></div>
      <div className="content-wrapper login-wrapper">
        <Logo />
        <div className="text-center">
          <h3>Log in</h3>
        </div>

        <GoogleLogin
          onSuccess={onGoogleSignInSuccess}
          onError={() => console.log('fail')}
          useOneTap={false}
          width="360px"
          logo_alignment="center"
          auto_select={false}
          text="signin_with"
          context="signin"
          shape={'rectangular'}
        />

        <div className="or-separator">
          <span>Or</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`text-input-container ${isSubmitting ? "disabled" : ""}`}
          >
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Email address"
              className="email-input"
              {...register("email", {
                required: true,
                pattern: EMAIL_REGEX
              })}
            />
          </div>
          <Button
            text="Log in with email"
            type="submit"
            isDisabled={!validateEmail(watch("email"))}
            isLoading={isSubmitting}
          />
        </form>
        <p className="dont-have-account">
          Don’t have an account?{" "}
          <Link
            className={`${isSubmitting ? "disabled" : ""}`}
            to={Path.SignUp}
          >
            Sign up
          </Link>
        </p>
        <p className="legal-footer login">
          <Link
            className={`${isSubmitting ? "disabled" : ""}`}
            to={Path.Terms}
            state={{ from: location }}
          >
            Terms
          </Link>
          {"    "}
          <Link
            className={`${isSubmitting ? "disabled" : ""}`}
            to={Path.PrivacyPolicy}
            state={{ from: location }}
          >
            Privacy
          </Link>
          {"    "}
          <Link
            className={`${isSubmitting ? "disabled" : ""}`}
            to="https://www.cobuy.io/disclaimer"
            target="_blank"
          >
            Disclaimer
          </Link>
        </p>
      </div>
    </>
  );
}

export default Login;
