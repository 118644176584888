import { ChatGroup, ChatMessageType } from "src/components/shared/types";
import { Pagination } from "src/services/Api/types";

// SET SELECTED CHAT GROUP
export const SET_SELECTED_CHAT_GROUP = "SET_SELECTED_CHAT_GROUP";
export interface SetSelectedChatGroupAction {
  type: typeof SET_SELECTED_CHAT_GROUP;
  payload: ChatGroup | null;
}
export const setSelectedChatGroup = (
  chatGroup: ChatGroup | null
): SetSelectedChatGroupAction => ({
  type: SET_SELECTED_CHAT_GROUP,
  payload: chatGroup,
});

// GET CHAT GROUP
// Pending
export const GET_CHAT_GROUP = "GET_CHAT_GROUP";
export interface GetChatGroupAction {
  type: typeof GET_CHAT_GROUP;
  payload: { isRefreshing?: boolean };
}
export const getChatGroup = (isRefreshing?: boolean): GetChatGroupAction => ({
  type: GET_CHAT_GROUP,
  payload: { isRefreshing },
});
// Success
export const GET_CHAT_GROUP_SUCCESS = "GET_CHAT_GROUP_SUCCESS";
export interface GetChatGroupSuccessAction {
  type: typeof GET_CHAT_GROUP_SUCCESS;
  payload: Array<ChatGroup>;
}
export const getChatGroupSuccess = (
  chatGroup: Array<ChatGroup>
): GetChatGroupSuccessAction => ({
  type: GET_CHAT_GROUP_SUCCESS,
  payload: chatGroup,
});
// Rejected
export const GET_CHAT_GROUP_REJECTED = "GET_CHAT_GROUP_REJECTED";
export interface GetChatGroupRejectedAction {
  type: typeof GET_CHAT_GROUP_REJECTED;
}
export const getChatGroupRejected = (): GetChatGroupRejectedAction => ({
  type: GET_CHAT_GROUP_REJECTED,
});

// SET SHOW SWIPE LOADER
export const SET_SHOW_SWIPE_LOADER = "SET_SHOW_SWIPE_LOADER";
export interface SetShowSwipeLoaderAction {
  type: typeof SET_SHOW_SWIPE_LOADER;
  payload: boolean;
}
export const setShowSwipeLoader = (
  value: boolean
): SetShowSwipeLoaderAction => ({
  type: SET_SHOW_SWIPE_LOADER,
  payload: value,
});

// GET NEXT PAGE MESSAGES
// Pending
export const GET_NEXT_PAGE_MESSAGES = "GET_NEXT_PAGE_MESSAGES";
export interface GetNextPageMessagesAction {
  type: typeof GET_NEXT_PAGE_MESSAGES;
}
export const getNextPageMessages = (): GetNextPageMessagesAction => ({
  type: GET_NEXT_PAGE_MESSAGES,
});
// Success
export const GET_NEXT_PAGE_MESSAGES_SUCCESS = "GET_NEXT_PAGE_MESSAGES_SUCCESS";
export interface GetNextPageMessagesSuccessAction {
  type: typeof GET_NEXT_PAGE_MESSAGES_SUCCESS;
  payload: Pagination<ChatMessageType>;
}
export const getNextPageMessagesSuccess = (
  chats: Pagination<ChatMessageType>
): GetNextPageMessagesSuccessAction => ({
  type: GET_NEXT_PAGE_MESSAGES_SUCCESS,
  payload: chats,
});
// Rejected
export const GET_NEXT_PAGE_MESSAGES_REJECTED =
  "GET_NEXT_PAGE_MESSAGES_REJECTED";
export interface GetNextPageMessagesRejectedAction {
  type: typeof GET_NEXT_PAGE_MESSAGES_REJECTED;
}
export const getNextPageMessagesRejected =
  (): GetNextPageMessagesRejectedAction => ({
    type: GET_NEXT_PAGE_MESSAGES_REJECTED,
  });

// REFRESH FIRST PAGE MESSAGES
// Pending
export const REFRESH_FIRST_PAGE_MESSAGES = "REFRESH_FIRST_PAGE_MESSAGES";
export interface RefreshFirstPageMessagesAction {
  type: typeof REFRESH_FIRST_PAGE_MESSAGES;
  payload: { isSwipeRefresh: boolean; refreshChatGroup: boolean };
}
export const refreshFirstPageMessages = (
  isSwipeRefresh: boolean,
  refreshChatGroup: boolean
): RefreshFirstPageMessagesAction => ({
  type: REFRESH_FIRST_PAGE_MESSAGES,
  payload: { isSwipeRefresh, refreshChatGroup },
});
// Success
export const REFRESH_FIRST_PAGE_MESSAGES_SUCCESS =
  "REFRESH_FIRST_PAGE_MESSAGES_SUCCESS";
export interface RefreshFirstPageMessagesSuccessAction {
  type: typeof REFRESH_FIRST_PAGE_MESSAGES_SUCCESS;
  payload: Pagination<ChatMessageType>;
}
export const refreshFirstPageMessagesSuccess = (
  chats: Pagination<ChatMessageType>
): RefreshFirstPageMessagesSuccessAction => ({
  type: REFRESH_FIRST_PAGE_MESSAGES_SUCCESS,
  payload: chats,
});
// Rejected
export const REFRESH_FIRST_PAGE_MESSAGES_REJECTED =
  "REFRESH_FIRST_PAGE_MESSAGES_REJECTED";
export interface RefreshFirstPageMessagesRejectedAction {
  type: typeof REFRESH_FIRST_PAGE_MESSAGES_REJECTED;
}
export const refreshFirstPageMessagesRejected =
  (): RefreshFirstPageMessagesRejectedAction => ({
    type: REFRESH_FIRST_PAGE_MESSAGES_REJECTED,
  });

// SEND CHAT MESSAGE
// Pending
export const SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE";
export interface SendChatMessageAction {
  type: typeof SEND_CHAT_MESSAGE;
  payload: { message: string; isOnBottom: boolean };
}
export const sendChatMessage = (
  message: string,
  isOnBottom: boolean
): SendChatMessageAction => ({
  type: SEND_CHAT_MESSAGE,
  payload: { message, isOnBottom },
});
// Success
export const SEND_CHAT_MESSAGE_SUCCESS = "SEND_CHAT_MESSAGE_SUCCESS";
export interface SendChatMessageSuccessAction {
  type: typeof SEND_CHAT_MESSAGE_SUCCESS;
}
export const sendChatMessageSuccess = (): SendChatMessageSuccessAction => ({
  type: SEND_CHAT_MESSAGE_SUCCESS,
});
// Rejected
export const SEND_CHAT_MESSAGE_REJECTED = "SEND_CHAT_MESSAGE_REJECTED";
export interface SendChatMessageRejectedAction {
  type: typeof SEND_CHAT_MESSAGE_REJECTED;
}
export const sendChatMessageRejected = (): SendChatMessageRejectedAction => ({
  type: SEND_CHAT_MESSAGE_REJECTED,
});

// Received
export const SEND_CHAT_MESSAGE_RECEIVED = "SEND_CHAT_MESSAGE_RECEIVED";
export interface SendChatMessageReceivedAction {
  type: typeof SEND_CHAT_MESSAGE_RECEIVED;
}
export const sendChatMessageReceived = (): SendChatMessageReceivedAction => ({
  type: SEND_CHAT_MESSAGE_RECEIVED,
});

