import { useDispatch, useSelector } from "react-redux";
import { DocumentCardType, UploadDocumentType } from "src/services/Api/types";
import { hideModal, showModal } from "src/store/actions/modal";
import { uploadDocument } from "src/store/actions/vault";
import { RootState } from "src/store/reducers";
import { MODAL_ADD_DOCUMENT } from "../modals/modalTypes";
import IconButton from "./IconButton";

function AddDocumentButton() {
  const dispatch = useDispatch();
  const {
    selectedDocumentId,
    vaultContent: {
      value: { otherDocuments },
    },
  } = useSelector(({ vault }: RootState) => vault);

  const showAddDocumentModal = (action?: string) => {
    let document;
    if (action == 'edit') {
      document = (otherDocuments as Array<DocumentCardType>)?.filter(
        (document: DocumentCardType) => document.id === selectedDocumentId
      )[0];
    } else {
      document = undefined;
    }
    dispatch(
      showModal(MODAL_ADD_DOCUMENT, {
        onButtonClick: (document: UploadDocumentType) =>
          dispatch(uploadDocument(document)),
        onCloseButtonClick: () => dispatch(hideModal(MODAL_ADD_DOCUMENT)),
        document,
        action
      })
    );
  };

  return (
    <IconButton
      iconClassName="icon-plus"
      className="add-document-button"
      //onClick={showAddDocumentModal}
      onClick={() => showAddDocumentModal("new")}
    />
  );
}

export default AddDocumentButton;
