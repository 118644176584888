import { useContext, useState } from "react";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import Api from "src/services/Api";
import PhoneInput from "src/components/inputs/PhoneInput";
import { SignUpFormData } from "..";
import { showModal } from "src/store/actions/modal";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import { useDispatch } from "react-redux";
import {
  ErrorMessages,
  SegmentEvents,
  SegmentResponseStatus,
  ToastType,
} from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";
import { captureMessage } from "@sentry/react";

function MobilePhoneNumberData({
  formData: { phoneNumber, emailChallengeId, email },
  nextStep,
  saveData,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const dispatch = useDispatch();
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const [stepData, setStepData] = useState(phoneNumber || "");
  const [isLoading, setIsLoading] = useState(false);

  const onNextClick = async () => {
    if (isValidPhoneNumber()) {
      try {
        setIsLoading(true);
        const {
          data: { phoneNumberChallengeId },
        } = await Api.signUpVerifyPhone({
          emailChallengeId: emailChallengeId || "",
          phoneNumber: stepData,
        });
        segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
          step: "What’s your mobile phone number?",
          data: phoneNumber,
          status: SegmentResponseStatus.Success,
        });
        saveData({
          phoneNumber: stepData,
          phoneNumberChallengeId,
        });
        setIsLoading(false);
        nextStep();
      } catch (error) {
        setIsLoading(false);
        captureMessage(JSON.stringify(error))
        segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
          step: "What’s your mobile phone number?",
          data: phoneNumber,
          status: SegmentResponseStatus.Error,
        });
        dispatch(
          showModal(MODAL_TOAST, {
            message: ErrorMessages.GenericError,
            type: ToastType.Error,
          })
        );
      }
    }
  };

  const isValidPhoneNumber = () => stepData.toString().length === 10;

  return (
    <Step
      title="What's your mobile phone number?"
      description="Required for ID verification"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonLoading={isLoading}
      isButtonDisabled={!isValidPhoneNumber()}
    >
      <PhoneInput
        id="phoneNumber"
        label="Phone number"
        value={stepData}
        onChange={(value) => setStepData(value)}
        name="phoneNumber"
        isDisabled={isLoading}
      />
    </Step>
  );
}

export default MobilePhoneNumberData;
