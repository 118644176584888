import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import ModalLayout from "../ModalLayout";

export type ConfirmationModalProps = {
  iconClass?: string;
  title?: string;
  description?: JSX.Element;
  rightButtonText?: string;
  onRightButtonClick?: () => void;
  leftButtonText?: string;
  onLeftButtonClick?: () => void;
  onCloseButtonClick?: () => void;
};

function ConfirmationModal() {
  const modal = useSelector((state: RootState) => state.modal.confirmation);
  const isDeletingCoOwnerOnboarding = useSelector(
    ({ onboarding }: RootState) => onboarding.isDeletingCoOwner
  );
  const isDeletingCoOwnerCoOwners = useSelector(
    ({ coOwners }: RootState) => coOwners.deleteCoOwner.isDeletingCoOwner
  );
  const isStepLoading = useSelector(
    ({ governance }: RootState) => governance.isStepLoading
  );
  const isDeletingCoOwner =
    isDeletingCoOwnerOnboarding || isDeletingCoOwnerCoOwners;

  const showLoader = isDeletingCoOwner || isStepLoading;

  const content = (
    <div className="confirmation-modal">
      {modal.onCloseButtonClick && (
        <div
          onClick={() =>
            !isDeletingCoOwner && !isStepLoading && modal.onCloseButtonClick?.()
          }
          className={`close-modal ${showLoader ? "disabled" : ""}`}
        >
          <i className="icon-cross" />
        </div>
      )}
      {modal.iconClass && <i className={modal.iconClass} />}
      {modal.title && <span className="modal-title">{modal.title}</span>}
      {modal.description && modal.description}

      <div className="button-center">
        {modal.leftButtonText && (
          <button
            onClick={() =>
              !isDeletingCoOwner &&
              !isStepLoading &&
              modal.onLeftButtonClick?.()
            }
            className={`${showLoader ? "loading-button" : ""}`}
          >
            {showLoader ? "" : modal.leftButtonText}
          </button>
        )}
        {modal.rightButtonText && (
          <button
            onClick={() =>
              !isDeletingCoOwner &&
              !isStepLoading &&
              modal.onRightButtonClick?.()
            }
            className={`tertiary-button ${showLoader ? "disabled" : ""}`}
            disabled={isDeletingCoOwner}
          >
            {modal.rightButtonText}
          </button>
        )}
      </div>
    </div>
  );

  return <ModalLayout content={content} />;
}

export default ConfirmationModal;
