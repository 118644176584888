import { all } from "redux-saga/effects";
import authSaga from "./auth";
import governanceSaga from "./governance";
import onboardingSaga from "./onboarding";
import coOwnersSaga from "./coOwners";
import notificationsSaga from "./notifications";
import chatSaga from "./chat";
import dashboardSaga from "./dashboard";
import signatureSaga from "./signature";
import vaultSaga from "./vault";
import globalSaga from "./global";
import profileSaga from "./profile";

export default function* rootSaga() {
  yield all([
    authSaga(),
    globalSaga(),
    onboardingSaga(),
    governanceSaga(),
    coOwnersSaga(),
    notificationsSaga(),
    chatSaga(),
    dashboardSaga(),
    signatureSaga(),
    vaultSaga(),
    profileSaga(),
  ]);
}
