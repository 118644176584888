import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import { DocumentVaultPermission } from "src/utils/helpers/permissions";
import VaultContent from "./VaultContent";
import VaultDocument from "./VaultDocument";
import VaultDocumentHistory from "./VaultDocumentHistory";

function DocumentVault() {
  // @ts-ignore
  const permission = useSelector(({ auth }: RootState) => auth.permission);
  const { selectedDocumentId } = useSelector(({ vault }: RootState) => vault);

  if (permission === DocumentVaultPermission.DOCUMENT && selectedDocumentId)
    return <VaultDocument id={selectedDocumentId} />;
  if (
    permission === DocumentVaultPermission.DOCUMENT_HISTORY &&
    selectedDocumentId
  )
    return <VaultDocumentHistory id={selectedDocumentId} />;
  else return <VaultContent />;
}

export default DocumentVault;
