export type AvatarType = {
  firstName: string;
  lastName: string;
  isChecked?: boolean;
};

function Avatar({ firstName, lastName, isChecked }: AvatarType) {
  const initials = `${firstName[0] ? firstName[0] : ""}${
    lastName[0] ? lastName[0] : ""
  }`.toUpperCase();

  const circleClass =
    isChecked === undefined
      ? "circle"
      : isChecked
      ? "circle checked"
      : "circle unchecked";

  return (
    <div className="avatar">
      <div className={circleClass}>{initials}</div>
      {isChecked && <div className="icon-checked" />}
    </div>
  );
}

export default Avatar;
