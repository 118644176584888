//@ts-nocheck
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/buttons/Button";
import IconTextButton from "src/components/buttons/IconTextButton";
import Address from "src/components/inputs/AddressInput/Address";
import DateInput from "src/components/inputs/DateInput";
import PhoneInput from "src/components/inputs/PhoneInput";
import SearchAddressInput from "src/components/inputs/SearchAddressInput";
import Select from "src/components/inputs/Select";
import TextInput from "src/components/inputs/TextInput";
import {
  MODAL_CONFIRMATION,
  MODAL_TOAST,
} from "src/components/modals/modalTypes";
import { suffixOptions } from "src/components/shared/constants";
import { DeviceType, ToastType } from "src/components/shared/enums";
import { deleteCoOwner } from "src/store/actions/coOwners";
import { setMainHeader } from "src/store/actions/global";
import { hideModal, showModal } from "src/store/actions/modal";
import { setEditingProfile, updateUserInfo } from "src/store/actions/profile";
import { RootState } from "src/store/reducers";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import { fromStepDataToStringDate } from "src/utils/helpers/dates";
import { UserType } from "src/services/Api/types";
import Banner from "src/components/Banner";
import { Platform } from "src/services/Api/enums";
import { maskPhoneNumber } from "src/utils/helpers/masks";

type MyProfileProps = {
  onEnterCodeButtonClick: (platform: Platform) => void;
};

function MyProfile({ onEnterCodeButtonClick }: MyProfileProps) {
  const dispatch = useDispatch();
  const session = useSelector(({ auth }: RootState) => auth.session);
  const { isEditingProfile, isUpdatingProfile } = useSelector(
    ({ profile }: RootState) => profile
  );
  const { pendingConsensusActions } = useSelector(
    ({ signature }: RootState) => signature.coOwners
  );

  const deviceType = useDeviceType();
  const initialState = {
    email: session?.user?.email || "",
    firstName: session?.user?.firstName || "",
    lastName: session?.user?.lastName || "",
    middleInitial: session?.user?.middleInitial || "",
    suffix: session?.user?.suffix || "",
    dateOfBirth: session?.user?.dateOfBirth || "",
    phoneNumber: session?.user?.phoneNumber || "",
    address: {
      address: session?.user?.address.street_address || "",
      vicinity: session?.user?.address.vicinity || "",
      county: session?.user?.address.county || "",
      city: session?.user?.address.locality || "",
      zipCode: session?.user?.address.postal_code || "",
      state: session?.user?.address.region || "",
      country: session?.user?.address.country || "",
    },
  };
  const [user, setUser] = useState<UserType>(initialState);

  useEffect(() => {
    return () => {
      dispatch(setEditingProfile(false));
    };
  }, []);

  useEffect(() => {
    dispatch(
      setMainHeader({
        showMenuDrawerOnDesktop: true,
        title: "My profile",
        showHamburgerButton: true,
        showEditProfileButton: !isEditingProfile,
        showDeleteAccountButton: isEditingProfile,
        showNotificationsButton: true,
      })
    );
  }, [isEditingProfile]);

  useEffect(() => {
    !isUpdatingProfile && setUser(initialState);
  }, [isUpdatingProfile]);

  const openDeleteAccountModal = () => {
    pendingConsensusActions.eventId
      ? dispatch(
          showModal(MODAL_TOAST, {
            message:
              "You can only add or delete one co-owner at a time. <b>Check the current modification on the Co-owners menu.</b>",
            type: ToastType.Info,
            autoDeleteTime: 15000,
          })
        )
      : dispatch(
          showModal(MODAL_CONFIRMATION, {
            title: `Are you sure you want to delete your account?`,
            description: "",
            leftButtonText: "Yes",
            rightButtonText: "Cancel",

            onLeftButtonClick: () =>
              dispatch(
                deleteCoOwner(
                  session!.user!.id,
                  session!.user!.isSignupCompleted,
                  true,
                  session!.user!.firstName,
                  session!.user!.lastName
                )
              ),
            onRightButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
            onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
          })
        );
  };

  const disableInputs = !isEditingProfile || isUpdatingProfile;
  const isDesktop = deviceType === DeviceType.Desktop;

  return (
    <div className="profile-wrapper">
      <div className="profile">
        {isDesktop && (
          <div className="desktop-header">
            <h2>My profile</h2>{" "}
            {isEditingProfile ? (
              <IconTextButton
                onClick={openDeleteAccountModal}
                text="Delete account"
                iconClass={"icon-trash"}
              />
            ) : (
              <IconTextButton
                onClick={() => dispatch(setEditingProfile(true))}
                text="Edit"
                iconClass={"icon-edit"}
              />
            )}
          </div>
        )}

        {(session?.user?.challenges?.email ||
          session?.user?.challenges?.phone) && (
          <div className="banners">
            {Object.entries(session?.user?.challenges).map(
              ([platform, challengeRequest], index) => (
                <Banner key={index} iconClass="icon-alert" className="warning">
                  <>
                    {`We sent a verification code to ${
                      platform === "email"
                        ? challengeRequest.newValue
                        : maskPhoneNumber(challengeRequest.newValue)
                    }. `}
                    <button
                      type={"button"}
                      className="inline-button"
                      onClick={() =>
                        onEnterCodeButtonClick(
                          platform === "email" ? Platform.EMAIL : Platform.SMS
                        )
                      }
                    >
                      Enter code
                    </button>
                  </>
                </Banner>
              )
            )}
          </div>
        )}

        <form onSubmit={(e) => e.preventDefault()}>
          <div className="column">
            <TextInput
              id={"firstName"}
              label={"First Name"}
              name={"firstName"}
              value={user.firstName}
              isDisabled={disableInputs}
              onChange={(firstName) => {
                setUser({ ...user, firstName });
              }}
            />
            <TextInput
              id="mi"
              name="mi"
              label="M.I."
              value={user.middleInitial}
              isDisabled={disableInputs}
              onChange={(middleInitial) => setUser({ ...user, middleInitial })}
            />
            <TextInput
              id="lastName"
              name="lastName"
              label="Last Name"
              value={user.lastName}
              isDisabled={disableInputs}
              onChange={(lastName) => setUser({ ...user, lastName })}
            />
            <Select
              id="suffix"
              label="Suffix, if applicable"
              options={suffixOptions}
              selectedId={user.suffix}
              isDisabled={disableInputs}
              onChange={(suffix) => setUser({ ...user, suffix })}
            />
          </div>
          <div className="column">
            <PhoneInput
              id="phoneNumber"
              name="phoneNumber"
              label="Phone number"
              value={user.phoneNumber}
              isDisabled={disableInputs}
              onChange={(phoneNumber) => setUser({ ...user, phoneNumber })}
            />
            <TextInput
              id="email"
              name="email"
              label="Email"
              value={user.email}
              isDisabled={disableInputs}
              onChange={(email) => setUser({ ...user, email })}
            />
            <DateInput
              id="birthDate"
              name="birthDate"
              label="Date of birth"
              value={fromStepDataToStringDate(user.dateOfBirth)}
              isDisabled={true}
            />
            <SearchAddressInput
              id="address"
              name="address"
              label="Address"
              onChange={(address) => setUser({ ...user, address })}
              address={user.address.address}
              isDisabled={disableInputs}
            />
          </div>
          <div className="column">
            <Address isDisabled={disableInputs} address={user.address} />
          </div>
        </form>
        {isEditingProfile && (
          <div className="buttons-profile">
            <Button
              text="Save"
              onClick={() =>
                !isUpdatingProfile && dispatch(updateUserInfo(user))
              }
              isDisabled={isUpdatingProfile}
              isLoading={isUpdatingProfile}
            />
            <Button
              className="tertiary-button"
              text="Cancel"
              onClick={() => {
                if (!isUpdatingProfile) {
                  setUser(initialState);
                  dispatch(setEditingProfile(false));
                }
              }}
              isDisabled={isUpdatingProfile}
              isLoading={false}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MyProfile;
