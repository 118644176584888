import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import ModalLayout from "../ModalLayout";
import AddUser from "src/components/AddUser";
import { GroupInvitationMember } from "src/services/Api/types";
import parse from "html-react-parser";

export type AddUserModalProps = {
  title?: string;
  description?: string;
  buttonText?: string;
  onButtonClick?: (coOwner: GroupInvitationMember) => void;
  onCloseButtonClick?: () => void;
};

function AddUserModal() {
  const modal = useSelector((state: RootState) => state.modal.addUser);
  const isAddingCoOwner = useSelector(
    (state: RootState) => state.onboarding.isAddingCoOwner
  );

  const content = (
    <div className="add-user-modal">
      {modal.onCloseButtonClick && (
        <div
          onClick={() => modal.onCloseButtonClick?.()}
          className={`close-modal ${isAddingCoOwner ? "disabled" : ""}`}
        >
          <i className="icon-cross" />
        </div>
      )}
      <h4>{modal.title || ""}</h4>
      <p>{parse(modal.description || "")}</p>
      <AddUser
        isAddingUser={isAddingCoOwner}
        onButtonClick={(user) =>
          !isAddingCoOwner && modal.onButtonClick?.(user)
        }
      />
    </div>
  );

  return <ModalLayout content={content} />;
}

export default AddUserModal;
