import { useContext, useState } from "react";
import Step from "src/components/MultiStepForm/Step";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import RadioButtonGroup from "src/components/inputs/RadioButtonGroup";
import { YesNoOptions } from "src/services/Api/enums";
import { SignUpFormData } from "src/pages/Onboarding/signUp/Leader";
import {
  SegmentEvents,
  SegmentResponseStatus,
} from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";

const yesNoOptions = [
  { id: YesNoOptions.Yes, value: "Yes, I am listed on Title" },
  { id: YesNoOptions.No, value: "No, I am not listed on Title" },
];

function CoOwnersTitleData({
  formData,
  nextStep,
  prevStep,
  saveData,
  progress,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const [stepData, setStepData] = useState(formData.coOwnersTitle);
  const [failed, setFailed] = useState(false);

  const onNextClick = () => {
    if (!!stepData) {
      const isCandidateQualified = stepData === YesNoOptions.Yes;

      segmentTrack(SegmentEvents.MemberPreSignUpStepSubmitted, {
        step: "Are you listed on Title?",
        data: stepData,
        status: isCandidateQualified
          ? SegmentResponseStatus.Success
          : SegmentResponseStatus.Error,
      });

      if (isCandidateQualified) {
        saveData({ coOwnersTitle: stepData });
        nextStep();
      } else {
        setFailed(true);
      }
    }
  };

  return (
    <Step
      title="Are you listed on Title?"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={!stepData}
      onBackClick={() => prevStep()}
      progress={progress}
      failed={failed}
      failedDescription="During our beta phase, we're focused on helping co-owner groups where everyone is listed on Title. "
      tooltip={`<p>
            Holding <strong>Title</strong> to a property conveys ownership
            rights.
          </p>
          <p>
            In most cases, an individual or group of individuals who hold Title
            to real estate are listed on a registered property deed (or similar
            legal document).
          </p>`}
    >
      <RadioButtonGroup
        options={yesNoOptions}
        selectedId={stepData}
        onChange={(value: string) => setStepData(value)}
      />
    </Step>
  );
}

export default CoOwnersTitleData;
