import { CommentsType, PopoverGuideType } from "src/components/shared/enums";
import { CommentType } from "src/components/shared/types";
import { Pagination } from "src/services/Api/types";
import { isEmpty } from "src/utils/helpers/helpers";
import {
  SET_FULL_PAGE_LOADING,
  SET_IS_LOADING_USER_DATA,
  SET_FORM_STATE,
  SET_MENU_DRAWER,
  SET_COMMENTS_DRAWER,
  SET_POPOVER_GUIDE,
  FormState,
  SetCommentsDrawerAction,
  SetFullPageLoadingAction,
  SetIsLoadingUserDataAction,
  SetFormStateAction,
  SetMenuDrawerAction,
  SetPopoverGuideAction,
  SetMainHeaderAction,
  SET_MAIN_HEADER,
  MainHeaderType,
  GetNextPageCommentsAction,
  GetNextPageCommentsRejectedAction,
  GetNextPageCommentsSuccessAction,
  GET_NEXT_PAGE_COMMENTS,
  GET_NEXT_PAGE_COMMENTS_REJECTED,
  GET_NEXT_PAGE_COMMENTS_SUCCESS,
  RefreshFirstPageCommentsAction,
  RefreshFirstPageCommentsRejectedAction,
  RefreshFirstPageCommentsSuccessAction,
  REFRESH_FIRST_PAGE_COMMENTS,
  REFRESH_FIRST_PAGE_COMMENTS_REJECTED,
  REFRESH_FIRST_PAGE_COMMENTS_SUCCESS,
  SendCommentAction,
  SendCommentRejectedAction,
  SendCommentSuccessAction,
  SEND_COMMENT,
  SEND_COMMENT_REJECTED,
  SEND_COMMENT_SUCCESS,
  SetShowSwipeLoaderAction,
  SET_SHOW_SWIPE_LOADER, SEND_COMMENT_RECEIVED, SendCommentReceivedAction
} from "../actions/global";

export const initialState: {
  isFullPageLoading: boolean;
  fullPageLoadingText?: string;
  mainHeader: MainHeaderType;
  isLoadingUserData: boolean;
  menuDrawer: {
    isOpened: boolean;
  };
  commentsDrawer: {
    isOpened: boolean;
    id: string | null;
    type: CommentsType | null;
    comments: {
      value: Pagination<CommentType>;
      hasNewComments: boolean;
      isRefreshingFirstPage: boolean;
      isGettingNextPage: boolean;
      showSwipeLoader: boolean;
      isSendingComment: boolean;
      sendCommentSuccess: boolean | null;
      sendCommentRejected: boolean | null;
    };
  };
  activePopoverGuide: PopoverGuideType | null;
  stepData: Partial<FormState>;
} = {
  isFullPageLoading: false,
  mainHeader: {
    hide: false,
    hideHeaderOnMobile: false,
    title: null,
    showLogo: false,
    showHamburgerButton: false,
    showMenuDrawerOnDesktop: false,
    showStepStatus: false,
    showDocumentStatus: false,
    avatars: [],
    showEditProfileButton: false,
    showDeleteAccountButton: false,
    showNotificationsButton: false,
    showAddDocumentButton: false,
    progress: null,
    hideBottomLine: false,
    darkMode: false,
  },
  isLoadingUserData: true,
  menuDrawer: {
    isOpened: false,
  },
  commentsDrawer: {
    isOpened: false,
    id: null,
    type: null,
    comments: {
      value: {
        count: 0,
        currentPage: 1,
        data: null,
        lastPage: null,
        nextPage: null,
        prevPage: null,
      },
      hasNewComments: false,
      isRefreshingFirstPage: false,
      isGettingNextPage: false,
      showSwipeLoader: false,
      isSendingComment: false,
      sendCommentSuccess: null,
      sendCommentRejected: null
    },
  },
  activePopoverGuide: null,
  stepData: {},
};

export function globalReducer(
  state = initialState,
  action:
    | SetFullPageLoadingAction
    | SetFormStateAction
    | SetIsLoadingUserDataAction
    | SetMenuDrawerAction
    | SetCommentsDrawerAction
    | SetPopoverGuideAction
    | SetMainHeaderAction
    | GetNextPageCommentsAction
    | GetNextPageCommentsSuccessAction
    | GetNextPageCommentsRejectedAction
    | RefreshFirstPageCommentsAction
    | RefreshFirstPageCommentsSuccessAction
    | RefreshFirstPageCommentsRejectedAction
    | SendCommentAction
    | SendCommentSuccessAction
    | SendCommentRejectedAction
    | SendCommentReceivedAction
    | SetShowSwipeLoaderAction
) {
  switch (action.type) {
    case SET_FULL_PAGE_LOADING:
      return {
        ...state,
        isFullPageLoading: action.payload.isLoading,
        fullPageLoadingText: action.payload.text,
      };
    case SET_IS_LOADING_USER_DATA:
      return {
        ...state,
        isLoadingUserData: action.payload,
      };
    case SET_MENU_DRAWER:
      return {
        ...state,
        menuDrawer: { ...state.menuDrawer, isOpened: action.payload.isOpened },
      };
    case SET_MAIN_HEADER:
      return {
        ...state,
        mainHeader: { ...initialState.mainHeader, ...action.payload },
      };
    case SET_COMMENTS_DRAWER:
      return {
        ...state,
        commentsDrawer: { ...state.commentsDrawer, ...action.payload },
      };
    case SET_POPOVER_GUIDE:
      return {
        ...state,
        activePopoverGuide: action.payload,
      };
    case SET_FORM_STATE:
      return {
        ...state,
        stepData: {
          formName: action.payload.formName || state.stepData.formName,
          formData: action.payload.formData
            ? isEmpty(action.payload.formData)
              ? {}
              : { ...state.stepData.formData, ...action.payload.formData }
            : state.stepData.formData,
          formStep: action.payload.formStep || state.stepData.formStep,
        },
      };
    case SET_SHOW_SWIPE_LOADER:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            showSwipeLoader: action.payload,
          },
        },
      };
    case GET_NEXT_PAGE_COMMENTS:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            isGettingNextPage: true,
          },
        },
      };
    case GET_NEXT_PAGE_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            value: action.payload.comments,
            isGettingNextPage: false,
          },
        },
      };
    case GET_NEXT_PAGE_COMMENTS_REJECTED:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            isGettingNextPage: false,
          },
        },
      };
    case REFRESH_FIRST_PAGE_COMMENTS:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            isRefreshingFirstPage: true,
          },
        },
      };
    case REFRESH_FIRST_PAGE_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            value: action.payload.comments,
            hasNewComments: action.payload.hasNewComments,
            isRefreshingFirstPage: false,
          },
        },
      };
    case REFRESH_FIRST_PAGE_COMMENTS_REJECTED:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            isRefreshingFirstPage: false,
          },
        },
      };
    case SEND_COMMENT:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            isSendingComment: true
          },
        },
      };
    case SEND_COMMENT_SUCCESS:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            isSendingComment: false,
            sendCommentSuccess: true
          },
        },
      };
    case SEND_COMMENT_RECEIVED:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            sendCommentRejected: null,
            sendCommentSuccess: null
          },
        },
      };
    case SEND_COMMENT_REJECTED:
      return {
        ...state,
        commentsDrawer: {
          ...state.commentsDrawer,
          comments: {
            ...state.commentsDrawer.comments,
            isSendingComment: false,
            sendCommentRejected: true
          },
        },
      };
    default:
      return state;
  }
}
