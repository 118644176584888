import React from "react";
import { acceptedFileTypes } from "../shared/constants";

type FileUploaderProps = {
  onChange: (file: any) => void;
  file: File | null;
  isDisabled?: boolean;
  isValid?: boolean;
  errorMessage?: string;
};

function FileUploader({
  onChange,
  file,
  isDisabled,
  errorMessage,
  isValid,
}: FileUploaderProps) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (e: any) => {
    !file && hiddenFileInput.current?.click();
  };

  const handleChange = (e: any) => {
    if (!isDisabled) {
      const fileUploaded = e.target.files[0];
      if (fileUploaded) {
        onChange(fileUploaded);
      }
    }
  };

  const getStringSize = (size: number): string => {
    return size >= 1000000
      ? `${Math.round(size / 10000) / 100}MB`
      : `${Math.round(size / 100) / 10}KB`;
  };

  const errorClass = isValid ? "" : "error";
  const fileClass = file ? "with-file" : "";
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <div className="file-uploader">
      <button
        className={`${fileClass} ${errorClass} ${disabledClass}`}
        onClick={handleClick}
        disabled={isDisabled}
        type="button"
      >
        {file ? (
          <>
            <p>{file.name}</p>
            <p className="file-size">{getStringSize(file.size)}</p>
            <i
              className={`icon-cross ${disabledClass}`}
              onClick={() => {
                !isDisabled && onChange(null);
              }}
            />
          </>
        ) : (
          <p>
            <i className="icon-upload-version" />
            Upload a file
          </p>
        )}
      </button>

      <input
        type="file"
        hidden
        ref={hiddenFileInput}
        onChange={handleChange}
        accept={acceptedFileTypes
          .map((fileType) => fileType.extension)
          .join(",")}
      />
      {!isValid && <div className="invalid-input">{errorMessage}</div>}
    </div>
  );
}

export default FileUploader;
