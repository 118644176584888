type IconTextButtonProps = {
  onClick: () => void;
  text: string;
  iconClass: string;
  isDisabled?: boolean;
};

function IconTextButton({
  onClick,
  text,
  iconClass,
  isDisabled = false,
}: IconTextButtonProps) {
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <button
      className={`icon-text-button ${disabledClass}`}
      type="button"
      onClick={onClick}
    >
      <i className={`${iconClass} ${disabledClass}`} />
      <span className={disabledClass}>{text}</span>
    </button>
  );
}

export default IconTextButton;
