import Avatar, { AvatarType } from "../Avatar";
import { PopoverGuideType } from "../shared/enums";
import PopoverGuide from "../popover/PopoverGuide";

type AvatarsProps = {
  avatars: Array<AvatarType>;
  isOnGovernance?: boolean;
};

function Avatars({ avatars, isOnGovernance }: AvatarsProps) {
  if (avatars.length === 0) return <></>;

  return (
    <div className="avatars-container">
      {avatars.map((avatar, index) => (
        (index === avatars.length - 1 && isOnGovernance) ? (<PopoverGuide
          type={PopoverGuideType.Avatar}
          content="Avatars show who’s responded."
          nextPopoverType={null}
          children={<Avatar
            key={index}
            firstName={avatar.firstName}
            lastName={avatar.lastName}
            isChecked={avatar.isChecked}
          />}
        />) : (
          <Avatar
            key={index}
            firstName={avatar.firstName}
            lastName={avatar.lastName}
            isChecked={avatar.isChecked}
          />)
      ))}
    </div>
  );
}

export default Avatars;
