import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_CONFIRMATION } from "src/components/modals/modalTypes";
import { menuOptionIcons } from "src/components/shared/constants";
import { MenuOptions } from "src/components/shared/enums";
import { logout, updatePermissions } from "src/store/actions/auth";
import { getChatGroup } from "src/store/actions/chat";
import { setCommentsDrawer, setMenuDrawer } from "src/store/actions/global";
import { cancelStepEdition, confirmStep, setEditionMode } from "src/store/actions/governance";
import { hideModal, showModal } from "src/store/actions/modal";
import { RootState } from "src/store/reducers";
import useInterval from "src/utils/customHooks/useInterval";
import {
  ChatPermission,
  CoOwnersPermission,
  DashboardPermission,
  DocumentVaultPermission,
  GovernancePermission,
  Page,
  ProfilePermission
} from "src/utils/helpers/permissions";
import { getMenuFromPage } from "src/utils/helpers/routing";
import MenuButton from "./MenuButton";
import { UserAccountDto } from "../../../services/Api/types";

function MenuDrawer() {
  const dispatch = useDispatch();
  const { session, permissions, page, permission }: any = useSelector(
    ({ auth }: RootState) => auth
  );
  const {
    menuDrawer: { isOpened },
    mainHeader: { hide, showMenuDrawerOnDesktop },
  } = useSelector(({ global }: RootState) => global);
  const { value: chatGroups, selectedChatGroup } = useSelector(
    ({ chat }: RootState) => chat.chatGroups
  );

  const { onEditionMode, stepValidation, currentSectionStep } = useSelector(
    ({ governance }: RootState) => governance
  );

  const [activeMenu, setActiveMenu] = useState<string>();
  const [topMenuOptions, setTopMenuOptions] = useState<Array<MenuOptions> | []>(
    []
  );
  const [bottomMenuOptions, setBottomMenuOptions] = useState<
    Array<MenuOptions> | []
  >([]);

  useEffect(() => {
    const pages = Object.keys(permissions ?? {}).filter(
      (page) => page !== Page.PROFILE && page !== Page.ONBOARDING
    );
    const menuOptions = pages.map(
      (page) => getMenuFromPage[page] as MenuOptions
    );
    setTopMenuOptions(menuOptions);
    if (!pages.includes(Page.ONBOARDING))
      setBottomMenuOptions([MenuOptions.MyProfile, MenuOptions.Status, MenuOptions.LogOut]);
  }, [permissions]);

  useEffect(() => {
    setActiveMenu(getMenuFromPage[page ?? ""] as MenuOptions);
  }, [page]);

  /* NOTIFICATIONS EVERY 11 seconds */
  useInterval(() => {
    const chatExists =
      topMenuOptions.length > 0 &&
      (topMenuOptions as string[]).includes(MenuOptions.Chat);
    chatExists && !selectedChatGroup && dispatch(getChatGroup(true));
  }, 11000);

  const resetOptions = (menuOption: MenuOptions) => {
    dispatch(setEditionMode(false));
    dispatch(setCommentsDrawer({ isOpened: false }));
    switch (menuOption) {
      case MenuOptions.LogOut:
        dispatch(logout());
        break;
      case MenuOptions.MyProfile:
        dispatch(
          updatePermissions({
            page: Page.PROFILE,
            permission: ProfilePermission.MY_PROFILE,
          })
        );
        dispatch(setMenuDrawer(false));
        break;
      case MenuOptions.Dashboard:
        dispatch(
          updatePermissions({
            page: Page.DASHBOARD,
            permission: DashboardPermission.DASHBOARD,
          })
        );
        dispatch(setMenuDrawer(false));
        break;
      case MenuOptions.DocumentVault:
        dispatch(
          updatePermissions({
            page: Page.DOCUMENT_VAULT,
            permission: DocumentVaultPermission.DOCUMENT_VAULT,
          })
        );
        dispatch(setMenuDrawer(false));
        break;
      case MenuOptions.Chat:
        dispatch(
          updatePermissions({
            page: Page.CHAT,
            permission: ChatPermission.CHAT,
          })
        );
        dispatch(setMenuDrawer(false));
        break;
      case MenuOptions.CoOwnershipAgreement:
        if (session?.user?.hasSignedMemoOfAgreement)
          dispatch(
            updatePermissions({
              page: Page.GOVERNANCE,
              permission: GovernancePermission.MEMORANDUM_OF_AGREEMENT_STATUS,
            })
          );
        else if (session?.user?.hasSignedCoAgreement)
          dispatch(
            updatePermissions({
              page: Page.GOVERNANCE,
              permission: GovernancePermission.CO_OWNERSHIP_AGREEMENT_STATUS,
            })
          );
        else
          dispatch(
            updatePermissions({
              page: Page.GOVERNANCE,
              permission: GovernancePermission.SECTIONS_STATUS,
            })
          );
        dispatch(setMenuDrawer(false));
        break;
      case MenuOptions.CoOwners:
        dispatch(
          updatePermissions({
            page: Page.CO_OWNERS,
            permission: CoOwnersPermission.CO_OWNERS,
          })
        );
        dispatch(setMenuDrawer(false));
        break;
      case MenuOptions.Status:
        window.open('https://status.cobuy.io', '_blank')
        dispatch(setMenuDrawer(false));
        break;
      // case MenuOptions.Reset:
      //   dispatch(setFullPageLoading(true))
      //   Api.resetGroupAsaAppTxn()
      //     .then(() => {
      //       dispatch(setFullPageLoading(false))
      //       dispatch(setMenuDrawer(false));
      //     });
      //   break;
      default:
        break;
    }
  };

  const showSaveAndConfirmModal = (menuOption: MenuOptions) => {
    dispatch(
      showModal(MODAL_CONFIRMATION, {
        title: "Do you want to save and confirm the changes before leaving?",
        leftButtonText: "Yes",
        onLeftButtonClick: () => {
          const atLeastOneMemberSignedCOA = session?.group?.users.find(
            (user: UserAccountDto) => user.hasSignedCoAgreement
          );
          if (atLeastOneMemberSignedCOA) {
            dispatch(hideModal(MODAL_CONFIRMATION));
            openConfirmationModal(menuOption);
          } else
            dispatch(confirmStep(true, false, () => resetOptions(menuOption)));
        },
        onRightButtonClick: () => {
          dispatch(cancelStepEdition());
          resetOptions(menuOption);
        },
        rightButtonText: "Don't save",
        onCloseButtonClick: () => {
          dispatch(hideModal(MODAL_CONFIRMATION));
        },
      })
    );
  };

  const openConfirmationModal = (menuOption: MenuOptions) =>
    dispatch(
      showModal(MODAL_CONFIRMATION, {
        title: "Are you sure you want to make this change?",
        description: (
          <p>
            If you make this change, all co-owners will have to re-execute your
            Co-ownership Agreement for it to take effect. Make any other
            necessary changes before re-executing (signing).
          </p>
        ),
        rightButtonText: "Cancel",
        onRightButtonClick: () => {
          dispatch(cancelStepEdition());
          resetOptions(menuOption);
        },
        leftButtonText: "Apply change",
        onLeftButtonClick: () => {
          dispatch(confirmStep(true, true, () => resetOptions(menuOption)));
        },
        onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
      })
    );

  const onMenuOptionClick = (menuOption: MenuOptions) => {
    if (
      (permission === DashboardPermission.SECTION_STEPS ||
        permission === GovernancePermission.SECTION_STEPS) &&
      onEditionMode &&
      stepValidation?.isValid &&
      currentSectionStep?.stepConfirmedBy?.length !== 0
    ) {
      showSaveAndConfirmModal(menuOption);
      dispatch(setMenuDrawer(false));
    } else {
      resetOptions(menuOption);
    }
  };

  const menuDrawerClass = isOpened ? "menu-drawer open" : "menu-drawer";

  if (hide || !showMenuDrawerOnDesktop) return <></>;

  return (
    <div className={menuDrawerClass}>
      <div
        className="menu-drawer-background"
        onClick={() => dispatch(setMenuDrawer(false))}
      ></div>
      <div className="menu-drawer-content">
        <div className="menu-header">
          <p className="address">
            {session?.group?.propertyAddress.street_address}
          </p>
          <p className="city">
            {session?.group?.propertyAddress.locality},{" "}
            {session?.group?.propertyAddress.region}
          </p>
        </div>
        <div className="menu-options">
          <ul className="menu-options-top">
            {topMenuOptions?.map((menuOption) => (
              <MenuButton
                key={menuOption}
                text={menuOption}
                onClick={() => onMenuOptionClick(menuOption)}
                iconClass={menuOptionIcons[menuOption]}
                isActive={menuOption === activeMenu}
                hasNotifications={
                  menuOption === MenuOptions.Chat
                    ? !!chatGroups.find(
                        (chatGroup) => chatGroup.newMessages > 0
                      )
                    : false
                }
              />
            ))}
          </ul>
          <ul></ul>
          <ul className="menu-options-bottom">
            {bottomMenuOptions?.map((menuOption) => (
              <MenuButton
                key={menuOption}
                text={menuOption}
                onClick={() => onMenuOptionClick(menuOption)}
                iconClass={menuOptionIcons[menuOption]}
                isActive={menuOption === activeMenu}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuDrawer;
