import Spinner from "../Spinner";
type FullPageLoadingProps = { loading?: boolean; text?: string };

function FullPageLoading({ loading, text }: FullPageLoadingProps) {
  return (
    <div className={`full-page-loading ${loading ? "show" : ""}`}>
      <div className="full-page-loading-content">
        <Spinner />
        {text && <p>{text}</p>}
      </div>
    </div>
  );
}

export default FullPageLoading;
