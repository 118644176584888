import coBuyLogo from "src/assets/img/coBuyLogo.svg";

type BannerProps = {
  className?: string;
  iconClass?: string;
  children: React.ReactNode;
};

function Banner({ className = "", iconClass, children }: BannerProps) {
  return (
    <div className={`banner ${className}`}>
      {!iconClass && <img src={coBuyLogo} alt="CoBuy logo" />}
      <p className={`${!!iconClass ? `icon ${iconClass}` : ""}`}>{children}</p>
    </div>
  );
}

export default Banner;
