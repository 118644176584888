import { useDispatch, useSelector } from "react-redux";
import IconTextButton from "src/components/buttons/IconTextButton";
import { MODAL_CONFIRMATION } from "src/components/modals/modalTypes";
import UsersList from "src/components/UsersList";
import { CoOwner, UserAccountDto } from "src/services/Api/types";
import { setCoOwnersStep } from "src/store/actions/coOwners";
import { hideModal, showModal } from "src/store/actions/modal";
import { RootState } from "src/store/reducers";
import Banner from "src/components/Banner";
import { CoOwnersStep, DeviceType } from "src/components/shared/enums";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import PendingConsensusBannerContent from "./PendingConsensusBannerContent";
import PendingPaymentBannerContent from "./PendingPaymentBannerContent";

type CoOwnerListProps = {
  user: UserAccountDto;
  coOwners: Array<CoOwner>;
  onCoOwnerClick: Function;
};

function CoOwnersList({ user, coOwners, onCoOwnerClick }: CoOwnerListProps) {
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  // @ts-ignore
  const isSubscribed = useSelector(({ auth }: RootState) => auth.session?.group?.isSubscribed);

  const { pendingConsensusActions } = useSelector(
    ({ signature }: RootState) => signature.coOwners
  );

  const openConfirmationModal = () => {
    if (!pendingConsensusActions.eventId) {
      dispatch(
        showModal(MODAL_CONFIRMATION, {
          title: `Are you sure you want to add a new co-owner to your group?`,
          description: (
            <p>
              Adding a co-owner means you’ll need to re-create your Co-ownership
              Agreement.
            </p>
          ),
          leftButtonText: "Yes",
          rightButtonText: "Cancel",
          onLeftButtonClick: () => {
            dispatch(setCoOwnersStep(CoOwnersStep.FillNewCoOwnerData));
            dispatch(hideModal(MODAL_CONFIRMATION));
          },
          onRightButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
          onCloseButtonClick: () => dispatch(hideModal(MODAL_CONFIRMATION)),
        })
      );
    }
  };

  const showAddCoOwnerButton = coOwners.length < 4;
  const isDesktop = deviceType === DeviceType.Desktop;

  return (
    <div className="co-owners-wrapper">
      <div className="co-owners">
        {isDesktop && (
          <div className="desktop-header">
            <h2>Co-owners</h2>
          </div>
        )}
        {!isSubscribed && (
          <Banner className="warning" iconClass="icon-alert">
            <PendingPaymentBannerContent />
          </Banner>
        )}
        {pendingConsensusActions.eventId && (
          <Banner>
            <PendingConsensusBannerContent coOwners={coOwners} user={user} />
          </Banner>
        )}

        <UsersList
          coOwners={coOwners}
          onClick={onCoOwnerClick}
          pendingConsensusActions={pendingConsensusActions}
        />
        {pendingConsensusActions.eventId && (
          <p>You can only add or delete one co-owner at a time.</p>
        )}
        {showAddCoOwnerButton && (
          <IconTextButton
            onClick={openConfirmationModal}
            text="Add another co-owner"
            iconClass="icon-plus"
            isDisabled={!!pendingConsensusActions.eventId}
          />
        )}
      </div>
    </div>
  );
}

export default CoOwnersList;
