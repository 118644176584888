import Avatar from "src/components/Avatar";
import { localDateAndTimeFromJSON } from "src/utils/helpers/dates";

type CommentMessageProps = {
  name: string;
  lastName: string;
  text?: string;
  createdAt: string;
};

function CommentMessage({
  name,
  lastName,
  text,
  createdAt,
}: CommentMessageProps) {
  return (
    <div className="comment">
      <Avatar firstName={name} lastName={lastName} />
      <div className="message">
        <p className="name">{`${name} ${lastName}`}</p>
        <p className="text">{text}</p>
      </div>
      <div className="timestamp">
        {localDateAndTimeFromJSON(createdAt, true)}
      </div>
    </div>
  );
}

export default CommentMessage;
