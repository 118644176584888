import { useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import DocumentPreviewCard from "src/components/cards/DocumentPreviewCard";
import DesktopNav from "src/components/DesktopNav";
import EmptyState from "src/components/EmptyState";
import { MODAL_ADD_DOCUMENT } from "src/components/modals/modalTypes";
import {
  DeviceType,
  DocumentStatus,
  DocumentType,
} from "src/components/shared/enums";
import Spinner from "src/components/Spinner";
import { DocumentCardType, UploadDocumentType } from "src/services/Api/types";
import { updatePermissions } from "src/store/actions/auth";
import { setMainHeader } from "src/store/actions/global";
import { hideModal, showModal } from "src/store/actions/modal";
import {
  getNextPageDocumentHistory,
  getVaultDocumentHistory,
  getVaultDocumentHistorySuccess,
  setPreviousVaultDocumentPermission,
  setSelectedDocumentId,
  uploadDocument,
} from "src/store/actions/vault";
import { RootState } from "src/store/reducers";
import { initialState } from "src/store/reducers/vault";
import useDeviceType from "src/utils/customHooks/useDeviceType";
import { localDateFromJSON } from "src/utils/helpers/dates";
import { DocumentVaultPermission, Page } from "src/utils/helpers/permissions";

type VaultDocumentHistoryProps = { id: string };

function VaultDocumentHistory({ id }: VaultDocumentHistoryProps) {
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const isDesktop = deviceType === DeviceType.Desktop;
  const {
    isLoading,
    value: vaultDocumentHistory,
    previousPermission,
    previousPage,
  } = useSelector(({ vault }: RootState) => (vault as any).vaultDocumentHistory);

  const divRef = useRef<HTMLDivElement | null>(null);

  const fetchNextPage = () =>
    vaultDocumentHistory.nextPage && dispatch(getNextPageDocumentHistory(id));

  useEffect(() => {
    dispatch(getVaultDocumentHistory(id));
  }, []);

  const document = vaultDocumentHistory.data
    ? vaultDocumentHistory.data[0]
    : undefined;

  useEffect(() => {
    dispatch(
      setMainHeader({
        showMenuDrawerOnDesktop: true,
        title: isDesktop ? undefined : "Document history",
        onBackButtonClick: isDesktop ? undefined : onBackButtonClick,
        showNotificationsButton: true,
        showAddDocumentButton:
          document?.status === DocumentStatus.Agreed &&
          document.type !== DocumentType.CoOwnershipAgreement &&
          document.type !== DocumentType.MemorandumOfAgreement,
      })
    );
  }, [isDesktop, document?.status]);

  const onBackButtonClick = () => {
    dispatch(setSelectedDocumentId(null));
    dispatch(
      updatePermissions({
        page: previousPage,
        permission: previousPermission,
      })
    );
    dispatch(
      getVaultDocumentHistorySuccess(initialState.vaultDocumentHistory.value)
    );
  };

  const showAddDocumentModal = () => {
    dispatch(
      showModal(MODAL_ADD_DOCUMENT, {
        onButtonClick: (document: UploadDocumentType) =>
          dispatch(uploadDocument(document)),
        onCloseButtonClick: () => dispatch(hideModal(MODAL_ADD_DOCUMENT)),
        document,
      })
    );
  };

  return (
    <div
      id="document-history-scroll-content"
      className="document-history"
      ref={divRef}
    >
      <DesktopNav
        title="Document history"
        backButton={{ onBackButtonClick }}
        rightButton={
          document?.status === DocumentStatus.Agreed &&
          document.type !== DocumentType.MemorandumOfAgreement &&
          document.type !== DocumentType.CoOwnershipAgreement
            ? {
                text: "Add new version",
                iconClass: "icon-plus",
                onClick: showAddDocumentModal,
              }
            : undefined
        }
      />
      {isLoading &&
      (vaultDocumentHistory.data === null ||
        vaultDocumentHistory.data?.length === 0) ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : vaultDocumentHistory.data &&
        vaultDocumentHistory.data.length !== 0 ? (
        <InfiniteScroll
          className="document-history-infinite-scroll"
          dataLength={vaultDocumentHistory.data.length}
          next={fetchNextPage}
          hasMore={vaultDocumentHistory.nextPage !== null}
          loader={true && <Spinner />}
          scrollableTarget="document-history-scroll-content"
        >
          {vaultDocumentHistory.data?.map((document: DocumentCardType, index: number) => (
            <DocumentPreviewCard
              key={index}
              name={document.name}
              author={document.addedBy}
              date={localDateFromJSON(document.createdAt)}
              onClick={() => {
                dispatch(setSelectedDocumentId(document.id));
                dispatch(
                  updatePermissions({
                    page: Page.DOCUMENT_VAULT,
                    permission: DocumentVaultPermission.DOCUMENT,
                  })
                );
                dispatch(
                  setPreviousVaultDocumentPermission(
                    Page.DOCUMENT_VAULT,
                    DocumentVaultPermission.DOCUMENT_HISTORY
                  )
                );
              }}
              status={
                document.status === DocumentStatus.Pending ||
                document.status === DocumentStatus.RequiresAction
                  ? document.status
                  : undefined
              }
            />
          ))}
        </InfiniteScroll>
      ) : (
        <EmptyState title="No versions yet" iconClass="icon-check-circled" />
      )}
    </div>
  );
}

export default VaultDocumentHistory;
