import Badge from "../Badge";
import { documentStatusBadgeClass } from "../shared/constants";
import { DocumentStatus } from "../shared/enums";

type DocumentPreviewCardProps = {
  name: string;
  author: string;
  date: string;
  status?: DocumentStatus;
  onClick?: () => void;
};

function DocumentPreviewCard({
  name,
  author,
  date,
  status,
  onClick,
}: DocumentPreviewCardProps) {
  return (
    <div className="document-preview" onClick={onClick}>
      <div className="document-preview-header">
        <h3>{name}</h3>
        {status && (
          <Badge text={status} className={documentStatusBadgeClass[status]} />
        )}
      </div>
      <div className="document-preview-body">
        <p>
          Added by <strong>{author}</strong>
        </p>
        <span>{date}</span>
      </div>
    </div>
  );
}

export default DocumentPreviewCard;
