//@ts-nocheck
import { call, put, select, takeLatest } from "redux-saga/effects";
import { NOTIFICATIONS_TO_TAKE } from "src/components/shared/constants";
import { NotificationType } from "src/components/shared/types";
import Api from "src/services/Api";
import { Pagination } from "src/services/Api/types";
import { mergePagination } from "src/utils/helpers/helpers";
import {
  Page,
  GovernancePermission,
  DashboardPermission,
} from "src/utils/helpers/permissions";
import { updatePermissions } from "../actions/auth";
import {
  getNotificationStatusRejected,
  getNotificationStatusSuccess,
  GET_NOTIFICATION_STATUS,
  getNextPageNotificationsRejected,
  getNextPageNotificationsSuccess,
  GET_NEXT_PAGE_NOTIFICATIONS,
  GetNotificationsAction,
  getNotificationsRejected,
  getNotificationsSuccess,
  setShowSwipeLoader,
  GET_NOTIFICATIONS,
} from "../actions/notifications";
import { RootState } from "../reducers";
import { captureMessage } from "@sentry/react";

export default function* notificationsSaga() {
  yield takeLatest(GET_NOTIFICATION_STATUS, getNotificationStatusSaga);
  yield takeLatest(GET_NOTIFICATIONS, getNotificationsSaga);
  yield takeLatest(GET_NEXT_PAGE_NOTIFICATIONS, getNextPageNotificationsSaga);
}

// GET NOTIFICATION STATUS
function* getNotificationStatusSaga() {
  try {
    const { data } = yield call(Api.getNotificationStatus);
    const { success, ...notificationStatus } = data;
    const page: Page = yield select(({ auth }: RootState) => auth.page);
    yield put(getNotificationStatusSuccess(notificationStatus));
    if (notificationStatus.modalNotifications.length !== 0)
      updatePermissions({
        page,
        permission:
          page === Page.GOVERNANCE
            ? GovernancePermission.SECTIONS_STATUS
            : DashboardPermission.SECTIONS_STATUS,
      });
  } catch (error) {
    captureMessage(JSON.stringify(error))
    yield put(getNotificationStatusRejected());
  }
}

// GET NOTIFICATIONS (REFRESH FIRST PAGE)
export function* getNotificationsSaga({
  payload: isSwipeRefresh,
}: GetNotificationsAction) {
  try {
    if (isSwipeRefresh) yield put(setShowSwipeLoader(true));
    const { data } = yield call(Api.getNotifications, 1, NOTIFICATIONS_TO_TAKE);
    yield put(getNotificationsSuccess(data.notifications));
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(getNotificationsRejected());
  } finally {
    if (isSwipeRefresh) yield put(setShowSwipeLoader(false));
  }
}

// GET NEXT PAGE NOTIFICATIONS
function* getNextPageNotificationsSaga() {
  const currentNotificationPagination: Pagination<NotificationType> =
    yield select(
      ({ notifications }: RootState) => notifications.notifications.value.data
    );
  try {
    const {
      data: { notifications: newNotificationPagination },
    } = yield call(
      Api.getNotifications,
      currentNotificationPagination.nextPage ?? 1,
      NOTIFICATIONS_TO_TAKE
    );

    const notifications = mergePagination<NotificationType>(
      currentNotificationPagination,
      newNotificationPagination
    );

    yield put(getNextPageNotificationsSuccess(notifications));
  } catch (err) {
    captureMessage(JSON.stringify(err))
    yield put(getNextPageNotificationsRejected());
  }
}
