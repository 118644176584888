import { createHash, createHmac } from "crypto";
import { FormSectionCodes, FormSectionStepCodes } from "src/components/shared/enums";
import { CoOwnedHomeAddressType, Pagination, UserAccountDto } from "src/services/Api/types";
import { INTERCOM_VERIFICATION_SECRET } from "../../constants";
import { DecodedJWT } from "../../components/shared/types";

export const isEmpty = (obj: object) => Object.keys(obj).length === 0;

export const sortObjProperties = (obj: object) =>
  JSON.stringify(obj, Object.keys(obj).sort());

export const decodeBase64 = (base64String: string) =>
  JSON.parse(atob(base64String));

export const encodeHash = (data: object) =>
  createHash("sha256").update(sortObjProperties(data)).digest("hex");

export const getFormatAddress = (address: CoOwnedHomeAddressType) => ({
  country: address.country,
  vicinity: address.vicinity,
  county: address.county,
  locality: address.city,
  postal_code: address.zipCode,
  region: address.state,
  street_address: address.address
});

export const addThousandsSeparator = (value: number | string): string =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const shuffleArray = (array: Array<any>) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }
  return array;
};

export function mergePagination<T>(
  currentPagination: Pagination<T>,
  newPagination: Pagination<T>
) {
  const repeatedData = newPagination.count - currentPagination.count;

  if (newPagination.data === null) return newPagination;

  const newPaginationWithoutRepetition =
    currentPagination.count === 0
      ? newPagination.data
      : repeatedData - newPagination.data.length >= 0
        ? []
        : newPagination.data.slice(repeatedData - newPagination.data.length);

  return currentPagination.nextPage === 1
    ? newPagination
    : {
      ...newPagination,
      data: [
        ...(currentPagination.data || []),
        ...newPaginationWithoutRepetition
      ]
    };
}

export const getSectionCodeFromStepCode = (
  stepCode: FormSectionStepCodes
): FormSectionCodes => {
  return stepCode.split("_")[0] as FormSectionCodes;
};

export const calculateUserHash = (userId: string): string => {
  if (userId === undefined || INTERCOM_VERIFICATION_SECRET === undefined) {
    return ''
  }

  return createHmac("sha256", INTERCOM_VERIFICATION_SECRET).update(userId).digest("hex").toString();
};

export const decodeJWT = (jwtToken: string): DecodedJWT => {
  const base64Url = jwtToken.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const groupVerificationStatus = (groupUsers: Array<UserAccountDto>): string => {
  const verifiedUsers = groupUsers.filter((user: UserAccountDto) => user.isIdentityVerified)

  if (verifiedUsers.length === groupUsers.length) {
    return "All"
  }

  if (verifiedUsers.length >= 1) {
    return "Some"
  }

  return "None"
}