//@ts-nocheck
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/buttons/Button";
import CoOwnershipAgreementItem from "src/components/cards/AgreementStatusCard";
import { setMainHeader } from "src/store/actions/global";
import {
  setAgreementSectionStatus,
  setAgreementStepStatusSuccess,
  setCurrentSection,
} from "src/store/actions/governance";
import { allSectionsAgreed } from "src/utils/helpers/governance";
import { RootState } from "src/store/reducers";
import {
  AgreementDocumentCode,
  CoOwnershipAgreementItemStatus,
  DeviceType,
} from "src/components/shared/enums";
import { updatePermissions } from "src/store/actions/auth";
import {
  Page,
  GovernancePermission,
  DashboardPermission,
} from "src/utils/helpers/permissions";
// import { hideModal, showModal } from "src/store/actions/modal";
// import { MODAL_CONFIRMATION } from "src/components/modals/modalTypes";
import useInterval from "src/utils/customHooks/useInterval";
// import { setModalNotifications } from "src/store/actions/notifications";
// import { NotificationCode } from "src/services/Api/enums";
import { getAgreementHash } from "src/store/actions/signature";
import Banner from "src/components/Banner";
import DesktopNav from "src/components/DesktopNav";
import useDeviceType from "src/utils/customHooks/useDeviceType";

function CoOwnershipAgreementSections() {
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const { page, session } = useSelector(({ auth }: RootState) => auth);
  const { agreementSectionsStatus } = useSelector(
    ({ governance }: RootState) => governance
  );
  const { isGettingHash } = useSelector(
    ({ signature }: RootState) => signature.agreements.CoOwnershipAgreement.hash
  );
  // const modalNotifications = useSelector(
  //   ({ notifications }: RootState) => notifications.modalNotifications.value
  // );
  const isMobile = deviceType === DeviceType.Mobile;
  const permission =
    page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;

  useEffect(() => {
    dispatch(
      setMainHeader({
        title:
          page === Page.GOVERNANCE
            ? session?.user?.hasCompletedCoAgreement
              ? "Agreement planning status"
              : "Your progress"
            : "Co-ownership agreement",
        showHamburgerButton: page === Page.GOVERNANCE,
        onBackButtonClick:
          page === Page.GOVERNANCE
            ? undefined
            : isMobile
            ? () =>
                dispatch(
                  updatePermissions({
                    page,
                    permission: DashboardPermission.DASHBOARD,
                  })
                )
            : undefined,
        showMenuDrawerOnDesktop: true,
        showNotificationsButton: true,
      })
    );

    dispatch(setAgreementSectionStatus(true));
  }, [isMobile]);

  // useEffect(() => {
  //   if (modalNotifications.length !== 0) {
  //     showNotificationModals();
  //   }
  // }, [modalNotifications]);

  /* NOTIFICATIONS EVERY 29 seconds */
  useInterval(() => {
    dispatch(setAgreementSectionStatus(false));
    console.log("setAgreementSectionStatus");
  }, 29000);

  // const showNotificationModals = () => {
  //   let i = 0;
  //
  //   const modalNotificationsWithoutDuplicates = modalNotifications.filter(
  //     (value, index, self) =>
  //       index === self.findIndex((v) => v.code === value.code)
  //   );
  //
  //   const runShowModal = () =>
  //     dispatch(
  //       showModal(MODAL_CONFIRMATION, {
  //         title: "Group membership updated",
  //         description:
  //           modalNotificationsWithoutDuplicates[i].code ===
  //           NotificationCode.ModalGroupModifiedMemberDeleted ? (
  //             <p>
  //               A co-owner was deleted from your group. Please co-create your
  //               new Co-ownership Agreement.
  //             </p>
  //           ) : modalNotificationsWithoutDuplicates[i].code ===
  //             NotificationCode.ModalGroupModifiedMemberAdded ? (
  //             <p>
  //               A co-owner was added to your group. Please co-create your new
  //               Co-ownership Agreement.
  //             </p>
  //           ) : undefined,
  //         iconClass: "icon-alert",
  //         leftButtonText: "Ok",
  //         onLeftButtonClick: () => {
  //           if (modalNotificationsWithoutDuplicates.length === i + 1) {
  //             dispatch(setModalNotifications([]));
  //             dispatch(hideModal(MODAL_CONFIRMATION));
  //           } else {
  //             i = i + 1;
  //             runShowModal();
  //           }
  //         },
  //       })
  //     );
  //   runShowModal();
  // };

  const onReviewAndSignButton = () => {
    if (allAgreed && !session?.user?.hasSignedCoAgreement) {
      dispatch(getAgreementHash(AgreementDocumentCode.CoOwnershipAgreement));
    } else if (
      allAgreed &&
      session?.user?.hasSignedCoAgreement &&
      !session?.user.hasSignedMemoOfAgreement
    ) {
      dispatch(
        updatePermissions({
          page,
          permission: permission.CO_OWNERSHIP_AGREEMENT_STATUS,
        })
      );
    } else if (
      allAgreed &&
      session?.user?.hasSignedCoAgreement &&
      session?.user.hasSignedMemoOfAgreement
    ) {
      dispatch(
        updatePermissions({
          page,
          permission: permission.MEMORANDUM_OF_AGREEMENT_STATUS,
        })
      );
    }
  };

  if (!agreementSectionsStatus) return <></>;

  const { sections, availableItemsHeader, unavailableItemsHeader } =
    agreementSectionsStatus;

  const items = sections.filter(
    (section) => section.status !== CoOwnershipAgreementItemStatus.NotStarted
  );
  const disabledItems = sections.filter(
    (section) => section.status === CoOwnershipAgreementItemStatus.NotStarted
  );

  const shouldShowFirstNotStartedItem = sections.every(
    (section) => section.status !== CoOwnershipAgreementItemStatus.InProgress
  );

  if (shouldShowFirstNotStartedItem) {
    const firstNotStartedItem = disabledItems?.shift();
    firstNotStartedItem && items?.push(firstNotStartedItem);
  }

  const allAgreed = allSectionsAgreed(sections);

  const disableReviewAndSign =
    !allAgreed ||
    (session?.user?.hasSignedCoAgreement &&
      session.user.hasSignedMemoOfAgreement &&
      session.group?.allMemberSignedCoAgreement &&
      session.group?.allMemberSignedMemoOfAgreement);

  return (
    <div className="agreement-status-wrapper">
      <div className="review-and-sign agreement-status sections">
        {page !== Page.GOVERNANCE && (
          <DesktopNav
            title={"Co-ownership agreement"}
            backButton={{
              onBackButtonClick: () =>
                dispatch(
                  updatePermissions({
                    page,
                    permission: DashboardPermission.DASHBOARD,
                  })
                ),
            }}
          />
        )}
        {allAgreed ? (
          <Banner iconClass="icon-check-circled-bold" className="success">
            All agreed!
          </Banner>
        ) : (
          <Banner>{availableItemsHeader}</Banner>
        )}
        {items
          ?.sort((a, b) => a.index - b.index)
          .map(({ id, title, statusText, icon, stepsCount, status }, index) => (
            <CoOwnershipAgreementItem
              key={id}
              iconClass={icon}
              title={title}
              statusText={statusText}
              status={status}
              stepsCount={stepsCount}
              showArrow={true}
              onClick={() => {
                console.log(setCurrentSection(sections[index]));
                dispatch(setCurrentSection(sections[index]));
                dispatch(setAgreementStepStatusSuccess());
                dispatch(
                  updatePermissions({
                    page,
                    permission: permission.STEPS_STATUS,
                  })
                );
              }}
            />
          ))}
        {!!disabledItems?.length && (
          <>
            <p className="gray-alert">{unavailableItemsHeader}</p>
            {disabledItems
              .sort((a, b) => a.index - b.index)
              .map(({ id, title, statusText, icon, stepsCount, status }) => (
                <CoOwnershipAgreementItem
                  key={id}
                  iconClass={icon}
                  title={title}
                  statusText={statusText}
                  status={status}
                  stepsCount={stepsCount}
                  isDisabled={true}
                />
              ))}
          </>
        )}
        <div className="bottom-fixed">
          <Button
            isDisabled={disableReviewAndSign}
            text="Review & sign"
            onClick={() => !isGettingHash && onReviewAndSignButton()}
            isLoading={isGettingHash}
          />
        </div>
      </div>
    </div>
  );
}
export default CoOwnershipAgreementSections;
