import {
  SetEditingProfileAction,
  SET_EDITING_PROFILE,
  UpdateUserInfoAction,
  UpdateUserInfoRejectedAction,
  UpdateUserInfoSuccessAction,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_REJECTED,
  UPDATE_USER_INFO_SUCCESS,
} from "../actions/profile";

export const initialState: {
  isEditingProfile: boolean;
  isUpdatingProfile: boolean;
  hasUpdatedProfile: boolean;
} = {
  isEditingProfile: false,
  isUpdatingProfile: false,
  hasUpdatedProfile: false
};

export function profileReducer(
  state = initialState,
  action:
    | SetEditingProfileAction
    | UpdateUserInfoAction
    | UpdateUserInfoSuccessAction
    | UpdateUserInfoRejectedAction
) {
  switch (action.type) {
    case SET_EDITING_PROFILE:
      return { ...state, isEditingProfile: action.payload };
    case UPDATE_USER_INFO:
      return {
        ...state,
        isUpdatingProfile: true
      };
    case UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        isUpdatingProfile: false,
        isEditingProfile: false
      };
    case UPDATE_USER_INFO_REJECTED:
      return {
        ...state,
        isUpdatingProfile: false,
        isEditingProfile: false,
      };
    default:
      return state;
  }
}
