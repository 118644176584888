import useDeviceType from "src/utils/customHooks/useDeviceType";
import Fail from ".";
import Logo from "../Logo";
import { DeviceType } from "../shared/enums";

function TopLevelFail() {
  const deviceType = useDeviceType();
  return (
    <>
      <div
        className={`main-header ${
          deviceType === DeviceType.Mobile ? "hide-line" : ""
        }`}
      >
        <div className="header-bottom">
          {deviceType === DeviceType.Desktop && (
            <div className="header-bottom-center">
              <Logo icon={true} />
            </div>
          )}
        </div>
      </div>
      <div className="top-level-fail-wrapper">
        <Fail
          title={"Something went wrong"}
          description={
            "An unexpected error occurred, please reload the page and try again."
          }
          buttonText={"Reload"}
          onButtonClick={() => window.location.reload()}
        />
      </div>
    </>
  );
}

export default TopLevelFail;
