import { combineReducers } from "redux";
import { modalReducer, initialState as modalInitialState } from "./modal";
import { globalReducer, initialState as globalInitialState } from "./global";
import { authReducer, initialState as authInitialState } from "./auth";
import Storage from "src/services/LocalStorage";
import {
  signatureReducer,
  initialState as signatureInitialState,
} from "./signature";
import {
  coOwnersReducer,
  initialState as coOwnersInitialState,
} from "./coOwners";
import {
  onboardingReducer,
  initialState as onboardingInitialState,
} from "./onboarding";
import {
  governanceReducer,
  initialState as governanceInitialState,
} from "./governance";
import {
  notificationsReducer,
  initialState as notificationsInitialState,
} from "./notifications";
import { profileReducer, initialState as profileInitialState } from "./profile";
import { chatReducer, initialState as chatInitialState } from "./chat";
import {
  dashboardReducer,
  initialState as dashboardInitialState,
} from "./dashboard";
import { vaultReducer, initialState as vaultInitialState } from "./vault";
import { CLEAR_SESSION } from "../actions";

const appReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  modal: modalReducer,
  onboarding: onboardingReducer,
  governance: governanceReducer,
  coOwners: coOwnersReducer,
  notifications: notificationsReducer,
  profile: profileReducer,
  chat: chatReducer,
  dashboard: dashboardReducer,
  signature: signatureReducer,
  vault: vaultReducer,
});

export function rootReducer(state: any, action: any) {
  switch (action.type) {
    case CLEAR_SESSION:
      // Clean local storage
      Storage.clear();
      // Clean store
      return {
        ...state,
        global: {
          ...globalInitialState,
          isLoadingUserData: false,
          mainHeader: { ...state.global.mainHeader },
        },
        auth: authInitialState,
        modal: modalInitialState,
        onboarding: onboardingInitialState,
        governance: governanceInitialState,
        coOwners: coOwnersInitialState,
        notifications: notificationsInitialState,
        profile: profileInitialState,
        chat: chatInitialState,
        dashboard: dashboardInitialState,
        signature: signatureInitialState,
        vault: vaultInitialState,
      };
    default:
      return appReducer(state, action);
  }
}

export type RootState = ReturnType<typeof appReducer>;
export default rootReducer;
