import { UserAccountDto } from "src/services/Api/types";
import { localTimeFromJSON } from "src/utils/helpers/dates";
import Avatar from "../Avatar";

type ChatMessageProps = {
  className?: string;
  showAvatar?: boolean;
  writtenByUser: UserAccountDto;
  text: string;
  createdAt: string;
  isReadByAllUsers?: boolean;
};

function ChatMessage({
  className = "",
  showAvatar,
  writtenByUser,
  text,
  createdAt,
  isReadByAllUsers,
}: ChatMessageProps) {
  return (
    <div className={`chat-message ${className}`}>
      {showAvatar && (
        <Avatar
          firstName={writtenByUser.firstName}
          lastName={writtenByUser.lastName}
        />
      )}
      <div className={`message-box`}>
        <p>{text}</p>
        <div className="time">
          {localTimeFromJSON(createdAt)}{" "}
          {isReadByAllUsers !== undefined && (
            <span
              className={isReadByAllUsers ? "icon-read" : "icon-sent"}
            ></span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;
