import MultiStepForm from "src/components/MultiStepForm";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import IdVerification from "./steps/IdVerification";

export type VerificationIdFormData = undefined;

function VerificationIdForm() {
  return (
    <MultiStepForm<VerificationIdFormData>
      totalSteps={2}
      formName="verificationId"
    >
      {({ ...props }) => {
        switch (props.formStep) {
          case 1:
            return (
              <InitialStep
                title="ID verification"
                description="To keep our platform secure we need you to verify your identity. You will just need a government ID and your phone's camera."
                primaryButtonText="Verify ID"
                onPrimaryButtonClick={props.nextStep}
                iconClass="icon-check-circled"
              />
            );
          case 2:
            return <IdVerification {...props} />;
          default:
            break;
        }
      }}
    </MultiStepForm>
  );
}

export default VerificationIdForm;
