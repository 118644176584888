type IconButtonProps = {
  className?: string;
  iconClassName: string;
  isDisabled?: boolean;
  onClick?: () => void;
  hasNotifications?: boolean;
  numberOfNotifications?: number;
};

function IconButton({
  className = "",
  iconClassName,
  isDisabled,
  onClick,
  hasNotifications,
  numberOfNotifications,
}: IconButtonProps) {
  const disabledClass = isDisabled ? "disabled" : "";

  return (
    <button
      type="button"
      className={`icon-button ${className} ${disabledClass}`}
      disabled={isDisabled}
      onClick={() => !isDisabled && onClick?.()}
      tabIndex={0}
    >
      <i className={`${iconClassName} ${disabledClass}`}>
        {hasNotifications && (
          <div
            className={`notification-badge ${disabledClass} ${
              iconClassName === "icon-bell" ? "small-icon" : ""
            }`}
          ></div>
        )}
      </i>
      {numberOfNotifications && (
        <p className={disabledClass}>{numberOfNotifications}</p>
      )}
    </button>
  );
}

export default IconButton;
