import {
  ADD_CO_OWNER_ONBOARDING,
  AddCoOwnerOnboardingAction,
  ADDED_CO_OWNER_ONBOARDING,
  addedCoOwnerOnboardingAction,
  IS_ADDING_CO_OWNER,
  IS_DELETING_CO_OWNER,
  IS_STEP_LOADING,
  IsAddingCoOwnerAction,
  IsDeletingCoOwnerAction,
  IsStepLoadingAction,
  RESET_ADD_CO_OWNER,
  resetAddCoOwnerAction
} from "../actions/onboarding";
import { GroupInvitationMember } from "../../services/Api/types";

export const initialState: {
  isDeletingCoOwner: boolean;
  isStepLoading: boolean;
  isAddingCoOwner: boolean;
  addedCoOwnerOnboarding: boolean;
  member: GroupInvitationMember
} = {
  isDeletingCoOwner: false,
  isAddingCoOwner: false,
  isStepLoading: false,
  addedCoOwnerOnboarding: false,
  member: {
    firstName: '',
    email: '',
    userRelationship: ''
  }
};

export function onboardingReducer(
  state = initialState,
  action: IsDeletingCoOwnerAction | IsAddingCoOwnerAction | IsStepLoadingAction | addedCoOwnerOnboardingAction | AddCoOwnerOnboardingAction | resetAddCoOwnerAction
) {
  switch (action.type) {
    case IS_DELETING_CO_OWNER:
      return { ...state, isDeletingCoOwner: action.payload };
    case IS_ADDING_CO_OWNER:
      return { ...state, isAddingCoOwner: action.payload };
    case IS_STEP_LOADING:
      return { ...state, isStepLoading: action.payload };
    case ADDED_CO_OWNER_ONBOARDING:
      return { ...state, addedCoOwnerOnboarding: true, isAddingCoOwner: false };
    case ADD_CO_OWNER_ONBOARDING:
      return { ...state, isAddingCoOwner: true, member: action.payload.member}
    case RESET_ADD_CO_OWNER:
      return { ...state, addedCoOwnerOnboarding: false, member: {
          firstName: '',
          email: '',
          userRelationship: ''
        }}
    default:
      return state;
  }
}
