import { useState } from "react";
import Filter from "./Filter";

type FilterBarProps = {
  filters: Array<string>;
  defaultFilter?: string;
  filterItems: Function;
};

function FilterBar({
  filters,
  defaultFilter = filters[0],
  filterItems,
}: FilterBarProps) {
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);

  return (
    <ul className="filter-status">
      {filters.map((filter, index) => (
        <Filter
          key={index}
          text={filter}
          isSelected={filter === selectedFilter}
          onClick={() => {
            setSelectedFilter(filter);
            filterItems(filter);
          }}
        />
      ))}
    </ul>
  );
}

export default FilterBar;
