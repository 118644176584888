//@ts-nocheck
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import OneTimeCode from "src/components/OneTimeCode";
import { ErrorMessages, SegmentEvents, ToastType } from "src/components/shared/enums";
import Api from "src/services/Api";
import { Platform } from "src/services/Api/enums";
import { updateSession, updateSessionSuccess } from "src/store/actions/auth";
import { setFullPageLoading, setMainHeader } from "src/store/actions/global";
import { showModal } from "src/store/actions/modal";
import { RootState } from "src/store/reducers";
import { maskPhoneNumber } from "src/utils/helpers/masks";
import { captureMessage } from "@sentry/react";
import { analyticsIdentifyUser, analyticsTrackEvent } from "../../../components/SegmentAnalytics/utils";

type SubmitCodeProps = {
  onBackButtonClick: () => void;
  platform: Platform;
};

function SubmitCode({ onBackButtonClick, platform }: SubmitCodeProps) {
  const dispatch = useDispatch();
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [oneTimeCode, setOneTimeCode] = useState("");
  const [hasErrorCode, setHasErrorCode] = useState(false);
  const [failed, setFailed] = useState(false);

  const emailChallenge = useSelector(
    ({ auth }: RootState) => auth.session?.user?.challenges?.email
  );
  const phoneChallenge = useSelector(
    ({ auth }: RootState) => auth.session?.user?.challenges?.phone
  );
  const isEmailPlatform = platform === Platform.EMAIL;

  useEffect(() => {
    dispatch(
      setMainHeader({
        showLogo: true,
        onBackButtonClick,
      })
    );
  }, []);

  const challenge = (isEmailPlatform ? emailChallenge : phoneChallenge) || {
    challengeId: "",
    newValue: "",
  };

  const resendCode = async () => {
    try {
      setResendCodeLoading(true);
      const { data } = await Api.resendOTC(platform, challenge);
      const { success, ...session } = data;
      dispatch(updateSessionSuccess(session));
      dispatch(
        showModal(MODAL_TOAST, {
          message: isEmailPlatform
            ? "Sent! Please check your inbox."
            : "Sent! Please check your phone.",
        })
      );
    } catch (error: any) {
      captureMessage(JSON.stringify(error))
      if (error.status === 426)
        dispatch(
          showModal(MODAL_TOAST, {
            message: ErrorMessages.PaymentError,
            type: ToastType.Info,
            autoDeleteTime: 10000,
          })
        );
      else
        dispatch(
          showModal(MODAL_TOAST, {
            message: ErrorMessages.GenericError,
            type: ToastType.Error,
          })
        );
    } finally {
      setResendCodeLoading(false);
    }
  };

  const verifyCode = async (value: string) => {
    try {
      dispatch(setFullPageLoading(true));
      setHasErrorCode(false);
      const { data } = await Api.submitContactInfoUpdate(platform, {
        challengeId: challenge?.challengeId || "",
        otpCode: value,
      });
      const { success, ...session } = data;
      dispatch(updateSessionSuccess(session));
      await analyticsIdentifyUser(session)
      await analyticsTrackEvent(session, SegmentEvents.UserUpdatedProfile)

      onBackButtonClick();
      dispatch(
        showModal(MODAL_TOAST, {
          message: isEmailPlatform
            ? "Email was successfully verified!"
            : "Phone number was successfully verified!",
        })
      );
    } catch (error: any) {
      captureMessage(JSON.stringify(error))
      if (error.status === 426)
        dispatch(
          showModal(MODAL_TOAST, {
            message: ErrorMessages.PaymentError,
            type: ToastType.Info,
            autoDeleteTime: 10000,
          })
        );
      else if (error.status === 403) setHasErrorCode(true);
      else if (error.status === 400) setHasErrorCode(true);
      else if (error.status === 404) {
        dispatch(updateSession(false, false, false));
        setFailed(true);
      }
    } finally {
      dispatch(setFullPageLoading(false));
    }
  };

  const onOneTimeCodeChange = (value: string) => {
    setOneTimeCode(value);
    value.length === 6 ? verifyCode(value) : setHasErrorCode(false);
  };

  return (
    <OneTimeCode
      otcFailed={failed}
      email={
        isEmailPlatform && emailChallenge ? emailChallenge.newValue : undefined
      }
      phone={
        !isEmailPlatform && phoneChallenge
          ? maskPhoneNumber(phoneChallenge?.newValue)
          : undefined
      }
      onTryAgainButtonClick={onBackButtonClick}
      oneTimeCode={oneTimeCode}
      onOneTimeCodeChange={onOneTimeCodeChange}
      hasErrorCode={hasErrorCode}
      countDownButton={{
        text: isEmailPlatform ? "Resend email" : `Resend text message`,
        countDownText: isEmailPlatform ? "email" : `text message`,
        time: 30,
        onButtonClick: resendCode,
        isLoading: resendCodeLoading,
      }}
    />
  );
}

export default SubmitCode;
