import { UserType } from "src/services/Api/types";

// SET EDITING PROFILE
export const SET_EDITING_PROFILE = "SET_EDITING_PROFILE";
export interface SetEditingProfileAction {
  type: typeof SET_EDITING_PROFILE;
  payload: boolean;
}
export const setEditingProfile = (
  isEditingProfile: boolean
): SetEditingProfileAction => ({
  type: SET_EDITING_PROFILE,
  payload: isEditingProfile,
});

// UPDATE USER INFORMATION
// Pending
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export interface UpdateUserInfoAction {
  type: typeof UPDATE_USER_INFO;
  payload: { user: UserType };
}
export const updateUserInfo = (user: UserType): UpdateUserInfoAction => ({
  type: UPDATE_USER_INFO,
  payload: { user },
});
// Success
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export interface UpdateUserInfoSuccessAction {
  type: typeof UPDATE_USER_INFO_SUCCESS;
}
export const updateUserInfoSuccess = (): UpdateUserInfoSuccessAction => ({
  type: UPDATE_USER_INFO_SUCCESS,
});
// Rejected
export const UPDATE_USER_INFO_REJECTED = "UPDATE_USER_INFO_REJECTED";
export interface UpdateUserInfoRejectedAction {
  type: typeof UPDATE_USER_INFO_REJECTED;
}
export const updateUserInfoRejected = (): UpdateUserInfoRejectedAction => ({
  type: UPDATE_USER_INFO_REJECTED,
});
