import { useEffect, useRef } from "react";

function useInterval(callback: (...args: any[]) => void, delay: number) {
  const savedCallbackRef = useRef<(...args: any[]) => void>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (delay > 0) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
}

export default useInterval;
