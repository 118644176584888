import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import ModalLayout from "../ModalLayout";
import Button from "src/components/buttons/Button";
import TextInput from "src/components/inputs/TextInput";
import { useState } from "react";
import Select from "src/components/inputs/Select";
import { DocumentType, MimeType } from "src/components/shared/enums";
import {
  acceptedFileTypes,
  DocumentTypeName,
  documentTypeOptions,
  MAX_DOCUMENT_FILE_SIZE,
  SHORT_TEXT_MAX_LENGTH,
} from "src/components/shared/constants";
import FileUploader from "src/components/inputs/FileUploader";
import { validateFile, validateShortText } from "src/utils/helpers/validations";
import { UploadDocumentType, DocumentCardType } from "src/services/Api/types";

export type AddDocumentModalProps = {
  onButtonClick?: (newDocument: UploadDocumentType) => void;
  onCloseButtonClick?: () => void;
  document?: DocumentCardType;
  action?: string
};

function AddDocumentModal() {
  
  const modal = useSelector(({ modal }: RootState) => modal.addDocument);
  const [newDocumentSizeExceeded, setNewDocumentSizeExceeded] = useState(false);
  const [isFileTypeNotAllowed, setIsFileTypeNotAllowed] = useState(false);

  let  newDocumentInitialState;
  console.log("Modal",modal);
  if (modal.action == 'edit') {
    console.log("Edit Modal");
    newDocumentInitialState = {
      name: modal.document?.name || "",
      type: modal.document?.type || null,
      file: null,
      mimeType: null,
    };
  } else {
    console.log("New Modal");
    newDocumentInitialState = {
      name: "",
      type: null,
      file: null,
      mimeType: null,
    };   
  }

  const [newDocument, setNewDocument] = useState<UploadDocumentType>(
    newDocumentInitialState
  );  

  const { uploadDocument: { isLoading: isUploading } } = useSelector(
    ({ vault }: RootState) => vault
  );

  const isFormValid =
    validateShortText(newDocument.name) &&
    DocumentTypeName[newDocument.type as DocumentType] &&
    validateFile(newDocument.file);

  const onButtonClick = () => {
    isFormValid && modal.onButtonClick?.(newDocument);
  };

  const onFileUploaderChange = (file: File) => {
    if (file && file.size > MAX_DOCUMENT_FILE_SIZE) {
      setNewDocumentSizeExceeded(true);
    } else if (
      file &&
      !acceptedFileTypes
        .map((fileType) => fileType.mimeType)
        .includes(file.type)
    )
      setIsFileTypeNotAllowed(true);
    else {
      setIsFileTypeNotAllowed(false);
      setNewDocumentSizeExceeded(false);
      setNewDocument({
        ...newDocument,
        file,
        mimeType: file ? (file.type as MimeType) : null,
        previousId: modal.document?.id,
      });
    }
  };

  const content = (
    <div className="add-document-modal">
      <div
        onClick={modal.onCloseButtonClick}
        className={`close-modal ${isUploading ? "disabled" : ""}`}
      >
        <i className="icon-cross" />
      </div>

      <div className="head">
        <h4>Add document</h4>
      </div>

      <form onSubmit={(e) => e.preventDefault()}>
        <TextInput
          id="name"
          name="name"
          label="Name"
          value={newDocument.name}
          isDisabled={isUploading}
          onChange={(name) => setNewDocument({ ...newDocument, name })}
          errorMessage={
            newDocument.name && !validateShortText(newDocument.name)
              ? `Document name limited to ${SHORT_TEXT_MAX_LENGTH} characters or less`
              : ""
          }
          isValid={!newDocument.name || !!validateShortText(newDocument.name)}
        />
        <Select
          id="type"
          label="Type of document"
          onChange={(value) => {
            !modal.document?.type &&
              setNewDocument({ ...newDocument, type: value });
          }}
          readOnly={!!modal.document?.type}
          isDisabled={isUploading || !!modal.document?.type}
          selectedId={newDocument.type || modal.document?.type}
          options={documentTypeOptions}
        />
        <FileUploader
          onChange={onFileUploaderChange}
          file={newDocument.file}
          isValid={!newDocumentSizeExceeded && !isFileTypeNotAllowed}
          isDisabled={isUploading}
          errorMessage={
            isFileTypeNotAllowed
              ? "Your file’s format is invalid. CoBuy only supports image/document files, please try again"
              : newDocumentSizeExceeded
              ? `The file is too big. Please make sure your file is under ${
                  MAX_DOCUMENT_FILE_SIZE / 1000000
                }MB.`
              : undefined
          }
        />
      </form>
      {isUploading && (
        <p className="loading-text">
          Add document transaction may take up to a minute.
        </p>
      )}
      <Button
        text="Add document"
        onClick={onButtonClick}
        isLoading={isUploading}
        isDisabled={!isFormValid}
      />
    </div>
  );

  return <ModalLayout content={content} />;
}

export default AddDocumentModal;
