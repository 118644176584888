import {
  Option,
  FormSectionStepConfirmationRequestType,
  FormSectionStepType,
} from "src/components/shared/types";
import { FieldsValidationType } from "src/store/actions/governance";
import { transformStepDataToAnswerData } from "src/utils/helpers/governance";
import { GovernanceFormSectionStepField } from "../shared/enums";
import { fillResetOtherFields } from "./fillResetOtherFields";
import { showHideOtherFields } from "./showHideOtherFields";

export const buttonGroupOnChange = (
  currentSectionStep: FormSectionStepType,
  formSectionStepFieldOptions: Array<Option>,
  stepAnswer: FormSectionStepConfirmationRequestType,
  fieldsValidation: FieldsValidationType,
  option: string | null
) => {
  let result = { _stepAnswer: stepAnswer, _fieldsValidation: fieldsValidation };
  result = showHideOtherFields(
    currentSectionStep,
    result._stepAnswer,
    result._fieldsValidation,
    option
  );

  result = fillResetOtherFields(
    currentSectionStep,
    result._stepAnswer,
    result._fieldsValidation,
    option
  );

  const buttonGroupOption = formSectionStepFieldOptions.find(
    (fieldOption) => fieldOption.id === option
  )?.value;
  const stepSimpleSwitch = currentSectionStep?.formSectionStepFields.find(
    (field) => field.type === GovernanceFormSectionStepField.SimpleSwitch
  );

  if (buttonGroupOption === "Equally") {
    if (stepSimpleSwitch) {
      result._stepAnswer = transformStepDataToAnswerData(
        stepSimpleSwitch.id,
        stepSimpleSwitch.index,
        stepSimpleSwitch.formSectionStepFieldOptions[0].id,
        null,
        result._stepAnswer
      );
    }
  }

  if (buttonGroupOption === "Other") {
    if (stepSimpleSwitch) {
      result._stepAnswer = transformStepDataToAnswerData(
        stepSimpleSwitch.id,
        stepSimpleSwitch.index,
        stepSimpleSwitch.formSectionStepFieldOptions[0].id,
        null,
        result._stepAnswer
      );
    }
  }

  return result;
};
