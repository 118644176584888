import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { AnalyticsContext, AnalyticsContextType } from "./Analytics";
import { SegmentEvents } from "./shared/enums";
import { downloadDocumentComplete, rejectDocumentComplete } from "../store/actions/vault";
import {  UserAccountDto } from "../services/Api/types";
import { userCompletedGovernanceReceived } from "../store/actions/governance";
import { resetInvite } from "../store/actions/coOwners";
import { resetAddCoOwner } from "../store/actions/onboarding";

const AnalyticsEventsHandler = () => {
  const dispatch = useDispatch();
  const { session } : any = useSelector(({ auth }: RootState) => auth);
  const governance = useSelector(({ governance }: RootState) => governance);
  const {
    uploadDocument: { isLoading: isDocumentUploading },
    deleteDocument: { deleteDocumentSuccess },
    downloadDocument: { downloadSuccess }
  } = useSelector(({ vault }: RootState) => vault);
  const {
    cancelledInvite,
    inviteSent,
    addCoOwner: { coOwnerAdded, newCoOwner }
  } = useSelector(({ coOwners }: RootState) => coOwners);

  const { addedCoOwnerOnboarding, member } = useSelector(({ onboarding }: RootState) => onboarding);


  const {
    segmentTrack,
    segmentIdentifyUser,
    segmentGroup,
    marshalSegmentGroup
  } = useContext(AnalyticsContext) as AnalyticsContextType;

  const trackEvent = (eventName: string, properties?: {}) => {
    const defaultProperties = {
      user_id: session?.user?.id,
      user_to_group_id: session?.userToGroupId
    };

    segmentTrack(
      eventName, { ...defaultProperties, ...properties }, { groupId: session?.group?.id }
    );
  };

  // Track logout event
  // useEffect(() => {
  //     if (isLoggingOut) {
  //       trackEvent(SegmentEvents.UserSignedOut);
  //       segmentReset();
  //     }
  //   }, [isLoggingOut, session?.user?.id, session?.userToGroupId, session?.group?.id]
  // );

  // Track download document event
  useEffect(() => {
      if (downloadSuccess) {
        trackEvent(SegmentEvents.DocumentDownloaded);
        dispatch(downloadDocumentComplete());
      }
    }, [downloadSuccess, session?.user?.id, session?.userToGroupId, session?.group?.id]
  );

  // Track document delete
  useEffect(() => {
    if (deleteDocumentSuccess) {
      trackEvent(SegmentEvents.DocumentRejected);
      dispatch(rejectDocumentComplete());
    }
  }, [deleteDocumentSuccess, session?.user?.id, session?.userToGroupId, session?.group?.id]);

  // Track upload request event
  useEffect(() => {
    if (isDocumentUploading) {
      trackEvent(SegmentEvents.DocumentProposed);
    }
  }, [isDocumentUploading, session?.user?.id, session?.userToGroupId, session?.group?.id]);

  // Track steps completed
  useEffect(() => {
    if (governance?.userCompletedGovernance && session?.user?.hasCompletedCoAgreement) {
      segmentIdentifyUser(session);
      trackEvent(SegmentEvents.UserCompletedGovernance);

      if (session?.group?.users?.filter((u: UserAccountDto) => u.hasCompletedCoAgreement && u.id !== session?.user?.id).length === session?.group?.users?.length! - 1) {
        segmentGroup(session?.group?.id!, marshalSegmentGroup(session?.group!));
        trackEvent(SegmentEvents.AccountCompletedGovernance);
      }

      dispatch(userCompletedGovernanceReceived());
    }

  }, [session?.group?.users, session?.user?.hasCompletedCoAgreement, session?.user?.id, session?.userToGroupId, session?.group?.id, governance?.userCompletedGovernance]);

  useEffect(() => {
    if (coOwnerAdded || inviteSent) {
      console.log("===> Triggered when coOwnerAdded and inviteSent")
      trackEvent(SegmentEvents.InviteSent, {
        invitee_first_name: newCoOwner?.firstName,
        invitee_email: newCoOwner?.email
      });
      dispatch(resetInvite());
    }

  }, [coOwnerAdded, inviteSent, newCoOwner, session?.user?.id, session?.userToGroupId, session?.group?.id]);

  useEffect(() => {
    if (addedCoOwnerOnboarding) {
      console.log("===> Triggered when onboarding")
      trackEvent(SegmentEvents.InviteSent, {
        invitee_first_name: member?.firstName,
        invitee_email: member?.email
      });
      dispatch(resetAddCoOwner());
    }

  }, [addedCoOwnerOnboarding, member?.firstName, member?.email, session?.user?.id, session?.userToGroupId, session?.group?.id]);


  useEffect(() => {
    if (cancelledInvite) {
      trackEvent(SegmentEvents.InviteDeleted);
      dispatch(resetInvite());
    }

  }, [cancelledInvite, session?.user?.id, session?.userToGroupId, session?.group?.id]);

  return (<>
    <i style={{ color: "transparent" }}>analytics</i>
  </>);
};

export default AnalyticsEventsHandler;