import { useRef, useState } from "react";
import { AvatarType } from "src/components/Avatar";
import Avatars from "src/components/Avatars";
import Button from "src/components/buttons/Button";
import parse from "html-react-parser";
import ScrollTrigger from "react-scroll-trigger";

type ReviewAndSignProps = {
  title: string;
  avatars: Array<AvatarType>;
  documentContent: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  primaryButtonClick: () => void;
  secondaryButtonClick?: () => void;
};

function ReviewAndSign({
                         title,
                         avatars,
                         documentContent,
                         primaryButtonText,
                         secondaryButtonText,
                         primaryButtonClick,
                         secondaryButtonClick
                       }: ReviewAndSignProps) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [reachedBottom, setReachedBottom] = useState(false);

  // @ts-ignore
  const onScroll = () => {
    if (divRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = divRef.current;
      if (scrollTop + clientHeight + 2 >= scrollHeight) {
        setReachedBottom(true);
      }
    }
  };

  // @ts-ignore
  const onScrollMobile = ({ progress, _velocity }, _ref) => {
    if (Math.floor(progress + 0.2) === 1) {
      setReachedBottom(true);
    }
  };

  return (

    <div
      className={`review-and-sign ${
        secondaryButtonText ? "extra-padding" : ""
      }`}
      onScroll={onScroll}
      ref={divRef}
    >
      {/*@ts-ignore*/}
      <ScrollTrigger onProgress={onScrollMobile}>
        <div className="height-limit-scroll">
          <h1>{title}</h1>
          <Avatars avatars={avatars} />
          <div className="agreement-document">{parse(documentContent || "")}</div>
        </div>
        <div className="bottom-fixed">
          <Button
            text={primaryButtonText}
            onClick={() => reachedBottom && primaryButtonClick()}
            isDisabled={!reachedBottom}
          />
          {secondaryButtonText && secondaryButtonClick && (
            <Button
              className="tertiary-button"
              text={secondaryButtonText}
              onClick={secondaryButtonClick}
            />
          )}
        </div>
      </ScrollTrigger>
    </div>

  );
}

export default ReviewAndSign;
