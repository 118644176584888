import { CoOwnersStep } from "src/components/shared/enums";
import { CoOwner, GroupInvitationMember } from "src/services/Api/types";
import {
  ADD_CO_OWNER,
  ADD_CO_OWNER_REJECTED,
  ADD_CO_OWNER_SUCCESS,
  AddCoOwnerAction,
  AddCoOwnerRejectedAction,
  AddCoOwnerSuccessAction,
  CANCEL_INVITE,
  CANCEL_INVITE_COMPLETE,
  CancelInviteAction,
  CancelInviteCompleteAction,
  DELETE_CO_OWNER,
  DELETE_CO_OWNER_REJECTED,
  DELETE_CO_OWNER_SUCCESS,
  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_COMPLETED,
  DeleteCoOwnerAction,
  DeleteCoOwnerRejectedAction,
  DeleteCoOwnerSuccessAction,
  DeleteUserAccountAction,
  DeleteUserAccountCompletedAction,
  REMOVE_USER_ACCOUNT,
  REMOVE_USER_ACCOUNT_COMPLETED,
  RemoveUserAccountAction,
  RemoveUserAccountCompletedAction,
  RESEND_INVITATION,
  RESEND_INVITATION_REJECTED,
  RESEND_INVITATION_SUCCESS,
  ResendInvitationAction,
  ResendInvitationRejectedAction,
  ResendInvitationSuccessAction, RESET_INVITE, ResetInviteAction,
  SEND_SIGNATURE_REMINDER,
  SEND_SIGNATURE_REMINDER_REJECTED,
  SEND_SIGNATURE_REMINDER_SUCCESS,
  SEND_VERIFICATION_ID_REMINDER,
  SEND_VERIFICATION_ID_REMINDER_REJECTED,
  SEND_VERIFICATION_ID_REMINDER_SUCCESS,
  SendSignatureReminderAction,
  SendSignatureReminderRejectedAction,
  SendSignatureReminderSuccessAction,
  SendVerificationIdReminderAction,
  SendVerificationIdReminderRejectedAction,
  SendVerificationIdReminderSuccessAction,
  SET_CO_OWNERS_STEP,
  SET_SELECTED_CO_OWNER,
  SetCoOwnersStepAction,
  SetSelectedCoOwnerAction
} from "../actions/coOwners";

export const initialState: {
  step: CoOwnersStep;
  selectedCoOwner: CoOwner | null;
  isSendingVerificationIdReminder: boolean;
  isSendingSignatureReminder: boolean;
  isResendingInvitation: boolean;
  isCancelingInvite: boolean;
  cancelledInvite: boolean;
  inviteSent: boolean;
  isDeletingUserAccount: boolean;
  isRemovingUserAccount: boolean;
  addCoOwner: {
    eventId: string | null;
    newCoOwner: GroupInvitationMember | null;
    isAddingCoOwner: boolean;
    coOwnerAdded: boolean;
  };
  coOwnerDeleted: boolean | null;
  deleteCoOwner: {
    eventId: string | null;
    userIdToRemove: string | null;
    isDeletingCoOwner: boolean;
  };
} = {
  step: CoOwnersStep.CoOwners,
  selectedCoOwner: null,
  isSendingVerificationIdReminder: false,
  isSendingSignatureReminder: false,
  isResendingInvitation: false,
  isCancelingInvite: false,
  cancelledInvite: false,
  isDeletingUserAccount: false,
  isRemovingUserAccount: false,
  inviteSent: false,
  addCoOwner: {
    eventId: null,
    newCoOwner: null,
    isAddingCoOwner: false,
    coOwnerAdded: false
  },
  coOwnerDeleted: null,
  deleteCoOwner: {
    eventId: null,
    userIdToRemove: null,
    isDeletingCoOwner: false
  }
};

export function coOwnersReducer(
  state = initialState,
  action:
    | SetCoOwnersStepAction
    | SetSelectedCoOwnerAction
    | AddCoOwnerAction
    | AddCoOwnerSuccessAction
    | AddCoOwnerRejectedAction
    | DeleteCoOwnerAction
    | DeleteCoOwnerSuccessAction
    | DeleteCoOwnerRejectedAction
    | ResendInvitationAction
    | ResendInvitationSuccessAction
    | ResendInvitationRejectedAction
    | SendVerificationIdReminderAction
    | SendVerificationIdReminderSuccessAction
    | SendVerificationIdReminderRejectedAction
    | SendSignatureReminderAction
    | SendSignatureReminderSuccessAction
    | SendSignatureReminderRejectedAction
    | CancelInviteAction
    | CancelInviteCompleteAction
    | DeleteUserAccountAction
    | DeleteUserAccountCompletedAction
    | RemoveUserAccountAction
    | RemoveUserAccountCompletedAction
    | ResetInviteAction
) {
  switch (action.type) {
    case SET_SELECTED_CO_OWNER:
      return {
        ...state,
        selectedCoOwner: action.payload
      };
    case SET_CO_OWNERS_STEP:
      return {
        ...state,
        step: action.payload
      };
    case ADD_CO_OWNER:
      return {
        ...state,
        addCoOwner: {
          ...state.addCoOwner,
          isAddingCoOwner: true,
          coOwnerAdded: false
        }
      };
    case ADD_CO_OWNER_SUCCESS:
      return {
        ...state,
        addCoOwner: {
          ...state.addCoOwner,
          eventId: action.payload.eventId,
          newCoOwner: action.payload.newCoOwner,
          isAddingCoOwner: false,
          coOwnerAdded: true
        }
      };
    case ADD_CO_OWNER_REJECTED:
      return {
        ...state,
        addCoOwner: {
          ...state.addCoOwner,
          isAddingCoOwner: false
        }
      };
    case DELETE_CO_OWNER:
      return {
        ...state,
        coOwnerDeleted: null,
        deleteCoOwner: {
          ...state.deleteCoOwner,
          isDeletingCoOwner: true
        }
      };
    case DELETE_CO_OWNER_SUCCESS:
      return {
        ...state,
        coOwnerDeleted: true,
        deleteCoOwner: {
          ...state.deleteCoOwner,
          eventId: action.payload.eventId,
          userIdToRemove: action.payload.userIdToRemove,
          isDeletingCoOwner: false
        }
      };
    case DELETE_CO_OWNER_REJECTED:
      return {
        ...state,
        coOwnerDeleted: false,
        deleteCoOwner: {
          ...state.deleteCoOwner,
          isDeletingCoOwner: false
        }
      };
    case RESEND_INVITATION:
      return {
        ...state,
        inviteSent: false,
        isResendingInvitation: true
      };
    case RESEND_INVITATION_SUCCESS:
      return {
        ...state,
        inviteSent: true,
        isResendingInvitation: false
      };
    case RESEND_INVITATION_REJECTED:
      return {
        ...state,
        isResendingInvitation: false
      };
    case SEND_VERIFICATION_ID_REMINDER:
      return {
        ...state,
        isSendingVerificationIdReminder: true
      };
    case SEND_VERIFICATION_ID_REMINDER_SUCCESS:
    case SEND_VERIFICATION_ID_REMINDER_REJECTED:
      return {
        ...state,
        isSendingVerificationIdReminder: false
      };
    case SEND_SIGNATURE_REMINDER:
      return {
        ...state,
        isSendingSignatureReminder: true
      };
    case SEND_SIGNATURE_REMINDER_SUCCESS:
    case SEND_SIGNATURE_REMINDER_REJECTED:
      return {
        ...state,
        isSendingSignatureReminder: false
      };
    case CANCEL_INVITE:
      return {
        ...state,
        cancelledInvite: false,
        isCancelingInvite: true
      };
    case CANCEL_INVITE_COMPLETE:
      return {
        ...state,
        cancelledInvite: true,
        isCancelingInvite: false,
        deleteCoOwner: {
          ...state.deleteCoOwner,
          isDeletingCoOwner: false
        }
      };
    case RESET_INVITE:
      return {
        ...state,
        cancelledInvite: false,
        inviteSent: false,
        addCoOwner: {
          eventId: null,
          newCoOwner: null,
          isAddingCoOwner: false,
          coOwnerAdded: false
        }
      };
    case DELETE_USER_ACCOUNT:
      return {
        ...state,
        isDeletingUserAccount: true
      };
    case DELETE_USER_ACCOUNT_COMPLETED:
      return {
        ...state,
        isDeletingUserAccount: false
      };
    case REMOVE_USER_ACCOUNT:
      return {
        ...state,
        isRemovingUserAccount: true
      };
    case REMOVE_USER_ACCOUNT_COMPLETED:
      return {
        ...state,
        isRemovingUserAccount: false
      };

    default:
      return state;
  }
}
