export enum Path {
  Home = "/",
  Login = "/login",
  SignUp = "/signup",
  Invitation = "/invite/:invitationId",
  Terms = "/terms",
  PrivacyPolicy = "/privacy-policy",
  Onboarding = "/onboarding",
  Profile = "/profile",
  CoOwners = "/co-owners",
  Governance = "/governance",
  Dashboard = "/dashboard",
  DocumentVault = "/document-vault",
  Chat = "/chat",
  Disclaimer = "/disclaimer"
}
