//@ts-nocheck
import { useDispatch, useSelector } from "react-redux";
import { AgreementStatus as Status } from "src/services/Api/enums";
import MemberItem from "src/components/MemberItem";
import InitialStepHeader from "src/components/MultiStepForm/InitialStep/InitialStepHeader";
import { AgreementDocumentCode } from "src/components/shared/enums";
import { RootState } from "src/store/reducers";
import { updatePermissions } from "src/store/actions/auth";
import Button from "src/components/buttons/Button";
import { agreementStatusTitles } from "src/components/shared/constants";
import NiceWork from "./NiceWork";
import { setMainHeader } from "src/store/actions/global";
import { useEffect } from "react";
import { sendSignatureReminder } from "src/store/actions/coOwners";
import {
  DashboardPermission,
  GovernancePermission,
  Page,
} from "src/utils/helpers/permissions";
import useInterval from "src/utils/customHooks/useInterval";
import { updateAgreementStatus } from "src/store/actions/governance";

type AgreementStatusProps = { code: AgreementDocumentCode };

function AgreementStatus({ code }: AgreementStatusProps) {
  // @ts-ignore
  const group = useSelector(({ auth }: RootState) => auth.session?.group);
  // @ts-ignore
  const user = useSelector(({ auth }: RootState) => auth.session?.user);
  // @ts-ignore
  const page = useSelector(({ auth }: RootState) => auth.page);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setMainHeader({
        showLogo: true,
        showMenuDrawerOnDesktop: true,
        showHamburgerButton: page === Page.GOVERNANCE,
        onBackButtonClick:
          page === Page.GOVERNANCE
            ? undefined
            : () =>
                dispatch(
                  updatePermissions({
                    page,
                    permission: DashboardPermission.DASHBOARD,
                  })
                ),
        showNotificationsButton: true,
      })
    );
  }, []);

  useInterval(() => {
    dispatch(updateAgreementStatus());
  }, 30000);

  const users = group?.users || [];

  const isCoOwnershipAgreement =
    code === AgreementDocumentCode.CoOwnershipAgreement;

  const allUsersSignedAgreement = isCoOwnershipAgreement
    ? group?.allMemberSignedCoAgreement
    : group?.allMemberSignedMemoOfAgreement;

  const onSendReminderButtonClick = (coOwnerId: string) =>
    dispatch(sendSignatureReminder(coOwnerId));

  return allUsersSignedAgreement ? (
    <NiceWork code={code} />
  ) : (
    <div className="ownership-group-wrapper">
      <div className="content-wrapper ownership-group">
        <InitialStepHeader
          iconClassName="icon-users-circled"
          {...agreementStatusTitles[code]}
        />
        <h5 className="small-title">Group members</h5>
        {users
          ?.sort((a: any, b: any) => (a.id === user?.id ? -1 : 1))
          .map((groupUser: any, index: number) => (
            <MemberItem
              key={index}
              name={`${groupUser.firstName} ${groupUser.lastName}`}
              email={groupUser.email}
              self={user?.id === groupUser.id}
              badgeText={
                isCoOwnershipAgreement
                  ? groupUser.hasSignedCoAgreement
                    ? Status.Signed
                    : Status.PendingSignature
                  : groupUser.hasSignedMemoOfAgreement
                  ? Status.Signed
                  : Status.PendingSignature
              }
              badgeClass={
                isCoOwnershipAgreement
                  ? groupUser.hasSignedCoAgreement
                    ? "success"
                    : "warning"
                  : groupUser.hasSignedMemoOfAgreement
                  ? "success"
                  : "warning"
              }
              leftButtonText={
                isCoOwnershipAgreement
                  ? groupUser.hasSignedCoAgreement
                    ? undefined
                    : "Send reminder"
                  : groupUser.hasSignedMemoOfAgreement
                  ? undefined
                  : "Send reminder"
              }
              onLeftButtonClick={
                isCoOwnershipAgreement
                  ? groupUser.hasSignedCoAgreement
                    ? undefined
                    : () => onSendReminderButtonClick(groupUser.id)
                  : groupUser.hasSignedMemoOfAgreement
                  ? undefined
                  : () => onSendReminderButtonClick(groupUser.id)
              }
            />
          ))}
        {isCoOwnershipAgreement && (
          <Button
            text="Make changes"
            onClick={() =>
              dispatch(
                updatePermissions({
                  page,
                  permission:
                    page === Page.GOVERNANCE
                      ? GovernancePermission.SECTIONS_STATUS
                      : DashboardPermission.SECTIONS_STATUS,
                })
              )
            }
          />
        )}
      </div>
    </div>
  );
}

export default AgreementStatus;
