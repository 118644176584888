import { addThousandsSeparator } from "src/utils/helpers/helpers";
import Tooltip from "../Tooltip";

type StatProps = {
  title: string;
  tooltip: string;
  price: number | null;
  percentage: number | null;
};

function Stat({ title, tooltip, price, percentage }: StatProps) {
  return (
    <div className="stat">
      {title && (
        <div className="stat-title">
          <p>{title}</p>
          <Tooltip content={tooltip} />
        </div>
      )}
      <div className="stat-table-price">
        <span className="price">
          {price ? `$${addThousandsSeparator(price)}` : "No info"}
        </span>
        {percentage && (
          <span className={`percentage ${percentage < 0 ? "negative" : ""}`}>
            {addThousandsSeparator(
              Math.round(Math.abs(percentage) * 100) / 100
            )}
            %
          </span>
        )}
      </div>
    </div>
  );
}

export default Stat;
