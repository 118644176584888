import { useState } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/buttons/Button";
import Checkbox from "src/components/inputs/Checkbox";
import DateInput from "src/components/inputs/DateInput";
import TextInput from "src/components/inputs/TextInput";
import { UserSignatureType } from "src/components/modals/SignatureModal";
import { RootState } from "src/store/reducers";
import {
  dateIsToday,
  localDateFromJSON,
  validDate,
} from "src/utils/helpers/dates";
import StepTitle from "src/components/MultiStepForm/Step/StepTitle";

const signatureInitialState = {
  fullName: "",
  date: "",
  confirmation: false,
};

type SignatureProps = {
  title: string;
  description: string;
  onButtonClick: ({ fullName, date, confirmation }: UserSignatureType) => void;
  backButton?: { onBackButtonClick: () => void };
};

function Signature({
  title,
  description,
  onButtonClick,
  backButton,
}: SignatureProps) {
  const [{ fullName, date, confirmation }, setSignature] =
    useState<UserSignatureType>(signatureInitialState);

  const isSigning = useSelector(
    ({ signature }: RootState) => signature.isSigning
  );

  // @ts-ignore
  const { firstName, lastName } = useSelector(({ auth }: RootState) => auth.session!.user!);

  const todayPlaceholder = localDateFromJSON(new Date().toJSON());
  const fullNamePlaceholder = `${firstName} ${lastName}`;

  /* Fix ********/
  // const isValidForm =
  //   fullName.includes(firstName) &&
  //   fullName.includes(lastName) &&
  //   confirmation &&
  //   dateIsToday(date) &&
  //   validDate(date);
  //console.log(fullNamePlaceholder.toLowerCase().toString() + " == " + fullName.toLowerCase().toString());
  const isValidForm =
    fullNamePlaceholder.toLowerCase().toString() === fullName.toLowerCase().toString() &&
    confirmation &&
    dateIsToday(date) &&
    validDate(date);  
  /******************/

  const isValidDate =
    date.length !== 8 || (dateIsToday(date) && validDate(date));
  const isToday = date.length === 8 && dateIsToday(date);

  const onClick = () => {
    if (isValidForm && !isSigning) {
      onButtonClick && onButtonClick({ fullName, date, confirmation });
    }
  };

  return (
    <div className="step-question-wrapper signature-wrapper">
      <div className="step-question">
        <StepTitle
          title={title}
          description={description}
          backButton={backButton}
          isDisabled={isSigning}
        />
        <form onSubmit={(e) => e.preventDefault()}>
          <TextInput
            id="fullName"
            name="fullName"
            label="Full name"
            value={fullName}
            onChange={(fullName) =>
              setSignature({ fullName, date, confirmation })
            }
            isDisabled={isSigning}
            placeholder={fullNamePlaceholder}
          />
          <DateInput
            id="date"
            name="date"
            label="Today's date"
            value={date}
            onChange={(date) => setSignature({ fullName, date, confirmation })}
            placeholder={todayPlaceholder}
            isDisabled={isSigning}
            errorMessage={
              isValidDate
                ? undefined
                : !isToday
                ? "Invalid date"
                : "Invalid date, format must be MM/DD/YYYY"
            }
            isValid={isValidDate}
          />
          <div className="agreement-checkbox-box">
            <Checkbox
              id="confirmation"
              name="confirmation"
              onChange={(confirmation) =>
                setSignature({ fullName, date, confirmation })
              }
              isDisabled={isSigning}
              checked={confirmation}
              label="I agree to use digital records and signatures."
            />
          </div>
        </form>
        {isSigning && (
          <p className="loading-text">
            The signature transaction may take up to a minute.
          </p>
        )}
        <Button
          text="Sign request"
          onClick={onClick}
          isLoading={isSigning}
          isDisabled={!isValidForm}
        />
      </div>
    </div>
  );
}

export default Signature;
