import {
  FormSectionStepType,
  SectionType,
  FormSectionStepConfirmationRequestType,
  AgreementSectionStatusType,
  AgreementStepStatusType,
} from "src/components/shared/types";

// SET EDITION MODE
export const SET_EDITION_MODE = "SET_EDITION_MODE";
export interface SetEditionModeAction {
  type: typeof SET_EDITION_MODE;
  payload: boolean;
}
export const setEditionMode = (
  onEditionMode: boolean
): SetEditionModeAction => ({
  type: SET_EDITION_MODE,
  payload: onEditionMode,
});

// SET SECTIONS STATUS
// Pending
export const SET_AGREEMENT_SECTIONS_STATUS = "SET_AGREEMENT_SECTIONS_STATUS";
export interface SetAgreementSectionsStatusAction {
  type: typeof SET_AGREEMENT_SECTIONS_STATUS;
  payload: boolean;
}
export const setAgreementSectionStatus = (
  isFullPageLoading: boolean
): SetAgreementSectionsStatusAction => ({
  type: SET_AGREEMENT_SECTIONS_STATUS,
  payload: isFullPageLoading,
});
// Success
export const SET_AGREEMENT_SECTIONS_STATUS_SUCCESS =
  "SET_AGREEMENT_SECTIONS_STATUS_SUCCESS";
export interface SetAgreementSectionsStatusSuccessAction {
  type: typeof SET_AGREEMENT_SECTIONS_STATUS_SUCCESS;
  payload: AgreementSectionStatusType;
}
export const setAgreementSectionStatusSuccess = (
  agreementSectionStatus: AgreementSectionStatusType
): SetAgreementSectionsStatusSuccessAction => ({
  type: SET_AGREEMENT_SECTIONS_STATUS_SUCCESS,
  payload: agreementSectionStatus,
});
// Rejected
export const SET_AGREEMENT_SECTIONS_STATUS_REJECTED =
  "SET_AGREEMENT_SECTIONS_STATUS_REJECTED";
export interface SetAgreementSectionsStatusRejectedAction {
  type: typeof SET_AGREEMENT_SECTIONS_STATUS_REJECTED;
}
export const setAgreementSectionStatusRejected =
  (): SetAgreementSectionsStatusRejectedAction => ({
    type: SET_AGREEMENT_SECTIONS_STATUS_REJECTED,
  });

// SET STEP STATUS
// Pending
export const SET_AGREEMENT_STEPS_STATUS = "SET_AGREEMENT_STEPS_STATUS";
export interface SetAgreementStepsStatusAction {
  type: typeof SET_AGREEMENT_STEPS_STATUS;
  payload: { code: string; isFullPageLoading: boolean };
}
export const setAgreementStepStatus = (
  code: string,
  isFullPageLoading: boolean
): SetAgreementStepsStatusAction => ({
  type: SET_AGREEMENT_STEPS_STATUS,
  payload: { code, isFullPageLoading },
});
// Success
export const SET_AGREEMENT_STEPS_STATUS_SUCCESS =
  "SET_AGREEMENT_STEPS_STATUS_SUCCESS";
export interface SetAgreementStepsStatusSuccessAction {
  type: typeof SET_AGREEMENT_STEPS_STATUS_SUCCESS;
  payload?: AgreementStepStatusType;
}
export const setAgreementStepStatusSuccess = (
  agreementStepStatus?: AgreementStepStatusType
): SetAgreementStepsStatusSuccessAction => ({
  type: SET_AGREEMENT_STEPS_STATUS_SUCCESS,
  payload: agreementStepStatus,
});
// Rejected
export const SET_AGREEMENT_STEPS_STATUS_REJECTED =
  "SET_AGREEMENT_STEPS_STATUS_REJECTED";
export interface SetAgreementStepsStatusRejectedAction {
  type: typeof SET_AGREEMENT_STEPS_STATUS_REJECTED;
}
export const setAgreementStepStatusRejected =
  (): SetAgreementStepsStatusRejectedAction => ({
    type: SET_AGREEMENT_STEPS_STATUS_REJECTED,
  });

// SET CURRENT SECTION
export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";
export interface SetCurrentSectionAction {
  type: typeof SET_CURRENT_SECTION;
  payload: SectionType;
}
export const setCurrentSection = (
  currentSection: SectionType
): SetCurrentSectionAction => ({
  type: SET_CURRENT_SECTION,
  payload: currentSection,
});

// SET SECTIONS
export const SET_SECTIONS = "SET_SECTIONS";
export interface SetSectionsAction {
  type: typeof SET_SECTIONS;
  payload: Array<SectionType>;
}
export const setSections = (
  sections: Array<SectionType>
): SetSectionsAction => ({
  type: SET_SECTIONS,
  payload: sections,
});

// SET STEP ANSWER
export const SET_STEP_ANSWER = "SET_STEP_ANSWER";
export interface SetStepAnswerAction {
  type: typeof SET_STEP_ANSWER;
  payload: FormSectionStepConfirmationRequestType;
}
export const setStepAnswer = (
  answer: FormSectionStepConfirmationRequestType
): SetStepAnswerAction => ({
  type: SET_STEP_ANSWER,
  payload: answer,
});

// SET FIELDS VALIDATION
export type ValidationType = {
  isValid: boolean;
  errorMessage: string;
};
export type FieldsValidationType = {
  [key: string]: ValidationType;
};
export const SET_FIELDS_VALIDATION = "SET_FIELDS_VALIDATION";
export interface SetFieldsValidationAction {
  type: typeof SET_FIELDS_VALIDATION;
  payload?: FieldsValidationType;
}
export const setFieldsValidation = (
  fieldsValidation?: FieldsValidationType
): SetFieldsValidationAction => ({
  type: SET_FIELDS_VALIDATION,
  payload: fieldsValidation,
});

// SET STEP VALIDATION
export const SET_STEP_VALIDATION = "SET_STEP_VALIDATION";
export interface SetStepValidationAction {
  type: typeof SET_STEP_VALIDATION;
  payload?: ValidationType;
}
export const setStepValidation = (
  stepValidation?: ValidationType
): SetStepValidationAction => ({
  type: SET_STEP_VALIDATION,
  payload: stepValidation,
});

// SET CURRENT STEP STATUS
// Pending
type CurrentStepStatus = {
  currentSectionStep: FormSectionStepType | null;
  previousSectionStep: string | null;
  allStepsCompleted: boolean | null;
};
export const SET_CURRENT_STEP_STATUS = "SET_CURRENT_STEP_STATUS";
export interface SetCurrentStepStatusAction {
  type: typeof SET_CURRENT_STEP_STATUS;
  payload: { code: string; isFullPageLoading: boolean; isRefreshing: boolean };
}
export const setCurrentStepStatus = (
  code: string,
  isFullPageLoading: boolean = true,
  isRefreshing: boolean = false
): SetCurrentStepStatusAction => ({
  type: SET_CURRENT_STEP_STATUS,
  payload: { code, isFullPageLoading, isRefreshing },
});
// Success
export const SET_CURRENT_STEP_STATUS_SUCCESS =
  "SET_CURRENT_STEP_STATUS_SUCCESS";
export interface SetCurrentStepStatusSuccessAction {
  type: typeof SET_CURRENT_STEP_STATUS_SUCCESS;
  payload: CurrentStepStatus;
}
export const setCurrentStepStatusSuccess = (
  currentStepStatus: CurrentStepStatus
): SetCurrentStepStatusSuccessAction => ({
  type: SET_CURRENT_STEP_STATUS_SUCCESS,
  payload: currentStepStatus,
});
// Rejected
export const SET_CURRENT_STEP_STATUS_REJECTED =
  "SET_CURRENT_STEP_STATUS_REJECTED";
export interface SetCurrentStepStatusRejectedAction {
  type: typeof SET_CURRENT_STEP_STATUS_REJECTED;
}
export const setCurrentStepStatusRejected =
  (): SetCurrentStepStatusRejectedAction => ({
    type: SET_CURRENT_STEP_STATUS_REJECTED,
  });

// SET ALL STEPS COMPLETED
export const SET_ALL_STEPS_COMPLETED = "SET_ALL_STEPS_COMPLETED";
export interface SetAllStepsCompletedAction {
  type: typeof SET_ALL_STEPS_COMPLETED;
  payload: boolean | null;
}
export const setAllStepsCompleted = (
  allStepsCompleted: boolean | null
): SetAllStepsCompletedAction => ({
  type: SET_ALL_STEPS_COMPLETED,
  payload: allStepsCompleted,
});

// NAVIGATE TO SECTIONS INDEX
// Pending
export const NAVIGATE_TO_SECTIONS_INDEX = "NAVIGATE_TO_SECTIONS_INDEX";
export interface NavigateToSectionIndexAction {
  type: typeof NAVIGATE_TO_SECTIONS_INDEX;
  payload: {
    section?: SectionType;
    isFullPageLoading?: boolean;
  };
}
export const navigateToSectionIndex = (
  section?: SectionType,
  isFullPageLoading?: boolean
): NavigateToSectionIndexAction => ({
  type: NAVIGATE_TO_SECTIONS_INDEX,
  payload: { section, isFullPageLoading },
});
// Success
export const NAVIGATE_TO_SECTIONS_INDEX_SUCCESS =
  "NAVIGATE_TO_SECTIONS_INDEX_SUCCESS";
export interface NavigateToSectionIndexSuccessAction {
  type: typeof NAVIGATE_TO_SECTIONS_INDEX_SUCCESS;
}
export const navigateToSectionIndexSuccess =
  (): NavigateToSectionIndexSuccessAction => ({
    type: NAVIGATE_TO_SECTIONS_INDEX_SUCCESS,
  });
// Rejected
export const NAVIGATE_TO_SECTIONS_INDEX_REJECTED =
  "NAVIGATE_TO_SECTIONS_INDEX_REJECTED";
export interface NavigateToSectionIndexRejectedAction {
  type: typeof NAVIGATE_TO_SECTIONS_INDEX_REJECTED;
}
export const navigateToSectionIndexRejected =
  (): NavigateToSectionIndexRejectedAction => ({
    type: NAVIGATE_TO_SECTIONS_INDEX_REJECTED,
  });

// SET STEP LOADER
export const SET_IS_STEP_LOADING = "SET_IS_STEP_LOADING";
export interface SetIsStepLoadingAction {
  type: typeof SET_IS_STEP_LOADING;
  payload: boolean;
}
export const setIsStepLoading = (loading: boolean): SetIsStepLoadingAction => ({
  type: SET_IS_STEP_LOADING,
  payload: loading,
});

// CANCEL STEP EDITION
export const CANCEL_STEP_EDITION = "CANCEL_STEP_EDITION";
export interface CancelStepEditionAction {
  type: typeof CANCEL_STEP_EDITION;
}
export const cancelStepEdition = (): CancelStepEditionAction => ({
  type: CANCEL_STEP_EDITION,
});

// CONFIRM STEP
// Pending
export const CONFIRM_STEP = "CONFIRM_STEP";
export interface ConfirmStepAction {
  type: typeof CONFIRM_STEP;
  payload: {
    keepOnTheSameStep?: boolean;
    isApplyChanges?: boolean;
    onNextFunction?: () => void;
  };
}
export const confirmStep = (
  keepOnTheSameStep: boolean = false,
  isApplyChanges: boolean = false,
  onNextFunction?: () => void
): ConfirmStepAction => ({
  type: CONFIRM_STEP,
  payload: {
    keepOnTheSameStep,
    isApplyChanges,
    onNextFunction,
  },
});
// Success
export const CONFIRM_STEP_SUCCESS = "CONFIRM_STEP_SUCCESS";
export interface ConfirmStepSuccessAction {
  type: typeof CONFIRM_STEP_SUCCESS;
}
export const confirmStepSuccess = (): ConfirmStepSuccessAction => ({
  type: CONFIRM_STEP_SUCCESS,
});
// Rejected
export const CONFIRM_STEP_REJECTED = "CONFIRM_STEP_REJECTED";
export interface ConfirmStepRejectedAction {
  type: typeof CONFIRM_STEP_REJECTED;
}
export const confirmStepRejected = (): ConfirmStepRejectedAction => ({
  type: CONFIRM_STEP_REJECTED,
});

// RESET INITIAL STEP ANSWERS
export const RESET_INITIAL_STEP_ANSWERS = "RESET_INITIAL_STEP_ANSWERS";
export interface ResetInitialStepAnswersAction {
  type: typeof RESET_INITIAL_STEP_ANSWERS;
}

export const resetStepAnswer = (): ResetInitialStepAnswersAction => ({
  type: RESET_INITIAL_STEP_ANSWERS,
});

// CONDITIONAL CHECKBOX
export const CONDITIONAL_CHECKBOX = "CONDITIONAL_CHECKBOX";
export interface ConditionalCheckboxAction {
  type: typeof CONDITIONAL_CHECKBOX;
  payload: number;
}
export const conditionalCheckBox = (
  fieldIndex: number
): ConditionalCheckboxAction => ({
  type: CONDITIONAL_CHECKBOX,
  payload: fieldIndex,
});

// CONDITIONAL SWITCH
export const CONDITIONAL_SWITCH = "CONDITIONAL_SWITCH";
type ConditionalSwitchType = {
  index: number;
  optionId: string;
};
export interface ConditionalSwitchAction {
  type: typeof CONDITIONAL_SWITCH;
  payload: ConditionalSwitchType;
}
export const conditionalSwitch = (
  index: number,
  optionId: string
): ConditionalSwitchAction => ({
  type: CONDITIONAL_SWITCH,
  payload: { index, optionId },
});

// UPDATE AGREEMENT STATUS
// Pending
export const UPDATE_AGREEMENT_STATUS = "UPDATE_AGREEMENT_STATUS";
export interface UpdateAgreementStatusAction {
  type: typeof UPDATE_AGREEMENT_STATUS;
}
export const updateAgreementStatus = (): UpdateAgreementStatusAction => ({
  type: UPDATE_AGREEMENT_STATUS,
});
// Success
export const UPDATE_AGREEMENT_STATUS_SUCCESS =
  "UPDATE_AGREEMENT_STATUS_SUCCESS";
export interface UpdateAgreementStatusSuccessAction {
  type: typeof UPDATE_AGREEMENT_STATUS_SUCCESS;
}
export const updateAgreementStatusSuccess =
  (): UpdateAgreementStatusSuccessAction => ({
    type: UPDATE_AGREEMENT_STATUS_SUCCESS,
  });
// Rejected
export const UPDATE_AGREEMENT_STATUS_REJECTED =
  "UPDATE_AGREEMENT_STATUS_REJECTED";
export interface UpdateAgreementStatusRejectedAction {
  type: typeof UPDATE_AGREEMENT_STATUS_REJECTED;
}
export const updateAgreementStatusRejected =
  (): UpdateAgreementStatusRejectedAction => ({
    type: UPDATE_AGREEMENT_STATUS_REJECTED,
  });

// started governance
export const USER_STARTED_GOVERNANCE = "USER_STARTED_GOVERNANCE";
export interface UserStartedGovernanceAction {
  type: typeof USER_STARTED_GOVERNANCE;
}
export const userStartedGovernance = (): UserStartedGovernanceAction => ({
  type: USER_STARTED_GOVERNANCE,
});

export const USER_STARTED_GOVERNANCE_RECEIVED = "USER_STARTED_GOVERNANCE_RECEIVED";
export interface UserStartedGovernanceReceivedAction {
  type: typeof USER_STARTED_GOVERNANCE_RECEIVED;
}
export const userStartedGovernanceReceived = (): UserStartedGovernanceReceivedAction => ({
  type: USER_STARTED_GOVERNANCE_RECEIVED,
});

// started governance
export const USER_COMPLETED_GOVERNANCE = "USER_COMPLETED_GOVERNANCE";
export interface UserCompletedGovernanceAction {
  type: typeof USER_COMPLETED_GOVERNANCE;
}
export const userCompletedGovernance = (): UserCompletedGovernanceAction => ({
  type: USER_COMPLETED_GOVERNANCE,
});

export const USER_COMPLETED_GOVERNANCE_RECEIVED = "USER_COMPLETED_GOVERNANCE_RECEIVED";
export interface UserCompletedGovernanceReceivedAction {
  type: typeof USER_COMPLETED_GOVERNANCE_RECEIVED;
}
export const userCompletedGovernanceReceived = (): UserCompletedGovernanceReceivedAction => ({
  type: USER_COMPLETED_GOVERNANCE_RECEIVED,
});