//@ts-nocheck
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import { VerificationIdFormData } from "..";
import Api from "src/services/Api";
import { useDispatch, useSelector } from "react-redux";
import { setFullPageLoading, setMainHeader } from "src/store/actions/global";
import { updatePermissions, updateSessionSuccess } from "src/store/actions/auth";
import { useContext, useEffect, useState } from "react";
import { getDefaultOnboardingPermission } from "src/utils/helpers/permissions";
import Fail from "src/components/Fail";
import { SegmentEvents, SegmentResponseStatus } from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { RootState } from "../../../../store/reducers";
import { Session } from "../../../../services/Api/types";
import { captureException, captureMessage } from "@sentry/react";
import snsWebSdk from "@sumsub/websdk";
import SnsWebSdk from "@sumsub/websdk/types/SnsWebSdk";
import LocalStorage from "../../../../services/LocalStorage";

function IdVerification({
                          prevStep
                        }: MultiStepFormChildrenProps<VerificationIdFormData>) {
  const dispatch = useDispatch();
  const currentSession = useSelector(({ auth }: RootState) => auth.session) as Session;
  const {
    segmentTrack,
    segmentIdentifyUser,
    segmentGroup,
    marshalSegmentGroup
  } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);

  const snsWebSdkInstance = (token) => {
    const config = {
      lang: "en",
      version: 2,
      email: currentSession.user?.email,
      phone: currentSession.user?.phoneNumber,
      uiConf: {
        customCss: "https://assets.cobuy.io/css/sumsub.css"
      },
      onError: captureException
    };

    const verificationLoadedHandler = ({ applicantId }) => {
      LocalStorage.setItem("applicantId", applicantId);
    };

    const verificationCompleteHandler = (_payload) => {
      let applicantId: string;
      let intervalId = setInterval(() => {
        applicantId = LocalStorage.getItem("applicantId");

        if (applicantId && intervalId) {
          clearInterval(intervalId);
          intervalId = undefined;
          LocalStorage.removeItem("applicantId");

          validateIdentity(applicantId).catch(captureException);
          _removeSdkContainer()
        }
      }, 100);
    };

    const verificationErrorHandler = (error) => {
      _removeSdkContainer()
      setFailed(true);
      captureMessage(JSON.stringify(error));
    };

    const verificationStatusHandler = (_payload) => {
      const { reviewStatus, reviewResult } = _payload;

      if (reviewStatus === "completed" && typeof (reviewResult) !== "undefined") {
        const { moderationComment, reviewAnswer } = reviewResult;
        captureMessage(JSON.stringify(reviewResult));

        if (reviewAnswer === "RED") {
          _removeSdkContainer()
          setFailedMessage(moderationComment);
          setFailed(true)
        }
        else if (reviewAnswer === "GREEN") {
          verificationCompleteHandler(_payload)
        }
      }
    };

    const _removeSdkContainer = () => {
      const element = document.getElementById("sumsub-websdk-container");
      element.style.display = "none";
    }

    return snsWebSdk
      .init(token, async () => Api.createAccessToken().data.token)
      .withConf(config)
      .on("idCheck.onApplicantLoaded", verificationLoadedHandler)
      .on("idCheck.onError", verificationErrorHandler)
      .on("idCheck.applicantReviewComplete", (payload) => {
        verificationCompleteHandler(payload)
      })
      .on("idCheck.onApplicantReviewComplete", (payload) => {
        verificationCompleteHandler(payload)
      })
      .on("idCheck.onApplicantStatusChanged", (payload) => {
        verificationStatusHandler(payload)
      })
      .build();
  };

  useEffect(() => {
    dispatch(setMainHeader({ hide: !failed, hideBottomLine: failed }));
  }, [failed]);

  useEffect(() => {
    let sdkInstance: SnsWebSdk;

    const _launchSnsWebSdkInstance = async () => {
      const { data } = await Api.createAccessToken();
      sdkInstance = snsWebSdkInstance(data.token);
      sdkInstance.launch("#sumsub-websdk-container");
    };

    _launchSnsWebSdkInstance().catch(captureException);

    return () => {
      sdkInstance?.destroy();
    };
  }, []);

  const validateIdentity = async (applicantId: string) => {
    try {
      dispatch(setFullPageLoading(true));
      const { data } = await Api.getApplicationStatus(applicantId);
      const { success, ...session } = data;

      segmentIdentifyUser(session);
      segmentTrack(SegmentEvents.UserVerifiedID, {
        user_id: session?.user?.id,
        status: SegmentResponseStatus.Success,
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });

      if (session?.group.allMembersVerified) {
        segmentGroup(session?.group?.id, marshalSegmentGroup(session.group));
        segmentTrack(SegmentEvents.AccountVerifiedIDs, {
          group_id: session?.group?.id
        }, { groupId: session?.group?.id });
      }

      dispatch(updateSessionSuccess(session));
      dispatch(
        updatePermissions({
          permission: getDefaultOnboardingPermission(session)
        })
      );
    } catch (error) {
      captureMessage(JSON.stringify(error));
      segmentIdentifyUser(currentSession);
      segmentTrack(SegmentEvents.UserVerifiedID, {
        user_id: currentSession?.user?.id,
        user_to_group_id: currentSession?.userToGroupId,
        status: SegmentResponseStatus.Error
      }, { groupId: currentSession?.group?.id });
      setFailed(true);
    } finally {
      dispatch(setFullPageLoading(false));
    }
  };

  if (failed)
    return (
      <Fail
        title="Something went wrong, please try again later."
        buttonText="Try again"
        description={failedMessage}
        onButtonClick={() => prevStep()}
      />
    );

  return (
    <div className="vouched-element">
      <div id="sumsub-websdk-container" style={{ height: "100%", overflow: "auto", paddingBottom: "40px" }} />
    </div>
  );
}

export default IdVerification;
