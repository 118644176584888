//@ts-nocheck
import { dateDiffInDays, formatIsoDateFromString } from "src/utils/helpers/dates";
import { SegmentClosingDateStatus, SegmentUserType } from "../shared/enums";
import { GroupDto, UserAccountDto } from "../../services/Api/types";
import { groupVerificationStatus } from "../../utils/helpers/helpers";
import { CLOSING_DATE_LOWER_BOUND, CLOSING_DATE_UPPER_BOUND, SEGMENT_KEY } from "../../constants";
import { AnalyticsBrowser } from '@segment/analytics-next'

export const analytics = AnalyticsBrowser.load({
  writeKey: SEGMENT_KEY,
})

export const getClosingDateStatus = (closingDateString: string) => {
  const today = new Date();
  const date = formatIsoDateFromString(closingDateString);
  const closingDate = new Date(date);
  const diffInDays = dateDiffInDays(today, closingDate);
  if (diffInDays <= -CLOSING_DATE_LOWER_BOUND) return SegmentClosingDateStatus.ActingDelayed;
  else if (diffInDays > -CLOSING_DATE_LOWER_BOUND && diffInDays < 0)
    return SegmentClosingDateStatus.ActingAfterTheFact;
  else if (diffInDays >= 0 && diffInDays <= CLOSING_DATE_UPPER_BOUND)
    return SegmentClosingDateStatus.ActingEarly;
  else return SegmentClosingDateStatus.ActingTooEarly;
};

export const analyticsTrackEvent = async (session: any, eventName: string, properties?: {}) => {
  const defaultProperties = {
    user_id: session?.user?.id,
    user_to_group_id: session?.userToGroupId
  };

  await analyticsTrack(
    eventName, { ...defaultProperties, ...properties }, { groupId: session?.group?.id }
  )
};

export const analyticsIdentifyUser = (session: any, overrides?: any) => {

  return analytics.identify(session?.user?.id, {
    user_id: session?.user?.id,
    suffix: session?.user?.suffix,
    first_name: session?.user?.firstName,
    middle_initial: session?.user?.middleInitial,
    last_name: session?.user?.lastName,
    birthday: session?.user?.dateOfBirth,
    email: session?.user?.email,
    phone: session?.user?.phoneNumber,
    address: {
      street_address: session?.user?.address?.street_address,
      vicinity: session?.user?.address?.vicinity,
      county: session?.user?.address?.county,
      city: session?.user?.address?.locality,
      state: session?.user?.address?.region,
      postal_code: session?.user?.address?.postal_code,
      country: session?.user?.address?.country
    },
    full_name: `${session?.user?.firstName} ${session?.user?.lastName}`,
    formatted_address: `${session?.user?.address?.street_address}, ${session?.user?.address?.locality}, ${session?.user?.address?.region} ${session?.user?.address?.postal_code}`,
    current_group_id: session?.group?.id,
    created_at: new Date(session?.user?.createdAt).toISOString(),
    group_id: [session?.group?.id],
    user_to_group_id: session?.userToGroupId,
    id_verified: session?.user?.isIdentityVerified,
    user_type: session?.invitation ? SegmentUserType.Member : SegmentUserType.Leader,
    governance_status: overrides?.governance_status
      ? overrides?.governance_status
      : session?.user?.hasSignedMemoOfAgreement && session?.user?.hasSignedCoAgreement
        ? "Steady-state"
        : session?.user?.hasSignedMemoOfAgreement
          ? "MOA Signed"
          : session?.user?.hasSignedCoAgreement
            ? "COA Signed"
            : session?.user?.needToReviewCoAgreement
              ? "Needs to review"
              : session?.user?.hasCompletedCoAgreement
                ? "Completed"
                : session?.user?.hasStartedCoAgreement
                  ? "Started"
                  : " "
  });
};

export const analyticsIdentify = (
  user: Pick<UserAccountDto, "id" | "email" | "phoneNumber">
) =>
  analytics.identify(user.id, {
    email: user.email,
    phone: user.phoneNumber
  });

export const analyticsReset = () => analytics.reset();
export const analyticsPage = (title: string, path: string) => analytics.page({ title, path });
export const analyticsGroup = (groupId: string, properties: Object) => analytics.group(groupId, properties);
export const analyticsTrack = (event: string, properties?: Object, context?: Object) => {
  return analytics.track(event, properties, { context });
};

export const marshalAnalyticsGroup = (group: GroupDto, overrides?: any) => {
  const started = group?.users?.filter(u => u.hasStartedCoAgreement).length >= 1;
  const completed = group?.users?.filter(u => u.hasStartedCoAgreement).length === group.users.length;
  const COASigned = group?.users?.filter(u => u.hasSignedCoAgreement).length >= 1;
  const COAExecuted = group?.allMemberSignedCoAgreement;
  const MOASigned = group?.users?.filter(u => u.hasSignedMemoOfAgreement).length >= 1;
  const MOAExecuted = group?.allMemberSignedMemoOfAgreement;

  return {
    group_id: group.id,
    created_at: group.createdAt,
    address: {
      street_address: group.propertyAddress.street_address,
      city: group.propertyAddress.locality,
      state: group.propertyAddress.region,
      county: group.propertyAddress.county,
      postal_code: group.propertyAddress.postal_code
    },
    group_size: overrides?.group_size ? overrides?.group_size : group.invitations.length + group.users.length,
    closing_date: group.closingPurchaseDate,
    group_status: "Active",
    id_verification_status: groupVerificationStatus(group.users),
    subscription_status: group.isSubscribed ? "Subscribed" : "Inactive",
    governance_status: overrides?.governance_status ? overrides?.governance_status : (MOAExecuted
      ? "MOA Executed"
      : MOASigned
        ? "MOA Signed"
        : COAExecuted
          ? "COA Executed"
          : COASigned
            ? "COA Signed"
            : completed
              ? "Completed"
              : started
                ? "Started"
                : " "),
    relationships: group.relations?.join(", ")

  };
};
