import { useDispatch } from "react-redux";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import { updateSession } from "src/store/actions/auth";
import { showModal } from "src/store/actions/modal";
import useSubscription from "src/utils/customHooks/useSubscription";

function PendingConsensusBannerContent() {
  const dispatch = useDispatch();
  const { subscribe } = useSubscription({
    onSuccess,
  });

  function onSuccess() {
    dispatch(
      showModal(MODAL_TOAST, {
        message: "Payment successful",
      })
    );
    dispatch(updateSession(false, true, true));
  }

  return (
    <>
      Your group subscription has expired.{" "}
      <button
        type={"button"}
        className="inline-button"
        onClick={() => subscribe()}
      >
        Click here to renew
      </button>
    </>
  );
}

export default PendingConsensusBannerContent;
