type ChatGroupProps = {
  name: string;
  newMessages: number;
  onClick: () => void;
  isActive: boolean;
};

function ChatGroup({
  name,
  newMessages,
  onClick,
  isActive = false,
}: ChatGroupProps) {
  const isActiveClass = isActive ? "active" : "";
  return (
    <div className={`chat-group ${isActiveClass}`} onClick={onClick}>
      <i className="icon-users" />
      <span className="name">{name}</span>
      {newMessages !== 0 && (
        <span className="badge-number">
          {newMessages > 99 ? "+99" : newMessages}
        </span>
      )}
      <i className="icon-arrow-right" />
    </div>
  );
}

export default ChatGroup;
