import Radio from "./Radio";

type RadioButtonProps = {
  id?: string;
  name?: string;
  label?: string;
  value?: string;
  checked?: boolean;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
};

function RadioButton({
  id,
  name,
  label,
  value,
  checked,
  onChange,
  isDisabled,
}: RadioButtonProps) {
  const disabledClass = isDisabled ? "disabled" : "";
  const checkedClass = checked ? "checked" : "";

  return (
    <div className={`radio-box ${checkedClass} ${disabledClass}`}>
      <Radio
        id={id}
        name={name}
        label={label}
        value={value}
        checked={checked}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default RadioButton;
