import { DocumentCardType, Pagination, UploadDocumentType, VaultContent, VaultDocument } from "src/services/Api/types";
import { Page, PagePermission } from "src/utils/helpers/permissions";

// GET VAULT
// Pending
export const GET_VAULT = "GET_VAULT";
export interface GetVaultAction {
  type: typeof GET_VAULT;
}
export const getVault = (): GetVaultAction => ({
  type: GET_VAULT,
});
// Success
export const GET_VAULT_SUCCESS = "GET_VAULT_SUCCESS";
export interface GetVaultSuccessAction {
  type: typeof GET_VAULT_SUCCESS;
  payload: { vaultContent: VaultContent };
}
export const getVaultSuccess = (
  vaultContent: VaultContent
): GetVaultSuccessAction => ({
  type: GET_VAULT_SUCCESS,
  payload: { vaultContent },
});
// Rejected
export const GET_VAULT_REJECTED = "GET_VAULT_REJECTED";
export interface GetVaultRejectedAction {
  type: typeof GET_VAULT_REJECTED;
}
export const getVaultRejected = (): GetVaultRejectedAction => ({
  type: GET_VAULT_REJECTED,
});

// UPLOAD DOCUMENT
// Pending
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export interface UploadDocumentAction {
  type: typeof UPLOAD_DOCUMENT;
  payload: { document: UploadDocumentType };
}
export const uploadDocument = (
  document: UploadDocumentType
): UploadDocumentAction => ({
  type: UPLOAD_DOCUMENT,
  payload: { document },
});
// Success
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export interface UploadDocumentSuccessAction {
  type: typeof UPLOAD_DOCUMENT_SUCCESS;
}
export const uploadDocumentSuccess = (): UploadDocumentSuccessAction => ({
  type: UPLOAD_DOCUMENT_SUCCESS,
});
// Rejected
export const UPLOAD_DOCUMENT_REJECTED = "UPLOAD_DOCUMENT_REJECTED";
export interface UploadDocumentRejectedAction {
  type: typeof UPLOAD_DOCUMENT_REJECTED;
}
export const uploadDocumentRejected = (): UploadDocumentRejectedAction => ({
  type: UPLOAD_DOCUMENT_REJECTED,
});

// REJECT DOCUMENT
// Pending
export const REJECT_DOCUMENT = "REJECT_DOCUMENT";
export interface RejectDocumentAction {
  type: typeof REJECT_DOCUMENT;
  payload: { id: string };
}
export const rejectDocument = (id: string): RejectDocumentAction => ({
  type: REJECT_DOCUMENT,
  payload: { id },
});
// Success
export const REJECT_DOCUMENT_SUCCESS = "REJECT_DOCUMENT_SUCCESS";
export interface RejectDocumentSuccessAction {
  type: typeof REJECT_DOCUMENT_SUCCESS;
}
export const rejectDocumentSuccess = (): RejectDocumentSuccessAction => ({
  type: REJECT_DOCUMENT_SUCCESS,
});
// Rejected
export const REJECT_DOCUMENT_REJECTED = "REJECT_DOCUMENT_REJECTED";
export interface RejectDocumentRejectedAction {
  type: typeof REJECT_DOCUMENT_REJECTED;
}
export const rejectDocumentRejected = (): RejectDocumentRejectedAction => ({
  type: REJECT_DOCUMENT_REJECTED,
});

// COMPLETE
export const REJECT_DOCUMENT_COMPLETE = "REJECT_DOCUMENT_COMPLETE";
export interface RejectDocumentCompleteAction {
  type: typeof REJECT_DOCUMENT_COMPLETE;
}
export const rejectDocumentComplete = (): RejectDocumentCompleteAction => ({
  type: REJECT_DOCUMENT_COMPLETE,
});

// GET VAULT DOCUMENT
// Pending
export const GET_VAULT_DOCUMENT = "GET_VAULT_DOCUMENT";
export interface GetVaultDocumentAction {
  type: typeof GET_VAULT_DOCUMENT;
  payload: { id: string; isUpdate?: boolean };
}
export const getVaultDocument = (
  id: string,
  isUpdate?: boolean
): GetVaultDocumentAction => ({
  type: GET_VAULT_DOCUMENT,
  payload: { id, isUpdate },
});
// Success
export const GET_VAULT_DOCUMENT_SUCCESS = "GET_VAULT_DOCUMENT_SUCCESS";
export interface GetVaultDocumentSuccessAction {
  type: typeof GET_VAULT_DOCUMENT_SUCCESS;
  payload: { vaultDocument: Partial<VaultDocument> };
}
export const getVaultDocumentSuccess = (
  vaultDocument: Partial<VaultDocument>
): GetVaultDocumentSuccessAction => ({
  type: GET_VAULT_DOCUMENT_SUCCESS,
  payload: { vaultDocument },
});
// Rejected
export const GET_VAULT_DOCUMENT_REJECTED = "GET_VAULT_DOCUMENT_REJECTED";
export interface GetVaultDocumentRejectedAction {
  type: typeof GET_VAULT_DOCUMENT_REJECTED;
}
export const getVaultDocumentRejected = (): GetVaultDocumentRejectedAction => ({
  type: GET_VAULT_DOCUMENT_REJECTED,
});

// GET VAULT DOCUMENT HISTORY
// Pending
export const GET_VAULT_DOCUMENT_HISTORY = "GET_VAULT_DOCUMENT_HISTORY";
export interface GetVaultDocumentHistoryAction {
  type: typeof GET_VAULT_DOCUMENT_HISTORY;
  payload: { id: string };
}
export const getVaultDocumentHistory = (
  id: string
): GetVaultDocumentHistoryAction => ({
  type: GET_VAULT_DOCUMENT_HISTORY,
  payload: { id },
});
// Success
export const GET_VAULT_DOCUMENT_HISTORY_SUCCESS =
  "GET_VAULT_DOCUMENT_HISTORY_SUCCESS";
export interface GetVaultDocumentHistorySuccessAction {
  type: typeof GET_VAULT_DOCUMENT_HISTORY_SUCCESS;
  payload: { vaultDocumentHistory: Pagination<DocumentCardType> };
}
export const getVaultDocumentHistorySuccess = (
  vaultDocumentHistory: Pagination<DocumentCardType>
): GetVaultDocumentHistorySuccessAction => ({
  type: GET_VAULT_DOCUMENT_HISTORY_SUCCESS,
  payload: { vaultDocumentHistory },
});
// Rejected
export const GET_VAULT_DOCUMENT_HISTORY_REJECTED =
  "GET_VAULT_DOCUMENT_HISTORY_REJECTED";
export interface GetVaultDocumentHistoryRejectedAction {
  type: typeof GET_VAULT_DOCUMENT_HISTORY_REJECTED;
}
export const getVaultDocumentHistoryRejected =
  (): GetVaultDocumentHistoryRejectedAction => ({
    type: GET_VAULT_DOCUMENT_HISTORY_REJECTED,
  });

// GET NEXT PAGE VAULT DOCUMENT HISTORY
// Pending
export const GET_NEXT_PAGE_DOCUMENT_HISTORY = "GET_NEXT_PAGE_DOCUMENT_HISTORY";
export interface GetNextPageDocumentHistoryAction {
  type: typeof GET_NEXT_PAGE_DOCUMENT_HISTORY;
  payload: { id: string };
}
export const getNextPageDocumentHistory = (
  id: string
): GetNextPageDocumentHistoryAction => ({
  type: GET_NEXT_PAGE_DOCUMENT_HISTORY,
  payload: { id },
});
// Success
export const GET_NEXT_PAGE_DOCUMENT_HISTORY_SUCCESS =
  "GET_NEXT_PAGE_DOCUMENT_HISTORY_SUCCESS";
export interface GetNextPageDocumentHistorySuccessAction {
  type: typeof GET_NEXT_PAGE_DOCUMENT_HISTORY_SUCCESS;
  payload: { vaultDocumentHistory: Pagination<DocumentCardType> };
}
export const getNextPageDocumentHistorySuccess = (
  vaultDocumentHistory: Pagination<DocumentCardType>
): GetNextPageDocumentHistorySuccessAction => ({
  type: GET_NEXT_PAGE_DOCUMENT_HISTORY_SUCCESS,
  payload: { vaultDocumentHistory },
});
// Rejected
export const GET_NEXT_PAGE_DOCUMENT_HISTORY_REJECTED =
  "GET_NEXT_PAGE_DOCUMENT_HISTORY_REJECTED";
export interface GetNextPageDocumentHistoryRejectedAction {
  type: typeof GET_NEXT_PAGE_DOCUMENT_HISTORY_REJECTED;
}
export const getNextPageDocumentHistoryRejected =
  (): GetNextPageDocumentHistoryRejectedAction => ({
    type: GET_NEXT_PAGE_DOCUMENT_HISTORY_REJECTED,
  });

// SET SELECTED ID DOCUMENT
export const SET_SELECTED_DOCUMENT_ID = "SET_SELECTED_DOCUMENT_ID";
export interface SetSelectedDocumentIdAction {
  type: typeof SET_SELECTED_DOCUMENT_ID;
  payload: { id: string | null };
}
export const setSelectedDocumentId = (
  id: string | null
): SetSelectedDocumentIdAction => ({
  type: SET_SELECTED_DOCUMENT_ID,
  payload: { id },
});

// SET PREVIOUS DOCUMENT HISTORY PERMISSION
export const SET_PREVIOUS_DOCUMENT_HISTORY_PERMISSION =
  "SET_PREVIOUS_DOCUMENT_HISTORY_PERMISSION";
export interface SetPreviousDocumentHistoryPermissionAction {
  type: typeof SET_PREVIOUS_DOCUMENT_HISTORY_PERMISSION;
  payload: {
    page: Page;
    permission: PagePermission;
  };
}
export const setPreviousDocumentHistoryPermission = (
  page: Page,
  permission: PagePermission
): SetPreviousDocumentHistoryPermissionAction => ({
  type: SET_PREVIOUS_DOCUMENT_HISTORY_PERMISSION,
  payload: { page, permission },
});

// SET PREVIOUS VAULT DOCUMENT PERMISSION
export const SET_PREVIOUS_VAULT_DOCUMENT_PERMISSION =
  "SET_PREVIOUS_VAULT_DOCUMENT_PERMISSION";
export interface SetPreviousVaultDocumentPermissionAction {
  type: typeof SET_PREVIOUS_VAULT_DOCUMENT_PERMISSION;
  payload: {
    page: Page;
    permission: PagePermission;
  };
}
export const setPreviousVaultDocumentPermission = (
  page: Page,
  permission: PagePermission
): SetPreviousVaultDocumentPermissionAction => ({
  type: SET_PREVIOUS_VAULT_DOCUMENT_PERMISSION,
  payload: { page, permission },
});

// DOWNLOAD FILE
// Pending
export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT";
export interface DownloadDocumentAction {
  type: typeof DOWNLOAD_DOCUMENT;
  payload: { id: string; fileName: string };
}
export const downloadDocument = (
  id: string,
  fileName: string
): DownloadDocumentAction => ({
  type: DOWNLOAD_DOCUMENT,
  payload: { id, fileName },
});
// Success
export const DOWNLOAD_DOCUMENT_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS";

export interface DownloadDocumentSuccessAction {
  type: typeof DOWNLOAD_DOCUMENT_SUCCESS;
}
export const downloadDocumentSuccess = (): DownloadDocumentSuccessAction => ({
  type: DOWNLOAD_DOCUMENT_SUCCESS,
});
// Rejected
export const DOWNLOAD_DOCUMENT_REJECTED = "DOWNLOAD_DOCUMENT_REJECTED";
export interface DownloadDocumentRejectedAction {
  type: typeof DOWNLOAD_DOCUMENT_REJECTED;
}
export const downloadDocumentRejected = (): DownloadDocumentRejectedAction => ({
  type: DOWNLOAD_DOCUMENT_REJECTED,
});

// Complete
export const DOWNLOAD_DOCUMENT_COMPLETE = "DOWNLOAD_DOCUMENT_COMPLETE";
export interface DownloadDocumentCompleteAction {
  type: typeof DOWNLOAD_DOCUMENT_COMPLETE;
}
export const downloadDocumentComplete = (): DownloadDocumentCompleteAction => ({
  type: DOWNLOAD_DOCUMENT_COMPLETE,
});
