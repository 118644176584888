//@ts-nocheck
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import RadioButtonGroup from "src/components/inputs/RadioButtonGroup";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import { SegmentEvents } from "src/components/shared/enums";
import Api from "src/services/Api";
import { DoesLiveAtCoOwnedHome, YesNoOptions } from "src/services/Api/enums";
import {
  updatePermissions,
  updateSessionSuccess,
} from "src/store/actions/auth";
import { OnboardingPermission } from "src/utils/helpers/permissions";
import { IndividualInputsFormData } from "..";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { captureMessage } from "@sentry/react";

const yesNoOptions = [
  { id: YesNoOptions.Yes, value: "Yes" },
  { id: YesNoOptions.No, value: "No" },
];

function CoBorrowerData({
  formData,
  nextStep,
  prevStep,
  saveData,
  progress,
}: MultiStepFormChildrenProps<IndividualInputsFormData>) {
  const { segmentTrack, segmentIdentifyUser } = useContext(AnalyticsContext) as AnalyticsContextType
  const [stepData, setStepData] = useState(formData?.coBorrower);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onNextClick = async () => {
    saveData({ coBorrower: stepData });

    try {
      setLoading(true);
      const address =
        formData.coOwnedHomeData.live === DoesLiveAtCoOwnedHome.Yes
          ? undefined
          : {
              street_address: formData.coOwnedHomeData.address?.address!,
              vicinity: formData.coOwnedHomeData.address?.vicinity!,
              county: formData.coOwnedHomeData.address?.county!,
              locality: formData.coOwnedHomeData.address?.city!,
              region: formData.coOwnedHomeData.address?.state!,
              postal_code: formData.coOwnedHomeData.address?.zipCode!,
              country: formData.coOwnedHomeData.address?.country!,
            };

      const userData = {
        firstName: formData.fullNameData.firstName!,
        lastName: formData.fullNameData.lastName!,
        middleInitial: formData.fullNameData.mi!,
        suffix: formData.fullNameData.suffix!,
        address: address,
        dateOfBirth: formData.birthDate!,
        doesLiveAtTheCoOwnedHome: formData.coOwnedHomeData.live!,
        isCoBorrowerOnAMortgage: stepData === "Yes",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      const { data } = await Api.completeSignUp(userData);
      const { success, hasCompletedGroupInvitation, ...session } = data;
      dispatch(updateSessionSuccess(session))
      segmentIdentifyUser(session);
      segmentTrack(SegmentEvents.UserDetailsStepSubmitted, {
        step: "Are you listed on the mortgage as a co-borrower?",
        data: stepData,
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });
      dispatch(updateSessionSuccess(session));
      setLoading(false);

      if (data.hasCompletedGroupInvitation) {
        nextStep();
      } else {
        dispatch(
          updatePermissions({
            permission: OnboardingPermission.GROUP_CREATION,
          })
        );
      }
    } catch (error) {
      captureMessage(JSON.stringify(error))
      setFailed(true);
      setLoading(false);
    }
  };

  return (
    <Step
      title="Are you listed on the mortgage as a 
      co-borrower?"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={!stepData}
      isButtonLoading={loading}
      progress={progress}
      onBackClick={() => {
        if (formData.coOwnedHomeData.live === "Yes") {
          prevStep(4);
        } else prevStep();
      }}
      tooltip={`<p>
            A <strong>co-borrower</strong> is anyone listed on a mortgage
            involving more than one party. Co-borrowers are
            <em>joint and severally liable</em>: each co-borrower is personally
            responsible for the repayment of debt in full.
          </p>`}
      failed={failed}
      failedTitle="Something went wrong, please try again later."
      failedNextText="Try again"
      onFailedNextClick={() => prevStep(2)}
    >
      <RadioButtonGroup
        options={yesNoOptions}
        selectedId={stepData}
        isDisabled={loading}
        onChange={(value: YesNoOptions.Yes | YesNoOptions.No) =>
          setStepData(value)
        }
      />
    </Step>
  );
}

export default CoBorrowerData;
