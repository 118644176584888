import { Option } from "src/components/shared/types";
import RadioButton from "./RadioButton";

type RadioButtonGroupProps = {
  options: Array<Option>;
  selectedId?: string | null;
  onChange?: (value?: any) => void;
  isDisabled?: boolean;
};

function RadioButtonGroup({
  options,
  selectedId,
  onChange,
  isDisabled,
}: RadioButtonGroupProps) {
  const showDisabledNote =
    options.some((x) => x.disabled === false) &&
    options.some((x) => x.disabled === true);
  const note = showDisabledNote
    ? options.filter((x) => x.disabled === true)[0].note
    : options.find((field) => field.id === selectedId)?.note;
  return (
    <div className="radio-button-group">
      {options.map(({ id, value, disabled }) => (
        <RadioButton
          id={id}
          key={id}
          name={id}
          label={value}
          value={id}
          checked={id === selectedId}
          onChange={onChange}
          isDisabled={isDisabled || disabled}
        />
      ))}
      {note && (
        <div className={`${showDisabledNote ? "note" : "invalid-input"}`}>
          {note}
        </div>
      )}
    </div>
  );
}

export default RadioButtonGroup;
