import { addThousandsSeparator } from "src/utils/helpers/helpers";
import { INTEGER_REGEX } from "../shared/constants";

type SliderProps = {
  id?: string;
  name?: string;
  min?: number;
  max?: number;
  value?: string;
  step?: number;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
};

function Slider({
  id,
  name,
  min = 0,
  max = 100,
  value,
  step,
  onChange,
  isDisabled,
}: SliderProps) {
  const disabledClass = isDisabled ? "disabled" : "";
  const percentageValue = value ? (parseFloat(value) * 100) / max : 0;
  const maskedValue = value ? `$${addThousandsSeparator(value)}` : "";

  const _onChange = (maskedValue: string) => {
    // Remove "," from the thousands separator and "$"
    const value = maskedValue.replaceAll(",", "").replaceAll("$", "");

    // Check correct value before change
    const regExp = new RegExp(INTEGER_REGEX);
    if (+value >= min && +value <= max && regExp.test(value))
      onChange && onChange(value);
  };

  return (
    <div className={`slider ${disabledClass}`}>
      <input
        type="tel"
        className="slider-value"
        value={maskedValue}
        max={max}
        min={min}
        onChange={(e) => _onChange(e.target.value)}
      />
      <div className="progress" style={{ width: `${percentageValue}%` }}></div>
      <input
        id={id}
        name={name}
        type="range"
        min={min}
        max={max}
        value={value}
        step={step}
        onChange={(e) => _onChange(e.target.value)}
        disabled={isDisabled}
      />
    </div>
  );
}

export default Slider;
