import { useState, useEffect } from "react";

type useCountDownProps = {
  initialTime: number;
  item: string;
};

function useCountDown({ initialTime = 30, item }: useCountDownProps) {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return {
    setTimeLeft,
    timeLeft,
    messageText: `Resend ${item} in ${timeLeft}s`,
  };
}

export default useCountDown;
