import { AgreementDocumentCode } from "src/components/shared/enums";
import { ConsensusEventStatusDto, PendingConsensusActions } from "src/services/Api/types";
import {
  CONSENT_DOCUMENT,
  CONSENT_DOCUMENT_COMPLETE,
  CONSENT_DOCUMENT_REJECTED,
  CONSENT_DOCUMENT_SUCCESS,
  ConsentDocumentAction,
  ConsentDocumentCompleteAction,
  ConsentDocumentRejectedAction,
  ConsentDocumentSuccessAction,
  GET_AGREEMENT_HASH,
  GET_AGREEMENT_HASH_REJECTED,
  GET_AGREEMENT_HASH_SUCCESS,
  GET_AGREEMENT_STATUS,
  GET_AGREEMENT_STATUS_REJECTED,
  GET_AGREEMENT_STATUS_SUCCESS,
  GET_PENDING_CONSENSUS_ACTIONS,
  GET_PENDING_CONSENSUS_ACTIONS_REJECTED,
  GET_PENDING_CONSENSUS_ACTIONS_SUCCESS,
  GetAgreementHashAction,
  GetAgreementHashRejectedAction,
  GetAgreementHashSuccessAction,
  GetAgreementStatusAction,
  GetAgreementStatusRejectedAction,
  GetAgreementStatusSuccessAction,
  GetPendingConsensusActionsAction,
  GetPendingConsensusActionsRejectedAction,
  GetPendingConsensusActionsSuccessAction,
  SIGN_ADD_CO_OWNER,
  SIGN_ADD_CO_OWNER_COMPLETED,
  SIGN_ADD_CO_OWNER_REJECTED,
  SIGN_ADD_CO_OWNER_SUCCESS,
  SIGN_AGREEMENT,
  SIGN_AGREEMENT_RECEIVED,
  SIGN_AGREEMENT_REJECTED,
  SIGN_AGREEMENT_SUCCESS,
  SIGN_DELETE_CO_OWNER,
  SIGN_DELETE_CO_OWNER_COMPLETE,
  SIGN_DELETE_CO_OWNER_REJECTED,
  SIGN_DELETE_CO_OWNER_SUCCESS,
  SignAddCoOwnerAction,
  SignAddCoOwnerCompletedAction,
  SignAddCoOwnerRejectedAction,
  SignAddCoOwnerSuccessAction,
  SignAgreementAction,
  SignAgreementReceivedAction,
  SignAgreementRejectedAction,
  SignAgreementSuccessAction,
  SignDeleteCoOwnerAction,
  SignDeleteCoOwnerCompleteAction,
  SignDeleteCoOwnerRejectedAction,
  SignDeleteCoOwnerSuccessAction
} from "../actions/signature";

export type AgreementStatus = {
  hash: { value: string | null; isGettingHash: boolean };
  agreementStatus: {
    value: ConsensusEventStatusDto | null;
    isGettingAgreementStatus: boolean;
  };
};

export const initialState: {
  isSigning: boolean;
  signingSuccess: boolean | null;
  signingRejected: boolean | null;
  signingAgreementCode: string | null;
  signingAddCoOwner: boolean | null;
  agreements: Record<AgreementDocumentCode, AgreementStatus>;
  coOwners: {
    isGettingPendingConsensusActions: boolean;
    pendingConsensusActions: PendingConsensusActions;
  };
  signAddCoOwnerSuccess: boolean | null;
  signAddCoOwnerRejected: boolean | null;
  signDeleteCoOwnerSuccess: boolean | null;
  signDeleteCoOwnerRejected: boolean | null;
  deleteCoOwner: {
    eventId: string | null;
    userIdToDelete: string | null;
    isDeletingCoOwner: boolean | null;
  }
} = {
  isSigning: false,
  signingRejected: null,
  signingSuccess: null,
  signingAgreementCode: null,
  signAddCoOwnerSuccess: null,
  signAddCoOwnerRejected: null,
  signingAddCoOwner: null,
  signDeleteCoOwnerSuccess: null,
  signDeleteCoOwnerRejected: null,
  deleteCoOwner: {
    eventId: null,
    userIdToDelete: null,
    isDeletingCoOwner: null
  },
  agreements: {
    [AgreementDocumentCode.CoOwnershipAgreement]: {
      hash: { value: null, isGettingHash: false },
      agreementStatus: {
        value: null,
        isGettingAgreementStatus: false
      }
    },
    [AgreementDocumentCode.MemorandumOfAgreement]: {
      hash: { value: null, isGettingHash: false },
      agreementStatus: {
        value: null,
        isGettingAgreementStatus: false
      }
    }
  },
  coOwners: {
    isGettingPendingConsensusActions: false,
    pendingConsensusActions: {
      eventId: null,
      userIdToRemove: null,
      createdByUserId: null,
      eventExpirationDate: null,
      invitedUserEmail: null,
      invitedUserFirstName: null,
      userIdsMissingToConfirmEvent: [],
      userIdsThatConfirmedEvent: []
    }
  }
};

export function signatureReducer(
  state = initialState,
  action:
    | GetAgreementHashAction
    | GetAgreementHashSuccessAction
    | GetAgreementHashRejectedAction
    | GetAgreementStatusAction
    | GetAgreementStatusSuccessAction
    | GetAgreementStatusRejectedAction
    | SignAgreementAction
    | SignAgreementSuccessAction
    | SignAgreementRejectedAction
    | SignAddCoOwnerAction
    | SignAddCoOwnerSuccessAction
    | SignAddCoOwnerRejectedAction
    | SignDeleteCoOwnerAction
    | SignDeleteCoOwnerSuccessAction
    | SignDeleteCoOwnerRejectedAction
    | GetPendingConsensusActionsAction
    | GetPendingConsensusActionsSuccessAction
    | GetPendingConsensusActionsRejectedAction
    | ConsentDocumentAction
    | ConsentDocumentSuccessAction
    | ConsentDocumentRejectedAction
    | ConsentDocumentCompleteAction
    | SignAgreementReceivedAction
    | SignDeleteCoOwnerCompleteAction
    | SignAddCoOwnerCompletedAction
) {
  switch (action.type) {
    case GET_AGREEMENT_HASH:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          [action.payload.code]: {
            ...state.agreements[action.payload.code],
            hash: {
              ...state.agreements[action.payload.code].hash,
              isGettingHash: true
            }
          }
        }
      };
    case GET_AGREEMENT_HASH_SUCCESS:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          [action.payload.code]: {
            ...state.agreements[action.payload.code],
            hash: { value: action.payload.hash, isGettingHash: false }
          }
        }
      };
    case GET_AGREEMENT_HASH_REJECTED:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          [action.payload.code]: {
            ...state.agreements[action.payload.code],
            hash: {
              ...state.agreements[action.payload.code].hash,
              isGettingHash: false
            }
          }
        }
      };
    case GET_AGREEMENT_STATUS:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          [action.payload.code]: {
            ...state.agreements[action.payload.code],
            agreementStatus: {
              ...state.agreements[action.payload.code].agreementStatus,
              isGettingAgreementStatus: true
            }
          }
        }
      };
    case GET_AGREEMENT_STATUS_SUCCESS:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          [action.payload.code]: {
            ...state.agreements[action.payload.code],
            agreementStatus: {
              value: action.payload.agreementStatus,
              isGettingAgreementStatus: false
            }
          }
        }
      };
    case GET_AGREEMENT_STATUS_REJECTED:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          [action.payload.code]: {
            ...state.agreements[action.payload.code],
            agreementStatus: {
              ...state.agreements[action.payload.code].agreementStatus,
              isGettingAgreementStatus: false
            }
          }
        }
      };
    case SIGN_AGREEMENT:
      return {
        ...state,
        isSigning: true,
        signingAgreementCode: action.payload.code
      };
    case SIGN_ADD_CO_OWNER:
      return {
        ...state,
        isSigning: true,
        signingAddCoOwner: true
      };
    case SIGN_DELETE_CO_OWNER:
      return {
        ...state,
        isSigning: true,
        signDeleteCoOwnerSuccess: false,
        deleteCoOwner: {
          ...state.deleteCoOwner,
          isDeletingCoOwner: true
        }
      };
    case CONSENT_DOCUMENT:
      return {
        ...state,
        isSigning: true
      };
    case SIGN_AGREEMENT_SUCCESS:
      return {
        ...state,
        signingSuccess: true,
        isSigning: false
      };
    case SIGN_AGREEMENT_REJECTED:
      return {
        ...state,
        isSigning: false,
        signingRejected: true
      };
    case SIGN_AGREEMENT_RECEIVED:
      return {
        ...state,
        signingRejected: null,
        signingSuccess: null,
        signingAgreementCode: null
      };
    case SIGN_ADD_CO_OWNER_SUCCESS:
      return {
        ...state,
        isSigning: false,
        signAddCoOwnerSuccess: true
      };
    case SIGN_ADD_CO_OWNER_REJECTED:
      return {
        ...state,
        isSigning: false,
        signAddCoOwnerRejected: true
      };
    case SIGN_ADD_CO_OWNER_COMPLETED:
      return {
        ...state,
        signAddCoOwnerSuccess: null,
        signAddCoOwnerRejected: null,
        signingAddCoOwner: null
      };
    case SIGN_DELETE_CO_OWNER_SUCCESS:
      return {
        ...state,
        isSigning: false,
        signDeleteCoOwnerSuccess: true,
        deleteCoOwner: {
          ...state.deleteCoOwner,
          isDeletingCoOwner: false
        }
      };
    case SIGN_DELETE_CO_OWNER_REJECTED:
      return {
        ...state,
        isSigning: false,
        signDeleteCoOwnerRejected: true,
        deleteCoOwner: {
          ...state.deleteCoOwner,
          isDeletingCoOwner: false
        }
      };
    case SIGN_DELETE_CO_OWNER_COMPLETE:
      return {
        ...state,
        isSigning: false,
        signDeleteCoOwnerRejected: null,
        signDeleteCoOwnerSuccess: null,
        deleteCoOwner: {
          eventId: null,
          userIdToDelete: null,
          isDeletingCoOwner: null
        }
      };
    case CONSENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        isSigning: false,
        signingSuccess: true
      };
    case CONSENT_DOCUMENT_REJECTED:
      return {
        ...state,
        isSigning: false,
        signingRejected: true
      };
    case CONSENT_DOCUMENT_COMPLETE:
      return {
        ...state,
        signingSuccess: null,
        signingRejected: null
      };
    case GET_PENDING_CONSENSUS_ACTIONS:
      return {
        ...state,
        coOwners: { ...state.coOwners, isGettingPendingConsensusActions: true }
      };
    case GET_PENDING_CONSENSUS_ACTIONS_SUCCESS:
      return {
        ...state,
        coOwners: {
          ...state.coOwners,
          pendingConsensusActions: action.payload.pendingConsensusActions,
          isGettingPendingConsensusActions: false
        }
      };
    case GET_PENDING_CONSENSUS_ACTIONS_REJECTED:
      return {
        ...state,
        coOwners: {
          ...state.coOwners,
          isGettingPendingConsensusActions: false
        }
      };
    default:
      return state;
  }
}
