//@ts-nocheck
import { RootState } from "../reducers";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  CommentType,
  FormSectionStepConfirmationRequestType,
  FormSectionStepType, StepStatusType
} from "src/components/shared/types";
import Api from "src/services/Api";
import {
  Page,
  GovernancePermission,
  DashboardPermission,
  Permissions,
} from "src/utils/helpers/permissions";
import { updatePermissions, updateSession } from "../actions/auth";
import {
  refreshFirstPageCommentsSuccess,
  setCommentsDrawer,
  setFullPageLoading,
  setMenuDrawer,
  setPopoverGuide,
} from "../actions/global";
import {
  setIsStepLoading,
  navigateToSectionIndexRejected,
  navigateToSectionIndexSuccess,
  setCurrentSection,
  setCurrentStepStatusRejected,
  setCurrentStepStatusSuccess,
  setEditionMode,
  setSections,
  confirmStepRejected,
  confirmStepSuccess,
  NavigateToSectionIndexAction,
  SetCurrentStepStatusAction,
  SET_CURRENT_STEP_STATUS,
  CONFIRM_STEP,
  NAVIGATE_TO_SECTIONS_INDEX,
  setFieldsValidation,
  setStepAnswer,
  setStepValidation,
  CONDITIONAL_CHECKBOX,
  ConditionalCheckboxAction,
  navigateToSectionIndex,
  CANCEL_STEP_EDITION,
  resetStepAnswer,
  CONDITIONAL_SWITCH,
  ConditionalSwitchAction,
  SET_AGREEMENT_SECTIONS_STATUS,
  setAgreementSectionStatusSuccess,
  setAgreementSectionStatusRejected,
  SET_AGREEMENT_STEPS_STATUS,
  SetAgreementStepsStatusAction,
  setAgreementStepStatusRejected,
  setAgreementStepStatusSuccess,
  setAllStepsCompleted,
  ConfirmStepAction,
  SetAgreementSectionsStatusAction,
  UPDATE_AGREEMENT_STATUS,
  setAgreementStepStatus,
  setCurrentStepStatus, userCompletedGovernance
} from "../actions/governance";
import {
  getFieldsValidation,
  getInitialFieldsOptions,
  getNextSection,
  getStepValidation,
} from "src/utils/helpers/governance";
import { Pagination, Session, UserAccountDto } from "src/services/Api/types";
import {
  ErrorMessages,
  FormSectionStepCodes,
  PopoverGuideType,
  ToastType,
} from "src/components/shared/enums";
import { hideModal, showModal } from "../actions/modal";
import {
  MODAL_CONFIRMATION,
  MODAL_TOAST,
} from "src/components/modals/modalTypes";
import { updateSessionSaga } from "./auth";
import { setModalNotifications } from "../actions/notifications";
import { initialState } from "../reducers/global";
import { getCOAStatePermission } from "src/utils/helpers/getCOAStatePermission";
import { getSectionCodeFromStepCode } from "src/utils/helpers/helpers";
import { captureMessage } from "@sentry/react";

export default function* governanceSaga() {
  yield takeLatest(NAVIGATE_TO_SECTIONS_INDEX, navigateToSectionIndexSaga);
  yield takeLatest(SET_CURRENT_STEP_STATUS, setCurrentStepStatusSaga);
  yield takeLatest(CONFIRM_STEP, confirmStepSaga);
  yield takeLatest(CONDITIONAL_CHECKBOX, checkboxConditionallySaga);
  yield takeLatest(CONDITIONAL_SWITCH, switchConditionallySaga);
  yield takeLatest(CANCEL_STEP_EDITION, cancelStepEditionSaga);
  yield takeLatest(UPDATE_AGREEMENT_STATUS, updateAgreementStatusSaga);
  yield takeLatest(
    SET_AGREEMENT_SECTIONS_STATUS,
    setAgreementSectionStatusSaga
  );
  yield takeLatest(SET_AGREEMENT_STEPS_STATUS, setAgreementStepStatusSaga);
}

function* cancelStepEditionSaga() {
  try {
    const currentSectionStep: FormSectionStepType = yield select(
      ({ governance }: RootState) => governance.currentSectionStep
    );
    const stepAnswer: FormSectionStepConfirmationRequestType = yield select(
      ({ governance }: RootState) => governance.stepAnswer
    );

    // Get current page
    const page: Page = yield select(({ auth }: RootState) => auth.page);
    const firstFlowPass: boolean = yield select(
      ({ auth }: RootState) => auth.session?.group?.firstFlowPass
    );
    const permission =
      page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;

    // Undo changes
    yield put(resetStepAnswer());

    // Reset validation
    yield put(setFieldsValidation());
    yield put(setStepValidation());

    // Validate old fields
    const fieldsValidation = getFieldsValidation(
      currentSectionStep.formSectionStepFields,
      stepAnswer
    );
    yield put(setFieldsValidation(fieldsValidation));

    // Validate old step
    const stepValidation = getStepValidation(
      fieldsValidation,
      currentSectionStep,
      stepAnswer
    );
    yield put(setStepValidation(stepValidation));

    // Back to status
    if (!firstFlowPass) {
      yield put(setEditionMode(false));
      yield put(
        updatePermissions({
          page,
          permission: permission.STEPS_STATUS,
        })
      );
    } else
      yield put(
        setEditionMode(currentSectionStep.stepConfirmedBy?.length === 0)
      );
  } catch (error) {
    captureMessage(JSON.stringify(error))
  } finally {
    const isModalOpened: boolean = yield select(
      ({ modal }: RootState) => modal.confirmation.isOpen
    );
    if (isModalOpened) yield put(hideModal(MODAL_CONFIRMATION));
  }
}

function* navigateToSectionIndexSaga({
  payload,
}: NavigateToSectionIndexAction) {
  try {
    // Set loadings
    if (payload.isFullPageLoading) yield put(setFullPageLoading(true));
    yield put(setIsStepLoading(true));

    // Get sections data
    const { data } = yield call(Api.getGovernanceSections);

    // Set sections data
    yield put(setSections(data.sections));

    // Set current section
    if (payload.section)
      yield put(setCurrentSection(data.sections[payload.section?.index - 1]));
    else yield put(setCurrentSection(data.sections[0]));

    // Set edition mode
    yield put(setEditionMode(false));

    // Get current page
    const page: Page = yield select(({ auth }: RootState) => auth.page);
    const permission =
      page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;

    // Navigate to index
    yield put(
      updatePermissions({
        page,
        permission: permission.SECTIONS_INDEX,
      })
    );

    //Success
    yield put(navigateToSectionIndexSuccess());
  } catch (error) {
    captureMessage(JSON.stringify(error))
    yield put(navigateToSectionIndexRejected());
  } finally {
    yield put(setIsStepLoading(false));
    if (payload.isFullPageLoading) yield put(setFullPageLoading(false));
  }
}

function* setAgreementSectionStatusSaga({
  payload,
}: SetAgreementSectionsStatusAction) {
  try {
    // Set loadings
    if (payload) yield put(setFullPageLoading(true));

    // Get sections status data
    const { data } = yield call(Api.getAgreementSectionsStatus);
    yield put(setSections(data.agreementSectionStatus.sections));
    // Set sections status data
    yield put(setAgreementSectionStatusSuccess(data.agreementSectionStatus));
  } catch (error) {
    captureMessage(JSON.stringify(error))
    yield put(setAgreementSectionStatusRejected());
    yield call(updateSessionSaga, updateSession(false, true, true));
  } finally {
    if (payload) yield put(setFullPageLoading(false));
  }
}
function* setAgreementStepStatusSaga({
  payload,
}: SetAgreementStepsStatusAction) {
  try {
    // Set loadings
    if (payload.isFullPageLoading) yield put(setFullPageLoading(true));

    // Get sections status data
    const { data } = yield call(Api.getAgreementStepsStatus, payload.code);

    // Set sections status data
    yield put(setAgreementStepStatusSuccess(data.agreementStepStatus));

    // Get current step code
    const currentStepCode: string = yield select(({ governance }: RootState) => governance?.currentSectionStep?.code);

    if (currentStepCode) {
      const stepStatus = data.agreementStepStatus.filter((a: StepStatusType) => a.code === currentStepCode)[0]

      if (stepStatus && stepStatus.hasNewComments) {
        const comments: Pagination<CommentType> = yield select(({ global }: RootState) => global?.commentsDrawer?.comments?.value);
        yield put(refreshFirstPageCommentsSuccess(comments, stepStatus.hasNewComments));
      }
    }


  } catch (error) {
    captureMessage(JSON.stringify(error))
    yield put(setAgreementStepStatusRejected());
  } finally {
    if (payload.isFullPageLoading) yield put(setFullPageLoading(false));
  }
}

function* setCurrentStepStatusSaga({
  payload: { isFullPageLoading, isRefreshing, code },
}: SetCurrentStepStatusAction) {
  try {
    // Set loadings
    if (isFullPageLoading) yield put(setFullPageLoading(true));
    yield put(setIsStepLoading(true));

    // Get current step code
    const currentStepCode: string = yield select(
      ({ governance }: RootState) => governance?.currentSectionStep?.code
    );

    // Get current page permissions
    const permissions: Permissions = yield select(
      ({ auth }: RootState) => auth.permissions
    );
    const page = permissions[Page.GOVERNANCE]
      ? Page.GOVERNANCE
      : Page.DASHBOARD;
    const permission =
      page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;

    // Get step data
    const { data } = yield call(
      Api.getGovernanceStepByCode,
      code,
      !isRefreshing ? currentStepCode : undefined
    );

    if (data.modalNotifications) {
      yield put(setModalNotifications(data.modalNotifications));
      yield put(
        updatePermissions({
          page,
          permission: permission.SECTIONS_STATUS,
        })
      );
    } else {
      if (!isRefreshing) {
        // Close comments
        yield put(setCommentsDrawer({ isOpened: false }));
        // Clear comments
        yield put(
          refreshFirstPageCommentsSuccess(
            initialState.commentsDrawer.comments.value,
            !!data.sectionStep?.formSectionStepCommentsNotRead
          )
        );
      }

      // Set answers from received data
      const stepAnswer = {
        sectionStepId: data.sectionStep?.id,
        fields: getInitialFieldsOptions(
          data.sectionStep?.formSectionStepFields
        ),
      };
      yield put(setStepAnswer(stepAnswer));

      // Reset validation
      yield put(setFieldsValidation());
      yield put(setStepValidation());

      // Validate new fields
      const fieldsValidation = getFieldsValidation(
        data.sectionStep.formSectionStepFields,
        stepAnswer
      );
      yield put(setFieldsValidation(fieldsValidation));

      // Validate step
      const stepValidation = getStepValidation(
        fieldsValidation,
        data.sectionStep,
        stepAnswer
      );
      yield put(setStepValidation(stepValidation));
      if (!isRefreshing) {
        // Navigate to dynamic steps
        yield put(
          updatePermissions({
            page,
            permission: permission.SECTION_STEPS,
          })
        );

        // Set menu
        yield put(setMenuDrawer(false));

        // Show popover guide
        const userId: string = yield select(
          ({ auth }: RootState) => auth.session?.user?.id
        );
        if (
          data.sectionStep.isFirstStepVisited &&
          !data.sectionStep.stepConfirmedBy
            .map((user: UserAccountDto) => user.id)
            .includes(userId)
        )
          yield put(setPopoverGuide(PopoverGuideType.Edit));
        else yield put(setPopoverGuide(null));
      }
      // Success
      yield put(
        setCurrentStepStatusSuccess({
          currentSectionStep: data.sectionStep,
          previousSectionStep: data.sectionStep.previousStepCode,
          allStepsCompleted: data.allStepsCompleted,
        })
      );

      // TODO: Move to use hooks
      // segmentPage(data.sectionStep.code, window.location.pathname);

      if (!isRefreshing) {
        // Set edition mode
        yield put(
          setEditionMode(data.sectionStep.stepConfirmedBy?.length === 0)
        );
      }
      // Update current section
      const sectionCode = getSectionCodeFromStepCode(
        code as FormSectionStepCodes
      );
      yield put(setAgreementStepStatus(sectionCode, false));
    }
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(setCurrentStepStatusRejected());
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
  } finally {
    yield put(setIsStepLoading(false));
    if (isFullPageLoading) yield put(setFullPageLoading(false));
  }
}

function* confirmStepSaga({ payload }: ConfirmStepAction) {
  try {
    // Set loadings
    yield put(setIsStepLoading(true));

    // Get current page
    const page: Page = yield select(({ auth }: RootState) => auth.page);
    const permission =
      page === Page.GOVERNANCE ? GovernancePermission : DashboardPermission;
    const answer: FormSectionStepConfirmationRequestType = yield select(
      (state: RootState) => state.governance.stepAnswer
    );

    // Send Answer
    const { data } = yield call(Api.submitGovernanceFormsStep, answer);

    // On governance flow if some steps were skipped a modal is shown before passing to the next section
    if (data.modalNotifications) {
      yield put(setModalNotifications(data.modalNotifications));
      yield put(
        updatePermissions({
          page,
          permission: permission.SECTIONS_STATUS,
        })
      );
    } else {
      // Keep on the same step
      if (payload.keepOnTheSameStep) {
        yield put(setEditionMode(false));
        yield put(setCommentsDrawer({ isOpened: false }));
        if (payload.onNextFunction) payload.onNextFunction();
        else {
          const { currentSectionStep } = yield select(
            ({ governance }: RootState) => governance
          );
          yield call(
            setCurrentStepStatusSaga,
            setCurrentStepStatus(currentSectionStep.code, false, true)
          );
        }
      } else {
        yield put(setEditionMode(data.next?.stepConfirmedBy?.length === 0));
        yield put(setCommentsDrawer({ isOpened: false }));

        const session: Session = yield select(
          (state: RootState) => state.auth.session
        );

        if (data.next) {
          const isNextStepConfirmedByUser = data.next?.stepConfirmedBy?.find(
            (coOwner: UserAccountDto) => coOwner.id === session.user?.id
          );
          // Move to the step status
          if (isNextStepConfirmedByUser) {
            const { currentSectionStep } = yield select(
              ({ governance }: RootState) => governance
            );
            // Update current section
            const sectionCode = getSectionCodeFromStepCode(
              currentSectionStep.code as FormSectionStepCodes
            );
            yield call(
              setAgreementStepStatusSaga,
              setAgreementStepStatus(sectionCode, false)
            );
            yield put(
              updatePermissions({
                page,
                permission: permission.STEPS_STATUS,
              })
            );
          }
          // Move to the next step
          else {
            // Update current step
            yield put(
              setCurrentStepStatusSuccess({
                currentSectionStep: data.next,
                previousSectionStep: data.previous,
                allStepsCompleted: data.allStepsCompleted,
              })
            );

            // TODO: Move to use hooks
            // segmentPage(data.next.code, window.location.pathname);

            // Save comments
            /***** Fix */
            // yield put(
            //   refreshFirstPageCommentsSuccess(
            //     initialState.commentsDrawer.comments.value,
            //     !!data.sectionStep?.formSectionStepCommentsNotRead
            //   )
            // );
            yield put(
              refreshFirstPageCommentsSuccess(
                initialState.commentsDrawer.comments.value,
                !!data.next?.formSectionStepCommentsNotRead
              )
            );            
            /*******************/

            // Set answers from received data
            const stepAnswer = {
              sectionStepId: data.next.id,
              fields: getInitialFieldsOptions(data.next.formSectionStepFields),
            };
            yield put(setStepAnswer(stepAnswer));

            // Reset validation
            yield put(setStepValidation());
            yield put(setFieldsValidation());

            // Validate new fields
            const fieldsValidation = getFieldsValidation(
              data.next.formSectionStepFields,
              stepAnswer
            );
            yield put(setFieldsValidation(fieldsValidation));

            // Validate step
            const stepValidation = getStepValidation(
              fieldsValidation,
              data.next,
              stepAnswer
            );
            yield put(setStepValidation(stepValidation));
          }
        }
        // If it is the last step of the section
        else {
          if (data.allStepsCompleted) {
            if (session.user?.hasCompletedCoAgreement) {
              yield put(
                updatePermissions({
                  page,
                  permission: permission.STEPS_STATUS,
                })
              );

            } else {
              const { currentSection, sections } = yield select(
                ({ governance }: RootState) => governance
              );
              const nextSection = getNextSection(sections, currentSection);
              if (nextSection) {
                // Navigate to section index
                yield call(
                  navigateToSectionIndexSaga,
                  navigateToSectionIndex(nextSection, false)
                );
              } else {
                // Update session
                yield call(
                  updateSessionSaga,
                  updateSession(false, false, false)
                );
                yield put(
                  updatePermissions({
                    page,
                    permission: permission.SECTIONS_STATUS,
                  })
                );
                // Success
                yield put(confirmStepSuccess());
                return;
              }
            }
          } else {
            yield put(setAllStepsCompleted(data.allStepsCompleted));
          }

          yield put(userCompletedGovernance())
        }
      }
      // Update session
      if (payload.isApplyChanges)
        yield call(updateSessionSaga, updateSession(false, false, false));
    }
    // Success
    yield put(confirmStepSuccess());
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(confirmStepRejected());
    if (error.status === 426)
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
  } finally {
    const isModalOpened: boolean = yield select(
      ({ modal }: RootState) => modal.confirmation.isOpen
    );
    if (isModalOpened) yield put(hideModal(MODAL_CONFIRMATION));
    yield put(setIsStepLoading(false));
  }
}

function* checkboxConditionallySaga({ payload }: ConditionalCheckboxAction) {
  try {
    const currentSectionStep: FormSectionStepType = yield select(
      ({ governance }: RootState) => governance.currentSectionStep
    );
    let stepAnswer: FormSectionStepConfirmationRequestType = yield select(
      ({ governance }: RootState) => governance.stepAnswer
    );
    const isChecked =
      stepAnswer.fields[payload].sectionStepFieldInput === "true";
    const stepField = currentSectionStep.formSectionStepFields[payload];
    const newCheckboxAnswer = {
      sectionStepFieldId: stepField.id,
      sectionStepFieldInput: isChecked ? "false" : "true",
      sectionStepFieldOptionId: null,
    };
    if (!isChecked) {
      // // Overwrite options
      const fields =
        stepField.defaultCheckboxOptions?.map((x) => ({
          sectionStepFieldId: x.formSectionStepFieldId,
          sectionStepFieldOptionId: x.formSectionStepFieldOptionId,
          sectionStepFieldInput: null,
        })) || [];
      stepAnswer = {
        sectionStepId: currentSectionStep.id,
        fields: [newCheckboxAnswer, ...fields],
      };
    }
    stepAnswer.fields[payload] = newCheckboxAnswer;
    yield put(setStepAnswer(stepAnswer));
  } catch (error) {
    captureMessage(JSON.stringify(error))
  }
}

function* switchConditionallySaga({ payload }: ConditionalSwitchAction) {
  try {
    const currentSectionStep: FormSectionStepType = yield select(
      ({ governance }: RootState) => governance.currentSectionStep
    );
    let stepAnswer: FormSectionStepConfirmationRequestType = yield select(
      ({ governance }: RootState) => governance.stepAnswer
    );
    const { index, optionId } = payload;
    const stepFieldCheckbox = currentSectionStep.formSectionStepFields[0];
    stepAnswer.fields[index].sectionStepFieldOptionId = optionId;
    const defaultCheckboxOptions =
      stepFieldCheckbox.defaultCheckboxOptions || [];
    const conditionalFields = stepAnswer.fields.slice(1);
    const everyChecked = conditionalFields.every(
      (x, i) =>
        defaultCheckboxOptions[i].formSectionStepFieldOptionId ===
        x.sectionStepFieldOptionId
    );

    const newCheckboxAnswer = {
      sectionStepFieldId: stepFieldCheckbox.id,
      sectionStepFieldInput: !everyChecked ? "false" : "true",
      sectionStepFieldOptionId: null,
    };
    stepAnswer.fields[0] = newCheckboxAnswer;
    yield put(setStepAnswer(stepAnswer));
  } catch (error) {
    captureMessage(JSON.stringify(error))
  }
}

function* updateAgreementStatusSaga() {
  try {
    yield call(updateSessionSaga, updateSession(false, false, false));
    const user: UserAccountDto = yield select(
      ({ auth }: RootState) => auth.session?.user
    );
    const page: Page = yield select(({ auth }: RootState) => auth.page);
    yield put(
      updatePermissions({
        page,
        permission: getCOAStatePermission(user, page),
      })
    );
  } catch (error) {
    captureMessage(JSON.stringify(error))
  }
}
