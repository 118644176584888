import { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "src/components/EmptyState";
import Spinner from "src/components/Spinner";
import { NotificationCode } from "src/services/Api/enums";
import {
  getNextPageNotifications,
  getNotifications,
  setNotificationsDrawer,
} from "src/store/actions/notifications";
import { RootState } from "src/store/reducers";
import useSwipe from "src/utils/customHooks/useSwipe";
import RightDrawer from "../RightDrawer";
import Notification from "./Notification";

function NotificationsDrawer() {
  const {
    notifications,
    notificationsDrawer: { isHidden, isOpened },
  } = useSelector(({ notifications }: RootState) => notifications);
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement | null>(null);

  const { onTouchEnd, onTouchMove, onTouchStart, swipeDistance } = useSwipe({
    divRef: divRef,
    onSwipeDown: () => dispatch(getNotifications(true)),
    swipeThreshold: 50,
  });

  const fetchNextPage = () =>
    notifications.value.nextPage && dispatch(getNextPageNotifications());

  if (isHidden) return <></>;

  return (
    <RightDrawer isOpened={isOpened}>
      <div className="fixed-content">
        <div className="header">
          <p className="title">Notifications</p>
          <i
            className="icon-cross"
            onClick={() => dispatch(setNotificationsDrawer(false))}
          />
        </div>
      </div>
      <div
        id="notifications-scroll-content"
        className="notifications-scroll-content"
        ref={divRef}
        style={{
          paddingTop: `${swipeDistance > 100 ? 100 : swipeDistance}px`,
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {notifications.value.data === null ? (
          notifications.isGettingNotifications ? (
            <Spinner />
          ) : (
            <EmptyState
              title="No notifications yet"
              iconClass="icon-check-circled"
            />
          )
        ) : notifications.value.data.length !== 0 ? (
          <InfiniteScroll
            className="notifications-infinite-scroll"
            dataLength={notifications.value.data.length}
            next={fetchNextPage}
            hasMore={notifications.value.nextPage !== null}
            loader={notifications.isGettingNextPage && <Spinner />}
            scrollableTarget="notifications-scroll-content"
          >
            {notifications.showSwipeLoader && <Spinner />}
            {notifications.value.data.map((notification, index) => (
              <Notification
                key={index}
                code={notification.code}
                initials={notification.initials}
                documentId={notification.documentId}
                iconClass={
                  notification.code ===
                    NotificationCode.AgreementReached_CoOwnership ||
                  notification.code === NotificationCode.AgreementReached_MOA ||
                  notification.code ===
                    NotificationCode.DocumentCommentCreated ||
                  notification.code ===
                    NotificationCode.DocumentUpdatedConsensusAchieved ||
                  notification.code ===
                    NotificationCode.DocumentUploadedConsensusAchieved ||
                  notification.code ===
                    NotificationCode.AgreementUpdateReached_CoOwnership ||
                  notification.code ===
                    NotificationCode.AgreementUpdateReached_MOA
                    ? "icon-document-bold"
                    : "icon-cobuy"
                }
                text={notification.text}
                createdAt={notification.createdAt}
                isRead={notification.isNotificationRead}
                redirect={notification.redirect}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyState
            title="No notifications yet"
            iconClass="icon-check-circled"
          />
        )}
      </div>
    </RightDrawer>
  );
}

export default NotificationsDrawer;
