import { FieldsValidationType } from "src/store/actions/governance";
import {
  transformStepDataToAnswerData,
  validateField,
} from "src/utils/helpers/governance";
import { GovernanceFormSectionStepField } from "../shared/enums";
import {
  FormSectionStepConfirmationRequestType,
  FormSectionStepType,
} from "../shared/types";

export const simpleSwitchOnChange = (
  currentSectionStep: FormSectionStepType,
  stepAnswer: FormSectionStepConfirmationRequestType,
  fieldsValidation: FieldsValidationType
) => {
  let _stepAnswer = stepAnswer;
  let _fieldsValidation = fieldsValidation;

  const numberInputs = currentSectionStep?.formSectionStepFields.filter(
    (field) => field.type === GovernanceFormSectionStepField.NumberInput
  );
  numberInputs?.forEach((numberInput) => {
    _fieldsValidation = {
      ..._fieldsValidation,
      [numberInput.id]: validateField(
        null,
        null,
        numberInput.formSectionStepFieldConstant,
        GovernanceFormSectionStepField.NumberInput
      ),
    };
    _stepAnswer = transformStepDataToAnswerData(
      numberInput.id,
      numberInput.index,
      null,
      null,
      _stepAnswer
    );
  });

  return { _stepAnswer, _fieldsValidation };
};
