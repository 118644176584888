import { takeLatest, call, put } from "redux-saga/effects";
import { MODAL_TOAST } from "src/components/modals/modalTypes";
import { ErrorMessages, SegmentEvents, ToastType } from "src/components/shared/enums";
import Api from "src/services/Api";
import { getFormatAddress } from "src/utils/helpers/helpers";
import { updateSessionSuccess } from "../actions/auth";
import { showModal } from "../actions/modal";
import {
  UpdateUserInfoAction,
  updateUserInfoRejected,
  updateUserInfoSuccess,
  UPDATE_USER_INFO,
} from "../actions/profile";
import { analyticsIdentifyUser, analyticsTrackEvent } from "../../components/SegmentAnalytics/utils";
import { captureMessage } from "@sentry/react";

export default function* profileSaga() {
  yield takeLatest(UPDATE_USER_INFO, updateUserInfoSaga);
}

function* updateUserInfoSaga({ payload: { user } }: UpdateUserInfoAction) {
  try {
    const userInfo = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      middleInitial: user.middleInitial,
      suffix: user.suffix,
      phoneNumber: user.phoneNumber,
      address: getFormatAddress(user.address),
    };

    const {
      data: { success, ...session },
    } = yield call(Api.updateUserInfo, userInfo);
    yield put(updateSessionSuccess(session));
    yield put(
      showModal(MODAL_TOAST, {
        message: "Your profile data was successfully updated",
      })
    );
    yield call(analyticsIdentifyUser, session)
    yield call(analyticsTrackEvent, session, SegmentEvents.UserUpdatedProfile)
    yield put(updateUserInfoSuccess());
  } catch (error: any) {
    captureMessage(JSON.stringify(error))
    yield put(updateUserInfoRejected());
    if (error.status === 426) {
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.PaymentError,
          type: ToastType.Info,
          autoDeleteTime: 10000,
        })
      );
    } else
      yield put(
        showModal(MODAL_TOAST, {
          message: ErrorMessages.GenericError,
          type: ToastType.Error,
        })
      );
  }
}
