type ProgressBarProps = { progress: number | null; show: boolean };

function ProgressBar({ progress, show }: ProgressBarProps) {
  if (progress === null) return <></>;

  return (
    <div className={`progress-bar-container ${show ? "show" : ""}`}>
      <div
        className="progress-bar"
        style={{ width: `${progress > 100 ? 100 : progress}%` }}
      ></div>
    </div>
  );
}

export default ProgressBar;
