import { useDispatch, useSelector } from "react-redux";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import { accessGovernance } from "src/store/actions/auth";
import { RootState } from "src/store/reducers";

function AccessGovernance() {
  let dispatch = useDispatch();
  const { isAccessingGovernance } = useSelector(
    (state: RootState) => state.auth
  );
  return (
    <InitialStep
      title="Congratulations! Your"
      boldTitle="subscription payment"
      titleAfterBold="was successful"
      primaryButtonText="Next"
      onPrimaryButtonClick={() =>
        !isAccessingGovernance && dispatch(accessGovernance())
      }
      iconClass="icon-check-circled"
      isPrimaryButtonLoading={isAccessingGovernance}
    />
  );
}

export default AccessGovernance;
